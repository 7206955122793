import React, { useState } from 'react';
import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton, TextField, Button,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import { formatBoolean } from '../common/formatter';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';
import { api_call, console_log, empty } from '../helpers/untils';
import { useSelector } from 'react-redux';
import { getIsAdmin } from '../common/selectors';
import CreditLogsModal from '../settings/CreditLogsModal';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  creditValue: {
    cursor: 'pointer'
  },
}));

const UnmatchedDevicesView = ({ updateTimestamp, onMenuClick }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [items, setItems] = useState([]);

  useEffectAsync(async () => {
    const response = await api_call('/api/unmatched-devices');
    if (response.ok) {
      const itemList = await response.json()
      setItems(itemList);
      setFilteredItems(itemList);

    }
  }, [updateTimestamp]);

  ////////////////////////////////credit log modal////////////////////////////////
  const [deviceItem, setDeviceItem] = useState({});
  const showCreditLogsPage = (item) => {
    setDeviceItem(item);
    setCreditModalOpen(true)
  }
  const [creditModalOpen, setCreditModalOpen] = useState(false);
  ////////////////////////////////////////////////////////////////////////////////

  const [filteredItems, setFilteredItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const onChangeSearchText = (e) => {
    const filter = e.target.value
    setSearchText(filter)
    const filtered_items = items.filter(
      (item) => {
        let t_separator = "   "
        let subjectText = item.emnifyName + t_separator + item.uniqueId + t_separator + item.iccid
        return subjectText.toLowerCase().includes(filter.toLowerCase())
      }
    );

    setFilteredItems(filtered_items);
    //console_log("search, filtered_items:::", value, filtered_items)
  }


  return (
    <TableContainer>
      <div className='pull-right mb-3' style={{ float: 'right' }}>
        <TextField
          margin="normal"
          value={searchText}
          onChange={(e) => onChangeSearchText(e)}
          label={`Search...`}
          inputProps={{ type: 'text' }}
        />
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.columnAction} />
            <TableCell>Emnify/Hologram Name</TableCell>
            <TableCell>MooveTrax ID</TableCell>
            <TableCell>ICCID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredItems.map((item) => (
            <TableRow key={item.id}>
              <TableCell className={classes.columnAction} padding="none">
                <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id)}>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
              <TableCell>{item.emnifyName}</TableCell>
              <TableCell>{item.uniqueId}</TableCell>
              <TableCell>{item.iccid}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <CreditLogsModal
        open={creditModalOpen}
        setOpen={setCreditModalOpen}
        item={deviceItem}
        setItem={setDeviceItem}
      />

    </TableContainer>
  );
};

const UnmatchedDevicesPage = () => {
  const isAdmin = useSelector(getIsAdmin);
  return (
    <>
      {
        (isAdmin) ? (<>
          <OptionsLayout>
            <EditCollectionView content={UnmatchedDevicesView} editPath="/device" endpoint="devices" disableAdd={true} />
          </OptionsLayout>
        </>) :
          (<></>)
      }
    </>
  )
}

export default UnmatchedDevicesPage;
