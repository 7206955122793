import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton, Button, Box, CircularProgress,
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';


import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import { formatBoolean } from '../common/formatter';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';

import moment from 'moment';
import { api_call, console_log, empty } from '../helpers/untils';
import CoHostDevicesPage from './CoHostDevicesPage';
import { Link, useHistory } from 'react-router-dom';
import { layoutActions } from '../store';
import { sleep } from '../helpers/misc';

const useStyles = makeStyles((theme) => ({
  rotateIcon: {
    animation: "$spin 2s linear infinite"
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)"
    },
    "100%": {
      transform: "rotate(0deg)"
    }
  },
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  radioGroupBox: {
    width: '100%'
  },
  radioGroupListBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  inviteButton: {
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 20,
    paddingRight: 20,
    height: 36,
    textTransform: 'none',
  },
  coHostContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  coHostButtonBox: {
    width: 120,
    height: 46,
    display: 'inline-flex'
  },
}));

const TuroTripsView = (props) => {
  const { updateTimestamp, onMenuClick } = props
  const classes = useStyles();
  const t = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory()
  const user = useSelector((state) => state.session.user);

  useEffect(() => {
    startCheckTuroTripQueue()
    addTuroCalQueue()
  }, [])

  const addTuroCalQueue = async () => {
    const response = await api_call(`/api/turo-trip/add-turo-cal-queue`);
    if (response.ok) {
      const parseResult = await response.json();
      console_log("parseResult:::", parseResult)
    }
  }

  const onClickDeviceItem = (deviceId) => {
    console.log('deviceId:::', deviceId)
    if (deviceId) {
      dispatch(layoutActions.setCurrentDeviceId({ id: deviceId }));
      history.push('/main')
    }
  }

  const loadingTripIdListRef = useRef({})
  const checkTripIsLoading = (tripId) => {
    const loadingTripIdList = loadingTripIdListRef.current
    if (loadingTripIdList && loadingTripIdList[tripId]) {
      return true
    }
    return false
  }
  const addItemFromTripLoadingList = (tripId, queueId) => {
    const loadingTripIdList = loadingTripIdListRef.current
    if (loadingTripIdList && loadingTripIdList[tripId]) {
      return true
    } else {
      loadingTripIdList[tripId] = queueId
      loadingTripIdListRef.current = loadingTripIdList
      return true
    }
  }
  const removeItemFromTripLoadingList = (tripId) => {
    const loadingTripIdList = loadingTripIdListRef.current
    if (loadingTripIdList && loadingTripIdList[tripId]) {
      delete loadingTripIdList[tripId]
      loadingTripIdListRef.current = loadingTripIdList
    }
    return true
  }
  const updateQueueFromTripLoadingList = (tripId, queueId) => {
    const loadingTripIdList = loadingTripIdListRef.current
    loadingTripIdList[tripId] = queueId
    loadingTripIdListRef.current = loadingTripIdList
    return loadingTripIdListRef
  }
  const getQueueListFromTripLoadingList = () => {
    const queieIdList = []
    const loadingTripIdList = loadingTripIdListRef.current
    for (let k in loadingTripIdList) {
      const queueId = loadingTripIdList[k]
      if (queueId > 0) {
        queieIdList.push(queueId)
      }
    }
    return queieIdList
  }

  const startCheckTuroTripQueue = async () => {
    while (true) {
      const loadingTripIdList = { ...loadingTripIdListRef.current }
      //console_log("current loadingTripIdList:::", loadingTripIdList)
      const queueList = getQueueListFromTripLoadingList()
      if (queueList && queueList.length > 0) {
        await handleCheckTuroTripQueue(queueList)
      }

      await sleep(3 * 1000)  //3 seconds
    }
  }

  const handleCheckTuroTripQueue = async (turo_trip_queue_list) => {
    const url = `/api/turo-trip/check-parse-queue`;
    const payload = {
      turo_trip_queue_ids: turo_trip_queue_list.join(',')
    }

    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      let success_turo_trip_list = responseObj['success_turo_trip_list']
      let failed_turo_trip_list = responseObj['failed_turo_trip_list']
      if (success_turo_trip_list.length > 0 || failed_turo_trip_list.length > 0) {
        for (let k in success_turo_trip_list) {
          const tripId = success_turo_trip_list[k]
          removeItemFromTripLoadingList(tripId)
        }
        for (let k in failed_turo_trip_list) {
          const tripId = failed_turo_trip_list[k]
          removeItemFromTripLoadingList(tripId)
        }

        await reloadTable()
      }
      return true
    } else {
      return false
    }
  }

  const refreshTuroTrip = async (id) => {
    console_log("id:::", id)
    const tripIdIsLoading = checkTripIsLoading(id)
    if (tripIdIsLoading) {
      return false
    } else {
      addItemFromTripLoadingList(id, -1)
    }
    await reloadTable()

    const response = await api_call(`/api/turo-trip/parse-queue/${id}`);
    if (response.ok) {
      const parseResult = await response.json();
      console_log("parseResult:::", parseResult)
      if (parseResult['queue']) {
        // success
        const queueId = parseResult['queue']['id']
        updateQueueFromTripLoadingList(id, queueId)
      } else {
        alert("Failed, Please try back after 5 minutes");
      }
    }
    await reloadTable()
  }

  const getLoadingIconClass = (value) => {
    const loadingTripIdList = { ...loadingTripIdListRef.current }
    //console_log("currentLoadingId, value", loadingTripIdList, value)
    if (loadingTripIdList[value]) {
      return classes.rotateIcon
    } else {
      return ""
    }
  }

  const onClickCheckVin = async (tripId) => {
    const response = await api_call(`/api/turo-trip/get-novin-device/${tripId}`);
    if (response.ok) {
      const apiRes = await response.json();
      console_log("apiRes:::", apiRes)
      const device = apiRes.device
      if (device) {
        goDeviceEditPage(device['id'])
        return true
      }
    }
    alert("Can not find any device which has no vin")
  }

  const goDeviceEditPage = (deviceId) => {
    //return false;
    console_log("deviceId:::", deviceId)
    history.push('/device/' + deviceId)
  }

  const onClickDeleteTripLink = (tripId) => {
    console_log("tripId:::", tripId)
    if (window.confirm("Are you sure you want to delete the link?")) {
      deleteTripLink(tripId)
    }
  }

  const deleteTripLink = async (tripId) => {
    const response = await api_call(`/api/turo-trip/delete-shared-link/${tripId}`);
    if (response.ok) {
      const apiRes = await response.json();
      console_log("apiRes:::", apiRes)
    }
    await reloadTable()
  }

  const columnDefs = [
    {
      name: 'id',
      label: 'id',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{value}</div>
          );
        },
        display: false
      },
    },
    {
      name: 'deviceId',
      label: 'deviceId',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{value}</div>
          );
        },
        display: false
      },
    },
    {
      name: 'url',
      label: 'Url',
      options: {
        display: false,
      }
    },
    {
      name: 'uid',
      label: 'Reservation',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log("tableMeta", tableMeta)
          const turo_url = tableMeta['rowData'][2]
          return (
            <div className='td-div text-emphasis' style={{ maxWidth: '175px' }} title={value}><a href={turo_url} target="_blank" >{value}</a></div>
          );
        },
      },
    },
    {
      name: 'lastmodified',
      label: 'Updated',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{moment(value).format('LLL')}</div>
          );
        },
      },
    },
    {
      name: 'start',
      label: 'Start',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{moment(value).format('LLL')}</div>
          );
        },
      },
    },
    {
      name: 'end',
      label: 'End',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{moment(value).format('LLL')}</div>
          );
        },
      },
    },
    {
      name: 'summary',
      label: 'Summary',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console_log("tableMeta:::", tableMeta)
          const deviceId = tableMeta['rowData'][1]
          return (
            <div className='td-div cursor-pointer' onClick={() => onClickDeviceItem(deviceId)}>{value}</div>
          );
        },
      },
    },
    {
      name: 'parse_result',
      label: 'Parse Result',
      options: {
        display: false
      },
    },
    {
      name: 'location',
      label: 'Location',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const parse_result = tableMeta['rowData'][8]
          const pickup_location = tableMeta['rowData'][16]

          return (
            <div className='td-div text-center'>
              {parse_result === 2 ? <span className='text-danger'>Error</span> : empty(value) ? pickup_location : value}
            </div>
          );
        },
      },
    },
    {
      name: 'primary_driver',
      label: 'Driver',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{value}</div>
          );
        },
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{value}</div>
          );
        },
      },
    },
    {
      name: 'shareUrl',
      label: 'Moovetrax Code',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console_log("tableMeta:::", tableMeta)
          const parse_result = tableMeta['rowData'][8]
          if (parse_result) { //if parse_result is true
            if (value) {
              return (
                <div className='td-div text-emphasis' style={{ maxWidth: '175px' }} title={value}><Link to={`/turo-trip-link/${value}`} target="_blank" >{value}</Link></div>
              )
            } else {
              const turo_logins = tableMeta['rowData'][13]
              if (true || turo_logins && turo_logins.length > 0) {
                return (
                  <div className='td-div text-danger text-emphasis cursor-pointer' style={{ maxWidth: '175px' }} title={`Check VIN`} dataTripId={tableMeta['rowData'][0]} onClick={() => onClickCheckVin(tableMeta['rowData'][0])} >{`Check VIN`}</div>
                )
              } else {
                return (
                  <div className='td-div' title="No login found">{``}</div>
                )
              }
            }
          }
        },
      },
    },
    {
      name: 'turo_logins',
      label: 'turo_logins',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          if (value && value.length > 0) {
            return (
              <div className='td-div'>{value[0]['id']}</div>
            )
          } else {
            return (
              <div className='td-div'>{`No login found`}</div>
            )
          }
        },
        display: false
      },
    },
    {
      name: 'id',
      label: 'Refresh',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console_log("value, tableMeta, updateValue", updateValue)
          return (
            <div className='td-div' dataId={value}>
              <span className='cursor-pointer text-green' onClick={() => refreshTuroTrip(value)}>
                <CachedIcon
                  style={{ color: 'green' }}
                  className={getLoadingIconClass(value)}
                />
              </span>
            </div>
          );
        },
      },
    },
    {
      name: 'id',
      label: 'Action',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          console_log("tableMeta::::", tableMeta)
          const sharedUrl = tableMeta['rowData'][12]
          return (
            <div className='td-div' dataId={value}>
              <span className={`cursor-pointer ${sharedUrl ? '' : 'd-none'} `} title="Delete" onClick={() => onClickDeleteTripLink(value)}>
                <CloseIcon />
              </span>
            </div>
          )
        }
      }
    },
    {
      name: 'pickup_location',
      label: 'pickup_location',
      options: {
        display: false
      },
    },
  ]

  const [page, setPage] = useState(0)
  const [count, setCount] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [sortOrder, setSortOrder] = useState({ name: "start", direction: "asc" })
  const [data, setData] = useState([])
  const [columns, setColumns] = useState(columnDefs)
  const [isLoading, setIsLoading] = useState(false)

  const reloadTable = async () => {
    const filter = {
      filter_date_tpye: value
    }
    await xhrRequest(page, sortOrder, filter)
  }
  useEffect(() => {
    // getData(0);
  }, [])

  const sort = async (page, sortOrder) => {
    const res = await xhrRequest(page, sortOrder);
  };

  // mock async function
  const xhrRequest = async (page, sortOrder = {}, filter_options = {}) => {
    if (empty(user)) {
      return false
    }

    setIsLoading(true);
    let api_url = "/api/turo-trip/get-data-table"
    let post_data = {}
    post_data['page'] = page
    post_data['rowsPerPage'] = rowsPerPage
    post_data['sortOrder'] = sortOrder

    const filterOptions = {
      filter_date_tpye: value,
      ...filter_options,
    }
    if (user.administrator) {
      //filterOptions['userId'] = user.id
    } else {
      filterOptions['userId'] = user.id
    }

    const response = await api_call(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...post_data, ...filterOptions }),
    });
    if (response.ok) {
      const res = await response.json();
      setData(res.data)
      setPage(res.page)
      setSortOrder(sortOrder)
      setIsLoading(false)
      setCount(res.total)
      return res
    } else {
      return false
    }
  }

  const changePage = async (page, sortOrder) => {
    const res = await xhrRequest(page, sortOrder)
  }

  const onChangeRowsPerPage = (r) => {
    setRowsPerPage(r)
  }

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive: 'standard',
    serverSide: true,
    count: count,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [],
    // onChangeRowsPerPage: onChangeRowsPerPage,
    sortOrder: sortOrder,
    download: false,
    viewColumns: false,
    print: false,
    search: false,
    selectableRowsHideCheckboxes: true,
    onTableChange: (action, tableState) => {
      // console.log("action, tableState:::", action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want
      switch (action) {
        case 'changePage':
          changePage(tableState.page, tableState.sortOrder);
          break;
        case 'sort':
          sort(tableState.page, tableState.sortOrder);
          break;
        default:
        //console.log('action not handled.');
      }
    },
  }

  useEffect(() => {
    xhrRequest(0, sortOrder, {})
  }, [user]);

  const [value, setValue] = useState('future');// ('all');

  const handleChange = (event) => {
    let v = event.target.value
    //console_log('v::::', v)
    setValue(v);
    var firstPage = 0;
    setPage(firstPage)

    const sort_order = { ...sortOrder }
    if (v === 'all') {
      sort_order['name'] = "start"
      sort_order['direction'] = "desc"
    }
    else if (v === 'old') {
      sort_order['name'] = "end"
      sort_order['direction'] = "desc"
    }
    else if (v === 'active') {
      sort_order['name'] = "start"
      sort_order['direction'] = "desc"
    }
    else if (v === 'future') {
      sort_order['name'] = "start"
      sort_order['direction'] = "asc"
    }
    xhrRequest(firstPage, sort_order, { filter_date_tpye: v })
  };

  return (
    <div>
      <FormControl className={classes.radioGroupBox}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}

        >
          <div className={classes.radioGroupListBox}>
            <div>
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel value="old" control={<Radio />} label="Old" />
              <FormControlLabel value="active" control={<Radio />} label="Current" />
              <FormControlLabel value="future" control={<Radio />} label="Future" />
            </div>
          </div>

        </RadioGroup>
      </FormControl>

      <div className="mt-3">
        <MUIDataTable
          title={""}
          data={data}
          columns={columns}
          options={options}
          isLoading={isLoading}
        />
      </div>
    </div>
  )

}

const TuroTripsPage = () => {
  return (
    <>
      <OptionsLayout>
        <EditCollectionView content={TuroTripsView} editPath="/trip" endpoint="turo-trips" disableAdd={true} />
      </OptionsLayout>
    </>
  );
};

export default TuroTripsPage;
