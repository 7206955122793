import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions, sessionActions } from '../store';

import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Grid, makeStyles, Typography, FormControlLabel, Checkbox, Box, Link, Modal, TextField, Button, Snackbar,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'
import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';
import SelectField from '../form/SelectField';
import moment from 'moment';
import { api_call, console_log, empty, getLocalTimezone, getTimezoneList, get_data_value, get_utc_timestamp, get_utc_timestamp_ms, isGpsMT3VProtocolDevice, trimPhone } from '../helpers/untils';
import PaypalPaymentModal from './PaypalPaymentModal';
import { DEFAULT_LOW_BATTERY_VOLT, MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID } from '../config/constant';
import { Stack } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';

const TIMEZONE_LIST = getTimezoneList()

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '712px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
    minWidth: '600px'
  },
  formNotifierRect: {
    position: 'relative',
  },
  formNotifierTimezoneRect: {
    position: 'absolute',
    width: '250px',
    top: '15px',
    right: '10px',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkboxBlock: {
    width: 250,
  },
  inputBlock: {
    flex: 1,
  },
  checkboxOnlyBlock: {
    width: 100,
    display: 'none', //'flex'
    justifyContent: 'center',
  },
  formNotifier: {
    display: 'block',
    width: '100%',
    backgroundColor: '#f7f7f7',
    padding: '15px',
    marginBottom: '15px'
  },
  formNotifierLabel: {
    minWidth: '75px'
  },
  formNotifierItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}));


const NotificationForm = (props) => {
  const { open, setOpen, selectedDevice, updateTimestamp, setUpdateTimestamp, setDeviceItem } = props;
  const user = useSelector((state) => state.session.user);

  const firstSetting = useRef(false)

  const classes = useStyles();
  useEffect(() => {
    initNotificationSettings();
  }, [selectedDevice, updateTimestamp])

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [apiLoading, setApiLoading] = useState(false)


  ///////////////////////////////////////////////////////
  const localTimezone = getLocalTimezone()
  const defaultTimezoneOption = {
    id: localTimezone,
    label: localTimezone
  }
  const [timezone, setTimezone] = useState(null);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const initTimezoneOptions = () => {
    let v_options = []
    TIMEZONE_LIST.map((definition) => {
      if (definition.includes("GMT")) {
        v_options.push({
          id: definition,
          label: definition,
        })
      }
    });
    setTimezoneOptions(v_options)
    setTimezone(JSON.parse(selectedDevice?.timezone))
  }
  useEffect(() => {
    if (selectedDevice) {
      initTimezoneOptions()
    }
  }, [selectedDevice])
  const timezoneFilter = createFilterOptions({
    stringify: (option) => option.key + "   " + option.label,
  });

  const getTimezoneLabel = (timezoneInfo, returnType = "text") => {
    if (returnType === "object") {
      return (
        <div>
          <div>{`${timezoneInfo.label}`}</div>
        </div>
      )
    } else {
      return timezoneInfo.label
    }
  }

  const onChangeTimezone = (option) => {
    setTimezone(option);
    handleSaveTimezoneSetting(option)
  }
  const handleSaveTimezoneSetting = async (option) => {

    const url = `/api/devices/notificationTimezone`;
    const payload = {
      user_id: user.id,
      user_name: user.name,
      device_id: selectedDevice['id'],
      timezone: JSON.stringify(option),
    }
    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });


    setApiLoading(false)
    if (response.ok) {
      setToastMessage(`Notification Timezone setting has been updated successfully`);
      setSnackbarOpen(true);
    } else {
      setToastMessage(`Unknown error`);
      setSnackbarOpen(true);
    }
  }

  const getNotificationTypeList = () => {
    const notification_type_list = [
      {
        label: 'Unlock',
        value: 'Unlock',
        desc: "Unlock Command Sent"
      },
      {
        label: 'Lock',
        value: 'Lock',
        desc: "Lock Command Sent"
      },
      {
        label: 'Horn',
        value: 'Horn',
        desc: "Horn Command Sent"
      },
      {
        label: 'Unkill',
        value: 'Unkill',
        desc: "Unkill Command Sent"
      },
      {
        label: 'Kill',
        value: 'Kill',
        desc: "Kill Command Sent"
      },
      {
        label: 'Overspeed',
        value: 'Overspeed',
        desc: "Car Is Speeding"
      },
      // {
      //   label: 'Geofence',
      //   value: 'Geofence'
      // },
      {
        label: 'Offline',
        value: 'Offline',
        desc: "Device Shows Offline"
      },
      {
        label: 'Smoking',
        value: 'Smoking',
        desc: "Smoke Detected"
      },
      {
        label: 'Over Miles',
        value: 'Overmiles',
        desc: "Alotted Miles Exceeded"
      },
      {
        label: 'Unlocked by Bluetooth',
        value: 'Unlocked_by_Bluetooth',
        desc: ""
      },
      {
        label: 'Locked by Bluetooth',
        value: 'Locked_by_Bluetooth',
        desc: ""
      },
      {
        label: 'Bluetooth On',
        value: 'Bluetooth_On',
        desc: ""
      },
      {
        label: 'Bluetooth Off',
        value: 'Bluetooth_Off',
        desc: ""
      },
      {
        label: 'Low Battery',
        value: 'Low_Battery',
        desc: ""
      },
      {
        label: 'Hood',
        value: 'Hood',
        desc: ""
      },
      {
        label: 'Door',
        value: 'Door',
        desc: ""
      },
    ]
    const uniqueId = selectedDevice.uniqueId
    if (isGpsMT3VProtocolDevice(uniqueId)) {
      notification_type_list.push(
        {
          label: 'Shock',
          value: 'Shock',
          desc: ""
        }
      )
    }
    notification_type_list.push(
      {
        label: 'Device Suspension',
        value: 'Device_Suspension',
        desc: "Device Has Been Suspended"
      }
    )
    notification_type_list.push(
      {
        label: 'Ignition On',
        value: 'Ignition_ACC',
        desc: "Ignition Turned On"
      }
    )
    notification_type_list.push(
      {
        label: 'No Position Update',
        value: 'No_Position_Update',
        desc: "Car Position has not updated in 3+ Hours"
      }
    )
    notification_type_list.push(
      {
        label: 'No Trip Movement',
        value: 'No_Trip_Movement',
        desc: "No Trip Scheduled But Car Is Moving"
      }
    )
    notification_type_list.push(
      {
        label: 'Possible Towing',
        value: 'Possible_Towing',
        desc: "Ignition Is Off But Car Is Moving"
      }
    )
    notification_type_list.push(
      {
        label: 'Severe Weather',
        value: 'Severe_Weather',
        desc: "Severe Weather Expected Within An Hour"
      }
    )
    notification_type_list.push(
      {
        label: 'Late Return',
        value: 'Late_Return',
        desc: "Trip has ended but car is not at return location"
      }
    )
    return notification_type_list
  }
  const notificationTypeList = getNotificationTypeList()

  const [notificationSettingList, setNotificationSettingList] = useState({});

  const initNotificationSettings = () => {
    let notificationSettings = selectedDevice.notificationSettings
    if (!empty(notificationSettings)) {
      notificationSettings = JSON.parse(notificationSettings)
      firstSetting.current = false
    } else {
      notificationSettings = {}
      firstSetting.current = true
    }
    //console.log("firstSetting, notificationSettings:::::", firstSetting.current, notificationSettings)
    setNotificationSettingList(notificationSettings)
  }

  const onChangeNotificationSettingStatus = (type, event) => {
    const settings = { ...notificationSettingList }
    if (empty(settings[type])) {
      settings[type] = {
        status: false,
        email: '',
        post_on_turo: false
      }
    }
    settings[type]['status'] = event.target.checked
    setNotificationSettingList(settings)
  }
  const onChangeNotificationSettingEmail = (type, event) => {
    const settings = { ...notificationSettingList }
    if (false && firstSetting.current) {
      bulkUpdateNotificationSettingEmail(event)
    } else {
      if (empty(settings[type])) {
        settings[type] = {
          status: false,
          email: '',
          post_on_turo: false
        }
      }
      settings[type]['email'] = event.target.value
      setNotificationSettingList(settings)
    }
  }

  const onChangeNotificationSettingValue = (type, event) => {
    const settings = { ...notificationSettingList }
    if (empty(settings[type])) {
      settings[type] = {
        status: false,
        email: '',
        value: '',
        post_on_turo: false,
      }
    }
    settings[type]['value'] = event.target.value
    setNotificationSettingList(settings)
  }

  const onChangeNotificationSettingPostOnTuro = (type, event) => {
    const settings = { ...notificationSettingList }
    if (empty(settings[type])) {
      settings[type] = {
        status: false,
        email: '',
        post_on_turo: false
      }
    }
    settings[type]['post_on_turo'] = event.target.checked
    setNotificationSettingList(settings)
  }

  const bulkUpdateNotificationSettingEmail = (event) => {
    const val = event.target.value
    const settings = { ...notificationSettingList }
    for (let k in notificationTypeList) {
      const typeInfo = notificationTypeList[k]
      const type = typeInfo['value']
      if (empty(settings[type])) {
        settings[type] = {
          status: false,
          email: val,
          post_on_turo: false
        }
      } else {
        settings[type]['email'] = val
      }
    }
    setNotificationSettingList(settings)
  }

  const handleSaveSetting = async () => {
    console_log("setting data:::", notificationSettingList)

    const url = `/api/devices/notificationSetting`;
    const payload = {
      user_id: user.id,
      user_name: user.name,
      device_id: selectedDevice['id'],
      notificationSettingList: JSON.stringify(notificationSettingList),
    }
    // if (Number(payload['amount']) <= 0) {
    //   setToastMessage(`Invalid amount`);
    //   setSnackbarOpen(true);
    //   return false
    // }
    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    setApiLoading(false)
    if (response.ok) {
      setToastMessage(`Notification setting has been updated successfully`);
      setSnackbarOpen(true);
    } else {
      setToastMessage(`Unknown error`);
      setSnackbarOpen(true);
    }
  }

  const checkPostOnTuroChecked = (itemType) => {
    if (notificationSettingList[itemType.value]) {
      if (notificationSettingList[itemType.value]['post_on_turo']) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  //console.log("user:::", user)
  const yourphonenumber = user.phone ? trimPhone(user.phone) : "yourphonenumber"

  const [showTooltip, setShowTooltip] = useState(true) //todo quan

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        autoHideDuration={1500}
        message={toastMessage}
      />

      <div className={classes.formContainer}>
        <div className={classes.formNotifierRect}>
          <div className={classes.formNotifier}>
            <div className={classes.formNotifierItem}>
              <span className={classes.formNotifierLabel}>{`AT&T:`}</span>
              <span>{`${yourphonenumber}@txt.att.net`}</span>
            </div>
            <div className={classes.formNotifierItem}>
              <span className={classes.formNotifierLabel}>{`T-Mobile:`}</span>
              <span>{`${yourphonenumber}@tmomail.net`}</span>
            </div>
            <div className={classes.formNotifierItem}>
              <span className={classes.formNotifierLabel}>{`Verizon:`}</span>
              <span>{`${yourphonenumber}@vtext.com`}</span>
            </div>
          </div>
          <div className={classes.formNotifierTimezoneRect}>
            <Grid item xs={24} sm={24}>
              <Autocomplete
                onOpen={() => {
                  setTimeout(() => {
                    const optionEl = document.querySelector(
                      `[class="MuiAutocomplete-option"][data-option-index="${TIMEZONE_LIST.indexOf(timezone?.label ?? localTimezone)}"]`
                    );
                    if (optionEl) {
                      optionEl.scrollIntoView();
                    }
                  }, 1);
                }}
                onChange={(_, option) => {
                  console.log("option::::", option)
                  onChangeTimezone(option)
                }}
                filterOptions={(options, params) => {
                  const filtered = timezoneFilter(options, params);
                  return filtered;
                }}
                options={timezoneOptions}
                getOptionLabel={(option) => getTimezoneLabel(option, "text")}
                renderOption={(option) => getTimezoneLabel(option, "text")}
                value={timezone}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} label={`Timezone`} variant="filled" margin="normal" />
                )}
              />
            </Grid>
          </div>
        </div>



        <div className={classes.formRow}>
          <div className={classes.checkboxBlock}>
            <label>Notify</label>
          </div>
          <div className={classes.inputBlock}>
            <label>Send Email to (separate with comma)</label>
          </div>
          <div className={classes.checkboxOnlyBlock}>
            <label>Post on Turo</label>
          </div>
        </div>

        {
          (notificationTypeList.map((itemType, index) => {
            return (
              <div className={classes.formRow} key={index}>
                <div className={classes.checkboxBlock}>
                  <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} sx={{ width: '100%' }} spacing={1}>
                    <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} sx={{ flex: 1 }}>
                      <FormControlLabel
                        control={<Checkbox checked={notificationSettingList[itemType.value] ? notificationSettingList[itemType.value]['status'] : false}
                          onChange={(event) => onChangeNotificationSettingStatus(itemType.value, event)} />}
                        label={itemType.label}
                      />
                      <>
                        {
                          (itemType.value === 'Low_Battery') ? (
                            <>
                              <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1}>
                                <TextField
                                  margin="small"
                                  value={notificationSettingList[itemType.value] ? get_data_value(notificationSettingList[itemType.value], 'value', DEFAULT_LOW_BATTERY_VOLT) : DEFAULT_LOW_BATTERY_VOLT}
                                  onChange={(event) => onChangeNotificationSettingValue(itemType.value, event)}
                                  label=""
                                  variant="filled"
                                  type="number"
                                  style={{ width: '50px' }}
                                />
                                <Typography>v</Typography>
                              </Stack>
                            </>
                          ) : (
                            <></>
                          )
                        }
                      </>
                    </Stack>
                    {
                      (showTooltip && itemType.desc) && (
                        <Box sx={{ paddingRight: '8px' }}>
                          <Tooltip
                            arrow
                            title={itemType.desc}
                          >
                            <InfoIcon color="action" />
                          </Tooltip>
                        </Box>
                      )
                    }
                  </Stack>
                </div>
                <div className={classes.inputBlock}>
                  <TextField
                    margin="small"
                    value={notificationSettingList[itemType.value] ? get_data_value(notificationSettingList[itemType.value], 'email') : ''}
                    onChange={(event) => onChangeNotificationSettingEmail(itemType.value, event)}
                    label=""
                    variant="filled"
                    style={{ width: '100%' }}
                  />
                </div>
                <div className={classes.checkboxOnlyBlock}>
                  <FormControlLabel
                    control={<Checkbox checked={checkPostOnTuroChecked(itemType)}
                      onChange={(event) => onChangeNotificationSettingPostOnTuro(itemType.value, event)} />}
                    label={``}
                  />
                </div>
              </div>
            )
          }))
        }
      </div>

      <div className={classes.buttons} style={{ paddingTop: '15px', maxWidth: '400px', margin: 'auto' }}>
        <Button type="button" color="primary" variant="contained" onClick={() => handleSaveSetting()} disabled={apiLoading}>
          Save
        </Button>
        <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
          Close
        </Button>
      </div>
    </>
  )
}
const MT2VNotificationModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const { open, setOpen, deviceItem } = props;
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (deviceItem && deviceItem.id && open) {
      loadDeviceInfo(deviceItem.id);
    }
  }, [deviceItem, open])

  const loadDeviceInfo = async (deviceId) => {
    const url = `/api/devices/${deviceId}`
    const response = await api_call(url);
    if (response.ok) {
      const device = await response.json()
      setItem(device);
      setUpdateTimestamp(get_utc_timestamp_ms())
    }
  }
  const [updateTimestamp, setUpdateTimestamp] = useState(0)

  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    if (open) {
      dispatch(layoutActions.closeDeviceListMenu());
    }
  }, [open]);
  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />

          <Typography id="modal-modal-title" variant="h6" component="h2">Notification</Typography>

          <div className='mt-3' style={{ maxHeight: "calc(100vh - 340px)", overflow: "auto" }}>
            {
              (!empty(item)) ? (
                <div className=''>
                  <NotificationForm
                    open={open}
                    setOpen={setOpen}
                    selectedDevice={item}
                    updateTimestamp={updateTimestamp}
                    setUpdateTimestamp={setUpdateTimestamp}
                    setDeviceItem={setItem}
                  />
                </div>
              ) : (
                <div className='text-center'>
                  Loading....
                </div>
              )
            }
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MT2VNotificationModal;
