import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions, sessionActions } from '../../store';

import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Grid, Typography, Box, Modal, Snackbar, makeStyles, Divider
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { useParams } from 'react-router-dom';

import moment from 'moment';
import { api_call, console_log, empty, getLocalTimezone, getUnixTimestampFromTimezone, get_utc_timestamp, get_utc_timestamp_ms, trimPhone } from '../../helpers/untils';
import MaintListTable from '../MaintListTable';
import IncomeExpenseBlock from './IncomeExpenseBlock';
import IncomeExpenseChartBlock from './IncomeExpenseChartBlock';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '96%',
  maxWidth: '992px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));

const IncomeExpenseChartModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const { open, setOpen, deviceItem, history } = props;
  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const showToast = (msg) => {
    setToastMessage(msg)
    setSnackbarOpen(true)
  }

  useEffect(() => {
    if (open) {
      dispatch(layoutActions.closeDeviceListMenu());
    }
  }, [open]);
  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />
          <Box sx={{ paddingTop: 0 }}>
            <IncomeExpenseChartBlock
              open={open}
              setOpen={setOpen}
              deviceId={deviceItem?.id}
              deviceItem={deviceItem}
              showToast={showToast}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default IncomeExpenseChartModal;
