import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from '../store';

import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, Typography, Checkbox, Box, Link, Modal, TextField, Button, Snackbar, Accordion, AccordionSummary, AccordionDetails, Grid
} from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';

import moment from 'moment';
import { api_call, console_log, empty, get_utc_timestamp } from '../helpers/untils';
import { DEVICE_TYPE, GPS_ID_PLACEHOLDER, ICCID_PLACEHOLDER, IMAGE_CACHE_VERSION, MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID } from '../config/constant';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '420px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  commandLabelBlock: {
    flex: 1,
  },
  inputRadioBlock: {
    flex: 1,
  },
  inputRadio2Block: {
    flex: 2,
  },
  imgSmall: {
    width: '50px',
    height: '42px',
    marginLeft: '5px',
    marginTop: '16px',
    marginBottom: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'center'
  },
}));

const commandTypeList = [
  {
    label: 'Unlock',
    value: 'Unlock'
  },
  {
    label: 'Lock',
    value: 'Lock'
  },
  {
    label: 'Horn',
    value: 'Horn'
  },
  {
    label: 'Unkill',
    value: 'Unkill'
  },
  {
    label: 'Kill',
    value: 'Kill'
  },
]

const MoovetraxDeviceForm = (props) => {
  const { item, setItem, snackbarOpen, setSnackbarOpen, toastMessage, setToastMessage } = props;
  const user = useSelector((state) => state.session.user);

  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const [apiLoading, setApiLoading] = useState(false)
  const [deviceItem, setDeviceItem] = useState({})

  useEffect(() => {
    if (!empty(item)) {
      setDeviceItem(item)
    }
  }, [item]);

  /////////////////////////////////start iccid modal/////////////////////////////////////////////
  const iccidImage = `/images/iccid/large1.jpg?v=${IMAGE_CACHE_VERSION}`
  const gpsidImage = `/images/gpsid/large1.jpg?v=${IMAGE_CACHE_VERSION}`
  const [isOpen, setIsOpen] = useState('');
  const [currentImage, setCurrentImage] = useState('');
  /////////////////////////////////end iccid modal/////////////////////////////////////////////

  const handleSubmitDevice = async () => {
    if (empty(deviceItem.uniqueId)) {
      setToastMessage("Please enter GPS ID")
      setSnackbarOpen(true)
      return false
    }
    if (empty(deviceItem.iccid)) {
      setToastMessage("Please enter ICCID")
      setSnackbarOpen(true)
      return false
    }
    const url = `/api/devices/submitAdditionMoovetraxDevice`;
    const payload = {
      user_id: user.id,
      device_id: item.id,
      ...deviceItem,
    }

    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    setApiLoading(false)
    if (response.ok) {
      const apiData = await response.json()
      setItem(apiData['device'])

      setToastMessage(`Moovetrax device has been added successfully`);
      setSnackbarOpen(true);
    } else {
      const apiError = response
      console_log("apiError:::", apiError)
      setToastMessage(apiError.error);
      setSnackbarOpen(true);
    }
  }

  return (
    <>
      <Accordion defaultExpanded>
        <>
          <AccordionDetails className={classes.details}>
            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
              <TextField
                required
                fullWidth
                margin="normal"
                value={deviceItem.uniqueId || ''}
                onChange={(event) => setDeviceItem({ ...deviceItem, uniqueId: event.target.value })}
                label="GPS ID"
                variant="filled"
                placeholder={GPS_ID_PLACEHOLDER}
                InputLabelProps={{
                  style: {
                    fontStyle: 'italic',
                  }
                }
                }
              />
              <img className={classes.imgSmall}
                src={`/images/gpsid/small1.jpg?v=${IMAGE_CACHE_VERSION}`}
                alt="How to get GPSID"
                title="How to get GPSID"
                onClick={() => {
                  setCurrentImage(gpsidImage)
                  setIsOpen(true)
                }}
              />
            </Grid>

            <>
              <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  value={deviceItem.iccid || ''}
                  onChange={(event) => setDeviceItem({ ...deviceItem, iccid: event.target.value.substring(0, 9) })}
                  label="ICCID"
                  variant="filled"
                  placeholder={ICCID_PLACEHOLDER}
                  style={{ flex: 1 }}
                  inputProps={{ style: { textTransform: "none" }, type: "number", min: 0 }}
                />
                <img className={classes.imgSmall}
                  src={`/images/iccid/small1.jpg?v=${IMAGE_CACHE_VERSION}`}
                  alt="How to get ICCID"
                  title="How to get ICCID"
                  onClick={() => {
                    setCurrentImage(iccidImage)
                    setIsOpen(true)
                  }}
                />
              </Grid>
            </>

            <div className={classes.buttons} style={{ paddingTop: '15px', width: '100%', margin: 'auto' }}>
              <Button type="button" color="primary" variant="contained" onClick={() => handleSubmitDevice()} disabled={apiLoading}>
                Submit
              </Button>
            </div>
          </AccordionDetails>
        </>
      </Accordion>

      {isOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </>
  );
}

const MoovetraxDeviceControlForm = (props) => {
  const { item, setItem, open, setOpen, snackbarOpen, setSnackbarOpen, toastMessage, setToastMessage } = props;
  const user = useSelector((state) => state.session.user);

  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!empty(item) && !empty(item.commandSetting)) {
      const setting = JSON.parse(item.commandSetting)
      setCommandSetting(setting)
    } else {
      initCommandSetting();
    }
  }, [item]);

  const [apiLoading, setApiLoading] = useState(false)

  const [commandSetting, setCommandSetting] = useState(null);
  const initCommandSetting = () => {
    let setting = {}
    for (let k in commandTypeList) {
      const commandTypeItem = commandTypeList[k]
      const commandType = commandTypeItem['value']
      if (commandType === 'Unlock' || commandType === 'Lock') {
        setting[commandType] = DEVICE_TYPE.SMARTCAR
      } else {
        setting[commandType] = DEVICE_TYPE.MOOVETRAX
      }
    }
    console_log("setting::::::", setting)
    setCommandSetting(setting)

  }
  const onChangeCommandSetting = (commandType, event) => {
    const setting = { ...commandSetting }
    setting[commandType] = event.target.value
    setCommandSetting(setting)
  }

  const handleSaveSetting = async () => {
    if (empty(item.uniqueId) || empty(item.iccid)) {
      setToastMessage(`Please submit Moovetrax device first`);
      setSnackbarOpen(true);
      return false;
    }
    const url = `/api/devices/saveAdditionMoovetraxControlSetting`;
    const payload = {
      user_id: user.id,
      device_id: item.id,
      commandSetting: JSON.stringify(commandSetting),
    }

    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    setApiLoading(false)
    if (response.ok) {
      const apiData = await response.json()
      setItem(apiData['device'])

      setToastMessage(`Moovetrax device has been saved successfully`);
      setSnackbarOpen(true);
    } else {
      const apiError = response
      console_log("apiError:::", apiError)
      setToastMessage(apiError.error);
      setSnackbarOpen(true);
    }

  }

  return (
    <>
      <Accordion defaultExpanded>
        <>
          <AccordionDetails className={classes.details}>
            <div className={classes.formContainer}>
              <div className={classes.formRow}>
                <div className={classes.commandLabelBlock}>
                  <label></label>
                </div>
                <div className={classes.inputRadioBlock}>
                  <label>Built In</label>
                </div>
                <div className={classes.inputRadioBlock}>
                  <label>Moovetrax</label>
                </div>
              </div>

              {
                (commandSetting !== null) && (commandTypeList.map((itemType, index) => {
                  return (
                    <div className={classes.formRow} key={index}>
                      <div className={classes.commandLabelBlock}>
                        <label>{itemType.label}</label>
                      </div>
                      <div className={classes.inputRadio2Block}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={commandSetting[itemType.value]}
                          onChange={(event) => onChangeCommandSetting(itemType.value, event)}
                          style={{ justifyContent: "flex-start", flex: 1 }}
                          row
                        >
                          <div className={classes.inputRadioBlock}>
                            <FormControlLabel value={DEVICE_TYPE.SMARTCAR} control={<Radio />} label="" />
                          </div>
                          <div className={classes.inputRadioBlock}>
                            <FormControlLabel value={DEVICE_TYPE.MOOVETRAX} control={<Radio />} label="" />
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                  )
                }))
              }
            </div>
            <div className={classes.buttons} style={{ paddingTop: '15px', width: '100%', margin: 'auto' }}>
              <Button type="button" color="primary" variant="contained" onClick={() => handleSaveSetting()} disabled={apiLoading}>
                Save
              </Button>
              <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
                Close
              </Button>
            </div>

          </AccordionDetails>
        </>
      </Accordion>
    </>
  );
}

const AdditionMoovetraxModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const { open, setOpen, item, setItem } = props;
  const [updateTimestamp, setUpdateTimestamp] = useState(0)

  useEffect(() => {

  }, [open]);

  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />
          <Typography>Add Moovetrax device</Typography>

          <div className='mt-3'>
            <MoovetraxDeviceForm
              item={item}
              setItem={setItem}
              setOpen={setOpen}
              snackbarOpen={snackbarOpen}
              setSnackbarOpen={setSnackbarOpen}
              toastMessage={toastMessage}
              setToastMessage={setToastMessage}
            />
          </div>
          <div className='mt-5'>
            <MoovetraxDeviceControlForm
              item={item}
              setItem={setItem}
              open={open}
              setOpen={setOpen}
              snackbarOpen={snackbarOpen}
              setSnackbarOpen={setSnackbarOpen}
              toastMessage={toastMessage}
              setToastMessage={setToastMessage}
            />
          </div>

        </Box>
      </Modal>
    </>
  );
};

export default AdditionMoovetraxModal;
