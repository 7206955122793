import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'devices',
  initialState: {
    items: {},
    userDeviceItems: {},
    selectedId: null,
    selectedDeviceName: "", //used for report page dropdown
    selectedDeviceId: null
  },
  reducers: {
    refresh(state, action) {
      state.items = {};
      action.payload.forEach((item) => state.items[item.id] = item);
    },
    update(state, action) {
      // action.payload.forEach((item) => state.items[item.id] = item);
      action.payload.forEach((item) => { if (state.items[item.id]) state.items[item.id] = item });
    },
    refreshUserDeviceItems(state, action) {
      state.userDeviceItems = {};
      action.payload.forEach((item) => state.userDeviceItems[item.id] = item);
    },
    select(state, action) {
      state.selectedId = action.payload.id;
    },
    unselect(state, action) {
      state.selectedId = null;
    },
    remove(state, action) {
      delete state.items[action.payload];
    },
    setSelectedDeviceName(state, action) {
      state.selectedDeviceName = action.payload;
    },
    setSelectedDeviceId(state, action) {
      state.selectedDeviceId = action.payload;
    },
  },
});

export { actions as devicesActions };
export { reducer as devicesReducer };
