import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Button, FormControl, Container, Checkbox, FormControlLabel, Box, Snackbar, useMediaQuery,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

import { sessionActions } from '../store';
import EditAttributesView from '../attributes/EditAttributesView';
import useDeviceAttributes from '../attributes/useDeviceAttributes';
import useUserAttributes from '../attributes/useUserAttributes';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';
import { api_call, calculateDeviceCount, calculateResellerDeviceCount, empty } from '../helpers/untils';
import { getIsAdmin } from '../common/selectors';
import { Stack } from '@mui/material';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    flexDirection: 'column',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
  },
  formSectionContainer: {
    alignItems: 'center',
    marginRight: '16px',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'flex-start',
    },
  },
  formBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  formLabel: {
    paddingLeft: '4px'
  },
  formSubmitButton: {
    marginTop: '24px'
  }
}));

const SettingPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const t = useTranslation();
  const isAdmin = useSelector(getIsAdmin);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const defaultFormData = {
    loggingRawData: "false"
  }
  const [formData, setFormData] = useState(defaultFormData)

  const getPageData = async () => {
    const response = await api_call('/api/setting/get-setting');
    if (response.ok) {
      const form_data = await response.json()
      setFormData({
        ...defaultFormData,
        ...form_data
      });
    }
  }

  useEffect(() => {
    getPageData()
  }, [])

  const onChangeFormData = (field_name, event) => {
    const value = event.target.value
    const form_data = { ...formData }
    form_data[field_name] = value

    setFormData(form_data)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await api_call('/api/setting/update-setting', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      showToast("Success!")
    }
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const showToast = (msg) => {
    setToastMessage(msg)
    setSnackbarOpen(true)
  }

  return (
    <>
      {
        (isAdmin) ? (<>
          <OptionsLayout>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackbarOpen}
              onClose={() => { setSnackbarOpen(false) }}
              autoHideDuration={3000}
              message={toastMessage}
            />

            <Container maxWidth="md1" className={classes.container}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    Setting
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <form onSubmit={handleSubmit}>
                    <Box className={classes.formContainer} spacing={2}>
                      <Stack className={classes.formSectionContainer} direction={`column`} spacing={2}>
                        <Box className={classes.formBox}>
                          <FormControlLabel
                            control={<Checkbox checked={formData.loggingRawData === "true"} onChange={(event) => setFormData({ ...formData, loggingRawData: event.target.checked ? "true" : "false" })} />}
                            label={`Logging GPS Raw Data`}
                          />
                        </Box>
                        <Box className={classes.formBox}>
                          <Button type="submit" color="primary" variant="contained">
                            {`Update Setting`}
                          </Button>
                        </Box>
                      </Stack>
                    </Box>
                  </form>
                </AccordionDetails>
              </Accordion>
            </Container>
          </OptionsLayout>
        </>) :
          (<></>)
      }
    </>
  );
};

export default SettingPage;
