import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeStyles, Paper, Toolbar, TextField, IconButton, Button, InputAdornment,
} from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ListIcon from '@material-ui/icons/ViewList';
import SortIcon from '@material-ui/icons/Sort';

import DevicesList from './DevicesList';
import Map from './map/Map';
import SelectedDeviceMap from './map/SelectedDeviceMap';
import AccuracyMap from './map/AccuracyMap';
import GeofenceMap from './map/GeofenceMap';
import CurrentPositionsMap from './map/CurrentPositionsMap';
import CurrentLocationMap from './map/CurrentLocationMap';
import BottomMenu from './components/BottomMenu';
import { useTranslation } from './LocalizationProvider';
import PoiMap from './map/PoiMap';
import MapPadding from './map/MapPadding';
import { api_call, console_log, get_utc_timestamp_ms } from './helpers/untils';
import { devicesActions, layoutActions } from './store';
import PaypalCreditLogsModal from './settings/PaypalCreditLogsModal';
import { AuthorizedLayout } from './layout/AuthorizedLayout';
import MainSort from './components/MainSort';
import CreditLogsModal from './settings/CreditLogsModal';
import { AccountCircle } from '@material-ui/icons';
import EscrowLogsModal from './settings/EscrowLogsModal';
import { checkDeviceChargeRequired } from './helpers/misc';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    left: 0,
    top: 0,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    bottom: 56,
    zIndex: 1301,
    transition: 'transform .5s ease',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-100vw)',
    },
  },
  paper: {
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    '& > *': {
      margin: theme.spacing(0, 0.25),
    },
  },
  deviceList: {
    flex: 1,
  },
  sidebarToggle: {
    position: 'fixed',
    left: theme.spacing(1.5),
    top: theme.spacing(3),
    borderRadius: '0px',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(3),
      left: 0,
    },
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: '#777777',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  searchTextBox: {
    position: 'relative',
    marginRight: '8px',
    // paddingRight: '50px'
  },
  searchTextSpan: {
    position: 'absolute',
    right: "8px",
    top: "0px",
    bottom: "0px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const MainPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation()
  const theme = useTheme();
  const t = useTranslation();

  const user = useSelector((state) => state.session.user);
  const [escrowUser, setEscrowUser] = useState(user);
  useEffect(() => {
    if (user?.id) {
      setEscrowUser(user)
    }
  }, [user?.id])
  const [escrowLogsModalOpen, setEscrowLogsModalOpen] = useState(false);

  //////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    checkQueryParams()
  }, [])

  const checkQueryParams = () => {
    const params = new URLSearchParams(location.search)
    if (params) {
      let mainUrl = `/main`
      const showEscrowPage = params.get("showEscrowPage")
      if (showEscrowPage) {
        if (!user.administrator) {
          dispatch(layoutActions.closeDeviceListMenu());
          setEscrowLogsModalOpen(true)

          params.delete("showEscrowPage");
          const queryString = params.toString()
          if (queryString) {
            mainUrl = `?${queryString}`
          }
          window.history.pushState('', '', mainUrl);
        }
      }
      const creditLogDevice = params.get("creditLogDevice")
      if (creditLogDevice) {
        if (!user.administrator) {
          params.delete("creditLogDevice");
          const queryString = params.toString()
          if (queryString) {
            mainUrl = `?${queryString}`
          }
          window.history.pushState('', '', mainUrl);
          loadDeviceInfo(creditLogDevice)
        }
      }
    }
  }
  const loadDeviceInfo = async (deviceId) => {
    console.log("loadDeviceInfo:")
    const url = `/api/devices/${deviceId}`
    const apiRes = await api_call(url);
    if (apiRes.ok) {
      const device = await apiRes.json()
      if (device?.id && device?.editable) {
        dispatch(layoutActions.closeDeviceListMenu());
        const chargeRequired = checkDeviceChargeRequired(device, false)
        if (chargeRequired) {
          showPaypalCreditLogsPage(device, chargeRequired)
        } else {
          showDeviceCreditLogsPage(device, chargeRequired)
        }

        // if (chargeRequired == 1) {
        //   dispatch(layoutActions.showCreditLogsModal());
        //   if (typeof showPaypalCreditLogsPage === 'function') {
        //     showPaypalCreditLogsPage(device)
        //   }
        // } else if (chargeRequired == 2) {
        //   dispatch(layoutActions.closeCreditLogsModal());
        //   if (typeof showPaypalCreditLogsPage === 'function') {
        //     showPaypalCreditLogsPage(device)
        //     return false;
        //   }
        // } else {
        //   dispatch(layoutActions.closeCreditLogsModal());
        //   showDeviceCreditLogsPage(device)
        // }

      }
    } else {
      return false
    }
  }
  //////////////////////////////////////////////////////////////////////////////////

  const state = location.state
  //console_log(`state::::::::::::::::::::::::::::::`, state)
  const showCreditLogdeviceInfo = state?.showCreditLogdeviceInfo
  //console_log(`showCreditLogdeviceInfo::::::::::::::::::::::::::::::`, showCreditLogdeviceInfo)
  useEffect(() => {
    if (showCreditLogdeviceInfo?.id) {
      setTimeout(() => {
        showDeviceCreditLogsPage(showCreditLogdeviceInfo)
      }, 700)
      history.replace({ ...location, state: {} });
    }
  }, [showCreditLogdeviceInfo?.id])
  ///////////////////////////////////////////////////////////////////////////////////

  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'));
  // console.log(isTablet + " " + isPhone);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  // console.log(isTablet);

  const handleClose = () => {
    if (collapsed) {

      dispatch(layoutActions.openDeviceListMenu());
    }
    setCollapsed(!collapsed);

  };


  useEffect(() => setCollapsed(isTablet), [isTablet]);

  const deviceListMenu = useSelector((state) => state.layout.deviceListMenu);

  useEffect(() => {
    if (deviceListMenu === "close") {
      setCollapsed(true);
    }
  }, [deviceListMenu]);

  /////////////////////////////////////////start paypal credit logs modal//////////////////////////////////////////
  
  const showPaypalCreditLogsPage = (item, chargeRequired = 0) => {
    console.log("inputchargeRequired:", chargeRequired)
    dispatch(layoutActions.closeDeviceListMenu());
    setDeviceItem(item)
    setPaypalCreditModalOpen(true)
    setChargeRequiredNum(chargeRequired)
  }
  const [paypalCreditModalOpen, setPaypalCreditModalOpen] = useState(false);
  const [deviceItem, setDeviceItem] = useState({});
  const [chargeRequiredNum, setChargeRequiredNum] = useState(0)
  /////////////////////////////////////////end paypal credit logs modal////////////////////////////////////////////////////

  /////////////////////////////////////////start device credit logs modal//////////////////////////////////////////
  const [creditModalOpen, setCreditModalOpen] = useState(false);
  const showDeviceCreditLogsPage = (item) => {
    console_log(`showDeviceCreditLogsPage::::::::::::::::::::::::::::`, item)
    dispatch(layoutActions.closeDeviceListMenu());
    setDeviceItem(item)
    setCreditModalOpen(true)
  }
  /////////////////////////////////////////end device credit logs modal////////////////////////////////////////////////////


  const [deviceCountData, setDeviceCountData] = useState({});
  const updateDeviceCountData = (countData) => {
    //console_log("countData:::", countData)
    setDeviceCountData(countData)
  }

  const deviceReloadTimestamp = useSelector((state) => state.layout.deviceReloadTimestamp);
  const reloadDeviceList = async () => {
    if (user.id !== 0) {
      var url = `/api/devices?source=mainPage`;
      const response = await api_call(url);
      if (response.ok) {
        const devices = await response.json();

        if (!user.administrator) {
          let filtered_device_list = devices ? devices.filter((item) => (item.connected_device_id === 0)) : []
          dispatch(devicesActions.refresh(filtered_device_list));
          dispatch(devicesActions.refreshUserDeviceItems(filtered_device_list));
        } else {
          dispatch(devicesActions.refresh(devices));
          dispatch(devicesActions.refreshUserDeviceItems(devices));
        }
      }
    }
  }
  useEffect(() => {
    if (deviceReloadTimestamp && deviceReloadTimestamp > 0) {
      reloadDeviceList()
    }
  }, [deviceReloadTimestamp])

  const getSearchTextSpan = () => {
    const spanText = `${deviceCountData['online_device_count']}/${deviceCountData['device_count']}${user.administrator ? '/' + deviceCountData['inside_map_device_count'] : ''}`
    return spanText
  }

  const getSearchPlaceholder = () => {
    let placeholder = getSearchTextSpan()
    return placeholder
  }

  const getFilteredDeviceCount = () => {
    let cnt = 0
    if (deviceCountData['filtered_device_count']) {
      cnt = deviceCountData['filtered_device_count']
    }
    return cnt
  }


  ///////////////////////////////////////////////////////////

  
  // const [tick, setTick] = useState(true);
  // const [intervalId, setIntervalID] = useState(null);

  // useEffect(() => {
  //   setIntervalID(setInterval(timer, 10000));
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);
  // const timer = () => {
  //   setTick((t) => !t);
  // };
  // const creditLogModalTimestamp = useSelector((state) => state.layout.creditLogModalTimestamp);
  // useEffect(() => {
  //   if (creditLogModalTimestamp != 0 && creditLogModalTimestamp <= get_utc_timestamp_ms() && !creditModalOpen) {
  //     showPaypalCreditLogsPage(deviceItem)
  //   }
  // }, [tick]);

  return (
    <>
      <div className={classes.root}>
        <Map>
          {/* {!isTablet && <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />} */}
          {/* <CurrentLocationMap />
        <GeofenceMap />
        <AccuracyMap />  */}
          <CurrentPositionsMap
            showPaypalCreditLogsPage={showPaypalCreditLogsPage}
          />
          <SelectedDeviceMap />
          <PoiMap />
        </Map>
        <Button
          variant="contained"
          color={isPhone ? 'secondary' : 'primary'}
          classes={{ containedPrimary: classes.sidebarToggleBg }}
          className={classes.sidebarToggle}
          onClick={handleClose}
          disableElevation
        >
          <ListIcon />
          <div className={classes.sidebarToggleText}>{t('deviceTitle')}</div>
        </Button>
        <Paper square elevation={3} className={`${classes.sidebar} ${collapsed && classes.sidebarCollapsed}`}>
          <Paper className={classes.paper} square elevation={3}>
            <Toolbar className={classes.toolbar} disableGutters>
              {isTablet && (
                <IconButton onClick={handleClose}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <div className={classes.searchTextBox}>
                <TextField
                  fullWidth
                  name="searchKeyword"
                  value={searchKeyword}
                  autoComplete="searchKeyword"
                  onChange={(event) => setSearchKeyword(event.target.value)}
                  placeholder={getSearchPlaceholder()}
                  variant="filled"
                  InputProps={{
                    classes: {
                      input: user.administrator ? classes.searchText2 : classes.searchText2
                    },
                    endAdornment: (
                      <>
                        {
                          (searchKeyword !== "") ? (
                            <span style={{ paddingLeft: '8px' }}>{getFilteredDeviceCount()}</span>
                          ) : (
                            <></>
                          )
                        }
                      </>
                    ),
                  }}
                />
              </div>

              <MainSort />

              <IconButton onClick={() => history.push('/device')}>
                <AddIcon />
              </IconButton>
              {!isTablet && (
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              )}
            </Toolbar>
          </Paper>
          <div className={classes.deviceList}>
            <DevicesList
              filter={searchKeyword}
              updateDeviceCountData={updateDeviceCountData}
              showPaypalCreditLogsPage={showPaypalCreditLogsPage}
              showDeviceCreditLogsPage={showDeviceCreditLogsPage}
            />
          </div>
        </Paper>
        <BottomMenu />

        <>
          <PaypalCreditLogsModal
            open={paypalCreditModalOpen}
            setOpen={setPaypalCreditModalOpen}
            item={deviceItem}
            setItem={setDeviceItem}
            chargeRequiredNum={chargeRequiredNum}
          />
        </>

        <>
          <CreditLogsModal
            open={creditModalOpen}
            setOpen={setCreditModalOpen}
            item={deviceItem}
            setItem={setDeviceItem}
          />
        </>

        <>
          <EscrowLogsModal
            open={escrowLogsModalOpen}
            setOpen={setEscrowLogsModalOpen}
            item={escrowUser}
            setItem={setEscrowUser}
            modalType={'self'}
          />
        </>

      </div>
    </>
  )
}

export default MainPage;
