import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import {
  ButtonBase,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AdjustIcon from '@material-ui/icons/Adjust';
import AlbumIcon from '@material-ui/icons/Album';
import { Stack } from '@mui/material';

const useStyles = makeStyles(() => ({
  CameraBox: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000000'
  },
  CameraActionBtnBox: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    zIndex: 2,
    textAlign: 'center'
  },
  CameraActionBtnList: {
    padding: '24px 0',
    backgroundImage: 'linear-gradient(rgba(0,0,0,0.77), transparent)'
  },

}));

const CameraImageBox = (props) => {
  const {imageDataURL, setImageDataURL} = props
  const classes = useStyles();

  useEffect(() => {
  }, [])

  const takePhoto = () => {

  }

  const pickPhoto = () => {

  }

  return (
    <>
      {
        (imageDataURL) ? (
          <>
            
          </>
        ) : (
          <></>
        )
      }
    </>
  )
}

export default CameraImageBox;
