import React from 'react';
import {
  AppBar, Toolbar, Typography, Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import { api_call, console_log } from '../helpers/untils';
import { setSettingPersist } from '../helpers/misc';

const classes = {
  carTabBar: {
    minHeight: "32px",
    borderBottom: '2px solid rgba(0,0,0,0.12)'
  },
  carTabItem: {
    flex: 1,
    marginTop: 0,
    marginBottom: 0,
    paddingTop: '14px',
    paddingBottom: '14px',
    height: 'auto',
    borderRadius: 0,
    border: '3px solid rgba(0, 0, 0, 0)',
  },
  carTabItemActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    border: '3px solid rgba(0, 0, 0, 0.42)',
  },
}

const CarTypeTabBar = (props) => {
  const { currentPage } = props;
  const history = useHistory()
  const OnClickMoovetrax = () => {
    if(currentPage === 'oem-device') {
      history.push('/device')
    }
  }
  const OnClickSmartCar = async () => {
    if(currentPage === 'device') {
      history.push('/oem-device')
    }
  }
  
  return (
    <Toolbar disableGutters style={classes.carTabBar}>
      <Button style={{ ...classes.carTabItem, ...(currentPage === 'device' ? classes.carTabItemActive : {}) }} onClick={() => { OnClickMoovetrax() }}>
        Moovetrax
      </Button>
      <Button style={{ ...classes.carTabItem, ...(currentPage === 'oem-device' ? classes.carTabItemActive : {}) }} onClick={() => { OnClickSmartCar() }}>
        OEM Car App
      </Button>
    </Toolbar>
  )
}

export default CarTypeTabBar;
