import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  makeStyles, Typography, Box, Modal, Button, Snackbar, FormControlLabel, Checkbox
} from '@material-ui/core';
import { APP_NAME } from '../../config/constant';
import { Link, Stack } from '@mui/material';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '440px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '30%',
    },
  },

}));


const LoginAgreeModal = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { open, setOpen, onClickAgree, onClickCancel } = props;

  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const defaultFormData = {
    agree_terms: false,
    agree_privacy: false,
    agree_disclaimer: false,
  }
  const [formData, setFormData] = useState(defaultFormData);

  const onChangeCheckBox = (event, field_name) => {
    let form_data = { ...formData }
    form_data[field_name] = event.target.checked
    setFormData(form_data)

    if(form_data['agree_terms'] && form_data['agree_privacy'] && form_data['agree_disclaimer']) {
      onClickAgree(true)
    }
  }
 
  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />
          <Typography id="modal-modal-title" variant="h6" component="h6">{APP_NAME}</Typography>
          <Box sx={{ width: '100%', marginTop: '16px' }}>
            <Stack direction={`column`} justifyContent={`flex-start`} alignItems={`center`} spacing={3} style={{ width: '100%' }}>
              <Stack direction={`column`} justifyContent={`flex-start`} alignItems={`flex-start`} spacing={1} style={{ width: '100%', maxWidth: '360px' }}>
                <Stack direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={1} style={{ width: '100%' }}>
                  <Typography variant="body1">I agree to <Link href='/terms-conditions' target='_blank'>Terms And Conditions</Link></Typography>
                  <FormControlLabel
                    control={<Checkbox checked={formData['agree_terms'] ? true : false} onChange={(event) => onChangeCheckBox(event, 'agree_terms')} />}
                    label=""
                    labelPlacement="start"
                  />
                </Stack>
                <Stack direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={8} style={{ width: '100%' }}>
                  <Typography variant="body1">I agree to <Link href='/privacy-policy' target='_blank'>Privacy Policy</Link></Typography>
                  <FormControlLabel
                    control={<Checkbox checked={formData['agree_privacy'] ? true : false} onChange={(event) => onChangeCheckBox(event, 'agree_privacy')} />}
                    label=""
                    labelPlacement="start"
                  />
                </Stack>
                <Stack direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={8} style={{ width: '100%' }}>
                  <Typography variant="body1">I agree to <Link href='/disclaimer' target='_blank'>Disclaimer</Link></Typography>
                  <FormControlLabel
                    control={<Checkbox checked={formData['agree_disclaimer'] ? true : false} onChange={(event) => onChangeCheckBox(event, 'agree_disclaimer')} />}
                    label=""
                    labelPlacement="start"
                  />
                </Stack>
              </Stack>
              <div className={classes.buttons} style={{ width: '100%' }}>
                <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
                  Close
                </Button>
              </div>
            </Stack>
          </Box>


        </Box>
      </Modal>
    </>
  );
};

export default LoginAgreeModal;
