import React from 'react';
import { GoogleMap, LoadScript, Marker, OverlayView, Circle } from '@react-google-maps/api';
import CustomClusterMarker from './CustomClusterMarker';
import { getDistanceFromMiles } from '../helpers/untils';
import { useState } from 'react';
import { useEffect } from 'react';

const defaultPosition = {
  lat: 0,
  lng: 0
}

const defaultCircleOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0, // marker?.id === currentMarker?.id ? 0.35 : 0,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
}

const InstallerMarker = (props) => {
  const {
    position = defaultPosition,
    label,
    onClick,
    clusterer,
    marker = {},
    currentMarker = {},
    currentInstallerData = {}
  } = props

  const { data } = marker

  const [circleOptions, setCircleOptions] = useState(defaultCircleOptions)
  useEffect(() => {
    const circle_options = { ...circleOptions }
    let fillOpacity = 0
    if (marker?.id === currentMarker?.id) {
      fillOpacity = 0.35
    } else {
      fillOpacity = 0
    }
    circle_options['fillOpacity'] = fillOpacity
    setCircleOptions(circle_options)

  }, [marker?.id, currentMarker?.id])

  const customMarkerIcon = {
    url: "/assets/img/map/pin1.png",
    scaledSize: new window.google.maps.Size(30, 40),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(15, 15),
    labelOrigin: label ? new window.google.maps.Point(15, 50) : null
  };

  const labelObject = label ? {
    className: "custom-cluster-marker-label",
    text: label,
    color: "black",
    fontSize: "14px",
    fontWeight: "bold"
  } : null

  return (
    <>
      <CustomClusterMarker
        position={position}
        customMarkerIcon={customMarkerIcon}
        label={label}
        labelObject={labelObject}
        onClick={onClick}
        clusterer={clusterer}
      />

      {
        (data?.mobile_installer === '1' && data.radius_serviced) ? (
          <>
            <Circle
              center={position}
              options={circleOptions}
              radius={getDistanceFromMiles(data.radius_serviced, 'm', false, true)} // Adjust the radius as needed (in meters)
            />
          </>
        ) : (
          <></>
        )
      }

    </>
  );
}

export default InstallerMarker;
