import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from './LocalizationProvider';
import { api_call, get_utc_timestamp_ms } from './helpers/untils';
import { useDispatch } from 'react-redux';
import { layoutActions } from './store';

const RemoveDialog = ({
  open, endpoint, itemId, onResult,
}) => {
  const dispatch = useDispatch();
  const t = useTranslation();

  const handleRemove = async () => {
    const response = await api_call(`/api/${endpoint}/${itemId}`, { method: 'DELETE' });
    if (response.ok) {
      dispatch(layoutActions.setDeviceReloadTimestamp({ value: get_utc_timestamp_ms() }));
      onResult(true);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => { onResult(false); }}
    >
      <DialogContent>
        <DialogContentText>{t('sharedRemoveConfirm')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleRemove}>{t('sharedRemove')}</Button>
        <Button autoFocus onClick={() => onResult(false)}>{t('sharedCancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
