import { DEFAULT_GEO_LOCATION } from "../config/constant";
import { empty } from "../helpers/untils";

export const getIsAdmin = (state) => state.session.user?.administrator;

export const getUserId = (state) => state.session.user?.id;

export const getDevices = (state) => Object.values(state.devices.items);

export const getUserDevices = (state) => Object.values(state.devices.userDeviceItems);

export const getPosition = (id) => (state) => {    
    let position = state.positions.items[id]
    if(empty(position) || empty(position['latitude'])) {
        position = {};
        position['attributes'] = {}
        position['latitude'] = DEFAULT_GEO_LOCATION[0]
        position['longitude'] = DEFAULT_GEO_LOCATION[1]
    }
    return position
};
