import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Button, FormControl, Container, Checkbox, FormControlLabel, Box, Snackbar, useMediaQuery,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

import { sessionActions } from '../store';
import EditAttributesView from '../attributes/EditAttributesView';
import useDeviceAttributes from '../attributes/useDeviceAttributes';
import useUserAttributes from '../attributes/useUserAttributes';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';
import { api_call, calculateDeviceCount, calculateResellerDeviceCount, empty } from '../helpers/untils';
import { getIsAdmin } from '../common/selectors';
import { Stack } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    flexDirection: 'column',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
  },
  formSectionContainer: {
    alignItems: 'center',
    marginRight: '16px',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'flex-start',
    },
  },
  formBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  formLabel: {
    paddingLeft: '4px'
  },
  formSubmitButton: {
    marginTop: '24px'
  }
}));

const ResellerInventoryPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const t = useTranslation();
  const isAdmin = useSelector(getIsAdmin);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const defaultFormData = {
    email: '',
    first_device: '',
    last_device: '',
    total: '',
    price: '',
    notes: ''
  }
  const [formData, setFormData] = useState(defaultFormData)

  const onChangeFormData = (field_name, event) => {
    const value = event.target.value
    const form_data = { ...formData }
    form_data[field_name] = value
    if (field_name === 'first_device' || field_name === 'last_device') {
      let total = calculateResellerDeviceCount(form_data['first_device'], form_data['last_device'])
      form_data['total'] = Number(total)
    }
    setFormData(form_data)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form_data = { ...formData }
    const total = form_data['total']
    if (empty(total) || total === 0) {
      showToast(`Please enter fields correctly`)
      return false
    }

    const response = await api_call('/api/devices/add-reseller-devices', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      showToast("Success!")
      setFormData(defaultFormData)
    }
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const showToast = (msg) => {
    setToastMessage(msg)
    setSnackbarOpen(true)
  }

  return (
    <>
      {
        (isAdmin) ? (<>
          <OptionsLayout>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackbarOpen}
              onClose={() => { setSnackbarOpen(false) }}
              autoHideDuration={3000}
              message={toastMessage}
            />

            <Container maxWidth="md1" className={classes.container}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    Reseller Inventory
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <form onSubmit={handleSubmit}>
                    <Box className={classes.formContainer} spacing={2}>
                      <Stack className={classes.formSectionContainer} direction={isMobile ? `column` : `row`} spacing={2}>
                        <Box className={classes.formBox}>
                          <label className={classes.formLabel}>Email</label>
                          <TextField
                            className={classes.formController}
                            value={formData['email']}
                            onChange={(event) => onChangeFormData('email', event)}
                            label={""}
                            variant="filled"
                            inputProps={{
                              type: 'email'
                            }}
                            required={true}
                            fullWidth
                          />
                        </Box>
                        <Box className={classes.formBox}>
                          <label className={classes.formLabel}>First Device</label>
                          <TextField
                            className={classes.formController}
                            value={formData['first_device']}
                            onChange={(event) => onChangeFormData('first_device', event)}
                            label={""}
                            variant="filled"
                            required={true}
                            fullWidth
                          />
                        </Box>
                        <Box className={classes.formBox}>
                          <label className={classes.formLabel}>Last Device</label>
                          <TextField
                            className={classes.formController}
                            value={formData['last_device']}
                            onChange={(event) => onChangeFormData('last_device', event)}
                            label={""}
                            variant="filled"
                            required={true}
                            fullWidth
                          />
                        </Box>
                        <Box className={classes.formBox}>
                          <label className={classes.formLabel}>Total</label>
                          <TextField
                            className={classes.formController}
                            value={formData['total']}
                            label={""}
                            variant="filled"
                            disabled={true}
                            fullWidth
                          />
                        </Box>
                      </Stack>

                      <Stack className={classes.formSectionContainer} direction={isMobile ? `column` : `row`} spacing={2}>
                        <Box className={classes.formBox}>
                          <label className={classes.formLabel}>Price</label>
                          <TextField
                            className={classes.formController}
                            value={formData['price']}
                            onChange={(event) => onChangeFormData('price', event)}
                            label={""}
                            variant="filled"
                            inputProps={{
                              type: 'number',
                              step: 0.01,
                              min: 0
                            }}
                            required={true}
                            fullWidth
                          />
                        </Box>
                        <Box className={classes.formBox}>
                          <label className={classes.formLabel}>Notes</label>
                          <TextField
                            className={classes.formController}
                            value={formData['notes']}
                            onChange={(event) => onChangeFormData('notes', event)}
                            label={""}
                            variant="filled"
                            //  required={true}
                            fullWidth
                          />
                        </Box>
                        <Box className={classes.formBox}>
                          <Button type="submit" color="primary" variant="contained" className={classes.formSubmitButton}>
                            Save
                          </Button>
                        </Box>
                      </Stack>
                    </Box>
                  </form>
                </AccordionDetails>
              </Accordion>
            </Container>
          </OptionsLayout>
        </>) :
          (<></>)
      }
    </>
  );
};

export default ResellerInventoryPage;
