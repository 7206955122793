import React, { useState } from 'react';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, InputLabel, Link, MenuItem, Select, TextField,
} from '@material-ui/core';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { console_log, empty, getGoogleGeoLocationAddress } from '../helpers/untils';
import { useEffect } from 'react';


const GoogleGeolocationAddressBox = (props) => {
  const { lat, lng, autoShow = false } = props

  //////////////////////////////////////////////////////////////
  const [address, setAddress] = useState("")
  const showAddress = async () => {
    const aa = await getGoogleGeoLocationAddress(lat, lng)
    setAddress(aa)
  }

  useEffect(() => {
    if (lat && lng && autoShow) {
      showAddress()
    }
  }, [lat, lng, autoShow])
  //////////////////////////////////////////////////////////////

  return (
    <>
      {
        (autoShow) ? (
          <>
            {address}
          </>
        ) : (
          <>
            {
              (address) ? (
                <>
                  {address}
                </>
              ) : (
                <>
                  <Link onClick={() => showAddress()} style={{ cursor: 'pointer', color: '#1366ff' }}>Show Address</Link>
                </>
              )
            }
          </>
        )
      }
    </>
  );
};

export default GoogleGeolocationAddressBox;
