import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  makeStyles, FormControlLabel, Checkbox, TextField, Button, IconButton
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { api_call, console_log, createArrayFromCount, createEmptyArrayFromCount, empty, is_null } from '../helpers/untils';

const useStyles = makeStyles((theme) => ({
  formRowInline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  //  marginRight: '30px',

  },
  formRowInlineController: {
    flex: 1,
    paddingRight: '8px'
  },
  textList: {
    display: 'block',
    paddingTop: '8px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },

}));


const UnlimitedTextBoxList = (props) => {
  const { defaultName, defaultCount, defaultValues, inputProps, setValues, placeholder="" } = props;
  //console.log("defaultValues:::", defaultValues)
  const classes = useStyles();
  useEffect(() => {
    initForm()
  }, [defaultValues])

  const [fieldName, setFieldName] = useState("")
  const [fieldCount, setFieldCount] = useState(0)
  const [fieldValues, setFieldValues] = useState([])

  const initForm = () => {
    setFieldName(defaultName)
    setFieldCount(defaultCount)
    if(is_null(defaultValues)) {
      setFieldValues(createEmptyArrayFromCount(defaultCount))
    }else{
      setFieldValues(defaultValues)
    }
  }
  
  const onClickRemoveItem = (index) => {
    const field_values = [...fieldValues]
    field_values.splice(index, 1)
    console.log("field_values:::", field_values)
    setFieldValues(field_values)
    setValues(field_values)
  }

  const onClickAddNew = () => {
    const field_values = [...fieldValues]
    field_values.push("")
    console.log("field_values:::", field_values)
    setFieldValues(field_values)
    setValues(field_values)
  }

  const onChangeTuroForm = (event, index) => {
    const field_values = [...fieldValues]
    field_values[index] = event.target.value
    setFieldValues(field_values)
    setValues(field_values)
  }

  const DrawForm = () => { //field_name, field_count, field_values
    //let formIndexList = createArrayFromCount(defaultValues.length)
    //console.log("formIndexList:::", formIndexList)
    const field_values = [...fieldValues]

    return (
      field_values.map((item, index) => {
        return (
          <div className={classes.formRowInline} key={index}>
            <TextField
              fullWidth
              className={classes.formRowInlineController}
              value={fieldValues[index]}
              onChange={(event) => onChangeTuroForm(event, index)}
              label=""
              variant="filled"
              inputProps={{
                ...inputProps,
                placeholder: placeholder
              }}
            />
            <div className={classes.formActionBox}>
              <IconButton className={classes.removeButton} color="primary" size="medium" onClick={() => onClickRemoveItem(index)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        )
      })
    )
  }

  return (
    <>
      <div className={classes.textList}>
        {
          DrawForm()
        }
        <div className={classes.formRowInline}>
          <Button type="button" color="primary" variant="contained" startIcon={<AddIcon />} onClick={() => { onClickAddNew() }}>
            Add New
          </Button>
        </div>
      </div>
    </>
  )
}

export default UnlimitedTextBoxList;
