import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box, Button, Modal, Snackbar, makeStyles
} from '@material-ui/core';
import IncomeExpenseBlock from './settings/modals/IncomeExpenseBlock';
import { api_call, console_log } from './helpers/untils';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '96%',
  maxWidth: '992px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));

const IncomeExpenseModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  //const { open, setOpen, deviceItem, history } = props;
  const [open, setOpen] = useState(false)
  const deviceItem = {
    id: 14963,
    name: 'MT2V001038'
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const showToast = (msg) => {
    setToastMessage(msg)
    setSnackbarOpen(true)
  }

  const onClickConnectTesla = async () => {
    const response = await api_call(`/api/tesla/get-login-url`);
      if (response.ok) {
        const res = await response.json()
        console_log("Tesla res:::", res)
        //setSettingPersist('tesla_auth_source', 'device')
        window.location.href = res.link
      } else {
        alert("Tesla authentication error")
      }
  }

  return (
    <>
      <Box>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          onClose={() => { setSnackbarOpen(false) }}
          autoHideDuration={1500}
          message={toastMessage}
        />
        <Box sx={{ paddingTop: 0 }}>
          <Button onClick={() => { onClickConnectTesla() }}>
            Connect Tesla
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default IncomeExpenseModal;
