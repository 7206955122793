import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions, sessionActions } from '../store';

import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, Typography, Checkbox, Box, Link, Modal, TextField, Button, Snackbar,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';
import VisibilityOffIcon from '@material-ui/icons/Close';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import moment from 'moment';
import { api_call, console_log, empty, get_utc_timestamp, get_utc_timestamp_ms } from '../helpers/untils';
import PaypalSubscriptionModal from './PaypalSubscriptionModal';
import { APP_NAME, MAX_CREDIT_AMOUNT, MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID } from '../config/constant';
import ConfirmDialog from '../components/ConfirmDialog';
import EscrowLogsModal from './EscrowLogsModal';

const itemType = "credit"

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '1024px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

const modalInnerStyle = {
  position: 'relative',
  width: '100%',
  maxHeight: 'calc(100vh - 160px)',
  overflowY: 'auto'
};

const paypalBtnStyle = {
  cursor: 'pointer',
  background: 'rgb(253 195 58)',
  padding: '4px 35px',
  borderRadius: '15px',
  fontSize: '14px',
  color: '#0a519a',
  textAlign: 'center',
  marginBottom: '0px',
  lineHeight: 1.5,
  display: 'inline-block',
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  paypalBtnBox: {
    display: 'inline-block',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  paypalHideBtn: {
    cursor: 'pointer',
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '4px',
    paddingBottom: '4px',
    marginLeft: '2px',
  },
  modalTitleBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
}));

const CreditLogsTable = (props) => {
  const { selectedDevice, setDevice, updateTimestamp, setUpdateTimestamp, paypalSubscriptionDetail, setPaypalSubscriptionDetail, logItems, setLogItems, escrowUser, setEscrowUser } = props;

  const user = useSelector((state) => state.session.user);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setCurrentTableData] = useState([]);
  const PageSize = 5;

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTableData(logItems.slice(firstPageIndex, lastPageIndex))
  }, [currentPage]);

  useEffectAsync(async () => {
    var url = `/api/creditLogsByDeviceId/` + selectedDevice['id'];
    const response = await api_call(url);
    let t_items = []
    if (response.ok) {
      const creditLogsApiRes = await response.json()
      console_log("creditLogsApiRes::::", creditLogsApiRes)
      t_items = creditLogsApiRes['log_list']
      setItems(t_items);

      const device = creditLogsApiRes['device']
      if (device) {
        setDevice(device)
      }

      const suscription_detail = creditLogsApiRes['suscription_detail']
      if (!empty(suscription_detail)) {
        setPaypalSubscriptionDetail(suscription_detail)
      } else {
        setPaypalSubscriptionDetail({})
      }

      const escrow_user = creditLogsApiRes['escrow_user']
      if (!empty(escrow_user)) {
        setEscrowUser(escrow_user)
      } else {
        setEscrowUser({})
      }
    }
    setLogItems(t_items)
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTableData(t_items.slice(firstPageIndex, lastPageIndex));
  }, [updateTimestamp]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Added by</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Device Name</TableCell>
              <TableCell>Serial Number</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>When</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (currentTableData.length > 0) ? (
                currentTableData.map((item) =>
                  <TableRow key={item.id}>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>{item.from_name ?? 'Owner'}</TableCell>
                    <TableCell>{item.note}</TableCell>
                    <TableCell>{item.to_name}</TableCell>
                    <TableCell>{item.device_serial_number}</TableCell>
                    <TableCell>{item.to_balance}</TableCell>
                    <TableCell>{moment(item.createdAt).format('LLL')}</TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow key={`no-data`}>
                  <TableCell colSpan={7} align="center">No log data</TableCell>
                </TableRow>
              )
            }

          </TableBody>
        </Table>
        <div style={{ marginTop: '5px' }}></div>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={PageSize}
          totalCount={logItems.length}
          onPageChange={page => setCurrentPage(page)}
        />
      </TableContainer>
    </>
  )
}

const CreditLogForm = (props) => {
  const { open, setOpen, selectedDevice, updateTimestamp, setUpdateTimestamp, setDeviceItem } = props;
  const user = useSelector((state) => state.session.user);

  const classes = useStyles();
  const defaultFormData = {
    charge_type: 'credit',
    amount: '0.00',
    note: ''
  }
  const [item, setItem] = useState(defaultFormData)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [apiLoading, setApiLoading] = useState(false)

  const handleAddCredit = async () => {
    const url = `/api/creditLogs`;
    const payload = {
      user_id: user.id,
      user_name: user.name,
      device_id: selectedDevice['id'],
      ...item,
    }
    if (Number(payload['amount']) <= 0) {
      setToastMessage(`Invalid amount`);
      setSnackbarOpen(true);
      return false
    }
    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });


    setApiLoading(false)
    if (response.ok) {
      setToastMessage(`Credit has been added successfully`);
      setSnackbarOpen(true);
      setUpdateTimestamp(get_utc_timestamp())
      setItem(defaultFormData)
      const deviceInfo = await response.json();
      setDeviceItem(deviceInfo)
    } else {
      setToastMessage(`Unknown error`);
      setSnackbarOpen(true);
    }
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        autoHideDuration={1500}
        message={toastMessage}
      />

      <div style={{ display: 'inline-block', background: '#ffffff', paddingRight: '20px' }}>
        <SelectField
          margin="normal"
          value={item.charge_type || 'credit'}
          emptyValue={null}
          onChange={(event) => setItem({ ...item, charge_type: event.target.value })}
          data={[{ id: 'credit', name: 'Add Credit' }, { id: 'invoice', name: 'Add Invoice' }]}
          label=''
        />
      </div>
      <div>
        <TextField
          margin="normal"
          value={item.amount || ''}
          onChange={(event) => setItem({ ...item, amount: event.target.value })}
          label={`Credit Amount`}
          inputProps={{ type: 'number', step: 0.01 }}
        />
      </div>
      <div>
        <TextField
          margin="normal"
          label="Note"
          multiline={true}
          rows={3}
          value={item.note || ''}
          onChange={(event) => setItem({ ...item, note: event.target.value })}
          style={{ width: '100%' }}
        />
      </div>
      <div className={classes.buttons} style={{ paddingTop: '15px', maxWidth: '400px', margin: 'auto' }}>
        <Button type="button" color="primary" variant="contained" onClick={() => handleAddCredit()} disabled={apiLoading}>
          Add
        </Button>
        <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
          Close
        </Button>
      </div>
    </>
  )
}
const CreditLogsModal = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.session.user);
  const classes = useStyles();

  const { open, setOpen, item, setItem } = props;
  const [updateTimestamp, setUpdateTimestamp] = useState(0)
  const [logItems, setLogItems] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [billingSource, setBillingSource] = useState(item?.billing_source);

  useEffect(() => {
    setBillingSource(item?.billing_source)
  }, [item]);

  useEffect(() => {
    setPaypalSubscriptionDetail(null)
  }, [open]);

  const handleCreditModalClose = () => {
    setOpen(false)
  }

  ////////////////////////////////////paypal subscription modal///////////////////////////////////////////
  const [planId, setPlanId] = useState("");
  const [paypalAccessToken, setPaypalAccessToken] = useState("");
  const [paypalSubscriptionDetail, setPaypalSubscriptionDetail] = useState(null);

  const showPaypalPaymentModal = async () => {
    if (user.administrator) {
      return false
    }

    let creditAmount = item?.credit;
    //console.log("creditAmount:::", creditAmount)
    if (true || creditAmount && creditAmount >= MIN_CREDIT_AMOUNT) {
      let planInfo = await getMoovetraxMonthlyPlan()
      console_log("PlanInfo:::", planInfo)
      if (!empty(planInfo)) {
        setPlanId(planInfo['id'])
        setPaypalPaymentModalOpen(true)
      }
    } else {
      console_log("credit amount is under ", MIN_CREDIT_AMOUNT)
    }
  }
  const getMoovetraxMonthlyPlan = async () => {
    let planInfo = await createMoovetraxMonthlyPlan();
    return planInfo;
  }
  const createMoovetraxMonthlyPlan = async () => {
    let url = "/api/payment/paypal/create-plan"
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ itemId: item.id, itemType: itemType }),
    });

    if (response.ok) {
      let payalResponse = await response.json()
      if (payalResponse['id']) {
        setItem({ ...item, plan_id: payalResponse['id'] })
      }
      return payalResponse;
    }
    return false;
  }

  var cancellingSubscription = false;
  const onClickCancelSubscription = async (subscription_id) => {
    // if (user.administrator) {
    //   return false
    // }
    if (cancellingSubscription) {
      return false;
    }
    if (window.confirm("Are you sure you want to cancel subscription?")) {
      var url = `/api/payment/paypal/cancel-subscription`
      const post_data = {
        subscription_id: subscription_id,
        itemType: itemType
      }
      cancellingSubscription = true;
      const response = await api_call(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(post_data),
      });

      cancellingSubscription = false;
      if (response.ok) {
        let responseObj = await response.json()
        console_log("Cancel subscription result:::", responseObj)
        setPaypalSubscriptionDetail({})
      } else {
        alert("Error encounted during canceling subscription")
      }
    }
  }

  const [paypalPaymentModalOpen, setPaypalPaymentModalOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const checkShowPaypalButton = () => {
    return true
    // let show = false;
    // let credit = item?.credit
    // if(credit > MIN_CREDIT_AMOUNT ) {
    //   show = true
    // }else{
    //   show = false
    // }
    // return show
  }

  //////////////////////////////////////////////////////////////////////////////////////////////
  const [confirmAction, setConfirmAction] = useState('')
  const [confirmText, setConfirmText] = useState(`Escrow In Use - Device Paypal Suspended`)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const onClickAgreeConfirm = () => {
    saveBillingSource()
  }
  const onClickCancelConfirm = () => {
    setShowConfirmModal(false)
    if (billingSource === 'escrow') {
      setBillingSource('paypal')
    }
  }
  const onClickSaveBillingSource = (billing_source) => {
    setBillingSource(billing_source)
    if (billing_source === 'escrow') {
      if (paypalSubscriptionDetail['status'] === 'ACTIVE' || paypalSubscriptionDetail['status'] === 'SUSPENDED') {
        setConfirmAction('saveBillingSource')
        setShowConfirmModal(true)
        return false
      }
    }
    saveBillingSource(billing_source)
  }
  const saveBillingSource = async (billing_source = null) => {
    if (apiLoading) {
      return false
    }
    if (empty(billing_source)) {
      billing_source = billingSource
    }
    var url = `/api/devices/set-billing-source`
    const post_data = {
      device_id: item.id,
      billing_source: billing_source
    }
    setApiLoading(true)
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(post_data),
    });
    setApiLoading(false)
    if (response.ok) {
      let responseObj = await response.json()
      setItem(responseObj)
    } else {
      let errorObj = response
      if (errorObj['error']) {
        alert(errorObj['error'])
        setBillingSource(item?.billing_source)
        if (errorObj['escrow_balance_insufficient']) {
          setOpen(false)
          showEscrowLogsModal()
        }
      } else {
        alert("Unknown error")
      }
    }
    setShowConfirmModal(false)
    setUpdateTimestamp(get_utc_timestamp_ms())
  }

  const [escrowUser, setEscrowUser] = useState();
  const [escrowLogsModalOpen, setEscrowLogsModalOpen] = useState(false);
  const showEscrowLogsModal = async () => {
    setEscrowLogsModalOpen(true)
  }

  const OnClickEscrowBalance = (event) => {
    event.preventDefault()
    setOpen(false)
    showEscrowLogsModal()
  }

  const onClickShowVideo = () => {
    dispatch(layoutActions.showPaymentVideoModal());
  }

  useEffect(() => {
    if (open) {
      dispatch(layoutActions.closeDeviceListMenu());
    }
  }, [open]);
  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={modalInnerStyle}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackbarOpen}
              onClose={() => { setSnackbarOpen(false) }}
              autoHideDuration={1500}
              message={toastMessage}
            />

            <Box className='m-block' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Box className={classes.modalTitleBox}>
                <Box sx={{ mr: 4 }}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <span>Balance:&nbsp;{item?.credit ? Number(item.credit).toFixed(2) : '0.00'}</span>
                  </Typography>
                </Box>
                {
                  (paypalSubscriptionDetail !== null) && (
                    <>
                      <Box sx={{ mr: 1 }}>
                        <FormControl disabled={user.administrator}>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={billingSource || ''}
                            onChange={(event) => onClickSaveBillingSource(event.target.value)}
                            style={{ justifyContent: "flex-start" }}
                            row
                          >
                            <FormControlLabel value="paypal" control={<Radio />} label={
                              (paypalSubscriptionDetail !== null) ? (
                                <Box sx={{ opacity: user.administrator ? 1 : 1 }}>
                                  {
                                    ((paypalSubscriptionDetail['status'] === 'ACTIVE' || paypalSubscriptionDetail['status'] === 'SUSPENDED')) ? (
                                      <div className={classes.paypalBtnBox}>
                                        <span onClick={() => onClickCancelSubscription(paypalSubscriptionDetail['id'])} style={{ ...paypalBtnStyle }}>Cancel Subscription</span>
                                      </div>
                                    ) : (
                                      <>
                                        {(checkShowPaypalButton()) && (
                                          <div className={classes.paypalBtnBox}>
                                            <span onClick={() => showPaypalPaymentModal()} style={{ ...paypalBtnStyle, cursor: user.administrator ? 'default' : null }}><img src="/images/paypal-logo.png" style={{ width: '60px', height: 'auto' }} /></span>
                                          </div>
                                        )}
                                      </>
                                    )
                                  }
                                </Box>
                              ) : (<></>)
                            } />
                            <FormControlLabel value="escrow" control={<Radio />} label={
                              <Box>
                                Pay from Escrow {escrowUser?.id ? <span style={{ cursor: 'pointer', color: '#1366ff' }} onClick={(e) => OnClickEscrowBalance(e)}>({escrowUser?.escrow_balance})</span> : <></>}
                              </Box>
                            } />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {/* {
                      (billingSource !== item?.billing_source) && (
                        <Box sx={{ mr: 1 }}>
                          <Button color="primary" variant="contained" size="small" onClick={() => onClickSaveBillingSource()}>Apply</Button>
                        </Box>
                      )
                    } */}
                      <Box sx={{ mr: 1 }}>
                        <Typography variant='subtitle2'
                          onClick={() => onClickShowVideo()}
                          style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1366ff', display: 'block', paddingTop: '8px', paddingBottom: '8px' }}
                        >
                          Video
                        </Typography>
                      </Box>
                    </>
                  )
                }
              </Box>

              <Box className='m-block' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography className='pe-5 py-1' id="modal-modal-title1" variant="h6" component="h6">
                  Start:&nbsp;<span>{moment(item?.createdAt).format('LL')}</span>
                </Typography>

                <Typography className='pe-3 py-1' id="modal-modal-title2" variant="h6" component="h6">
                  Billed on: {(logItems.length > 0) && <span>&nbsp;{item?.billed_date}</span>}
                </Typography>
              </Box>
            </Box>

            <Typography id="creditLogTableTitle" className='mt-3'>
              Credit Logs
            </Typography>
            <div className='mt-3'>
              <CreditLogsTable
                open={open}
                setOpen={setOpen}
                selectedDevice={item}
                setDevice={setItem}
                updateTimestamp={updateTimestamp}
                setUpdateTimestamp={setUpdateTimestamp}
                paypalSubscriptionDetail={paypalSubscriptionDetail}
                setPaypalSubscriptionDetail={setPaypalSubscriptionDetail}
                logItems={logItems}
                setLogItems={setLogItems}
                escrowUser={escrowUser}
                setEscrowUser={setEscrowUser}
              />

              <>
                {
                  (user.administrator) ? (
                    <>
                      <hr style={{ marginTop: '2.5rem' }} />

                      <div className='' style={{ marginTop: '-60px' }}>
                        <CreditLogForm
                          open={open}
                          setOpen={setOpen}
                          selectedDevice={item}
                          updateTimestamp={updateTimestamp}
                          setUpdateTimestamp={setUpdateTimestamp}
                          setDeviceItem={setItem}
                        />
                      </div>
                    </>) : (<></>)
                }
              </>
            </div>

            <>
              {
                (!user.administrator) ? (
                  <>
                    <div className={classes.buttons} style={{ paddingTop: '15px', maxWidth: '400px', margin: 'auto' }}>
                      <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
                        Close
                      </Button>
                    </div>
                  </>) : (<></>)
              }
            </>

          </Box>
        </Box>
      </Modal>

      <>
        <PaypalSubscriptionModal
          open={paypalPaymentModalOpen}
          setOpen={setPaypalPaymentModalOpen}
          item={item}
          setItem={setItem}
          plan_id={planId}
          updateTimestamp={updateTimestamp}
          setUpdateTimestamp={setUpdateTimestamp}
          itemType={itemType}
        />
      </>

      <>
        <ConfirmDialog
          open={showConfirmModal}
          setOpen={setShowConfirmModal}
          title={APP_NAME}
          content={confirmText}
          textYes={`Agree`}
          textNo={`Cancel`}
          onClickYes={() => onClickAgreeConfirm()}
          onClickNo={() => onClickCancelConfirm()}
        />
      </>

      <>
        {
          (escrowUser && escrowUser.id) ? (
            <>
              <EscrowLogsModal
                open={escrowLogsModalOpen}
                setOpen={setEscrowLogsModalOpen}
                item={escrowUser}
                setItem={setEscrowUser}
                modalType={user.administrator ? 'user' : 'self'}
              />
            </>
          ) : (<></>)
        }
      </>

    </>
  );
};

export default CreditLogsModal;
