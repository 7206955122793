import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { sessionReducer as session } from './session';
import { devicesReducer as devices } from './devices';
import { positionsReducer as positions } from './positions';
import { geofencesReducer as geofences } from './geofences';
import { groupsReducer as groups } from './groups';
import { driversReducer as drivers } from './drivers';
import { maintenancesReducer as maintenances } from './maintenances';
import { layoutReducer as layout } from './layout';


const reducer = combineReducers({
  session:session,
  devices:devices,
  positions:positions,
  geofences:geofences,
  groups:groups,
  drivers:drivers,
  maintenances:maintenances,
  layout:layout,
});

export { sessionActions } from './session';
export { devicesActions } from './devices';
export { positionsActions } from './positions';
export { geofencesActions } from './geofences';
export { groupsActions } from './groups';
export { driversActions } from './drivers';
export { maintenancesActions } from './maintenances';
export { layoutActions } from './layout';


export default configureStore({ reducer });
