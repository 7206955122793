import React, { useState } from 'react';
import {
  Grid, Button, TextField, Typography, Link, makeStyles, Snackbar,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StartPage from '../../StartPage';
import { useTranslation } from '../../LocalizationProvider';
import useQuery from '../../common/useQuery';
import { api_call, console_log } from '../../helpers/untils';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    textTransform: 'uppercase',
  },
  link: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginTop: theme.spacing(0.5),
    cursor: 'pointer',
  },
}));

const SetPasswordForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();
  const query = useQuery();

  const token = query.get('q');


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [c_password, setCPassword] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    let response;
    if (password != c_password || password === "") {
      setMessage("Password does not match")
      setSnackbarOpen(true);
      return false;
    }
    let post_param = {
      token: token,
      password: password
    }
    response = await api_call('/api/password/set', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(post_param),
    });

    if (response.ok) {
      setMessage(t('loginUpdateSuccess'))
      setSnackbarOpen(true);
      redirectToLogin()
    } else {
      //const apiError = await response.json()
      setMessage("Invalid or expired link")
      setSnackbarOpen(true);
    }
  };

  const redirectToLogin = () => {
    setTimeout(() => {
      history.push('/login')
    }, 1500)
  }

  return (
    <StartPage>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        message={message}
      />
      <Grid container direction="column" spacing={2}>
        <Grid container item>
          <Grid item>
            <Typography className={classes.link} color="primary">
              <Link onClick={() => history.push('/login')}>
                <ArrowBackIcon />
              </Link>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography className={classes.title} color="primary">
              {t('loginReset')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            label={`Password`}
            name="password"
            value={password}
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            label={`Confrim Password`}
            name="c_password"
            value={c_password}
            type="password"
            onChange={(event) => setCPassword(event.target.value)}
            variant="filled"
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={!password && !c_password}
            fullWidth
          >
            {`Submit`}
          </Button>
        </Grid>
      </Grid>
    </StartPage>
  );
};

export default SetPasswordForm;
