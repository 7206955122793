import React, { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Checkbox
} from '@material-ui/core';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useTranslation } from '../LocalizationProvider';

const AddCoHostDeviceDialog = (props) => {
  const { open, onResult, definitions, definitionLength } = props
  const t = useTranslation();

  useEffect(() => {
    if (definitionLength && definitionLength > 0) {
      setDeviceList(definitions)
    }
  }, [definitionLength])

  const filter = createFilterOptions({
    stringify: (option) => option.name + "   " + option.uniqueId,
  });

  // const options = Object.entries(definitions).map((definition) => ({
  //   key: definition.id,
  //   name: definition.name,
  // }));

  // var options = [];
  // definitions.map((definition) => {
  //   options.push({
  //     key: definition.id,
  //     name: definition.name,
  //     uniqueId: definition.uniqueId,
  //   })
  // });

  const defaultItem = {
    kill_switch: true
  }
  const [item, setItem] = useState(defaultItem);
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState([]);

  const updateDeviceList = (idArr) => {
    let new_deivce_list = []
    const device_list = [...deviceList]
    for (let k in device_list) {
      if (idArr.includes(device_list[k]['id'])) {
        // continue
      } else {
        new_deivce_list.push(device_list[k])
      }
    }
    setDeviceList(new_deivce_list)
  }
  const getOptionKeysFromOptionList = (options) => {
    const idArr = []
    if (options) {
      for (let k in options) {
        let option = options[k]
        if (option) {
          idArr.push(option['id'])
        }
      }
    }
    //setSelectedDeviceIds(idArr)
    //updateDeviceList(idArr)
    return idArr;
  }

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogContent>
        <div className="co-host-device-modal">
          <FormControl
            variant="filled"
            margin="normal"
            fullWidth
          >
            <TextField
              margin="normal"
              value={item.email || ''}
              onChange={(event) => setItem({ ...item, email: event.target.value })}
              label={t('userEmail')}
              variant="filled"
            />
          </FormControl>

          <Autocomplete
            multiple
            onChange={(_, option) => {
              // setKey(option && typeof option === 'object' ? option.key : option);
              //console.log('options::', option)
              const deviceIds = getOptionKeysFromOptionList(option)
              //console.log('deviceIds::', deviceIds)
              setItem({ ...item, deviceIds: deviceIds.join(',') })
              // if (option && option.type) {
              //   setType(option.type);
              // }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue) {

              }
              return filtered;
            }}
            options={deviceList}
            getOptionLabel={(option) => option.name}//(option && typeof option === 'object' ? option.name : option)}
            renderOption={(option) => option.name}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label={t('sharedDevice')} variant="filled" margin="normal" />
            )}
          />

          <div>
            <FormControlLabel
              control={<Checkbox checked={item.kill_switch} onChange={(event) => setItem({ ...item, kill_switch: event.target.checked })} />}
              label="Killswitch"
            />
          </div>

        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!(item.email && item.deviceIds)}
          onClick={() => onResult(item)}
        >
          {t('sharedAdd')}
        </Button>
        <Button
          autoFocus
          onClick={() => onResult(false)}
        >
          {t('sharedCancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCoHostDeviceDialog;
