import React, { useEffect, useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton,
} from '@material-ui/core';

import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Button, FormControl, Container, Checkbox, FormControlLabel, Box, Snackbar, useMediaQuery,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';

import { sessionActions } from '../store';
import EditAttributesView from '../attributes/EditAttributesView';
import useDeviceAttributes from '../attributes/useDeviceAttributes';
import useUserAttributes from '../attributes/useUserAttributes';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';
import { api_call, calculateDeviceCount, calculateResellerDeviceCount, empty } from '../helpers/untils';
import { getIsAdmin } from '../common/selectors';
import { Stack } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { years, months } from './constants/data';
import moment from 'moment';
import CreditLogsInfoModal from '../settings/CreditLogsInfoModal';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(0),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    flexDirection: 'column',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
  },
  formSectionContainer: {
    alignItems: 'center',
    marginRight: '16px',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'flex-start',
    },
  },
  formBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  formLabel: {
    paddingLeft: '4px'
  },
  formSubmitButton: {
    marginTop: '24px'
  }
}));

const MissedInvoicePage = () => {
  const currentYear = moment().year();
  const currentMonth = moment().month();
  const [matchedYear, setMatchedYear] = useState(null);
  const [matchedMonth, setMatchedMonth] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [adding, setAdding] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const intervalRef = useRef(null); // Ref to store the interval ID
  const [creditModalOpen, setCreditModalOpen] = useState(false);
  const [deviceId, setDeviceId] = useState();
  const [itemID, setItemID] = useState(0);
  const [hideAddBtn, setHideAddBtn] = useState([])

  const viewMissedCredit = (itemID) => {
    console.log(itemID);

    setDeviceId(itemID)
    setCreditModalOpen(true)

  }

  const deleteRecord = (deviceId) => {
    setData((data) =>
      data.filter(record => record.id !== deviceId)
    );
  };

  useEffect(() => {
    const foundYear = years.find(year => year.label === currentYear.toString());
    const foundMonth = months.find(month => month.id === currentMonth);
    setMatchedYear(foundYear);
    setMatchedMonth(foundMonth);
    // const intervalId = setInterval(fetchResults, 5000); // Poll every 5 seconds
    // return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);


  const classes = useStyles();
  const theme = useTheme();

  const t = useTranslation();
  const isAdmin = useSelector(getIsAdmin);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))


  const handleSubmit = async (event) => {
    setData([])
    setHideAddBtn([])
    event.preventDefault();
    setLoading(true);
    showToast('Starting process...');

    const form_data = {
      month: matchedMonth,
      year: matchedYear,
    }

    try {
      api_call('/api/creditLogs/missed', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form_data),
      }).then(async (response) => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const processStream = async () => {
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              setLoading(false)
              break;
            }

            const chunk = decoder.decode(value, { stream: true });

            let resData = JSON.parse(chunk);
            if (resData[0].dummy != 0) {
              setData(prevData => [...prevData, ...resData]);
            }
          }
        };

        await processStream();

      })

    } catch (error) {
      console.error('Error fetching stream:', error);
    }
  }

  const handleMissedCredit = async (deviceId, date, amount) => {
    setItemID(deviceId);
    setAdding(true);
    if (empty(deviceId) || empty(date) || empty(amount)) {
      setToastMessage("Invalid invoice data");
      setAdding(false);
      return;
    }

    const note = `Add Invoice - Missed [${date}]`;
    const url = `/api/creditLogs`;
    const payload = {
      charge_type: 'invoice',
      device_id: deviceId,
      amount: amount,
      note: note
    }

    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });


    setAdding(false)
    if (response.ok) {
      const creditLog = await response.json();
      console.log(creditLog);
      deleteRecord(creditLog.to_id)
      setToastMessage(`Credit has been added successfully`);
      setSnackbarOpen(true);

      setHideAddBtn(prevData => [...prevData, deviceId])
      console.log(deviceId, hideAddBtn);

    } else {
      setToastMessage(`Unknown error`);
      setSnackbarOpen(true);
    }

  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const showToast = (msg) => {
    setToastMessage(msg)
    setSnackbarOpen(true)
  }

  return (
    <>
      {
        (isAdmin) ? (<>
          <OptionsLayout>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackbarOpen}
              onClose={() => { setSnackbarOpen(false) }}
              autoHideDuration={3000}
              message={toastMessage}
            />

            <Container maxWidth="md1" className={classes.container}>
              <Accordion expanded>
                <AccordionSummary>
                  <Typography variant="subtitle1">
                    Missed Invoices
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <form onSubmit={handleSubmit}>
                    <Box className={classes.formContainer} spacing={2}>
                      <Stack className={classes.formSectionContainer} direction={`row`} spacing={4}>
                        <Autocomplete
                          disablePortal
                          options={years}
                          getOptionLabel={(option) => option.label}
                          value={matchedYear}
                          onChange={(event, newValue) => {
                            setMatchedYear(newValue);
                          }}
                          sx={{ width: 200 }}
                          renderInput={(params) => <TextField {...params} label="Year" />}
                        />
                        <Autocomplete
                          disablePortal
                          options={months}
                          getOptionLabel={(option) => option.label}
                          value={matchedMonth}
                          onChange={(event, newValue) => {
                            setMatchedMonth(newValue);
                          }}
                          sx={{ width: 200 }}
                          renderInput={(params) => <TextField {...params} label="Month" />}
                        />
                        <Box className={classes.formBox}>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!matchedYear || !matchedMonth || loading}
                            className={classes.formSubmitButton}>
                            {`${(loading) ? 'Processing' : 'Search'}`}
                          </Button>
                        </Box>
                      </Stack>
                    </Box>
                  </form>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.columnAction} />
                          <TableCell>Unique ID</TableCell>
                          <TableCell>Missed Date</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>View</TableCell>
                          <TableCell>Add</TableCell>
                          {/* <TableCell>{t('sharedDisabled')}</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.uniqueId != null ? item.uniqueId : item.vehicleId}</TableCell>
                            <TableCell>{item.BillingToDate}</TableCell>
                            <TableCell>{item.monthly_cost}</TableCell>
                            <TableCell>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => viewMissedCredit(item.id)}
                              >
                                View
                              </Button>
                            </TableCell>
                            <TableCell>
                              {!hideAddBtn.includes(item.id) && (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  disabled={adding && itemID === item.id}
                                  id={item.id}
                                  onClick={() => handleMissedCredit(item.id, item.BillingToDate, item.monthly_cost)}
                                >
                                  {`${(adding && itemID === item.id) ? 'Adding' : 'Add'}`}
                                </Button>
                              )}
                            </TableCell>
                            {/* <TableCell>{formatBoolean(item.disabled, t)}</TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <CreditLogsInfoModal
                open={creditModalOpen}
                setOpen={setCreditModalOpen}
                item={deviceId}
                setItem={setDeviceId}
              />
            </Container>
          </OptionsLayout>
        </>) :
          (<></>)
      }
    </>
  );
};

export default MissedInvoicePage;
