import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  makeStyles,
} from '@material-ui/core';
import { Snackbar } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useState } from 'react';

import { GoogleMap, LoadScript, Marker, MarkerClusterer, InfoWindow } from '@react-google-maps/api';
import Map from './google-map/Map';
import InstallerMarker from './google-map/InstallerMarker';
import { api_call, console_log, is_null } from './helpers/untils';
import InstallerInfoWindow from './google-map/InstallerInfoWindow';
import { useRef } from 'react';

const defaultMapZoom = 8
const gapPosition = 0.001

const FindInstallerPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const [map, setMap] = useState(null)

  ///////////////////// init map center location //////////////////////////////
  const defaultPosition = { lat: 39.611063, lng: -105.043326 } // {lat: DEFAULT_GEO_LOCATION[0], lng: DEFAULT_GEO_LOCATION[1]}
  const [currentPosition, setCurrentPosition] = useState(defaultPosition);

  const initCurrentLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const positionData = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        console.log(`current-positionData::::`, positionData)
        setCurrentPosition(positionData);
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }

  useEffect(() => {
    initCurrentLocation()
    loadPageData()
  }, []);

  const [installerList, setInstallerList] = useState([])
  const uniquePositionsRef = useRef([])

  const loadPageData = async () => {
    const url = `/api/find-installers`
    const response = await api_call(url);
    if (response.ok) {
      const apiResObj = await response.json()
      console_log("apiResObj::::", apiResObj)
      const installer_list = apiResObj['installer_list']
      setInstallerList(installer_list)
      const marker_list = createMarkers(installer_list)
      console_log(`marker_list:::`, marker_list)
      setMarkers(marker_list)
    }
  }

  const getNewPosition = (unique_positions, position) => {
    let originPosition = { ...position }
    position = {
      lat: position.lat + gapPosition,
      lng: position.lng + gapPosition,
    }
    let positionStr = `${position.lat.toFixed(7)},${position.lng.toFixed(7)}`
    while (unique_positions.includes(positionStr)) {
      position = {
        lat: position.lat + gapPosition,
        lng: position.lng + gapPosition,
      }
      positionStr = `${position.lat.toFixed(7)},${position.lng.toFixed(7)}`
    }
    return position
  }

  const createMarkers = (installer_list) => {
    const marker_list = []
    let unique_positions = uniquePositionsRef.current
    for (let k in installer_list) {
      const row = installer_list[k]
      if (!is_null(row.latitude) && !is_null(row.longitude)) {
        let position = { lat: row.latitude, lng: row.longitude }
        let positionStr = `${position.lat.toFixed(7)},${position.lng.toFixed(7)}`
        let isDuplicated = false
        if (unique_positions.includes(positionStr)) {
          position = getNewPosition(unique_positions, position)
          positionStr = `${position.lat.toFixed(7)},${position.lng.toFixed(7)}`
          unique_positions.push(positionStr)
          isDuplicated = true
        } else {
          unique_positions.push(positionStr)
          isDuplicated = false
        }

        const marker_info = {
          //...row,
          id: row.id,
          position: position,
          label: `${row.zip_code}`,
          data: row,
          isDuplicated: isDuplicated
        }
        marker_list.push(marker_info)
      }
    }
    console_log(`unique_positions:::`, unique_positions)
    uniquePositionsRef.current = unique_positions
    return marker_list
  }

  const defaultMarkers = [
    // { position: { lat: 40.748817, lng: -73.985428 }, label: 'AAAAAAAA 111' },
    // { position: { lat: 40.74817, lng: -73.98628 }, label: 'BBB DFDDDF aaaER eEee' },
  ];
  const [markers, setMarkers] = useState(defaultMarkers)
  const [currentMarker, setCurrentMarker] = useState()
  const [currentInstallerData, setCurrentInstallerData] = useState()
  const [openInstallerInfoWindow, setOpenInstallerInfoWindow] = useState(false)

  const handleMarkerClick = async (e, marker) => {
    setOpenInstallerInfoWindow(false)

    console.log(`onClickMarker e, marker::::`, e, marker)
    const installerId = marker.id
    const url = `/api/get-installer-detail/${installerId}`
    const response = await api_call(url);
    if (response.ok) {
      const apiResObj = await response.json()
      console.log("apiResObj::::", apiResObj)
      const installer_data = apiResObj['installer_data']
      setCurrentInstallerData(installer_data)
      setCurrentMarker(marker)
      setOpenInstallerInfoWindow(true)
    } else {
      const errorObj = response
      let msg = "Invalid request!"
      if (errorObj?.error) {
        msg = errorObj.error
      }
      showToast(msg)
    }
  }

  const getCurrentMarkerPosition = (id) => {
    const row = installerList.find((item) => item.id === id)
    const position = { lat: row?.latitude, lng: row?.longitude }
    return position
  }

  useEffect(() => {
    if (map) {
      // const bounds = new window.google.maps.LatLngBounds();
      // Extend the bounds with the marker's position
      // bounds.extend(currentPosition);
      // map.fitBounds(bounds);


      // Set the central position after the map is loaded
      map.panTo(currentPosition);
      map.setZoom(defaultMapZoom)
    }
  }, [currentPosition, map]);

  /////////////////////////////////////////////////////////////////////
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toast, setToast] = useState("");
  const showToast = (msg) => {
    setToast(msg)
    setSnackbarOpen(true)
  }

  return (
    <>
      <>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          onClose={() => { setSnackbarOpen(false) }}
          autoHideDuration={1500}
          message={toast}
        />
      </>

      <Map
        map={map}
        setMap={setMap}
        currentPosition={currentPosition}
        zoom={defaultMapZoom}
      >
        { /* Child components, such as markers, info windows, etc. */}

        <>
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={60}
          >
            {(clusterer) => markers.map((marker, index) => (
              // <Marker
              //   key={index}
              //   position={marker.position}
              //   label={marker.label}
              //   clusterer={clusterer}
              // />

              <InstallerMarker
                key={index}
                position={marker.position}
                //label={marker.label}
                //clusterer={clusterer}
                onClick={(e) => handleMarkerClick(e, marker)}
                marker={marker}
                currentMarker={currentMarker}
                currentInstallerData={currentInstallerData}
              />

            ))}
          </MarkerClusterer>

          {openInstallerInfoWindow && (
            <>
              <InstallerInfoWindow
                position={getCurrentMarkerPosition(currentMarker.id)}
                marker={currentMarker}
                installerData={currentInstallerData}
                open={openInstallerInfoWindow}
                setOpen={setOpenInstallerInfoWindow}
              />
            </>
          )}
        </>
      </Map>
    </>
  )
}

export default FindInstallerPage // React.memo(FindInstallerPage)