import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { getDefaultSharedTextForClipboard } from '../helpers/misc';

const ShareTextDialog = (props) => {
  const { open, setOpen, title, content, textYes, textNo, onClickYes, onClickNo, onSaveSharedTextTemplate, sharedText } = props
  const handleClose = () => {
    setOpen(false);
  };

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  const [value, setValue] = useState("")
  useEffect(() => {
    if (open) {
      setValue(sharedText)
    }
  }, [open, sharedText])

  const onClickReset = () => {
    const defaultValue = getDefaultSharedTextForClipboard()
    setValue(defaultValue)
    onSaveSharedTextTemplate(defaultValue)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-shared-text"
    >
      {
        (title) && (
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
        )
      }

      <DialogContent>
        <div style={{ width: '440px', maxWidth: '100%' }}>
          <TextField
            fullWidth
            label=""
            multiline={true}
            minRows={9}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            variant="outlined" //standard, outlined
          />
        </div>
      </DialogContent>

      <DialogActions>
        <>
          <Button onClick={() => onClickReset()}>{`Reset`}</Button>
        </>
        {
          (textYes) && (
            <>
              <Button onClick={() => onClickYes(value)}>{textYes}</Button>
            </>
          )
        }
        {
          (textNo) && (
            <>
              <Button onClick={onClickNo}>{textNo}</Button>
            </>
          )
        }
      </DialogActions>
    </Dialog>
  );
};

export default ShareTextDialog;
