import React, { useState } from 'react';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@material-ui/core';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { console_log, empty } from '../helpers/untils';
import { useEffect } from 'react';

const GeofenceAutoCompleteSingleDropdown = (props) => {
  const { definitions = [], value, setValue, label = "Geofences", multiple = false } = props
  //console_log(`definitions:::`, definitions)

  const filter = createFilterOptions({
    stringify: (option) => option.name,
  });

  const options = [];
  definitions.map((definition) => {
    options.push({
      key: definition.id,
      name: definition.name,
    })
  });

  const getDefaultValue = () => {
    return definitions.find((item) => item.id === value);
  }

  const getOptionLabel = (option) => {
    const label = option.name
    return label
  }

  return (
    <>
      <Autocomplete
        onChange={(_, option) => {
          console.log("_, option", _, option)
          if (option) {
            setValue(option.key);
          } else {
            setValue(null);
          }
        }}

        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return filtered;
        }}
        options={options}
        defaultValue={getDefaultValue()}
        getOptionLabel={(option) => getOptionLabel(option)}
        renderOption={(option) => getOptionLabel(option)}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={label} variant="filled" margin="normal" />
        )}
      />
    </>
  );
};

const GeofenceAutoCompleteMultiDropdown = (props) => {
  const { definitions = [], value = [], setValue, label = "Geofences", multiple = false } = props
  // console_log(`definitions:::`, definitions, value)

  const [mValue, setMValue] = useState([])

  const filter = createFilterOptions({
    stringify: (option) => option.name,
  });

  const getOptionItem = (definition) => {
    if (definition) {
      return {
        id: definition.id,
        name: definition.name,
      }
    }
  }

  const options = definitions.map((definition) => getOptionItem(definition));

  useEffect(() => {
    if (value) {
      const m_value = value.map((id) => getOptionItem(definitions.find((item) => item.id === id)));
      //console_log(`m_value:::`, m_value)
      setMValue(m_value)
    } else {
      setMValue([])
    }
  }, [value, definitions])

  const getDefaultValue = () => {
    return []
  }

  const getOptionLabel = (option) => {
    const label = option?.name
    return label
  }

  const checkOptionSelected = (option) => {
    if (mValue) {
      const selected = mValue.find((item) => item.id === option.id)
      if (selected) {
        return true
      }
    }
    return false
  }

  const checkDuplicatedValue = (valueList, v) => {
    let cnt = 0
    for (let k in valueList) {
      if (valueList[k].id === v?.id) {
        cnt++;
        if (cnt >= 2) {
          return true
        }
      }
    }
    return false
  }

  const onChnageOption = (option, changedValue) => {
    //console_log(`changedValue::::`, changedValue, changedValue.id)
    let newOption = []
    if (option) {
      if (checkDuplicatedValue(option, changedValue)) {
        newOption = option.filter((item) => item.id !== changedValue?.id)
      } else {
        newOption = option
      }
    }
    setMValue(newOption)

    const newValue = newOption.map((item) => item.id)
    console_log(`newValue:::`, newValue)
    setValue(newValue)
  }

  return (
    <>
      <Autocomplete
        multiple
        onChange={(_, option, reason, details) => {
          console_log("_, option, reason, details", _, option, reason, details)
          onChnageOption(option, details?.option)
        }}
        freeSolo
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return filtered;
        }}
        options={options}
        defaultValue={getDefaultValue()}
        value={mValue}
        getOptionLabel={(option) => getOptionLabel(option)}
        // renderOption={(option) => getOptionLabel(option)}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              // icon={icon}
              // checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={checkOptionSelected(option)}
            />
            {getOptionLabel(option)}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="standard" />
        )}
      />
    </>
  );
};

const GeofenceAutoCompleteDropdown = (props) => {
  const { definitions = [], value, setValue, label = "Geofences", multiple = false } = props
  //console_log(`definitions:::`, definitions)
  return (
    <>
      {
        (multiple) ? (
          <>
            <GeofenceAutoCompleteMultiDropdown
              {...props}
            />
          </>
        ) : (
          <>
            <GeofenceAutoCompleteSingleDropdown
              {...props}
            />
          </>
        )
      }
    </>
  );
};

export default GeofenceAutoCompleteDropdown;
