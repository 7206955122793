import React, { useState, useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useEffectAsync } from './reactHelper';
import {
  makeStyles, Paper, Toolbar, TextField, IconButton, Button,
} from '@material-ui/core';

import { positionsActions, devicesActions, sessionActions } from './store';

import { trueAuthenticated } from './SocketController';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import DevicesList from './DevicesList';
import Map, { map } from './map/Map';
import SelectedDeviceMap from './map/SelectedDeviceMap';
import AccuracyMap from './map/AccuracyMap';
import GeofenceMap from './map/GeofenceMap';
import CurrentPositionsMap from './map/CurrentPositionsMap';
import CurrentLocationMap from './map/CurrentLocationMap';
import { useTranslation } from './LocalizationProvider';
import PoiMap from './map/PoiMap';
import MapPadding from './map/MapPadding';
import { api_call, console_log, empty, getUnixTimestampFromTimezone, get_data_value } from './helpers/untils';
import moment from 'moment';
import TemporaryBottomMenu from './components/TemporaryBottomMenu';
import TuroTripTemporaryUploadPage from './TuroTripTemporaryUploadPage';
import TuroTripTemporarySoonPage from './TuroTripTemporarySoonPage';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    top: 0,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    bottom: 56,
    zIndex: 1301,
    transition: 'transform .5s ease',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-100vw)',
    },
  },
  paper: {
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  deviceList: {
    flex: 1,
  },
  sidebarToggle: {
    position: 'absolute',
    left: theme.spacing(1.5),
    top: theme.spacing(3),
    borderRadius: '0px',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: '#777777',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

const TuroTripTemporaryPage = () => {
  //const current = moment();

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const t = useTranslation();
  const dispatch = useDispatch();

  const { shareUrl } = useParams();

  const [authenticated, setAuthenticated] = useState(true);

  const refreshAll = async () => {
    const responseSession = await api_call(`/api/auth`);
    if (responseSession.ok) {
      const user = await responseSession.json();
      dispatch(sessionActions.updateUser({ ...user, shareUrl }));
    } else {
      const user = { id: 0 }
      dispatch(sessionActions.updateUser({ ...user, shareUrl }));
    }
    setAuthenticated(true);
    trueAuthenticated();
  }

  const [currentLink, setCurrentLink] = useState(null)
  const [currentTripInfo, setCurrentTripInfo] = useState(null)
  const [currentDevice, setCurrentDevice] = useState(null)
  const [currentCommands, setCurrentCommands] = useState({})


  const [current, setCurrent] = useState(moment().unix())
  const [currentTimestamp, setCurrentTimestamp] = useState(moment().unix())
  const startCounter = () => {
    setTimeout(function () {
      setCurrent(moment().unix())
      setCurrentTimestamp(moment().unix())
      checkLinkIsActive()
    }, 10000)
  }

  const loadLinkInfo = async (cb) => {
    if (shareUrl) {
      const response = await api_call(`/api/turo-trip-links/${shareUrl}`);
      if (response.ok) {
        const linkData = await response.json();
        console_log("linkData:::", linkData)
        const link = linkData.link
        const tripInfo = linkData.tripInfo
        const device = linkData.deviceInfo
        const commands = linkData.commands

        if(device.turo_settings) {
          setCurrentLink(link)
          setCurrentTripInfo(tripInfo)
          setCurrentDevice(device)
          setCurrentCommands(commands)
  
          dispatch(devicesActions.refresh([device]));
          dispatch(devicesActions.select({ id: device.id }));
          return true

        }else{
          alert("Invalid url");
          history.push("/main")
          return false
        }

      } else {
        const error = response
        console_log("error:::", error)
        alert(error.error)
        return false
      }
    } else {
      return false
    }
  }

  useEffectAsync(async () => {
    refreshAll()
    startCounter()
  }, [shareUrl]);

  const loopLoadLinkInfo = async () => {
    const rslt = await loadLinkInfo()
    if(rslt) {
      setTimeout(() => {
        loopLoadLinkInfo()
      }, 3000);
    }
  }
  useEffect(() => {
    loopLoadLinkInfo()
  }, [shareUrl]);


  const indexes = [1, 2, 3, 4, 5] // turo upload indexes
  const getUploadKeyList = (key = "avail") => {
    const turo_settings = currentDevice.turo_settings
    let key_list = []
    for (let i in indexes) {
      let k = key + i
      if (turo_settings[k]) {
        key_list.push(k)
      }
    }
    return key_list
  }
  const sholdUploadPhoto = (type = "avail") => {
    if (currentLink && currentTripInfo && currentDevice) {
      const turo_settings = currentDevice.turo_settings
      if (turo_settings[type + '_car']) {
        let keyList = getUploadKeyList(type)
        if (keyList && keyList.length > 0) {
          const photo_uploads = currentLink.photo_uploads
          let photo_uploads_obj = {}
          if (photo_uploads) {
            photo_uploads_obj = JSON.parse(photo_uploads)
          }
          if (photo_uploads_obj[type]) {
            return false
          } else {
            return true
          }
        }
      }
    }
    return false
  }

  const getApproveStatus = (type = "avail") => {
    let approve_status = "denied"
    if (currentLink && currentTripInfo && currentDevice) {
      const turo_settings = currentDevice.turo_settings
      if (turo_settings[type + '_car']) {
        let keyList = getUploadKeyList(type)
        if (keyList && keyList.length > 0) {
          const photo_uploads = currentLink.photo_uploads
          let photo_uploads_obj = {}
          if (photo_uploads) {
            photo_uploads_obj = JSON.parse(photo_uploads)
          }
          let approve_key = type + "_approved"
          approve_status = get_data_value(photo_uploads_obj, approve_key, "denied")
        }
      } else {
        approve_status = "approved"
      }
    } else {
      approve_status = "denied"
    }
    return approve_status
  }

  const [deltaTimestamp, setDeltaTimestamp] = useState(0)
  const checkLinkIsActive = () => {
    let result = 0
    let delta = 0
    if (currentDevice) {
      const turo_settings = currentDevice.turo_settings
      //console.log("turo_settings:::", turo_settings)
      if (turo_settings) {
        let url_start_minutes = Number(turo_settings['url_start_minutes'])
        let url_ends_minutes = Number(turo_settings['url_ends_minutes'])
        let startTimestamp = getUnixTimestampFromTimezone(currentTripInfo.trip_start, currentTripInfo.tzid)
        let endTimestamp = getUnixTimestampFromTimezone(currentTripInfo.trip_end, currentTripInfo.tzid)

        if (currentTimestamp - startTimestamp > -1 * url_start_minutes * 60) {
          if (currentTimestamp - endTimestamp < url_ends_minutes * 60) {
            result = 1
          } else {
            delta = -1 * (currentTimestamp - endTimestamp - url_ends_minutes * 60) // minus delta (link expired delta seconds agod)
          }
        } else {
          delta = startTimestamp - currentTimestamp - url_start_minutes * 60 //plus delta (link will start delta seconds)
        }
      }
    }
    //setDeltaTimestamp(delta)
    //console.log("checkLinkIsActive::::", result)
    return result
  }

  const getDeltaTimestamp = () => {
    let result = 0
    let delta = 0
    if (currentDevice) {
      const turo_settings = currentDevice.turo_settings
      if (turo_settings) {
        //console.log("turo_settings", turo_settings)
        //console.log("currentTripInfo", currentTripInfo)
        let url_start_minutes = Number(turo_settings['url_start_minutes'])
        let url_ends_minutes = Number(turo_settings['url_ends_minutes'])
        let startTimestamp = getUnixTimestampFromTimezone(currentTripInfo.trip_start, currentTripInfo.tzid)
        let endTimestamp = getUnixTimestampFromTimezone(currentTripInfo.trip_end, currentTripInfo.tzid)

        if (currentTimestamp - startTimestamp > -1 * url_start_minutes * 60) {
          if (currentTimestamp - endTimestamp < url_ends_minutes * 60) {
            result = 1
          } else {
            delta = -1 * (currentTimestamp - endTimestamp - url_ends_minutes * 60) // minus delta (link expired delta seconds agod)
          }
        } else {
          delta = startTimestamp - currentTimestamp - url_start_minutes * 60 //plus delta (link will start delta seconds)
        }
      }
    }

    return delta
  }

  return (
    <div className={classes.root}>
      {
        (authenticated) ? (
          <>
            {
              (!empty(currentLink) && currentTripInfo && currentDevice) ? (
                <>
                  {
                    (currentDevice.turo_settings) ? (
                      <>
                        {
                          (currentTripInfo.auto_send_result && getApproveStatus() !== "approved") ? (
                            <>
                              <TuroTripTemporaryUploadPage
                                link={currentLink}
                                setLink={setCurrentLink}
                                tripInfo={currentTripInfo}
                                device={currentDevice}
                                current_timestamp={currentTimestamp}
                                type="avail"
                                approve_status={getApproveStatus()}
                                loadLinkInfo={() => loadLinkInfo()}
                              />
                            </>
                          ) : (
                            <>
                              {
                                (checkLinkIsActive() === 1) ? (
                                  <>
                                    {
                                      (getApproveStatus() !== "approved") ? (
                                        <>
                                          <TuroTripTemporaryUploadPage
                                            link={currentLink}
                                            setLink={setCurrentLink}
                                            tripInfo={currentTripInfo}
                                            device={currentDevice}
                                            current_timestamp={currentTimestamp}
                                            type="avail"
                                            approve_status={getApproveStatus()}
                                            loadLinkInfo={() => loadLinkInfo()}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <Map>
                                            <CurrentPositionsMap temporaryPage={true} currentLink={currentLink} commands={currentCommands} />
                                            <SelectedDeviceMap />
                                            <PoiMap />
                                          </Map>

                                          <TemporaryBottomMenu deviceId={currentLink.deviceId} />
                                        </>
                                      )
                                    }
                                  </>
                                )
                                  :
                                  (
                                    <>
                                      <TuroTripTemporarySoonPage
                                        deltaTimestamp={getDeltaTimestamp()}
                                      />
                                    </>
                                  )
                              }
                            </>
                          )
                        }
                      </>
                    ) : (
                      <>
                        {/* <div>Invalid url</div> */}
                      </>
                    )
                  }
                </>
              ) : (
                <></>
              )
            }
          </>
        ) : (
          <div></div>
        )
      }
    </div>
  );
};

export default TuroTripTemporaryPage;
