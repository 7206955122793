import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles, Button, Box, Typography,
} from '@material-ui/core';


import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { api_call, empty, isContainsOnlyDigits, is_null } from '../helpers/untils';
import { Stack } from '@mui/material';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import { APP_NAME } from '../config/constant';
import LockUnlockSettingModal from '../settings/LockUnlockSettingModal';

const SENSITIVITY_MIN = 3
const SENSITIVITY_MAX = 15
const SENSITIVITY_DEFAULT_VAL = 9
const SensitivityMarks = [
  {
    value: SENSITIVITY_MIN,
    label: 'Most',
  },
  {
    value: SENSITIVITY_MAX,
    label: 'Least',
  },
];

const DURATION_MIN = 10
const DURATION_MAX = 99
const DURATION_DEFAULT_VAL = 45
const DurationMarks = [
  {
    value: DURATION_MIN,
    label: 'Short',
  },
  {
    value: DURATION_MAX,
    label: 'Long',
  },
];

const useStyles = makeStyles((theme) => ({
  bluetoothModal: {
    // width: '600px',
  },
  bluetoothConfirmModal: {
    width: '400px',
    margin: 'auto'
  },
}));

const ShockAlamDialog = (props) => {
  const { open, setOpen, device, userDeviceItem, userInfo } = props;
  const dispatch = useDispatch();

  const user = userInfo ?? useSelector((state) => state.session.user);
  //console.log("user:::", user)
  const classes = useStyles();

  const showToast = (msg) => {
    window.alert(msg)
  }

  const handleDialogClose = () => {
    setOpen(false);
  };

  ////////////////////////////////////////////////////
  const [apiLoading, setApiLoading] = useState(false)

  const defaultFormData = {
    mt3v_shock_status: false,
    mt3v_shock_sensitivity: SENSITIVITY_DEFAULT_VAL,
    mt3v_shock_duration: DURATION_DEFAULT_VAL
  }
  const [formData, setFormData] = useState(defaultFormData)

  useEffect(() => {
    if (userDeviceItem) {
      const form_data = {
        ...formData
      }
      if (!is_null(userDeviceItem.mt3v_shock_status)) {
        form_data['mt3v_shock_status'] = userDeviceItem.mt3v_shock_status
      }
      if (!is_null(userDeviceItem.mt3v_shock_sensitivity)) {
        form_data['mt3v_shock_sensitivity'] = userDeviceItem.mt3v_shock_sensitivity
      }
      if (!is_null(userDeviceItem.mt3v_shock_duration)) {
        form_data['mt3v_shock_duration'] = userDeviceItem.mt3v_shock_duration
      }
      setFormData(form_data)
    }
  }, [userDeviceItem])


  const handleChangeStatus = (e) => {
    const checked = e.target.checked
    const form_data = { ...formData }
    form_data['mt3v_shock_status'] = checked
    setFormData(form_data)
  }
  const onChangeText = (e, field_name) => {
    let value = e.target.value
    const form_data = { ...formData }
    form_data[field_name] = value
    setFormData(form_data)
  }

  const handleChangeSlider = (e, value, field_name) => {
    const form_data = { ...formData }
    form_data[field_name] = value
    setFormData(form_data)
  }

  const checkFormValidation = () => {
    const form_data = { ...formData }

    return true
  }

  const checkPaymentRequired = () => {
    return false

    // if (user.administrator) {
    //   return false
    // }

    // const curMoment = moment();
    // const deviceMoment = moment(userDeviceItem.createdAt)
    // let timeDiff = curMoment.diff(deviceMoment, "days");
    // if (timeDiff > 30) {
    //   return true
    // } else {
    //   return false
    // }
  }

  const handleSaveSetting = () => {
    const isValid = checkFormValidation()
    if (isValid) {
      // const paymentRequired = checkPaymentRequired()
      // if (paymentRequired) {
      //   setShowConfirmDialog(true)
      // } else {
      //   saveSetting(false)
      // }
      saveSetting(false)
    }
  }

  const saveSetting = async (paymentRequired = false) => {
    setApiLoading(true)
    setNotificationShow(true)
    setShowConfirmDialog(false)
    let url = `/api/devices/update-shock-sensor-setting`;
    const data = {
      deviceId: device.id,
      shock_sensor_setting: formData,
      paymentRequired: paymentRequired ? '1' : '0'
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const resData = await response.json();
      handleDialogClose()
    } else {
      const errorObj = response
      if (errorObj['error']) {
        showToast(errorObj['error'])
      }
    }
    setApiLoading(false)
    setNotificationShow(false)
  }

  const [notificationShow, setNotificationShow] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState("Please wait...")

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const handleConfirmDialogClose = () => {
    setShowConfirmDialog(false);
  }
  const handleConfirmDialogAgree = () => {
    saveSetting(true)
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [lockUnlockDevice, setLockUnlockDevice] = useState(null)
  const loadMt2vLockUnlockSetting = async () => {
    const url = `/api/devices/${userDeviceItem.id}`;
    setApiLoading(true)
    let response = await api_call(url);
    setApiLoading(false)
    if (response.ok) {
      const apiData = await response.json()
      setLockUnlockDevice(apiData)
    } else {
      showToast("Invalid request")
    }
  }

  const [showLockUnlockModal, setShowLockUnlockModal] = useState(false)
  const onClickLockUnlockSetting = async () => {
    await loadMt2vLockUnlockSetting()
    setShowLockUnlockModal(true)
  }

  return (
    <>
      <Dialog fullWidth={true} maxWidth={`xs`} open={open} onClose={handleDialogClose}>

        <DialogTitle>{`Shock Alam`}</DialogTitle>
        <DialogContent>
          <Box className={classes.bluetoothModal}>
            <Stack direction={`column`} spacing={3}>
              <Box>
                <Stack direction={`row`} alignItems={`center`} justifyContent={`flex-start`} spacing={2}>
                  <DialogContentText style={{ marginBottom: 0 }}>
                    {`Status`}
                  </DialogContentText>
                  <Switch
                    checked={formData['mt3v_shock_status']}
                    onChange={handleChangeStatus}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography>
                    {formData['mt3v_shock_status'] ? 'On' : 'Off'}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <DialogContentText>
                  {`Sensitivity`}
                </DialogContentText>
                <Box sx={{ px: 2.5, py: 0 }}>
                  <Slider
                    aria-label="Sensitivity"
                    getAriaValueText={(valuetext) => { return valuetext }}
                    step={1}
                    valueLabelDisplay="auto"
                    marks={SensitivityMarks}
                    min={SENSITIVITY_MIN}
                    max={SENSITIVITY_MAX}
                    value={formData['mt3v_shock_sensitivity']}
                    onChange={(e, value) => handleChangeSlider(e, value, "mt3v_shock_sensitivity")}
                  />
                </Box>
              </Box>
              <Box>
                <DialogContentText>
                  {`Horn Duration`}
                </DialogContentText>
                <Box sx={{ px: 2.5, py: 0 }}>
                  <Slider
                    aria-label="Horn Duration"
                    getAriaValueText={(valuetext) => { return valuetext }}
                    step={1}
                    valueLabelDisplay="auto"
                    marks={DurationMarks}
                    min={DURATION_MIN}
                    max={DURATION_MAX}
                    value={formData['mt3v_shock_duration']}
                    onChange={(e, value) => handleChangeSlider(e, value, "mt3v_shock_duration")}
                  />
                </Box>
              </Box>

              <Box>
                <div className={classes.normalColumn}>
                  <Button variant="text" onClick={() => onClickLockUnlockSetting()}>{`Lock and Unlock settings >>`}</Button>
                </div>
              </Box>

            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={apiLoading} onClick={handleDialogClose}>Cancel</Button>
          <Button disabled={apiLoading} onClick={handleSaveSetting}>Save</Button>
        </DialogActions>

        {/* <Snackbar
          style={{ height: "100%", zIndex: 99999 }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={notificationShow}
          message={notificationMessage}
          onClose={() => { setNotificationShow(false) }}
        /> */}

      </Dialog>

      {
        (showConfirmDialog) ? (
          <Dialog fullWidth={true} maxWidth={`xs`} open={showConfirmDialog} onClose={handleConfirmDialogClose} className={classes.bluetoothConfirmModal}>
            <DialogTitle>{APP_NAME}</DialogTitle>
            <DialogContent>
              <Box>
                <Stack direction={`column`} spacing={3}>
                  <Box>
                    <DialogContentText color='primary'>
                      {`Cost to change Bluetooth is $1.`}
                    </DialogContentText>
                  </Box>
                </Stack>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button disabled={apiLoading} onClick={handleConfirmDialogClose}>Cancel</Button>
              <Button disabled={apiLoading} onClick={handleConfirmDialogAgree}>Agree</Button>
            </DialogActions>
          </Dialog>
        ) : (<></>)
      }

      {
        (showLockUnlockModal) && (lockUnlockDevice) && (
          <LockUnlockSettingModal
            open={showLockUnlockModal}
            setOpen={setShowLockUnlockModal}
            item={lockUnlockDevice}
            setItem={setLockUnlockDevice}
          />
        )
      }
    </>
  );
}

export default ShockAlamDialog;
