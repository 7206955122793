import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton, Button, Box,
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import { formatBoolean } from '../common/formatter';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';

import moment from 'moment';
import { api_call, console_log, empty, generateSharedFullUrl, getDistanceFromMiles, is_null } from '../helpers/untils';
import CoHostDevicesPage from './CoHostDevicesPage';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  radioGroupBox: {
    width: '100%'
  },
  radioGroupListBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  inviteButton: {
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 20,
    paddingRight: 20,
    height: 36,
    textTransform: 'none',
  },
  coHostContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  coHostButtonBox: {
    width: 120,
    height: 46,
    display: 'inline-flex'
  },
}));

var users;

const getUsers = async () => {
  const response = await api_call('/api/user-name-list');
  if (response.ok) {
    users = await response.json();
  }
};

const LinksView = ({ updateTimestamp, onMenuClick }) => {
  const classes = useStyles();
  const t = useTranslation();

  const user = useSelector((state) => state.session.user);
  const [items, setItems] = useState([]);

  useEffectAsync(async () => {
    if (empty(user)) {
      return false
    }
    await getUsers();

    var url = `/api/links`;
    const response = await api_call(url);
    if (response.ok) {
      setItems(await response.json());
    }
  }, [updateTimestamp, user]);

  const devices = useSelector((state) => state.devices.items);
  //console_log("devices::::::::::::", devices)

  const getDeviceItem = (deviceId) => {
    return devices[deviceId] ?? {}
  };
  const getDeviceName = (deviceId) => {
    for (const key in devices) if (devices.hasOwnProperty(key) && devices[key].id === deviceId) return devices[key].name;
  };
  const getUserName = (userId) => {
    for (const key in users) if (users.hasOwnProperty(key) && users[key].id === userId) return users[key].name;
  };
  const getUserEmail = (userId) => {
    for (const key in users) if (users.hasOwnProperty(key) && users[key].id === userId) return users[key].email;
  };

  const [value, setValue] = useState('active');// ('all');

  const handleChange = (event) => {
    let v = event.target.value
    //console_log('v::::', v)
    setValue(v);
  };
  const current = moment();


  ///////////////////////////////////////////////////////////////////////////////////////
  const [addDialogShown, setAddDialogShown] = useState(false);
  const onClickInvite = () => {
    setAddDialogShown(true)
  }

  //////////////////////////////////////////////////////////////////////////////////////
  const [coHostDevices, setCoHostDevices] = useState([])
  const [tableTimestamp, setTableTimestamp] = useState(0)
  useEffectAsync(async () => {
    if (empty(user)) {
      return false
    }
    var url = `/api/devices/co-host/get-all-data`;
    if (user.administrator) {
      url += "?owner_user_id=" + user.id
    } else {
      url += "?owner_user_id=" + user.id
    }
    const response = await api_call(url);
    if (response.ok) {
      const apiRes = await response.json()
      setCoHostDevices(apiRes['data'])
    } else {
      setCoHostDevices([])
    }
  }, [tableTimestamp, user]);

  const getSharedFullUrl = (item) => {
    let useSecondDomain = false
    const share_settings = item.share_settings
    if (share_settings) {
      const share_settings_obj = JSON.parse(share_settings)
      if (share_settings_obj['useSecondDomain']) {
        useSecondDomain = true
      }
    }
    const url = generateSharedFullUrl(item.shareUrl, useSecondDomain)
    return url
  }

  return (
    <>
      <FormControl className={classes.radioGroupBox}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <div className={classes.radioGroupListBox}>
            <div>
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel value="old" control={<Radio />} label="Expired" />
              <FormControlLabel value="active" control={<Radio />} label="Active" />
              <FormControlLabel value="future" control={<Radio />} label="Future" />
            </div>
            {
              (user.administrator) ? (
                <></>
              ) : (
                <>
                  <div className={classes.coHostContainer}>
                    <FormControlLabel value="co-host" control={<Radio />} label="Co-Host" />
                    <div className={classes.coHostButtonBox}>
                      {
                        (value === 'co-host') ? (
                          <Button type="button" color="primary" variant="contained" size="medium" className={classes.inviteButton} onClick={() => onClickInvite()}>Invite</Button>
                        ) : (
                          <></>
                        )
                      }
                    </div>
                  </div>
                </>
              )
            }
          </div>
        </RadioGroup>
      </FormControl>

      {
        (value === 'co-host') ? (
          <>
            <CoHostDevicesPage
              devices={devices}
              user={user}
              addDialogShown={addDialogShown}
              setAddDialogShown={setAddDialogShown}
              data={coHostDevices}
              setTableTimestamp={setTableTimestamp}
              tableTimestamp={tableTimestamp}
            />
          </>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.columnAction} />
                    <TableCell>User</TableCell>
                    <TableCell>Device</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Allot</TableCell>
                    <TableCell>Driven</TableCell>
                    <TableCell>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item) => {
                    if ((moment(item.from) < current && current < moment(item.to) && value === "active") ||
                      (moment(item.from) < current && moment(item.to) < current && value === "old") ||
                      (current < moment(item.from) && current < moment(item.to) && value === "future") ||
                      value === "all") {
                        const deviceItem = getDeviceItem(item.deviceId)
                      return (
                        <TableRow key={item.id}>
                          <TableCell className={classes.columnAction} padding="none">
                            <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id, ((moment(item.from) < current && current < moment(item.to)) ? true : false))}>
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>{getUserName(item.userId)}</TableCell>
                          <TableCell>{deviceItem?.name}</TableCell>
                          <TableCell>{moment(item.from).format('LLL')}</TableCell>
                          <TableCell>{moment(item.to).format('LLL')}</TableCell>
                          <TableCell><div className={`table-typograph ${(!is_null(item.allot_miles) && item.distance > item.allot_miles) ? 'error' : ''}`}>{getDistanceFromMiles(item.allot_miles, deviceItem.distance_unit)}</div></TableCell>
                          <TableCell><div className={`table-typograph ${(!is_null(item.allot_miles) && item.distance > item.allot_miles) ? 'error' : ''}`}>{getDistanceFromMiles(item.distance, deviceItem.distance_unit)}</div></TableCell>
                          <TableCell>{getSharedFullUrl(item)}</TableCell>
                        </TableRow>);
                    }
                    else {
                      return (<></>);
                    }
                  }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
      }

    </>
  );
};

const LinksPage = () => {
  return (
    <>
      <OptionsLayout>
        <EditCollectionView content={LinksView} editPath="/link" endpoint="links" disableAdd={true} />
      </OptionsLayout>
    </>
  )
}

export default LinksPage;
