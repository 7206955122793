import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ConfirmDialog = (props) => {
  const { open, setOpen, title, content, textYes, textNo, onClickYes, onClickNo } = props
  const handleClose = () => {
    setOpen(false);
  };

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {
        (title) && (
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
        )
      }

      {
        (content) && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>
          </DialogContent>
        )
      }

      <DialogActions>
        {
          (textYes) && (
            <>
              <Button onClick={onClickYes}>{textYes}</Button>
            </>
          )
        }
        {
          (textNo) && (
            <>
              <Button onClick={onClickNo}>{textNo}</Button>
            </>
          )
        }
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
