import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import { useDispatch } from 'react-redux';
import { MACHINE_ENVIRONMENT, RECAPTCHA_ENABLED, RECAPTCHA_KEY } from '../config/constant';
import { getSettingPersist } from '../helpers/misc';

const RECAPTCHA_DEFAULT_VALUE = "xxxxxxxxxxxxxxxxx"
const CaptchaBox = (props) => {
  const { recaptcha, setRecaptcha, captchaTimestamp, captchaKey = "" } = props
  const dispatch = useDispatch();

  const captchaRef = useRef(null)
  const setCaptchaRef = (ref) => {
    if (ref) {
      captchaRef.current = ref
    }
  }
  const resetCaptcha = () => {
    // maybe set it till after is submitted
    if(captchaRef && captchaRef.current) {
      captchaRef.current.reset();
    }
  }

  useEffect(() => {
    if (RECAPTCHA_ENABLED === "false") {
      setRecaptcha(RECAPTCHA_DEFAULT_VALUE)
    }
  }, [])

  const [captchaEnabled, setCaptchaEnabled] = useState(false)
  const checkCaptchaEnabled = () => {
    const captchaSettingEnabledValue = getSettingPersist(captchaKey, false)
    console.log("captchaSettingEnabledValue", captchaSettingEnabledValue)
    if (captchaSettingEnabledValue) { // show captcha
      setCaptchaEnabled(true)
      setRecaptcha((MACHINE_ENVIRONMENT === 'dev' || MACHINE_ENVIRONMENT === 'test') ? RECAPTCHA_DEFAULT_VALUE : "") //recaptcha
      if (window && window.grecaptcha) {
        resetCaptcha()
      }
    }
    else { // don't show captcha
      setCaptchaEnabled(false)
      setRecaptcha(RECAPTCHA_DEFAULT_VALUE)
    }
  }

  useEffect(() => {
    checkCaptchaEnabled(captchaKey)
  }, [captchaKey, captchaTimestamp])

  const onChange = (value) => {
    //console.log("ReCAPTCHA onchange::::", value);
    setRecaptcha(value);
  }

  const onExpired = () => {
    console.log("ReCAPTCHA onExpired::::");
    setRecaptcha((MACHINE_ENVIRONMENT === 'dev' || MACHINE_ENVIRONMENT === 'test') ? RECAPTCHA_DEFAULT_VALUE : "")
  }

  return (
    <>
      {
        (RECAPTCHA_ENABLED === "false") ? (
          <></>
        ) : (
          <>
            {
              (captchaEnabled) ? (
                <>
                  <ReCAPTCHA
                    ref={(r) => setCaptchaRef(r)}
                    sitekey={RECAPTCHA_KEY}
                    onChange={onChange}
                    onExpired={onExpired}
                  />
                </>
              ) : (
                <></>
              )
            }
          </>
        )
      }
    </>
  );
};

export default CaptchaBox;
