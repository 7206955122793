import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    ButtonBase,
    makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CameraUploadBox from './CameraUploadBox';
import { useState } from 'react';
import { empty } from '../../helpers/untils';
import CameraImageBox from './CameraImageBox';

const useStyles = makeStyles((theme) => ({
    hidden: {
        display: 'none'
    },
    CameraUploadModalContainer: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 9999,
        backgroundColor: '#000000'
    },
    CameraUploadModalWrapper: {
        position: 'relative',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#777777'
    },
    CameraUploadModalCloseBox: {
        position: 'absolute',
        top: '16px',
        right: '16px',
        zIndex: 3
    },
    CameraUploadModalCloseBtn: {
        padding: '4px',
        backgroundColor: '#ff0000',
        borderRadius: '100px',
        overflow: 'hidden',
        color: '#ffffff',
        border: '1px solid #ffffff'
    }
}));

const CameraUploadModal = (props) => {
    const { open, setOpen, onPickupPhoto, cameraSide = '', cameraOpenTimestamp, setCameraOpenTimestamp } = props
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    //////////////////////// app modal close feature //////////////////////////////////////////
    // const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
    // useEffect(() => {
    //     if (appModalCloseTimestamp) {
    //         setOpen(false)
    //     }
    // }, [appModalCloseTimestamp]);
    ///////////////////////////////////////////////////////////////////////////////////////////

    const [imageDataURL, setImageDataURL] = useState(null);

    const onClickPickupPhoto = () => {
        onPickupPhoto(imageDataURL)
        setImageDataURL('')
        handleClose()
    }

    useEffect(() => {
        if (open) {
            setImageDataURL('')
        }
    }, [open])

    return (
        <>
            <div className={open ? classes.CameraUploadModalContainer : classes.hidden}>
                <div className={classes.CameraUploadModalWrapper}>

                    {
                        empty(imageDataURL) ? (
                            <>
                                <div className={classes.CameraUploadModalCloseBox}>
                                    <ButtonBase className={classes.CameraUploadModalCloseBtn} onClick={() => handleClose()} title="Close">
                                        <CloseIcon />
                                    </ButtonBase>
                                </div>
                            </>
                        ) : (
                            <></>
                        )
                    }

                    {
                        (open) ? (
                            <>
                                <CameraUploadBox
                                    imageDataURL={imageDataURL}
                                    setImageDataURL={setImageDataURL}
                                    onPickupPhoto={() => onClickPickupPhoto()}
                                    cameraSide={cameraSide}
                                    open={open}
                                    cameraOpenTimestamp={cameraOpenTimestamp}
                                    setCameraOpenTimestamp={setCameraOpenTimestamp}
                                />
                            </>
                        ) : (
                            <></>
                        )
                    }

                </div>
            </div>
        </>
    )
}

export default CameraUploadModal;