import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Modal, Snackbar, makeStyles, Button, IconButton } from '@material-ui/core';
import TemporaryUploadSection from './TemporaryUploadSection';
import { Stack } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import TemporaryCameraUploadSection from './TemporaryCameraUploadSection';

const modalContainerStyle = {
  position: 'fixed',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const modalStyle = {
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '460px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));

const TemporaryUploadModal = (props) => {
  const { open, setOpen, link, type, loadLinkInfo, isTest = false } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);

  const getModalTitle = () => {
    let title = "Upload File To Enable Starter"
    if (type === "unlock") {
      title = "Upload to Unlock"
    }
    return title
  }

  const handleModalClose = () => {
    setOpen("")
  }

  const onLoadLinkInfo = () => {
    loadLinkInfo()
    handleModalClose()
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ zIndex: 1302 }}
      >
        <div style={modalContainerStyle}>
          <Box sx={modalStyle}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackbarOpen}
              onClose={() => { setSnackbarOpen(false) }}
              autoHideDuration={1500}
              message={toastMessage}
            />
            <Stack direction={`row`} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">{getModalTitle()}</Typography>
              <IconButton size="small" onClick={() => handleModalClose()}><CloseIcon /></IconButton>
            </Stack>
            <Box sx={{ paddingTop: 16 }}>
              <div>
                <TemporaryUploadSection
                  link={link}
                  type={type}
                  approve_status={``}
                  loadLinkInfo={() => onLoadLinkInfo()}
                  isTest={isTest}
                />
              </div>
            </Box>
          </Box>
        </div>

      </Modal>
    </>
  );
};

export default TemporaryUploadModal;
