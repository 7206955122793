import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import {
  ButtonBase,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AdjustIcon from '@material-ui/icons/Adjust';
import AlbumIcon from '@material-ui/icons/Album';
import { empty } from '../../helpers/untils';
import CameraImageBox from './CameraImageBox';
import { Stack } from '@mui/material';
import { Motion, spring } from 'react-motion';
import { sleep } from '../../helpers/misc';

const useStyles = makeStyles((theme) => ({
  CameraBox: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000000'
  },
  VideoBox: {
    display: 'block',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    objectFit: 'cover',

    // backgroundColor: '#00ff00'

    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // width: 'auto',
    // height: '100%',
    // transform: 'translate(-50%, -50%)'
  },
  CaptureBtnBox: {
    position: 'absolute',
    bottom: '80px',
    left: '0',
    right: '0',
    zIndex: 2,
    textAlign: 'center'
  },
  CaptureBtn: {
    color: '#ffffff'
  },
  CaptureBtnIcon: {
    backgroundColor: '#ffffff',
    border: '8px solid #cccccc',
    borderRadius: '100px',
    width: '80px',
    height: '80px',
    overflow: 'hidden'
  },
  CameraBox: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000000'
  },
  CameraBoxContent: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000000'
  },
  CameraActionBtnBox: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    zIndex: 2,
    textAlign: 'center'
  },
  CameraActionBtnList: {
    padding: '24px 16%',
    backgroundImage: 'linear-gradient(rgba(0,0,0,0.85), transparent)',
    [theme.breakpoints.down('md')]: {
      padding: '24px 0',
    },
  },
  CaptureImageBoxContent: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: 3,
  },
  hidden: {
    display: 'none'
  },
  CaptureImageBoxInner: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000000',
    textAlign: 'center'
  }
}));

const CameraUploadBox = (props) => {
  const { imageDataURL, setImageDataURL, onPickupPhoto, cameraSide = '', open, cameraOpenTimestamp, setCameraOpenTimestamp } = props
  const classes = useStyles();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const videoRotated = useRef(false);

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  useEffect(() => {
    if (cameraSide) {
      startCamera()
    }
    console.log(`window width, height, cameraSide:`, `${window.innerWidth}, ${window.innerHeight}`, cameraSide)
    return () => {
      stopVideoStream()
    }
  }, [cameraSide, cameraOpenTimestamp])

  const getUserMediaStream = async (constraints) => {
    try {
      let stream = null
      stream = await navigator.mediaDevices.getUserMedia(constraints);
      return stream
    } catch (error) {
      //alert(`getUserMediaStream Error: ${error.message}, ${error.name}, ${error.stack}`)
      console.log(`getUserMediaStream error`, error)
    }
  }

  const startCamera = async (width, height) => {
    try {
      if (empty(cameraSide)) {
        return true
      }

      if (empty(width)) {
        width = !videoRotated.current ? windowWidth : windowHeight
      }
      if (empty(height)) {
        height = !videoRotated.current ? windowHeight : windowWidth
      }

      // if (window.streamRef) {
      //   // Stop the previous stream
      //   window.streamRef.getTracks().forEach(track => track.stop());
      // }

      const constraints = {
        video: {
          facingMode: cameraSide === 'front' ? 'user' : 'environment', ////user, environment
          width: { ideal: width },
          height: { ideal: height },
          //aspectRatio: { ideal: window.innerWidth / window.innerHeight }, // 0.5 16:9 aspect ratio
        },
        audio: false
      }
      const stream = await getUserMediaStream(constraints)
      console.log(`constraints, stream::::`, constraints, stream)

      window.streamRef = stream; // Store the stream reference
      videoRef.current.srcObject = stream;

      const videoElement = videoRef.current
      videoElement.onloadedmetadata = () => {
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;

        // if ((videoWidth / videoHeight) > (width / height)) {
        //   // If the video aspect ratio is wider than the window, adjust height
        //   videoElement.style.width = '100%';
        //   videoElement.style.height = 'auto';
        // } else {
        //   // If the video aspect ratio is taller than the window, adjust width
        //   videoElement.style.width = 'auto';
        //   videoElement.style.height = '100%';
        // }

        console.log(`videoWidth, videoHeight, windowWidth, windowHeight, ${videoWidth},${videoHeight},${windowWidth},${windowHeight}:::`)

        if (((videoWidth / videoHeight) >= 1 && (windowWidth / windowHeight) >= 1) || ((videoWidth / videoHeight) <= 1 && (windowWidth / windowHeight) <= 1)) {
          //continue
        } else {
          if (!videoRotated.current) {
            restartCamera(windowHeight, windowWidth)
          }
          return false
        }
      }
      videoRef.current.play();
    } catch (error) {
      //alert(`Error accessing camera: ${error.message}, ${error.name}, ${error.stack}`)
      console.error('Error accessing camera: ', error);
    }
  }

  const restartCamera = async (width, height) => {
    try {
      videoRotated.current = true
      await startCamera(width, height)
      return true
    } catch (error) {
      console.error('Error restartCamera camera: ', error);
    }
  }

  const stopVideoStream = () => {
    try {
      const video = videoRef.current;
      if (video) {
        // Stop all video streams.
        if (video.srcObject) {
          video.srcObject.getTracks().forEach(track => track.stop());
          videoRef.current.srcObject = null;
        }
      }
      return true
    } catch (e) {
      console.log(`stopVideoStream error:::`, e)
    }
  }

  const [canvasSize, setCanvasSize] = useState({})
  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    console.log(`video.videoWidth, video.videoHeight:::`, video.videoWidth, video.videoHeight)
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    setCanvasSize({
      width: canvas.width,
      height: canvas.height
    })
    const image_data_url = canvas.toDataURL('image/png');
    setImageDataURL(image_data_url);
    stopVideoStream()
  }

  const uploadImage = async () => {
    if (imageDataURL) {
      try {
        const response = await fetch('http://localhost:5000/upload', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ image: imageDataURL })
        });
        const result = await response.json();
        console.log('Image uploaded successfully:', result);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  }

  const onClickRetry = () => {
    setImageDataURL("")
    startCamera()
  }

  const onClickPickupPhoto = () => {
    onPickupPhoto()
  }

  return (
    <div className={classes.CameraBox}>
      <div className={classes.CameraBoxContent}>
        <video ref={videoRef} className={classes.VideoBox} />
        <div className={classes.CaptureBtnBox}>
          <ButtonBase className={classes.CaptureBtn} disableRipple={true} onClick={() => takePhoto()} title="Capture">
            <div className={classes.CaptureBtnIcon}></div>
          </ButtonBase>
        </div>
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        {/* <div style={{ backgroundColor: 'rgba(0,0,0,0.75)', color: '#ffffff', position: 'fixed', top: '16px', left: '16px', padding: '4px 8px' }}>
          {`${window.innerWidth}, ${window.innerHeight} `}
        </div> */}
      </div>

      <div className={imageDataURL ? classes.CaptureImageBoxContent : classes.hidden}>
        <Motion style={{ x: spring(!empty(imageDataURL) ? 0 : window.innerWidth) }}>
          {({ x }) =>
            // children is a callback which should accept the current value of
            // `style`
            <div className={classes.CaptureImageBoxInner}>
              <div className={classes.CaptureImageBoxInner} style={{
                WebkitTransform: `translate3d(${x}px, 0, 0)`,
                transform: `translate3d(${x}px, 0, 0)`,
              }}>

                {
                  (imageDataURL) ? (
                    <>
                      <img src={imageDataURL} alt="Captured" style={{ maxWidth: '100vw', height: 'auto' }} />
                      <div className={classes.CameraActionBtnBox}>
                        <Stack direction={`row`} justifyContent={`space-around`} alignItems={`center`} className={classes.CameraActionBtnList}>
                          <ButtonBase disableRipple={true} onClick={() => onClickRetry()} title="Capture">
                            <Typography variant="subtitle1" style={{ color: 'white' }}>RETRY</Typography>
                          </ButtonBase>
                          <ButtonBase disableRipple={true} onClick={() => onClickPickupPhoto()} title="Capture">
                            <Typography variant="subtitle1" style={{ color: 'white' }}>OK</Typography>
                          </ButtonBase>
                        </Stack>
                      </div>
                      {/* <button onClick={uploadImage}>Upload Image</button> */}
                    </>
                  ) : (
                    <></>
                  )
                }
              </div>
            </div>
          }
        </Motion>
      </div>
    </div>
  )
}

export default CameraUploadBox;
