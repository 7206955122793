import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useHistory, useParams } from 'react-router-dom';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, FormControlLabel, Checkbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from './EditItemView';
import EditDevicesView from './devices/EditDevicesView';
import LinkField from './form/LinkField';
import { useTranslation } from './LocalizationProvider';
import useUserDevices from './devices/useUserDevices';
import { useSelector } from 'react-redux';
import { api_call, console_log } from './helpers/untils';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));


const UserPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const [item, setItem] = useState();
  // getDevices();
  const { id } = useParams();

  const user = useSelector((state) => state.session.user);

  const [allDevices, setAllDevices] = useState([])

  const getDevices = async () => {
    await api_call('/api/device-name-list')
      .then((response) => response.json())
      .then((res) => {
        setAllDevices(res);
      })
  }

  useEffect(() => {
    getDevices()
  }, []);

  return (
    <EditItemView endpoint="users" item={item} setItem={setItem}>
      {item
        && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="subtitle1">
                  Profile
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  margin="normal"
                  value={item.name || ''}
                  onChange={(event) => setItem({ ...item, name: event.target.value })}
                  label={t('sharedName')}
                  variant="filled"
                />
                <TextField
                  margin="normal"
                  value={item.email || ''}
                  onChange={(event) => setItem({ ...item, email: event.target.value })}
                  label={t('userEmail')}
                  variant="filled"
                  disabled={id ? true : false}
                />

                {user.administrator &&
                  (<>
                    {
                      (id) ? (
                        <></>
                      ) : (
                        <>
                          <TextField
                            margin="normal"
                            type="password"
                            onChange={(event) => setItem({ ...item, password: event.target.value })}
                            label={t('userPassword')}
                            variant="filled"
                          />
                        </>
                      )
                    }
                  </>
                  )}

                <TextField
                  margin="normal"
                  value={item.phone || ''}
                  onChange={(event) => setItem({ ...item, phone: event.target.value })}
                  label={t('sharedPhone')}
                  variant="filled"
                />
                {user.administrator &&
                  (<>
                    {/* <FormControlLabel
                      control={<Checkbox checked={item.administrator} onChange={(event) => setItem({ ...item, administrator: event.target.checked })} />}
                      label="Administrator"
                    /> */}
                    {
                      (id && allDevices.length > 0) && (
                        <>
                          <Typography variant="subtitle1">
                            Devices
                          </Typography>
                          <EditDevicesView
                            devices={item.devices}
                            setDevices={(devices) => setItem({ ...item, devices })}
                            definitions={allDevices}
                            userInfo={item}
                          />
                        </>
                      )
                    }
                  </>
                  )}
              </AccordionDetails>
            </Accordion>            
          </>
        )}
    </EditItemView>
  );
};

export default UserPage;
