import React from 'react';
import { GoogleMap, LoadScript, Marker, OverlayView } from '@react-google-maps/api';

const defaultPosition = {
  lat: 0,
  lng: 0
};

const CustomClusterMarker = (props) => {
  const {
    position = defaultPosition,
    customMarkerIcon,
    label,
    labelObject,
    onClick,
    clusterer
  } = props

  // const customMarkerIcon = {
  //   url: "/assets/img/map/pin1.png",
  //   scaledSize: new window.google.maps.Size(30, 40),
  //   origin: new window.google.maps.Point(0, 0),
  //   anchor: new window.google.maps.Point(15, 15),
  //   labelOrigin: label ? new window.google.maps.Point(15, 45) : null
  // };

  // const labelObject = label ? {
  //   className: "custom-cluster-marker-label",
  //   text: label,
  //   color: "black",
  //   fontSize: "14px",
  //   fontWeight: "bold"
  // } : null

  return (
    <>
      <Marker
        position={position}
        icon={customMarkerIcon}
        title={label}
        label={labelObject}
        onClick={onClick}
        clusterer={clusterer}
      />
    </>
  );
}

export default CustomClusterMarker;
