import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, FormControlLabel, Checkbox, Box, Link, Modal, Grid, Toolbar, Snackbar,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from './EditItemView';
import EditAttributesView from './attributes/EditAttributesView';
import SelectField from './form/SelectField';
import deviceCategories from './common/deviceCategories';
import LinkField from './form/LinkField';
import { prefixString } from './common/stringUtils';
import { useTranslation } from './LocalizationProvider';
import useDeviceAttributes from './attributes/useDeviceAttributes';
import { Button } from '@material-ui/core';
import CreditLogsModal from './settings/CreditLogsModal';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import CarTypeTab from './components/CarTypeTab';
import { api_call, console_log, empty } from './helpers/untils';
import { DEVICE_TYPE } from './config/constant';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const SmartcarDeviceNoAuthPage = (props) => {
  const { accessToken, refreshToken, email, vehicleInfoList, setVehicleInfoList, item, setItem, showToast } = props
  const classes = useStyles();
  const t = useTranslation();

  const history = useHistory()
  const location = useLocation()

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    loadVehicles();
  }, []);

  const loadVehicles = async () => {
    if (accessToken) {
      const response = await api_call(`/api/smartcar/get-all-vehicles?accessToken=${accessToken}`);
      if (response.ok) {
        const res = await response.json()
        console_log("smartcar vehicle list:::", res, item)
        const new_checked_idx = initCheckedVehicleIdxs(res['vehicle_info_list'])
        const newItem = {
          ...item,
          deviceType: DEVICE_TYPE.SMARTCAR,
          accessToken: accessToken,
          refreshToken: refreshToken,
          vehicle_info_list: res['vehicle_info_list'],
          vehicle_idxs: new_checked_idx,
          abi: false,
          tint_ai: false,
        }
        console_log(`newItem::::`, newItem)
        setItem(newItem)
        
        setVehicleInfoList(res['vehicle_info_list'])
        setCheckedVehicleIdxs(new_checked_idx);
      } else {
        const errorRes = response
        console_log("smartcar errorRes:::", errorRes)
        showToast(errorRes.description ? errorRes.description : "Smartcar Api error")
      }
    } else {
      showToast("Smartcar authentication error")
    }
  }

  const [checkedVehicleIdxs, setCheckedVehicleIdxs] = useState([])
  const OnChangeVehicleCheckBox = (index, event) => {
    const checked = event.target.checked
    let newCheckedIdxs = [...checkedVehicleIdxs];
    if (checked) {
      if (!checkedVehicleIdxs.includes(index)) {
        newCheckedIdxs = ([...checkedVehicleIdxs, index])
      }
    } else {
      if (checkedVehicleIdxs.includes(index)) {
        const newArr = checkedVehicleIdxs.filter(val => val !== index);
        newCheckedIdxs = ([...newArr])
      }
    }
    setCheckedVehicleIdxs(newCheckedIdxs)
    setItem({
      ...item,
      vehicle_idxs: newCheckedIdxs
    })
    console_log("newCheckedIdxs:::", newCheckedIdxs)
  }
  const initCheckedVehicleIdxs = (vehicle_info_list) => {
    let new_checked_idx = []
    if (!empty(vehicle_info_list)) {
      for (let k in vehicle_info_list) {
        new_checked_idx.push(parseInt(k))
      }
    }
    console_log("new_checked_idx:::", new_checked_idx)
    return new_checked_idx
  }

  const onChangeAbiCheckbox = (event) => {
    const checked = event.target.checked
    // if(checked) {
    //   if(empty(item.vin)) {
    //     alert("please update your VIN.")
    //     return false
    //   }
    // }
    setItem({ ...item, abi: checked })
  }

  const onChangeTintAiCheckbox = (event) => {
    const checked = event.target.checked
    // if(checked) {
    //   if(empty(item.vin)) {
    //     alert("please update your VIN.")
    //     return false
    //   }
    // }
    setItem({ ...item, tint_ai: checked })
  }
  
  return (
    <>
      <div className="vehicleListBox" style={{ paddingTop: '16px', paddingBottom: '16px' }}>
        {
          (vehicleInfoList === null) ? (
            <div className='text-center'>Loading vehicles....</div>
          ) : (
            <>
              <>
                {(!empty(vehicleInfoList) && vehicleInfoList.length > 0) ? (
                  <>
                    <div style={{ paddingBottom: '16px' }}>
                      <div>
                        <FormControlLabel
                          control={<Checkbox checked={item.abi} onChange={(event) => onChangeAbiCheckbox(event)} />}
                          label="Share With ABI Insurance"
                        />
                        <Tooltip
                          arrow
                          title="By enabling this option you will be sharing your car position and other details with American Business Insurance."
                        >
                          <InfoIcon color="action" />
                        </Tooltip>
                      </div>
                    </div>
                    <div style={{ paddingBottom: '16px' }}>
                      <div>
                        <FormControlLabel
                          control={<Checkbox checked={item.tint_ai} onChange={(event) => onChangeTintAiCheckbox(event)} />}
                          label="Share With Tint.Ai"
                        />
                        <Tooltip
                          arrow
                          title="By enabling this, you will be sharing your car position and other information with Tint.Ai"
                        >
                          <InfoIcon color="action" />
                        </Tooltip>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>

              <div>Vehicle list</div>
              {(!empty(vehicleInfoList) && vehicleInfoList.length > 0) ? (
                <>
                  <div>
                    {
                      (vehicleInfoList.map((vehicleInfo, index) => {
                        return (
                          <div key={index}>
                            <FormControlLabel
                              control={<Checkbox checked={checkedVehicleIdxs.includes(index)} onChange={(event) => OnChangeVehicleCheckBox(index, event)} />}
                              label={`${vehicleInfo['year']} ${vehicleInfo['make']} ${vehicleInfo['model']} ${vehicleInfo['vin']}`}
                            />
                          </div>
                        )
                      }))
                    }
                  </div>
                </>
              ) : (
                <>
                  <div className='text-center'>No vehicle found</div>
                </>
              )}
            </>
          )
        }
      </div>
    </>
  );
};

export default SmartcarDeviceNoAuthPage;
