import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Typography, Box, Modal, makeStyles, Button
} from '@material-ui/core';

import { Stack } from '@mui/material';
import { layoutActions } from '../store';
import { APP_NAME } from '../config/constant';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '96%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));

const TeslaMoovetraxInfoModal = (props) => {
  const { open, setOpen, onOk } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.session.user);
  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    if (open) {
      dispatch(layoutActions.closeDeviceListMenu());
    }
  }, [open]);

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  const handleOk = () => {
    onOk()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">{APP_NAME}</Typography> */}
          <Box sx={{ padding: 16 }}>
            <Box sx={{ width: '100%', maxWidth: '320px', margin: 'auto' }}>
              <Stack direction={`row`} justifyContent={`center`} alignItems={`center`} spacing={4}>
                <img src="/assets/img/tesla_icon.jpg" width="180" style={{ maxWidth: 'calc(50% - 16px)', height: 'auto' }} alt="Tesla Cars" />
                <img src="/assets/img/moovetrax_icon.png" width="180" style={{ maxWidth: 'calc(50% - 16px)', height: 'auto' }} alt="Moovetrax Cars" />
              </Stack>
            </Box>
            <div style={{ marginTop: '32px' }}>
              <Stack direction={`column`} justifyContent={`center`} alignItems={`center`} spacing={3}>
                <Typography variant="h6" align='center' display='block'>MooveTrax connects your car through a Tesla API.</Typography>
                <Box>
                  <Typography align='center' display='block'>
                    After logging in, Please turn on ALL permissions by selecting ALL options so that MooveTrax can connect to your vehicle.
                  </Typography>
                  <Typography align='center' display='block'>
                    You may edit/revoke permission at anytime from your Tesla app or by editing your car in MooveTrax.
                  </Typography>
                </Box>
                <Stack direction={`column`} justifyContent={`center`} alignItems={`center`} style={{ paddingTop: '16px' }}>
                  <Button type="button" color="primary" variant="contained" onClick={() => handleOk()} style={{ width: '180px' }}>
                    Continue
                  </Button>
                </Stack>
              </Stack>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TeslaMoovetraxInfoModal;
