import React from 'react';
import { useMediaQuery, makeStyles, Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    overflowY: 'auto'
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.contrastText,
    background: theme.palette.common.purple,
    paddingBottom: theme.spacing(5),
    width: theme.dimensions.sidebarWidth,
    [theme.breakpoints.down('md')]: {
      width: theme.dimensions.sidebarWidthTablet,
    },
    [theme.breakpoints.down('xs')]: {
      width: '0px',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 25, 0, 0),
    },
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    width: '100%',
    position: 'relative'
  },
  attribution: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1.5),
    fontSize: 'x-small',
  },
}));

const StartPage = ({ children, formStyle={} }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <main className={classes.root}>
        <div className={classes.sidebar}>
          {!useMediaQuery(theme.breakpoints.down('md'))
            && (
              // <svg height="64" width="240">
              //   <use src="./logo.svg" />
              // </svg>
              <Link to={`/`}>
                <img height="85" width="240" src="/logo.png" alt="desktop login" />
              </Link>
            )}
        </div>
        <Paper className={classes.paper}>
          <div className={classes.form} style={{...formStyle }}>
            {children}
          </div>
        </Paper>
      </main>
    </>
  );
};

export default StartPage;
