import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputLabel, Select, CircularProgress
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { useParams } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ReportLayout from './ReportLayout';

import moment from 'moment';
import { api_call, console_log, empty, is_null } from '../helpers/untils';
import { devicesActions } from '../store';
import { DEVICE_TYPE, MT2V_DC_VOLT_ALERT } from '../config/constant';

const EventDataTable = (props) => {
  const { id, devices } = props
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);

  const getDeviceName = (deviceId) => {
    for (const key in devices) {
      if (devices.hasOwnProperty(key) && devices[key].id === deviceId) {
        return devices[key].name;
      }
    }
    return "Unknown or Deleted device"
  }

  const getDeviceType = (deviceId) => {
    for (const key in devices) {
      if (devices.hasOwnProperty(key) && devices[key].id === deviceId) {
        return devices[key].deviceType;
      }
    }
    return ""
  }

  const columnDefs = [
    {
      name: 'deviceId',
      label: 'Device',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{getDeviceName(value)}</div>
          );
        },
      },
    },
    {
      name: 'warning',
      label: 'Event',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          if (tableMeta && tableMeta['rowData'] && tableMeta['rowData'][4]) {
            const warningData = JSON.parse(tableMeta['rowData'][4])
            if (value === 'Geofence') {
              return (
                <a className='td-div' href={warningData['googleMapUrl']} target="_blank">{`${value}: ${warningData['fence_name']}`}</a>
              )
            }
            else if (value === 'Odometer') {
              return (
                <div className='td-div'>{`Miles: ${warningData['mileage']}`}</div>
              )
            }
            else if (value === 'Overspeed') {
              return (
                <div className='td-div'>{`Overspeed: ${warningData['speed']}mph`}</div>
              )
            }
            else if (value === 'Vin') {
              return (
                <div className='td-div'>{`${warningData['warningText']}`}</div>
              )
            }
            else if (value === 'Hood_open') {
              return (
                <div className='td-div'>{`Hood open: ${warningData['mt2v_hood_volt']} V`}</div>
              )
            }
            else if (value === 'Hood_closed') {
              return (
                <div className='td-div'>{`Hood closed: ${warningData['mt2v_hood_volt']} V`}</div>
              )
            }
            else if (value === 'Door_open') {
              return (
                <div className='td-div'>{`Door open: ${warningData['mt2v_door_volt']} V`}</div>
              )
            }
            else if (value === 'Door_closed') {
              return (
                <div className='td-div'>{`Door closed: ${warningData['mt2v_door_volt']} V`}</div>
              )
            }
            else if (value === 'Low_Battery') {
              return (
                <div className='td-div'>{`Low Battery: ${warningData['mt2v_dc_volt']} V`}</div>
              )
            }
            else if (value === 'door_voltage_calibrated') {
              return (
                <div className='td-div'>{`Door calibrated: ${warningData['mt2v_door_open_volt']} V`}</div>
              )
            }
            else if (value === 'hood_voltage_calibrated') {
              return (
                <div className='td-div'>{`Hood calibrated: ${warningData['mt2v_hood_open_volt']} V`}</div>
              )
            }
            else if (value === 'fuel_min_voltage_calibrated') {
              return (
                <div className='td-div'>{`Fuel Quarter calibrated: ${warningData['minFuel']} V`}</div>
              )
            }
            else if (value === 'fuel_max_voltage_calibrated') {
              return (
                <div className='td-div'>{`Fuel Full calibrated: ${warningData['maxFuel']} V`}</div>
              )
            }
            else if (value === 'Unlocked_by_Bluetooth') {
              return (
                <div className='td-div'>{`Unlocked via Bluetooth`}</div>
              )
            }
            else if (value === 'Locked_by_Bluetooth') {
              return (
                <div className='td-div'>{`Locked via Bluetooth`}</div>
              )
            }
            else if (value === 'Bluetooth_On') {
              return (
                <div className='td-div'>{`Bluetooth On`}</div>
              )
            }
            else if (value === 'Bluetooth_Off') {
              return (
                <div className='td-div'>{`Bluetooth Off`}</div>
              )
            }
            else if (value === 'ACC_ON') {
              return (
                <div className='td-div'>{`ACC ON`}</div>
              )
            }
            else if (value === 'ACC_OFF') {
              return (
                <div className='td-div'>{`ACC OFF`}</div>
              )
            }
            else if (value === 'No_Trip_Movement') {
              return (
                <div className='td-div'>{`Car Moving - Possible theft`}</div>
              )
            }
            else if (value === 'Possible_Towing') {
              return (
                <div className='td-div'>{`Possible Towing`}</div>
              )
            }
            else if (value === 'Severe_Weather') {
              return (
                <div className='td-div'>{`${warningData['subject']}`}</div>
              )
            }
            else {
              return (
                <div className='td-div'>{value}</div>
              )
            }
          } else {
            return (
              <div className='td-div'>{value}</div>
            )
          }
        },
      },
    },
    {
      name: 'mt2v_dc_volt',
      label: 'Battery',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          const deviceId = tableMeta['rowData'][0]
          console_log(`deviceId::::`, deviceId)
          const deviceType = getDeviceType(deviceId)
          console_log(`deviceType::::`, deviceType)
          if (deviceType === DEVICE_TYPE.TESLA) {
            if (is_null(value)) {
              return (
                <div className='td-div'></div>
              )
            } else {
              return (
                <div className='td-div' style={value < 20 ? { color: '#d32f2f' } : {}}>{value} %</div>
              )
            }
          } else {
            if (is_null(value)) {
              return (
                <div className='td-div'></div>
              )
            } else {
              return (
                <div className='td-div' style={value < MT2V_DC_VOLT_ALERT ? { color: '#d32f2f' } : {}}>{value} V</div>
              )
            }
          }
        }
      }
    },
    {
      name: 'deviceTime',
      label: 'When',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{`${moment(value).format('LL')} ${moment(value).format('LTS')}`}</div>
          );
        },
      },
    },
    {
      name: 'warning_data',
      label: 'Data',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{`${moment(value).format('LL')} ${moment(value).format('LTS')}`}</div>
          );
        },
        display: false
      },
    },
  ]

  const [page, setPage] = useState(0)
  const [count, setCount] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [sortOrder, setSortOrder] = useState({ name: "deviceTime", direction: "desc" })
  const [data, setData] = useState([['Loading Data...']])
  const [columns, setColumns] = useState(columnDefs)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // getData(0);
  }, [])

  const sort = async (page, sortOrder) => {
    const res = await xhrRequest(page, sortOrder);
  };

  // mock async function
  const xhrRequest = async (page, sortOrder = {}, filter_options = {}) => {
    if (empty(user)) {
      return false
    }

    setIsLoading(true);
    let api_url = "/api/warning/get-data-table"
    let post_data = {}
    post_data['page'] = page
    post_data['rowsPerPage'] = rowsPerPage
    post_data['sortOrder'] = sortOrder

    const filterOptions = {
      deviceId: selectedDeviceId,
      ...filter_options
    }

    if (user.administrator) {
      //
    } else {
      filterOptions['userId'] = user.id
    }

    const response = await api_call(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...post_data, ...filterOptions }),
    });
    if (response.ok) {
      const res = await response.json();
      setData(res.data)
      setPage(res.page)
      setSortOrder(sortOrder)
      setIsLoading(false)
      setCount(res.total)
      return res
    } else {
      return false
    }
  };

  const changePage = async (page, sortOrder) => {
    const res = await xhrRequest(page, sortOrder)
  };

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive: 'standard',
    serverSide: true,
    count: count,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [],
    sortOrder: sortOrder,
    download: false,
    viewColumns: false,
    print: false,
    search: false,
    selectableRowsHideCheckboxes: true,
    onTableChange: (action, tableState) => {
      // console.log("action, tableState:::", action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want
      switch (action) {
        case 'changePage':
          changePage(tableState.page, tableState.sortOrder);
          break;
        case 'sort':
          sort(tableState.page, tableState.sortOrder);
          break;
        default:
        //console.log('action not handled.');
      }
    },
  }

  const reduxSelectedDeviceId = useSelector((state) => state.devices.selectedDeviceId);
  const [selectDevices, setSelectDevices] = useState([]); // device name for dropdown
  const [selectedDeviceId, setSelectedDeviceId] = useState(null)

  const getDefaultSelect = () => {
    var defaultSelect = "All";
    if (!empty(reduxSelectedDeviceId)) {
      defaultSelect = reduxSelectedDeviceId
    } else {
      if (id) {
        defaultSelect = null;
        for (const key in devices) {
          if (devices[key].id === Number(id)) {
            defaultSelect = devices[key].id;
            dispatch(devicesActions.setSelectedDeviceId(defaultSelect));
            setSelectedDeviceId(Number(id))
            return defaultSelect
          }
        }
      }
    }
    return defaultSelect
  }

  const getSelectDevices = () => {
    var items = [];
    items.push({ id: "All", name: "All" });
    for (const key in devices) items.push({ id: devices[key].id, name: devices[key].name });
    return items;
  }

  const onChangeSelectedDevice = async (deviceId) => {
    //console.log("deviceId::::", deviceId)
    dispatch(devicesActions.setSelectedDeviceId(deviceId));
    setSelectedDeviceId(deviceId)
    const filter_options = { deviceId: deviceId }
    const res = await xhrRequest(page, sortOrder, filter_options);
  }

  useEffect(() => {
    let defaultSelectedDeviceId = ""
    setSelectDevices(getSelectDevices())
    if (selectedDeviceId === null) {
      defaultSelectedDeviceId = getDefaultSelect()
    }
    setSelectedDeviceId(defaultSelectedDeviceId)
    xhrRequest(0, sortOrder, { deviceId: defaultSelectedDeviceId })
  }, [id, user]);

  return (
    <>
      {
        (!empty(devices) && !empty(selectedDeviceId)) ? (
          <>
            <div>
              <MUIDataTable
                title={
                  <div className='pt-3'>
                    <FormControl margin="normal" variant="filled">
                      <InputLabel>Device</InputLabel>
                      <Select
                        multiple={false}
                        value={selectedDeviceId}
                        onChange={(event) => onChangeSelectedDevice(event.target.value)}
                        disabled={selectDevices.length === 0}
                      >
                        {selectDevices.map((item) => (
                          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                    {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 24 }} />}
                  </div>
                }
                data={data}
                columns={columns}
                options={options}
              />
            </div>
          </>
        ) : (
          <></>
        )
      }
    </>
  )
}

const EventReportPage = () => {
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.devices.items);
  const { id } = useParams();
  return (
    <>
      <ReportLayout>
        {
          (!empty(devices)) ? (
            <EventDataTable
              id={id}
              devices={devices}
            />
          ) : (
            <></>
          )
        }
      </ReportLayout>
    </>
  );
}
export default EventReportPage
