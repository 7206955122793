import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';

import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import {
  Grid, useMediaQuery, makeStyles, InputLabel, Select, MenuItem, Button, TextField, Typography
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sessionActions } from '../../store';
import { useLocalization, useTranslation } from '../../LocalizationProvider';
import StartPage from '../../StartPage';
import usePersistedState from '../../common/usePersistedState';
import { api_call, console_log, empty, getToken, get_utc_timestamp_ms, setToken, validateEmail } from '../../helpers/untils';
import { GPS_ID_PLACEHOLDER, USB_GPS_ID_PLACEHOLDER, ICCID_PLACEHOLDER, IMAGE_CACHE_VERSION, RECAPTCHA_ENABLED, RECAPTCHA_KEY } from '../../config/constant';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { getSettingPersist, setSettingPersist, sleep } from '../../helpers/misc';
import { Alert } from '@mui/material';
import CaptchaBox from '../CaptchaBox';
import SelectField from '../../form/SelectField';
import { useRef } from 'react';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  imgSmall: {
    width: '50px',
    height: '40px',
    marginLeft: '5px',
    marginTop: '5px',
    borderRadius: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  customSnakebar: {
    position: 'absolute',
    top: '140px'
  },
  mobileInstallerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center'
  }
}));

const DeviceSignupForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const t = useTranslation();

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const [failed, setFailed] = useState(false);

  const formDefaultData = {
    iccid: "", // "000000000"
    uniqueId: "", // "MT09G00000"
    phone_email: "",
    mobile_installer: "0",
    iccid_prefix: "",
    device_type: "moovetrax"
  }
  const [formData, setFormData] = useState(formDefaultData);
  const [isOpen, setIsOpen] = useState('');
  const [currentImage, setCurrentImage] = useState('');

  const [submitting, setSubmitting] = useState(false);

  const iccidImage = `/images/iccid/large1.jpg?v=${IMAGE_CACHE_VERSION}`
  const gpsidImage = `/images/gpsid/large1.jpg?v=${IMAGE_CACHE_VERSION}`

  const defaultPageData = {
    iccidPrefixList: []
  }

  const [pageData, setPageData] = useState(defaultPageData)
  const [iccidPrefixList, setIccidPrefixList] = useState([])
  const getPageData = async () => {
    let url = `/api/get-iccid-prefix-list`
    let response = await api_call(url, {
      method: 'GET',
    });

    if (response.ok) {
      const apiRes = await response.json()
      setPageData(apiRes)
      setIccidPrefixList(getIccidPrefixListOptions(apiRes['iccidPrefixList']))
      const lastIccidPrefix = apiRes['iccidPrefixList'][0]
      setFormData({ ...formData, iccid_prefix: lastIccidPrefix?.iccid_prefix ?? "" })
    } else {
      return false
    }
  }
  useEffect(() => {
    getPageData()
  }, [])

  const getIccidPrefixListOptions = (iccid_prefix_ist) => {
    const iccidPrefixListOptions = []
    for (let i in iccid_prefix_ist) {
      iccidPrefixListOptions.push({ id: iccid_prefix_ist[i]['iccid_prefix'], name: iccid_prefix_ist[i]['iccid_prefix'] })
    }
    console.log(`iccidPrefixListOptions:::`, iccidPrefixListOptions)
    return iccidPrefixListOptions;
  }

  ///////////// start recaptcha part //////////////////////////////////////////////////////////////////////////
  const captchaKey = 'captcha_installer_login'
  const [recaptcha, setRecaptcha] = useState(RECAPTCHA_ENABLED === "false" ? "xxxxxxxxxxxxxxxxx" : "");
  const [captchaTimestamp, setCaptchaTimestamp] = useState(get_utc_timestamp_ms());
  const updateCaptchaSettingEnabled = (value) => {
    const oldValue = getSettingPersist(captchaKey)
    if (true || oldValue !== value) { // refresh every attempt
      setSettingPersist(captchaKey, value)
      setCaptchaTimestamp(get_utc_timestamp_ms())
    }
  }
  ///////////// end recaptcha part //////////////////////////////////////////////////////////////////////////

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSnackbarOpen(true)
    setSubmitting(true)
    if (empty(formData['iccid_prefix'])) {
      setToast("Please choose ICCID prefix")
      setSnackbarOpen(true)
      return false
    }

    if (!validateEmail(formData['phone_email'])) {
      setToast("Invalid email address")
      setSnackbarOpen(true);
      return false
    }

    if (formData['iccid'].length < 5) {
      setToast("Invalid ICCID")
      setSnackbarOpen(true);
      return false
    }


    setSubmitting(true)
    setToast("Please wait, Process can take up to 20 minutes.")

    const response = await api_call('/api/device-signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...formData }),
    });


    if (response.ok) {
      const data = await response.json();
      console_log("first data:::::::", data);
      if (data['error']) {
        setToast(data['error'])
        setSnackbarOpen(true);
        setSubmitting(false)
        updateCaptchaSettingEnabled(true)
      } else {
        if (data['status'] === 'waiting') {
          await scanCheckGpsIccidMatched({ ...formData })
          if (data['message']) {
            setToast(data['message'])
            setSnackbarOpen(true);
          }
        } else {
          UserDeviceSetting(formData.phone_email, formData.uniqueId, formData.device_type)
          updateCaptchaSettingEnabled(false)
          return true
        }
      }
    } else {
      setFailed(true);
      //setFormData(formDefaultData);
      setToast("Signup invalid combination")
      setSnackbarOpen(true)
      setSubmitting(false)
      updateCaptchaSettingEnabled(true)
    }

    setTimeout(() => {
      setSnackbarOpen(false)
    }, 3000)
  }

  const scanCheckGpsIccidMatched = async (data) => { // check every 30s for 10 minutes
    let i = 0;
    let cnt = 18; //18
    for (i = 0; i < cnt; i++) {
      let is_final = 0
      if (i === cnt - 1) {
        is_final = 1
      }
      const rslt = await checkGpsIccidMatched(data, is_final);
      if (rslt) { // if true, don't need to scan again
        return false
      }
      await sleep(30 * 1000)  //30 * 1000
    }
  }

  const checkGpsIccidMatched = async (data, is_final) => { // check every 30s for 10 minutes
    const response = await api_call('/api/check-gpsid-iccid-matched', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...data, is_final: is_final, is_type: 'true' }),
    });
    if (response.ok) {
      const sessionData = await response.json();
      console_log("sessionData:::::::", sessionData);
      if (sessionData['error']) {
        if (is_final === 1) {
          setToast(sessionData['error'])
          setSnackbarOpen(true);
          setSubmitting(false)
          updateCaptchaSettingEnabled(true)
          return true
        } else {
          return false // scan again
        }
      } else {
        UserDeviceSetting(formData.phone_email, formData.uniqueId, formData.device_type)
        updateCaptchaSettingEnabled(false)
        return true
      }
    } else {
      setToast("Invalid combination")
      setSnackbarOpen(true)
      setSubmitting(false)
      return true
    }
  }

  const UserDeviceSetting = async (email, uniqueId, deviceType) => {
    console.log("user device setting", email, uniqueId, deviceType);
    if (email === "") {
      alert("Enter the invlite email address");
      return false
    }
    let url = `/api/users/sign-user-device`;

    let postData = {
      email: email,
      uniqueId: uniqueId,
      deviceType: deviceType,
    }

    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    });

    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      if (responseObj['error']) {
        alert(responseObj['error'])
      }
      let cnt = responseObj['cnt'];
      if (empty(cnt) || cnt == 0) {
        return false
      }
      history.push("/main")
      return false
    } else {
      alert("Error encounted")
      // setShowConfirmTransferModal(false)
      return false
    }
  }

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && formData['iccid'] && formData['uniqueId'] && recaptcha) {
      handleSubmit(e);
    }
  };
  const onChange = (value) => {
    console.log(value);
    setRecaptcha(value);
  }

  const applyLogout = async () => {
    const m_token = getToken();
    if (m_token) {
      const url = `/api/installer-auth/${m_token}`
      const response = await api_call(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setToken("")
    }
  }

  useEffect(() => {

    applyLogout()
  }, []);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toast, setToast] = useState("");
  return (
    <>
      <StartPage>
        <div className='custom-snakebar'>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={10 * 60 * 1000}
            message={toast}
          >
          </Snackbar>
        </div>

        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            {useMediaQuery(theme.breakpoints.down('md'))
              && (
                <Grid item className={classes.logoContainer}>
                  <Link to="/">
                    <img height="85" width="240" src="/logo.png" alt="Mobile Logo Login" />
                  </Link>
                </Grid>
              )}
            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
              <FormControl fullWidth variant="filled" style={{ marginRight: '4px' }}>
                <Select
                  value={formData.device_type}
                  onChange={(event) => {
                    setFormData({ ...formData, device_type: event.target.value })
                  }}
                >
                  <MenuItem value="moovetrax">MT</MenuItem>
                  <MenuItem value="usb">USB</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
              <TextField
                required
                fullWidth
                error={failed}
                label={`GPS ID`}
                name="uniqueId"
                value={formData['uniqueId']}
                type={formData.device_type === "usb" ? "number" : "text"}
                autoFocus={true}
                onChange={(e) => {
                  setFormData({ ...formData, uniqueId: e.target.value })
                }
                }
                onKeyUp={handleSpecialKey}
                variant="filled"
                inputProps={{
                  style: { textTransform: (formData.device_type === "moovetrax") ? "uppercase" : "none" },
                  onChange: (e) => {
                    const maxLength = (formData.device_type === "moovetrax") ? 10 : 12;
                    if (e.target.value.length > maxLength) {
                      e.target.value = e.target.value.slice(0, maxLength);
                    }
                  }
                }}
                placeholder={formData.device_type === "moovetrax" ? GPS_ID_PLACEHOLDER : USB_GPS_ID_PLACEHOLDER}
                disabled={submitting}
              />
              <img className={classes.imgSmall}
                src={`/images/gpsid/small1.jpg?v=${IMAGE_CACHE_VERSION}`}
                alt="How to get GPSID"
                title="How to get GPSID"
                onClick={() => {
                  setCurrentImage(gpsidImage)
                  setIsOpen(true)
                }}
              />
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
              <FormControl fullWidth variant="filled" style={{ marginRight: '4px' }}>
                <InputLabel>{'ICCID Prefix'}</InputLabel>
                <Select
                  value={formData.iccid_prefix}
                  onChange={(event) => setFormData({ ...formData, iccid_prefix: event.target.value })}
                >
                  {iccidPrefixList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                fullWidth
                error={failed}
                label={`ICCID`}
                name="iccid"
                value={formData['iccid']}
                autoComplete="iccid"
                onChange={(e) => setFormData({ ...formData, iccid: e.target.value.substring(0, 9) })}
                onKeyUp={handleSpecialKey}
                //helperText={failed && 'The combination you entered is not found'}
                variant="filled"
                inputProps={{ style: { textTransform: "none" }, type: "number", min: 0 }}
                placeholder={ICCID_PLACEHOLDER}
                disabled={submitting}
              />
              <img className={classes.imgSmall}
                src={`/images/iccid/small1.jpg?v=${IMAGE_CACHE_VERSION}`}
                alt="How to get ICCID"
                title="How to get ICCID"
                onClick={() => {
                  setCurrentImage(iccidImage)
                  setIsOpen(true)
                }}
              />
            </Grid>
            <Grid item>

            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                error={failed}
                label={`User Email`}
                name="phone_email"
                value={formData['phone_email']}
                type="text"
                onChange={(e) => setFormData({ ...formData, phone_email: e.target.value.trim() })}
                onKeyUp={handleSpecialKey}
                variant="filled"
                disabled={submitting}
                inputProps={{
                  type: 'text'
                }}
              />
            </Grid>

            {
              (RECAPTCHA_ENABLED === "true") && (!submitting) && (
                <Grid item>
                  <div className={`recaptcha-wrpper`}>
                    <CaptchaBox
                      recaptcha={recaptcha}
                      setRecaptcha={setRecaptcha}
                      captchaKey={captchaKey}
                      captchaTimestamp={captchaTimestamp}
                    />
                  </div>
                </Grid>
              )
            }

            <Grid item>
              <Button
                type={`submit`}
                variant="contained"
                color="secondary"
                disabled={!formData['iccid'] || !formData['uniqueId'] || !formData['phone_email'] || !formData['iccid_prefix'] || !recaptcha || submitting}
                fullWidth
              >
                {`${(submitting) ? 'Processing' : 'Login'}`}
              </Button>
            </Grid>
          </Grid>
        </form>

        {isOpen && (
          <Lightbox
            mainSrc={currentImage}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}

      </StartPage>
    </>

  );
};

export default DeviceSignupForm;
