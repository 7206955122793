import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography, Box, Modal,
} from '@material-ui/core';

import moment from 'moment';
import PayPalBtn from '../components/PayPalBtn';
import { api_call, console_log, get_utc_timestamp } from '../helpers/untils';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '640px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

const PaypalSubscriptionModal = (props) => {
  const user = useSelector((state) => state.session.user);
  const { open, setOpen, item, setItem, plan_id = "", updateTimestamp, setUpdateTimestamp, itemType = "credit" } = props;

  const handleCreditModalClose = () => {
    setOpen(false)
  }

  const paypalSubscribe = (data, actions) => {
    console.log("data, actions:::", data, actions)
    return actions.subscription.create({
      'plan_id': plan_id,
      'custom_id': `${user['id']}_${item['id']}_${itemType}`
    })
  }

  const paypalOnError = (err) => {
    console.log("Error")
  }

  const paypalOnApprove = (data, actions) => {
    console_log("Payapl approved:::data, actions", data, actions)
    // Capture the funds from the transaction
    return actions.subscription.get().then(function (details) {
      // Show a success message to your buyer
      console_log("Subscription completed", details);
      submitSubscription(data)
    });
  }

  const submitSubscription = async (data) => {
    let url = "/api/payment/paypal/subscription-complete"
    let postData = {
      userId: user['id'],
      plan_id: plan_id,
      orderID: data.orderID,
      subscriptionID: data.subscriptionID,
      itemId: item['id'],
      itemType: itemType
    }

    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    });
    if (response.ok) {
      let payalResponse = await response.json()
      let newBalance = payalResponse['to_balance']
      if (itemType === 'credit') {
        setItem({ ...item, credit: newBalance })
      }
      else if (itemType === 'escrow') {
        setItem({ ...item, escrow_balance: newBalance })
      }
      setUpdateTimestamp(get_utc_timestamp())
      setOpen(false)
      return payalResponse;
    } else {
      setOpen(false)
    }
  }

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  const getPaypalPaymentAmount = ()=>{
    const defaultAmount = 15
    let amount = defaultAmount
    if(item && item.monthly_cost && Number(item.monthly_cost) > 0) {
      amount = Number(item.monthly_cost)
    }
    const amountDecimal = amount.toFixed(2)
    return amountDecimal
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleCreditModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>

          {/* <Typography id="paypalPaymentFormTitle" className='mt-3'>
            Paypal subscription
          </Typography> */}

          <div className='text-center' style={{ marginTop: '1rem' }}>
            <PayPalBtn
              amount={getPaypalPaymentAmount()}
              currency="USD"
              createSubscription={paypalSubscribe}
              onApprove={paypalOnApprove}
              catchError={paypalOnError}
              onError={paypalOnError}
              onCancel={paypalOnError}
            />

          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PaypalSubscriptionModal;