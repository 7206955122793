export default [
  'Default' ,
  'Atv' ,
  'Bus' ,
  'Convertable' ,
  'Coupe' ,
  'Minivan' ,
  'Motorcycle' ,
  'Pickup' ,
  'Sedan' ,
  'Suv' ,
  'Truck' ,
  'Van' ,
  // 'default',
  // 'animal',
  // 'bicycle',
  // 'boat',
  // 'bus',
  // 'car',
  // 'crane',
  // 'helicopter',
  // 'motorcycle',
  // 'offroad',
  // 'person',
  // 'pickup',
  // 'plane',
  // 'ship',
  // 'tractor',
  // 'train',
  // 'tram',
  // 'trolleybus',
  // 'truck',
  // 'van',
  // 'scooter',
];
