import React, { useEffect } from 'react';
import { Link, useHistory } from "react-router-dom"
import FrontSiteNavbar from '../FrontSiteNavbar';

export default function CmsPageLayout(props) {
    const { title, lastUpdated, children } = props

    const history = useHistory()

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "./assets/js/animation.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return (
        <React.Fragment>
            <FrontSiteNavbar />
            <main id="content" role="main">
                <div className="gradient-x-overlay-sm-primary position-relative overflow-hidden">
                    <div className="container content-space-t-3 content-space-t-lg-4 content-space-b-1 content-space-b-md-2">
                        <div className="w-lg-65 text-center mx-lg-auto">
                            <div className="">
                                <h2 className="">
                                    {title}
                                </h2>
                                {
                                    (lastUpdated) ? (
                                    <h4 className='mt-4'>Last updated: </h4>
                                    ) : (
                                    <></>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='bg-white overflow-hidden'>
                        <div className="container content-space-1 content-space-md-2" style={{ fontSize: "1rem", wordBreak: 'break-word' }}>
                            <div className='text-left'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="page-box">
                                            {children}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <a className="js-go-to go-to position-fixed" href="javascript:;" style={{ visibility: 'hidden' }}
                data-hs-go-to-options='{
            "offsetTop": 700,
            "position": {
                "init": {
                "right": "2rem"
                },
                "show": {
                "bottom": "2rem"
                },
                "hide": {
                "bottom": "-2rem"
                }
            }
            }'>
                <i className="bi-chevron-up"></i>
            </a>
        </React.Fragment>
    )
}
