import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  makeStyles, FormControlLabel, Checkbox, TextField, Button, IconButton
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { addItemToArray, api_call, console_log, copyObject, createArrayFromCount, createEmptyArrayFromCount, empty, is_null } from '../helpers/untils';

const useStyles = makeStyles((theme) => ({
  formRowInline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    //  marginRight: '30px',

  },
  formRowInlineController: {
    flex: 1,
    paddingRight: '8px'
  },
  textList: {
    display: 'block',
    paddingTop: '8px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },

}));


const UnlimitedStaticTextBoxList = (props) => {
  const { defaultName, defaultCount, defaultValues, inputProps, setValues, placeholder = "", primaryValues = [], primaryValueEditable = false, defaultPrimaryUploadApplied = false, enabled = true } = props;
  //console.log("defaultValues:::", defaultValues)
  const classes = useStyles();

  useEffect(() => {
    initForm()
  }, [])

  const [fieldName, setFieldName] = useState("")
  const [fieldCount, setFieldCount] = useState(0)
  const [fieldValues, setFieldValues] = useState([])

  const initForm = () => {
    setFieldName(defaultName)
    setFieldCount(defaultCount)

    let field_values = []
    if (is_null(defaultValues)) {
      field_values = createEmptyArrayFromCount(defaultCount)
    }
    else {
      field_values = [...defaultValues]
    }

    if (!defaultPrimaryUploadApplied) {
      field_values = field_values.filter((item) => !primaryValues.includes(item))
      field_values = [...primaryValues, ...field_values]
    }

    setFieldValues(field_values)
    setValues(field_values)
  }

  const onClickRemoveItem = (index) => {
    const field_values = [...fieldValues]
    field_values.splice(index, 1)
    console.log("field_values:::", field_values)
    setFieldValues(field_values)
    setValues(field_values)
  }

  const onClickAddNew = () => {
    const field_values = [...fieldValues]
    field_values.push("")
    console.log("field_values:::", field_values)
    setFieldValues(field_values)
    setValues(field_values)
  }

  const onChangeFormText = (event, index) => {
    const field_values = [...fieldValues]
    field_values[index] = event.target.value
    setFieldValues(field_values)
    setValues(field_values)
  }

  const onChangeFormCheckBox = (event, primaryItem) => {
    if (primaryValues) {
      let field_values = copyObject(fieldValues)
      const checked = event.target.checked
      console.log("checked:::", checked)
      if (checked) {
        field_values = field_values.filter((item) => !primaryValues.includes(item))
        for (let k in primaryValues) {
          let value = primaryValues[k]
          if (fieldValues.includes(value) || primaryItem === value) {
            field_values = [value, ...field_values]
          }
        }
      }
      else {
        field_values = field_values.filter((item) => item !== primaryItem)
      }
      console.log("field_values::::", field_values)
      setFieldValues(field_values)
      setValues(field_values)
    }
  }

  const DrawForm = () => { //field_name, field_count, field_values
    //let formIndexList = createArrayFromCount(defaultValues.length)
    //console.log("formIndexList:::", formIndexList)
    const field_values = [...fieldValues]

    return (
      <>
        {
          (
            (primaryValues) && primaryValues.map((item, index) => {
              return (
                <div className={classes.formRowInline} key={index}>
                  <>
                    <div className={classes.formActionBox}>
                      <FormControlLabel
                        control={<Checkbox checked={fieldValues.includes(item)}
                          onChange={(event) => onChangeFormCheckBox(event, item)} />}
                        label=""
                      />
                    </div>
                    <TextField
                      fullWidth
                      className={classes.formRowInlineController}
                      value={item}
                      //onChange={(event) => onChangeFormText(event, index)}
                      label=""
                      variant="filled"
                      inputProps={{
                        ...inputProps,
                        placeholder: placeholder,
                        // readOnly: !primaryValueEditable
                      }}
                      disabled={!primaryValueEditable}
                    />
                  </>
                </div>
              )
            })
          )
        }

        {
          (
            field_values.map((item, index) => {
              return (
                <Fragment key={index}>
                  {
                    (!primaryValues.includes(item)) ? (
                      <div className={classes.formRowInline}>
                        <>
                          <TextField
                            fullWidth
                            className={classes.formRowInlineController}
                            value={item}
                            onChange={(event) => onChangeFormText(event, index)}
                            label=""
                            variant="filled"
                            inputProps={{
                              ...inputProps,
                              placeholder: placeholder
                            }}
                          />
                          <div className={classes.formActionBox}>
                            <IconButton className={classes.removeButton} color="primary" size="medium" onClick={() => onClickRemoveItem(index)}>
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                </Fragment>
              )
            })
          )
        }
      </>
    )
  }

  const DrawDisabledEmptyForm = () => {
    const field_values = [...fieldValues]
    return (
      <>
        {
          (
            (primaryValues) && primaryValues.map((item, index) => {
              return (
                <div className={classes.formRowInline} key={index}>
                  <>
                    <div className={classes.formActionBox}>
                      <FormControlLabel
                        control={<Checkbox checked={false} disabled={true}
                        />}
                        label=""
                      />
                    </div>
                    <TextField
                      fullWidth
                      className={classes.formRowInlineController}
                      value={item}
                      label=""
                      variant="filled"
                      inputProps={{
                        ...inputProps,
                        placeholder: placeholder,
                        // readOnly: !primaryValueEditable
                      }}
                      disabled={true}
                    />
                  </>
                </div>
              )
            })
          )
        }
      </>
    )
  }

  return (
    <>
      {
        (enabled) ? (
          <div className={classes.textList}>
            {
              DrawForm()
            }
            <div className={classes.formRowInline}>
              <Button type="button" color="primary" variant="contained" startIcon={<AddIcon />} onClick={() => { onClickAddNew() }}>
                Add New
              </Button>
            </div>
          </div>
        ) : (
          <div className={classes.textList}>
            {
              DrawDisabledEmptyForm()
            }
          </div>
        )
      }
    </>
  )
}

export default UnlimitedStaticTextBoxList;
