import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton,
} from '@material-ui/core';

import {
  InputLabel, Select, CircularProgress
} from '@material-ui/core';

import MUIDataTable from "mui-datatables";
import { useParams } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ReportLayout from './ReportLayout';

import moment from 'moment';
import { api_call, console_log, empty, is_null } from '../helpers/untils';
import { devicesActions } from '../store';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
}));

const DailyMileageDataTable = (props) => {
  const { id, devices } = props
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);

  const getDeviceName = (deviceId) => {
    for (const key in devices) {
      if (devices.hasOwnProperty(key) && devices[key].id === deviceId) {
        return devices[key].name;
      }
    }
    return "Unknown or Deleted device"
  }

  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const sortOrder = {}

  useEffect(() => {
    // getData(0);
  }, [])

  // mock async function
  const xhrRequest = async (page, sortOrder = {}, filter_options = {}) => {
    if (empty(user)) {
      return false
    }

    setIsLoading(true);
    let api_url = "/api/get-device-mileage-data"
    let post_data = {}
    post_data['sortOrder'] = sortOrder

    const filterOptions = {
      deviceId: selectedDeviceId,
      ...filter_options
    }

    const response = await api_call(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...post_data, ...filterOptions }),
    });
    if (response.ok) {
      const res = await response.json();
      setData(res.data)
      setIsLoading(false)
      return res
    } else {
      return false
    }
  };

  const reduxSelectedDeviceId = useSelector((state) => state.devices.selectedDeviceId);
  const [selectDevices, setSelectDevices] = useState([]); // device name for dropdown
  const [selectedDeviceId, setSelectedDeviceId] = useState(null)

  const getDefaultSelect = () => {
    var defaultSelect = "All";
    if (!empty(reduxSelectedDeviceId)) {
      defaultSelect = reduxSelectedDeviceId
    } else {
      if (id) {
        defaultSelect = null;
        for (const key in devices) {
          if (devices[key].id === Number(id)) {
            defaultSelect = devices[key].id;
            dispatch(devicesActions.setSelectedDeviceId(defaultSelect));
            setSelectedDeviceId(Number(id))
            return defaultSelect
          }
        }
      }
    }
    return defaultSelect
  }

  const getSelectDevices = () => {
    var items = [];
    items.push({ id: "All", name: "All" });
    for (const key in devices) items.push({ id: devices[key].id, name: devices[key].name });
    return items;
  }

  const onChangeSelectedDevice = async (deviceId) => {
    //console.log("deviceId::::", deviceId)
    dispatch(devicesActions.setSelectedDeviceId(deviceId));
    setSelectedDeviceId(deviceId)
    const filter_options = { deviceId: deviceId }
    const res = await xhrRequest(0, sortOrder, filter_options);
  }

  useEffect(() => {
    let defaultSelectedDeviceId = ""
    setSelectDevices(getSelectDevices())
    if (selectedDeviceId === null) {
      defaultSelectedDeviceId = getDefaultSelect()
    }
    console_log(`defaultSelectedDeviceId:::`, defaultSelectedDeviceId)
    setSelectedDeviceId(defaultSelectedDeviceId)
    xhrRequest(0, sortOrder, { deviceId: defaultSelectedDeviceId })
  }, [id, user]);

  const getMileageDate = (item, index = 0) => {
    let dateStr = "Today"
    //dateStr = moment().subtract(index, 'days').format('dddd MMM Do YY') //let dateStr = moment(item.date).format('dddd MMM Do YY')
    dateStr = item['date_str']
    return dateStr
  }

  return (
    <>
      {
        (!empty(devices)) ? (
          <>
            <div className='MuiPaper-root MuiAccordion-root Mui-expanded MuiAccordion-rounded MuiPaper-elevation1 MuiPaper-rounded'>
              <div style={{ padding: '24px' }}>
                <div className="">
                  <FormControl margin="normal" variant="filled">
                    <InputLabel>Device</InputLabel>
                    <Select
                      multiple={false}
                      value={selectedDeviceId ?? 'All'}
                      onChange={(event) => onChangeSelectedDevice(event.target.value)}
                      disabled={selectDevices.length === 0}
                    >
                      {selectDevices.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date &nbsp; ( GMT-0 )</TableCell>
                        <TableCell>Distance</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        (empty(selectedDeviceId) || selectedDeviceId === 'All') ? (
                          <>
                            <TableRow>
                              <TableCell colSpan={2} align='center'>No device selected</TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <>
                            {
                              (data && !isLoading) ? (
                                <>
                                  {
                                    (data.length > 0) ? (
                                      <>
                                        {data.map((item, index) => (
                                          <TableRow key={item.id}>
                                            <TableCell>{getMileageDate(item, index)}</TableCell>
                                            <TableCell>{item.mileage}</TableCell>
                                          </TableRow>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        <TableRow>
                                          <TableCell colSpan={2} align='center'>No data found</TableCell>
                                        </TableRow>
                                      </>
                                    )
                                  }
                                </>
                              ) : (
                                <>
                                  <TableRow>
                                    <TableCell colSpan={2} align='center'>Loading...</TableCell>
                                  </TableRow>
                                </>
                              )
                            }
                          </>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </>
        ) : (
          <></>
        )
      }
    </>
  )
}

const DailyMileageReportPage = () => {
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.devices.items);
  const { id } = useParams();
  return (
    <>
      <ReportLayout>
        {
          (!empty(devices)) ? (
            <DailyMileageDataTable
              id={id}
              devices={devices}
            />
          ) : (
            <></>
          )
        }
      </ReportLayout>
    </>
  )
}
export default DailyMileageReportPage
