import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from '../store';

import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, Typography, Checkbox, Box, Link, Modal, TextField, Button, Snackbar, Accordion, AccordionSummary, AccordionDetails, Grid
} from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';

import moment from 'moment';
import { api_call, console_log, empty, get_utc_timestamp } from '../helpers/untils';
import PaypalPaymentModal from './PaypalPaymentModal';
import { MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID } from '../config/constant';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '460px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  commandLabelBlock: {
    flex: 1,
  },
  inputRadioBlock: {
    flex: 1,
  },
  inputRadio2Block: {
    flex: 2,
  },
  imgSmall: {
    width: '50px',
    height: '42px',
    marginLeft: '5px',
    marginTop: '16px',
    marginBottom: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  checkboxTdColumn: {
    paddingLeft: '16px'
  }
}));

const lockTypeList = [
  {
    label: 'BT Lock',
    value: 'BT_lock'
  },
  {
    label: 'App Lock',
    value: 'app_lock'
  },
  {
    label: 'BT Unlock',
    value: 'BT_unlock'
  },
  {
    label: 'App Unlock',
    value: 'app_unlock'
  }
]

const commandTypeList = [
  {
    label: 'Light',
    value: 'Light'
  },
  {
    label: 'Horn',
    value: 'Horn'
  },
  {
    label: 'KillSwitch',
    value: 'KillSwitch'
  },
  {
    label: 'Shock',
    value: 'Shock'
  },
]

const LockUnlockSettingModal = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const user = useSelector((state) => state.session.user);
  const { open, setOpen, item, setItem } = props;
  const [updateTimestamp, setUpdateTimestamp] = useState(0)

  useEffect(() => {

  }, [open]);

  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");


  const [lockSetting, setLockSetting] = useState(null)
  useEffect(() => {
    if (!empty(item) && !empty(item.lockUnlockSetting)) {
      const setting = JSON.parse(item.lockUnlockSetting)
      setLockSetting(setting)
    } else {
      initLockSetting();
    }
  }, [item]);

  const [apiLoading, setApiLoading] = useState(false)

  const initLockSetting = () => {
    let setting = {}
    for (let k in lockTypeList) {
      const lockTypeItem = lockTypeList[k]
      const lockType = lockTypeItem['value']
      setting[lockType] = {
        'Light': '0',
        'Horn': '0',
        'KillSwitch': '0',
        'Shock': '0'
      }
    }
    setLockSetting(setting)
  }

  const onChangeLockSetting = (lockTpye, commandType, status) => {
    const setting = { ...lockSetting }
    setting[lockTpye][commandType] = status ? '1' : '0'
    console.log("setting::::", setting)
    setLockSetting(setting)
  }

  const handleSaveSetting = async () => {
    const url = `/api/devices/saveLockUnlockSetting`;
    const payload = {
      user_id: user.id,
      device_id: item.id,
      lockUnlockSetting: JSON.stringify(lockSetting),
    }

    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    setApiLoading(false)
    if (response.ok) {
      const apiData = await response.json()
      setItem({...item, lockUnlockSetting: apiData['device']['lockUnlockSetting'] }) //setItem(apiData['device']) //
      setToastMessage(`Lock/Unlock setting has been saved successfully`);
      setSnackbarOpen(true);

      setTimeout(()=>{
        handleModalClose()
      }, 1000)
    } else {
      const apiError = response
      console_log("apiError:::", apiError)
      setToastMessage(apiError.error);
      setSnackbarOpen(true);
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />
          <Typography variant='h6'>Lock and Unlock Settings</Typography>

          <div className='mt-4'>
            <div className={classes.formContainer}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      {
                        commandTypeList.map((ci, index) => {
                          return (
                            <TableCell key={index}>
                              <Typography variant='subtitle2'>{ci.label}</Typography>
                            </TableCell>
                          )
                        })
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(lockSetting) && lockTypeList.map((ti, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant='subtitle2'>{ti.label}</Typography>
                        </TableCell>
                        {
                          commandTypeList.map((ci, i) => {
                            return (
                              <TableCell key={`${index}_${i}`} padding='checkbox' align='center'>
                                <div className={classes.checkboxTdColumn}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={lockSetting[ti.value][ci.value] === '1'}
                                        onChange={(event) => onChangeLockSetting(ti.value, ci.value, event.target.checked)}
                                      />
                                    }
                                    label=""
                                  />
                                </div>
                              </TableCell>
                            )
                          })
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className={classes.buttons} style={{ paddingTop: '15px', width: '100%', margin: 'auto' }}>
              <Button type="button" color="primary" variant="contained" onClick={() => handleSaveSetting()} disabled={apiLoading}>
                Save
              </Button>
              <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
                Close
              </Button>
            </div>
          </div>

        </Box>
      </Modal>
    </>
  );
};

export default LockUnlockSettingModal;
