import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom"
import "../public/assets/css/main.css"
import moment from 'moment';

import { console_log, empty, timeConverter } from './helpers/untils';
import FrontSiteNavbar from './FrontSiteNavbar';

export default function TuroTripTemporarySoonPage(props) {
    //console_log("props:::", props)
    const { deltaTimestamp = 0 } = props;
    const history = useHistory()

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "../assets/js/animation.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {

    }, []);

    const LoginEvent = () => {
        history.push('/login')
    }
    const [shoppingCartLink, setShoppingCartLink] = useState("")

    return (
        <React.Fragment>

            <FrontSiteNavbar
                setShoppingCartLink={setShoppingCartLink}
            />

            <main id="content" role="main">
                <div className="d-md-flex">
                    <div className="container d-flex align-items-center vh-100 content-space-t-3 content-space-b-1 content-space-b-md-3 content-space-md-0">
                        <div className="row justify-content-between align-items-center flex-grow-1">
                            {/* <div class="col-9 col-md-5 mb-5 mb-md-0">
                                <img class="img-fluid" src="../assets/svg/oc-yelling.svg" alt="SVG Illustration" />
                            </div> */}
                            <div className="col-md-12">
                                <div className="mb-4 text-center">
                                    <h1 style={{ maxWidth: "640px", margin: 'auto' }}>
                                        {
                                            (deltaTimestamp > 0) ? (
                                                <>
                                                    Page will start working {empty(timeConverter(deltaTimestamp)) ? "soon" : "in "} <span className='text-primary'>{timeConverter(deltaTimestamp)}</span>
                                                </>
                                            ) : (
                                                <>
                                                    Page has expired <span className='text-primary'>{timeConverter((-1) * deltaTimestamp)}</span> {empty(timeConverter((-1) * deltaTimestamp)) ? "just now" : " ago"}
                                                </>
                                            )
                                        }
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}
