import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector, Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import { ReactComponent as IgnitionIcon } from '../public/images/ignition.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import store, { devicesActions, layoutActions } from './store';
import EditCollectionView from './EditCollectionView';
import { useEffectAsync } from './reactHelper';
import { formatPosition } from './common/formatter';
import { getDevices, getIsAdmin, getPosition, getUserDevices } from './common/selectors';
import { useTranslation } from './LocalizationProvider';
import StatusView from './map/StatusView';
import theme from './theme';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { map } from './map/Map';
import maplibregl from 'maplibre-gl';
import { api_call, console_log, empty, getAddressFromGoogleGeoLocation, getDeviceImageUri, get_data_value, get_utc_timestamp_ms } from './helpers/untils';
import { DEVICE_TYPE, GOOGLE_MAP_API_KEY } from './config/constant';
import { checkDeviceCanBeShownInMap, checkDeviceChargeRequired, checkDeviceIsActive, checkShowPaypalBanner, sleep } from './helpers/misc';
import DeviceImageUploadModal from './settings/DeviceImageUploadModal';
import CreditLogsModal from './settings/CreditLogsModal';

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: '100%',
  },
  listInner: {
    position: 'relative',
    margin: theme.spacing(1.5, 0),
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  avatar: {
    width: '25px',
    height: '25px',
  },
  listItem: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  green: {
    color: theme.palette.common.Green,
  },
  red: {
    color: theme.palette.common.Red,
  },
  gray: {
    color: theme.palette.common.Gray,
  },
  indicators: {
    lineHeight: 1,
    cursor: 'pointer'
  },
}));

const getStatusColor = (status) => {
  switch (status) {
    case 'online':
      return 'green';
    case 'offline':
      return 'red';
    case 'unknown':
    default:
      return 'gray';
  }
};

const getCreditColor = (credit) => {
  var credit_float = parseFloat(credit);
  if (credit_float <= 0) {
    return 'green';
  } else if (credit_float > 0 && credit_float < 15) {
    return 'gray'
  } else {
    return 'red';
  }
};

const getBatteryStatus = (batteryLevel) => {
  if (batteryLevel >= 70) {
    return 'green';
  }
  if (batteryLevel > 30) {
    return 'gray';
  }
  return 'red';
};

const DeviceRow = (props) => {
  const { data, index, style } = props

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();

  const isAdmin = useSelector(getIsAdmin);

  const { items, showPaypalCreditLogsPage, showDeviceCreditLogsPage } = data;
  const item = items[index];
  const position = useSelector(getPosition(item.id));
  const showIgnition = position?.attributes.hasOwnProperty('ignition') && position.attributes.ignition;

  const currentDeviceId = useSelector((state) => state.layout.currentDeviceId);
  //console.log("currentDeviceId, item.id:::", currentDeviceId, item.id)

  useEffect(() => {
    if (currentDeviceId) {
      if (currentDeviceId === item.id) {
        setTimeout(() => {
          onClickDeviceRow()
        }, 2000)
      }
    }
  }, [currentDeviceId]);

  const popupList = useRef({});

  const onClosePopup = (deviceId) => {
    const popup_list = { ...popupList.current }
    const popup = popup_list[deviceId]
    if (popup) {
      popup.remove();
      delete popup_list[deviceId]
      popupList.current = { ...popup_list }
    }
  }
  const addPopupList = (popup, deviceId) => {
    const popup_list = { ...popupList.current }
    popup_list[deviceId] = popup
    popupList.current = { ...popup_list }
  }

  const onClickDeviceRow = async () => {
    let userDeviceItem = null
    // if(!checkDeviceIsActive(item)) {
    //   return false
    // }
    //console_log("selected device item, position:::", item, position)

    ////////////////////////start paypal credit log modal//////////////////////////////////////
    // we only suggest subscriotion // 
    const url = `/api/device-check-charge-required/${item.id}`
    const apiRes = await api_call(url);
    if (apiRes.ok) {
      const apiResObj = await apiRes.json()
      console.log("ChargeapiResObj::::", apiResObj)
      userDeviceItem = apiResObj['device']

      let chargeRequired = apiResObj['charge_required']
      // if (chargeRequired) {
      //   if (typeof showPaypalCreditLogsPage === 'function') {
      //     if (checkShowPaypalBanner(item.id)) {
      //       showPaypalCreditLogsPage(userDeviceItem, chargeRequired)
      //       return false;
      //     }
      //   }
      // }
      // chargeRequired = 1

      if (chargeRequired == 1) {
        if (typeof showPaypalCreditLogsPage === 'function') {
          showPaypalCreditLogsPage(userDeviceItem, chargeRequired)
        }
      } else if (chargeRequired == 2) {
        if (checkShowPaypalBanner(item.id)) {
          showPaypalCreditLogsPage(userDeviceItem, chargeRequired)
          return false;
        }
      }
    } else {
      alert("Invalid request")
      return false
    }

    // we force user to subscriotion // 
    // const chargeRequired = checkDeviceChargeRequired(item, isAdmin)
    // console.log("chargeRequired:chargeRequired:", chargeRequired)
    // if (chargeRequired == 1) {
    //   dispatch(layoutActions.showCreditLogsModal());
    //   if (typeof showPaypalCreditLogsPage === 'function') {
    //     showPaypalCreditLogsPage(userDeviceItem)
    //   }
    // } else if (chargeRequired == 2) {
    //   dispatch(layoutActions.closeCreditLogsModal());
    //   if (typeof showPaypalCreditLogsPage === 'function') {
    //     showPaypalCreditLogsPage(userDeviceItem)
    //     return false;
    //   }
    // } else {
    //   dispatch(layoutActions.closeCreditLogsModal());
    // }

    if (empty(userDeviceItem)) {
      return false
    }

    ////////////////////////end paypal credit log modal//////////////////////////////////////

    dispatch(devicesActions.select(item));
    dispatch(layoutActions.closeDeviceListMenu());
    dispatch(layoutActions.setCurrentDeviceId({ id: 0 }));
    dispatch(layoutActions.setDeviceReloadTimestamp({ value: get_utc_timestamp_ms() }));

    if (true || item.status === 'online' || item.status === 'offline') { //all devices are clickable
      const placeholder = document.createElement('div');
      ReactDOM.render(
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <StatusView
              deviceId={item.id}
              address={``}
              lat={position?.latitude}
              lng={position?.longitude}
              onShowDetails={(positionId) => history.push(`/position/${positionId}`)}
              onShowHistory={(deviceId) => history.push(`/replay/${deviceId}`)}
              onShareLink={(deviceId) => history.push(`/share/${deviceId}`)}
              onEditClick={(deviceId) => history.push(`/device/${deviceId}`)}
              onLogClick={(deviceId) => history.push(`/reports/event/${deviceId}`)}
              onClosePopup={(deviceId) => onClosePopup(deviceId)}
              history={history}
              userDeviceItem={userDeviceItem}
            />
          </ThemeProvider>
        </Provider>,
        placeholder,
      );
      const popup = new maplibregl.Popup({
        offset: 25,
        anchor: 'top',
      })

      popup.setDOMContent(placeholder)
        .setLngLat([position.longitude, position.latitude])
        .addTo(map);

      addPopupList(popup, item.id)
    }
  }

  ////////////////////////////////addition moovetrax modal////////////////////////////////
  const [deviceItem, setDeviceItem] = useState(item);
  const [deviceUploadModalOpen, setDeviceUploadModalOpen] = useState(false);
  ////////////////////////////////////////////////////////////////////////////////

  const showFilePopup = (device_tem) => {
    console_log("device_tem::::", device_tem)
    setDeviceItem(device_tem)
    dispatch(layoutActions.closeDeviceListMenu());
    setDeviceUploadModalOpen(true)
  }

  const onClickDeviceBalance = async () => {
    console_log(`onClickDeviceBalance:::::`)
    // onClickDeviceRow() // for testing
    // return true

    let userDeviceItem = null
    ////////////////////////start paypal credit log modal//////////////////////////////////////
    // we only suggest subscriotion // 
    const url = `/api/device-check-charge-required/${item.id}`
    const apiRes = await api_call(url);
    if (apiRes.ok) {
      const apiResObj = await apiRes.json()
      console.log("apiResObj::::", apiResObj)
      userDeviceItem = apiResObj['device']
      let chargeRequired = apiResObj['charge_required']
      // if (chargeRequired) {
      //   if (typeof showPaypalCreditLogsPage === 'function') {
      //     if (checkShowPaypalBanner(item.id)) {
      //       showPaypalCreditLogsPage(userDeviceItem, chargeRequired)
      //       return false;
      //     }
      //   }
      // }
      if (chargeRequired == 1) {
        if (typeof showPaypalCreditLogsPage === 'function') {
          showPaypalCreditLogsPage(userDeviceItem, chargeRequired)
        }
      } else if (chargeRequired == 2) {
        if (checkShowPaypalBanner(item.id)) {
          showPaypalCreditLogsPage(userDeviceItem, chargeRequired)
          return false;
        }
      }
    } else {
      alert("Invalid request")
      return false
    }

    // we force user to subscriotion //
    // const chargeRequired = checkDeviceChargeRequired(item, isAdmin)
    // if (chargeRequired == 1) {
    //   dispatch(layoutActions.showCreditLogsModal());
    //   if (typeof showPaypalCreditLogsPage === 'function') {
    //     showPaypalCreditLogsPage(userDeviceItem)
    //   }
    // } else if (chargeRequired == 2) {
    //   dispatch(layoutActions.closeCreditLogsModal());
    //   if (typeof showPaypalCreditLogsPage === 'function') {
    //     showPaypalCreditLogsPage(userDeviceItem)
    //     return false;
    //   }
    // } else {
    //   dispatch(layoutActions.closeCreditLogsModal());
    // }

    if (empty(userDeviceItem)) {
      return false
    }

    /////////////////////////////////////////////////////////////////
    showDeviceCreditLogsPage(userDeviceItem)
  }

  return (
    <div style={style} key={item.id}>
      <ListItem button key={item.id} className={classes.listItem} onClick={async () => { return false }}>
        <ListItemAvatar>
          <Avatar onClick={() => showFilePopup(item)}>
            <img className={deviceItem.image ? classes.avatar : classes.icon} src={getDeviceImageUri(deviceItem)} alt="" />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          onClick={() => onClickDeviceRow()}
          primary={<span className={item.deviceType === DEVICE_TYPE.SMARTCAR ? "smartcar-primary-color" : item.deviceType === DEVICE_TYPE.TESLA ? "tesla-primary-color" : item.deviceType === DEVICE_TYPE.USB ? "usb-primary-color" : item.deviceType === DEVICE_TYPE.OBD ? "obd-primary-color" : ""}>{`${item.name}`}</span>}
          secondary={`${item.status === 'offline' ? 'offline \u00A0 ' + (item.lastConnect ? moment(item.lastConnect).format('YYYY-MM-DD HH:mm') : '') : item.status}`}
          classes={{ secondary: classes[getStatusColor(item.status)] }}
        />

        <ListItemSecondaryAction className={classes.indicators} onClick={() => onClickDeviceBalance()}>
          <Grid container direction="row" alignItems="center" alignContent="center" spacing={2}>
            {showIgnition && (
              <Grid item>
                <SvgIcon component={IgnitionIcon} />
              </Grid>
            )}
            <ListItemText secondary={`${item.credit}`} classes={{ secondary: classes[getCreditColor(item.credit)] }} />

            {/* {position.attributes.hasOwnProperty('batteryLevel') && (
            <Grid item container xs alignItems="center" alignContent="center">
              <Grid item>
                <span className={classes.batteryText}>{formatPosition(position.attributes.batteryLevel, 'batteryLevel', t)}</span>
              </Grid>
              <Grid item>
                <BatteryFullIcon className={classes[getBatteryStatus(position.attributes.batteryLevel)]} />
              </Grid>
            </Grid>
            )} */}
          </Grid>
        </ListItemSecondaryAction>
      </ListItem>

      {
        (deviceUploadModalOpen) && (
          <>
            <DeviceImageUploadModal
              open={deviceUploadModalOpen}
              setOpen={setDeviceUploadModalOpen}
              item={deviceItem}
              setItem={setDeviceItem}
            />
          </>
        )
      }
    </div>
  );
};

const DeviceView = ({ updateTimestamp, onMenuClick, filter, updateDeviceCountData, showPaypalCreditLogsPage, showDeviceCreditLogsPage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentDeviceSortKey = useSelector((state) => state.layout.currentDeviceSortKey);
  const currentDeviceSortOrder = useSelector((state) => state.layout.currentDeviceSortOrder);
  //console.log("currentDeviceSortKey::::", currentDeviceSortKey)

  const listInnerEl = useRef(null);
  const user = useSelector((state) => state.session.user);
  const items = useSelector(getUserDevices);
  //console.log("device items", items)
  const [filteredItems, setFilteredItems] = useState(null);

  const sortWithAttributeAlphabetical = (a, b) => {
    if (currentDeviceSortOrder === "asc") {
      if (a === "") {
        return 1
      }
      else if (b === "") {
        return -1
      }
      else {
        return a.localeCompare(b)
      }
    } else {
      if (a === "") {
        return -1
      }
      else if (b === "") {
        return 1
      }
      else {
        return b.localeCompare(a)
      }
    }
  }

  const getSortedDeviceList = (device_list, sortKey = "") => {
    const deviceList = [...device_list]
    let sortedDeviceList = []

    if (sortKey === "Installation") {
      if (currentDeviceSortOrder === "asc") {
        sortedDeviceList = deviceList.sort((a, b) => Number(a.id) - Number(b.id));
      } else {
        sortedDeviceList = deviceList.sort((b, a) => Number(a.id) - Number(b.id));
      }
    }
    else if (sortKey === "Alphabetically") {
      if (currentDeviceSortOrder === "asc") {
        sortedDeviceList = deviceList.sort((a, b) => a?.name?.localeCompare(b?.name));
      } else {
        sortedDeviceList = deviceList.sort((b, a) => a?.name?.localeCompare(b?.name));
      }
    }
    else if (sortKey === "Balance") {
      if (currentDeviceSortOrder === "asc") {
        sortedDeviceList = deviceList.sort((a, b) => Number(b.credit) - Number(a.credit));
      } else {
        sortedDeviceList = deviceList.sort((b, a) => Number(b.credit) - Number(a.credit));
      }
    }
    else if (sortKey === "Online") {
      if (currentDeviceSortOrder === "asc") {
        sortedDeviceList = deviceList.sort((a, b) => {
          if (a.status === "online") {
            return -1
          }
          else if (b.status === "online") {
            return 1
          }
          else {
            const aLastConnectTimestamp = !empty(a.lastConnect) ? moment(a.lastConnect).unix() : 0
            const bLastConnectTimestamp = !empty(b.lastConnect) ? moment(b.lastConnect).unix() : 0
            if (aLastConnectTimestamp > bLastConnectTimestamp) {
              return -1
            }
            else if (aLastConnectTimestamp < bLastConnectTimestamp) {
              return 1
            } else {
              return 0
            }
          }
        })
      } else {
        sortedDeviceList = deviceList.sort((a, b) => {
          if (a.status === "online") {
            return 1
          }
          else if (b.status === "online") {
            return -1
          }
          else {
            const aLastConnectTimestamp = !empty(a.lastConnect) ? moment(a.lastConnect).unix() : 0
            const bLastConnectTimestamp = !empty(b.lastConnect) ? moment(b.lastConnect).unix() : 0
            if (aLastConnectTimestamp > bLastConnectTimestamp) {
              return 1
            }
            else if (aLastConnectTimestamp < bLastConnectTimestamp) {
              return -1
            } else {
              return 0
            }
          }
        })
      }
    }
    else if (sortKey === "Lock Status") {
      if (currentDeviceSortOrder === "asc") {
        sortedDeviceList = deviceList.sort((a, b) => {
          if (empty(a.lock_status_timestamp)) {
            return 1
          }
          else if (empty(b.lock_status_timestamp)) {
            return -1
          }
          else {
            return new Date(b.lock_status_timestamp) - new Date(a.lock_status_timestamp)
          }
        });
      } else {
        sortedDeviceList = deviceList.sort((b, a) => {
          if (empty(a.lock_status_timestamp)) {
            return 1
          }
          else if (empty(b.lock_status_timestamp)) {
            return -1
          }
          else {
            return new Date(b.lock_status_timestamp) - new Date(a.lock_status_timestamp)
          }
        });
      }

    }
    else if (sortKey === "Kill status") {
      if (currentDeviceSortOrder === "asc") {
        sortedDeviceList = deviceList.sort((a, b) => {
          if (empty(a.kill_status_timestamp)) {
            return 1
          }
          else if (empty(b.kill_status_timestamp)) {
            return -1
          }
          else {
            return new Date(b.kill_status_timestamp) - new Date(a.kill_status_timestamp)
          }
        });
      } else {
        sortedDeviceList = deviceList.sort((b, a) => {
          if (empty(a.kill_status_timestamp)) {
            return 1
          }
          else if (empty(b.kill_status_timestamp)) {
            return -1
          }
          else {
            return new Date(b.kill_status_timestamp) - new Date(a.kill_status_timestamp)
          }
        });
      }
    }
    else if (sortKey === "Make") {
      sortedDeviceList = deviceList.sort((a, b) => {
        return sortWithAttributeAlphabetical(a.make, b.make)
      })
    }
    else if (sortKey === "Model") {
      sortedDeviceList = deviceList.sort((a, b) => {
        return sortWithAttributeAlphabetical(a.model, b.model)
      })
    }
    else if (sortKey === "Color") {
      sortedDeviceList = deviceList.sort((a, b) => {
        return sortWithAttributeAlphabetical(a.color, b.color)
      })
    }
    else if (sortKey === "Category") {
      sortedDeviceList = deviceList.sort((a, b) => {
        return sortWithAttributeAlphabetical(a.category, b.category)
      })
    }
    else if (sortKey === "Miles") {
      if (currentDeviceSortOrder === "asc") {
        sortedDeviceList = deviceList.sort((a, b) => {
          if (empty(a.odometer)) {
            return -1
          }
          else if (empty(b.odometer)) {
            return 1
          }
          else {
            return Number(b.odometer) - Number(a.odometer)
          }
        });
      } else {
        sortedDeviceList = deviceList.sort((b, a) => {
          if (empty(a.odometer)) {
            return -1
          }
          else if (empty(b.odometer)) {
            return 1
          }
          else {
            return Number(b.odometer) - Number(a.odometer)
          }
        });
      }
    }
    else if (sortKey === "Vin") {
      sortedDeviceList = deviceList.sort((a, b) => {
        return sortWithAttributeAlphabetical(a.vin, b.vin)
      })
    }
    else {
      sortedDeviceList = deviceList
    }
    setFilteredItems(sortedDeviceList);
    return sortedDeviceList
  }

  const sortDeviceList = (device_list, sortKey = "") => {
    if (sortKey === "") {
      sortKey = currentDeviceSortKey
    }
    //sortKey = "Balance" // for testing
    const deviceList = [...device_list]
    const sortedDeviceList = getSortedDeviceList(deviceList, sortKey) // useMemo(() => getSortedDeviceList(deviceList, sortKey), [deviceList, sortKey]);
    setFilteredItems(sortedDeviceList);
    return sortedDeviceList
  }

  useEffect(() => {
    if (filteredItems && filteredItems.length > 0) {
      sortDeviceList([...filteredItems], currentDeviceSortKey)
    }
  }, [currentDeviceSortKey])

  useEffect(() => {
    let filtered_device_list = []
    if (filter.toLowerCase() === 'none') {
      filtered_device_list = items ? items.filter((item) => (empty(item.users) || item.users.length === 0)) : []
    } else {
      filtered_device_list = filter.trim().length > 0 ? items.filter((item) => `${item.name} ${item.uniqueId} ${item.vin} ${item.license_tag} ${item.iccid} ${item.user_email_list_str}`.toLowerCase().includes(filter?.toLowerCase())) : items
    }

    filtered_device_list = sortDeviceList([...filtered_device_list], currentDeviceSortKey)
    //setFilteredItems(filtered_device_list);

    const online_device_list = items ? items.filter((item) => {
      return checkDeviceIsActive(item)
    }) : []

    const inside_map_device_list = items ? items.filter((item) => {
      return checkDeviceCanBeShownInMap(item)
    }) : []

    const deviceCountData = {
      device_count: items ? items.length : 0,
      filtered_device_count: filtered_device_list ? filtered_device_list.length : 0,
      online_device_count: online_device_list.length,
      inside_map_device_count: inside_map_device_list.length,
    }
    updateDeviceCountData(deviceCountData)

  }, [filter, items]);

  if (listInnerEl.current) {
    listInnerEl.current.className = classes.listInner;
  }

  const [mapBoundaryInitialized, setMapBoundaryInitialized] = useState(false)
  const calculateMapBoundary = async (devices) => {
    console.log("::::: devices:::::", devices.length)
    if (devices.length) {
      if (! /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //map.setPadding({ left: parseInt(theme.dimensions.drawerWidthDesktop, 10) }); //quan code
      }
      var points = [];
      devices.forEach((device) => { if (device.longitude && device.latitude) points.push([device.longitude, device.latitude]) });
      if (points.length === 1) {
        //map.easeTo({center: points[0], zoom: 18});
        var coordinates = [];
        const diff = 0.03;
        coordinates.push([points[0][0] - diff, points[0][1] - diff])
        coordinates.push([points[0][0] + diff, points[0][1] + diff])
        var bounds = coordinates.reduce(function (bounds, coord) {
          return bounds.extend(coord);
        }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));
        map.fitBounds(bounds, { padding: { top: 50, bottom: 50, left: 50, right: 50 } });
      }
      else {
        var coordinates = points;
        var bounds = coordinates.reduce(function (bounds, coord) {
          return bounds.extend(coord);
        }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));
        map.fitBounds(bounds, { padding: { top: 50, bottom: 50, left: 50, right: 50 } });
      }
      setMapBoundaryInitialized(true)
    }

  }
  useEffect(() => {
    if (!mapBoundaryInitialized) {
      calculateMapBoundary(items)
    }
  }, [items]);


  return (
    <AutoSizer className={classes.list}>
      {({ height, width }) => (
        <List disablePadding>
          <FixedSizeList
            width={width}
            height={height}
            itemCount={filteredItems.length}
            itemData={{ items: filteredItems, showPaypalCreditLogsPage: showPaypalCreditLogsPage, showDeviceCreditLogsPage: showDeviceCreditLogsPage, onMenuClick }}
            itemSize={72}
            itemKey={index => {
              return index;
            }}
            overscanCount={filteredItems.length} //10
            innerRef={listInnerEl}
          >
            {DeviceRow}
          </FixedSizeList>
        </List>
      )}
    </AutoSizer>
  );
};

const DevicesList = ({ filter, updateDeviceCountData, showPaypalCreditLogsPage, showDeviceCreditLogsPage }) => (
  <EditCollectionView
    content={DeviceView}
    editPath="/device"
    endpoint="devices"
    disableAdd
    filter={filter}
    updateDeviceCountData={updateDeviceCountData}
    showPaypalCreditLogsPage={showPaypalCreditLogsPage}
    showDeviceCreditLogsPage={showDeviceCreditLogsPage}
  />
);

export default DevicesList;
