import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import theme from '@mapbox/mapbox-gl-draw/src/lib/theme';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { map } from './Map';
import { geofenceToFeature, geometryToArea, getPolygonBoundingBox, getPolygonBoundingBoxCenter } from './mapUtil';
import { geofencesActions } from '../store';
import { api_call, console_log } from '../helpers/untils';

const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    polygon: true,
    trash: true,
  },
  userProperties: true,
  styles: [...theme, {
    id: 'gl-draw-title',
    type: 'symbol',
    filter: ['all'],
    layout: {
      'text-field': '{user_name}',
      'text-font': ['Open Sans Regular'], //https://github.com/openmaptiles/fonts/tree/master/open-sans
      'text-size': 12,
    },
    paint: {
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  }],
});

const GeofenceEditMap = (props) => {
  const { geofenceTimestamp, setGeofenceTimestamp, handleClose } = props
  const dispatch = useDispatch();
  const history = useHistory();

  const geofences = useSelector((state) => Object.values(state.geofences.items));
  //console.log('geofences::::', geofences)
  const selectedGeofence = useSelector((state) => state.geofences.selectedGeofence );
  //console.log("selectedGeofence:::", selectedGeofence)

  const selectedDeviceId = useSelector((state) => state.geofences.selectedDeviceId );
  //console.log("selectedDeviceId:::", selectedDeviceId)
  

  const user = useSelector((state) => state.session.user);

  const refreshGeofences = async () => {
    let url = '/api/geofences/get-list'
    const response = await api_call(url);
    if (response.ok) {
      const geofence_list = await response.json()
      dispatch(geofencesActions.refresh(geofence_list));
      redrawGeofences(geofence_list)
    }
  };

  const redrawGeofences = async (geofence_list) => {
    draw.deleteAll();
    geofence_list.forEach((geofence) => {
      draw.add(geofenceToFeature(geofence));
    });
  };

  const onDrawCreate = async (event) => {
    //console.log("draw.create event:::", event)
    const feature = event.features[0];
    var center = event.features[0].geometry.coordinates;
    //console.log("draw.create center:::", center)

    const newItem = { userId: user.id, name: '', area: geometryToArea(feature.geometry), coordinates: feature.geometry.coordinates[0] };
    if(selectedDeviceId) {
      newItem['deviceId'] = selectedDeviceId
    }
    draw.delete(feature.id);
    console_log("draw.create newItem:::", newItem)

    const response = await api_call('/api/geofences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });
    if (response.ok) {
      const item = await response.json();
      history.push(`/geofence/${item.id}`);
    }
  }

  const onDrawUpdate = async (event) => {
    //console.log("draw.update event:::", event)
    const feature = event.features[0];
    console_log("draw.update feature:::", feature)
    var center = event.features[0].geometry.coordinates;
    //console.log("draw.update center:::", center)
    

    if (true) {
      const updatedItem = { area: geometryToArea(feature.geometry), coordinates: feature.geometry.coordinates[0] };
      console_log("draw.update updatedItem:::", updatedItem)

      const response = await api_call(`/api/geofences/${feature.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedItem),
      });
      if (response.ok) {
        refreshGeofences();
      }
    }
  }

  const onDrawDelete = async (event) => {
    //console.log("draw.delete event:::", event)
    const feature = event.features[0];
    const response = await api_call(`/api/geofences/${feature.id}`, { method: 'DELETE' });
    if (response.ok) {
      refreshGeofences();
    }
  }

  const onDrawRender = async (event) => {
    //console.log("draw.render event:::", event)
  }

  useEffect(() => {
    map.addControl(draw, 'top-right');

    map.on('draw.create', onDrawCreate);
    map.on('draw.delete', onDrawDelete);
    map.on('draw.update', onDrawUpdate);

     // map.on('click', onDrawRender);

    return () => {
      console_log("map off::::::::::::::")
      map.off('draw.create', onDrawCreate)
      map.off('draw.delete', onDrawDelete)
      map.off('draw.update', onDrawUpdate)
     
     // map.off('click', onDrawRender)

      map.removeControl(draw)
    }
  }, []);


  useEffect(() => {
    refreshGeofences();
  }, [geofenceTimestamp]);

  useEffect(() => {
    if(selectedGeofence) {
      const center = selectedGeofence.coordinates
      //console.log("center:::", center)
      if(center) {
        const centerCoordinates = JSON.parse(center)
        const centerPosition = getPolygonBoundingBoxCenter(centerCoordinates)
        //console.log("centerPosition:::::::::::::::", centerPosition)

        map.easeTo({
          center: centerPosition,
          //zoom: 9
        });

        const boundBox = getPolygonBoundingBox(centerCoordinates)
        map.fitBounds(boundBox);

        dispatch(geofencesActions.setSelectedGeofence(null))

        handleClose()
      }
    }
  }, [selectedGeofence]);

  return null
}

export default GeofenceEditMap;
