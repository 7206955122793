import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffectAsync } from './reactHelper';
import {
  makeStyles, Snackbar,
} from '@material-ui/core';



import { useTheme } from '@material-ui/core/styles';

import { useTranslation } from './LocalizationProvider';
import { api_call, console_log } from './helpers/untils';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    top: 0,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    bottom: 56,
    zIndex: 1301,
    transition: 'transform .5s ease',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-100vw)',
    },
  },
  paper: {
    zIndex: 1,
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  deviceList: {
    flex: 1,
  },
  sidebarToggle: {
    position: 'absolute',
    left: theme.spacing(1.5),
    top: theme.spacing(3),
    borderRadius: '0px',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: '#777777',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

const TemporaryLinkApprovePage = () => {
  //const current = moment();

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const t = useTranslation();
  const dispatch = useDispatch();

  const { shareUrl, approve_type, action } = useParams(); //approve_type: avail, unlock, unkill
  console_log("shareUrl, approve_type, action", shareUrl, approve_type, action)

  const [currentLink, setCurrentLink] = useState(null)

  const loadLinkInfo = async () => {
    if (shareUrl) {
      const response = await api_call(`/api/shared-links/${shareUrl}`);
      if (response.ok) {
        const linkData = await response.json();
        console_log("linkData:::", linkData)
        const link = linkData

        setCurrentLink(link)
        if (link && link.photo_uploads) {
          let photo_uploads = JSON.parse(link.photo_uploads)
          // let approve_key = approve_type + "_approved"
          // if (photo_uploads[approve_key] === "approved") {
          //   setToastMessage("Link is already approved")
          //   setSnackbarOpen(true)
          //   return redirectNextPage()
          // }
          await updateLinkStatus(link.id, approve_type, action)
          return true;
        }
      } else {
        // if (response.error) {
        //   setToastMessage(response.error)
        //   setSnackbarOpen(true)
        // }
      }
    }
    setToastMessage("Invalid request")
    setSnackbarOpen(true)
    return redirectNextPage()
  }

  const updateLinkStatus = async (linkId, type, status) => {
    var url = `/api/links/update-status`
    const post_data = {
      linkId: linkId,
      type: type,
      status: status
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(post_data),
    });

    if (response.ok) {
      let responseObj = await response.json()
      setToastMessage(responseObj.message)
      setSnackbarOpen(true)
      return redirectNextPage()
    } else {
      let error = response
      setToastMessage(error.message)
      setSnackbarOpen(true)
      return redirectNextPage()
    }
  }

  const redirectNextPage = async () => {
    setTimeout(() => {
      history.push("/main")
    }, 2000)
  }

  const availableApproveTypeList = ['approved', 'denied']
  useEffectAsync(async () => {
    if (shareUrl && approve_type && action) {
      if (availableApproveTypeList.includes(action)) {
        loadLinkInfo()
      } else {
        setToastMessage("Invalid request")
        setSnackbarOpen(true)
        return redirectNextPage()
      }
    }
  }, [shareUrl, approve_type, action]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        autoHideDuration={3000}
        message={toastMessage}
      />
    </div>
  );
};

export default TemporaryLinkApprovePage;
