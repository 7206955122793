import { parse, stringify } from 'wellknown';
import circle from '@turf/circle';
import palette from '../theme/palette';
import deviceCategories from '../common/deviceCategories';

export const loadImage = (url) => new Promise((imageLoaded) => {
  const image = new Image();
  image.onload = () => imageLoaded(image);
  image.src = url;
});

const canvasTintImage = (image, color) => {
  try {
    const canvas = document.createElement('canvas');
    canvas.width = image.width * devicePixelRatio;
    canvas.height = image.height * devicePixelRatio;
    canvas.style.width = `${image.width}px`;
    canvas.style.height = `${image.height}px`;

    const context = canvas.getContext('2d');

    context.save();
    context.fillStyle = color;
    context.globalAlpha = 1;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.globalCompositeOperation = 'destination-atop';
    context.globalAlpha = 1;
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    context.restore();

    return canvas;
  } catch (e) {
    console.log(`canvasTintImage error::`, e)
    return image
  }

};

export const prepareIcon = async (background, icon, color, accIcon) => {
  const canvas = document.createElement('canvas');
  //console.log("background.width", background.width, background.height, devicePixelRatio, background)
  let backgroundHeight = background.height

  const accIconOffsetY = 41
  if (accIcon) {
    backgroundHeight = backgroundHeight + accIconOffsetY
  }

  canvas.width = background.width * devicePixelRatio;
  canvas.height = backgroundHeight * devicePixelRatio;
  canvas.style.width = `${background.width}px`;
  canvas.style.height = `${backgroundHeight}px`;

  const context = canvas.getContext('2d');
  context.drawImage(background, 0, 0, canvas.width, canvas.width);

  context.strokeStyle = "#0000FF";
  context.beginPath();
  context.arc(canvas.width / 2, canvas.width / 2, canvas.width / 2, 0, 2 * Math.PI);
  context.stroke();

  if (color === palette.common['White']) {
    context.fillStyle = palette.common['Gray'];
    context.beginPath();
    context.arc(canvas.width / 2, canvas.width / 2, (canvas.width / 2) - 4, 0, 2 * Math.PI);
    context.fill();
  }

  if (icon) {
    const iconRatio = 0.5;
    const imageWidth = canvas.width * iconRatio;
    const imageHeight = canvas.width * iconRatio;
    if (navigator.userAgent.toLowerCase().includes('firefox') > 0) {
      context.drawImage(icon, (canvas.width - imageWidth) / 2, (canvas.width - imageHeight) / 2, imageWidth, imageHeight);
    } else {
      context.drawImage(canvasTintImage(icon, color), (canvas.width - imageWidth) / 2, (canvas.width - imageHeight) / 2, imageWidth, imageHeight);
    }
  }

  if (accIcon) {
    const iconRatio = 0.6;
    const imageWidth = canvas.width * iconRatio;
    const imageHeight = canvas.width * iconRatio;
    const offsetY = accIconOffsetY * devicePixelRatio
    context.drawImage(accIcon, (canvas.width - imageWidth) / 2, offsetY + (canvas.width - imageHeight) / 2, imageWidth, imageHeight);
  }

  return context.getImageData(0, 0, canvas.width, canvas.height);
};

export const reverseCoordinates = (it) => {
  if (!it) {
    return it;
  } if (Array.isArray(it)) {
    if (it.length === 2 && !Number.isNaN(it[0]) && !Number.isNaN(it[1])) {
      return [it[1], it[0]];
    }
    return it.map((it) => reverseCoordinates(it));
  }
  return {
    ...it,
    coordinates: reverseCoordinates(it.coordinates),
  };
};

export const geofenceToFeature = (item) => {
  if (item.area.indexOf('CIRCLE') > -1) {
    const coordinates = item.area.replace(/CIRCLE|\(|\)|,/g, ' ').trim().split(/ +/);
    const options = { steps: 32, units: 'meters' };
    const polygon = circle([Number(coordinates[1]), Number(coordinates[0])], Number(coordinates[2]), options);
    return {
      id: item.id,
      type: 'Feature',
      geometry: polygon.geometry,
      properties: { name: item.name },
    };
  }
  return {
    id: item.id,
    type: 'Feature',
    geometry: reverseCoordinates(parse(item.area)),
    properties: { name: item.name },
  };
};

export const geometryToArea = (geometry) => stringify(reverseCoordinates(geometry));

export const getPolygonBoundingBox = (polygon) => {
  // bounds [xMin, yMin][xMax, yMax]
  var bounds = [[], []];
  var polygon;
  var latitude;
  var longitude;

  for (var j = 0; j < polygon.length; j++) {
    longitude = polygon[j][0];
    latitude = polygon[j][1];

    bounds[0][0] = bounds[0][0] < longitude ? bounds[0][0] : longitude;
    bounds[1][0] = bounds[1][0] > longitude ? bounds[1][0] : longitude;
    bounds[0][1] = bounds[0][1] < latitude ? bounds[0][1] : latitude;
    bounds[1][1] = bounds[1][1] > latitude ? bounds[1][1] : latitude;
  }
  return bounds;
}

export const getPolygonBoundingBoxCenter = (polygon) => {
  const bounds = getPolygonBoundingBox(polygon)
  const center = [(bounds[0][0] + bounds[1][0]) / 2, (bounds[0][1] + bounds[1][1]) / 2]
  return center;
}

export const loadCategoryImages = async () => {
  const categoryImages = {}
  for (let k in deviceCategories) {
    let category = 'Default';
    if (deviceCategories[k]) {
      category = deviceCategories[k];
    }
    const image = await loadImage(`/images/icon/${category}.svg`)
    categoryImages[category] = image
  }
  return categoryImages
}
