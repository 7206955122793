import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SortIcon from '@material-ui/icons/Sort';
import CheckIcon from '@material-ui/icons/Check';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  makeStyles, Paper, Toolbar, TextField, IconButton, Button,
} from '@material-ui/core';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_SORT_KEY, DEFAULT_SORT_ORDER, SORT_KEY_LIST } from '../config/constant';
import { layoutActions } from '../store';
import { setSettingPersist } from '../helpers/misc';

const MainSort = (props) => {
  const dispatch = useDispatch();
  const currentDeviceSortKey = useSelector((state) => state.layout.currentDeviceSortKey);
  const currentDeviceSortOrder = useSelector((state) => state.layout.currentDeviceSortOrder);

  /////////////////////////////////////////////////////////////////////////////////////////
  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const onSortMenuClick = (anchorId) => {
    setSelectedAnchorEl(anchorId);
  }
  const menuHide = () => {
    setSelectedAnchorEl(null);
  }

  const defaultSortKey = currentDeviceSortKey ? currentDeviceSortKey : DEFAULT_SORT_KEY
  const defaultSortOrder = currentDeviceSortOrder ? currentDeviceSortOrder : DEFAULT_SORT_ORDER

  const [sortItem, setSortItem] = useState(defaultSortKey)
  const handleClickSortItem = (item) => {
    if (item !== "") {
      if (defaultSortKey !== item) {
        setSortItem(item)
        dispatch(layoutActions.setCurrentDeviceSortKey(item));
        setSettingPersist("main_device_sort_key", item)

        const sortOrder = DEFAULT_SORT_ORDER
        dispatch(layoutActions.setCurrentDeviceSortOrder(sortOrder));
        setSettingPersist("main_device_sort_order", sortOrder)
      } else {
        const sortOrder = defaultSortOrder === "asc" ? "desc" : "asc"
        dispatch(layoutActions.setCurrentDeviceSortOrder(sortOrder));
        setSettingPersist("main_device_sort_order", sortOrder)
      }

      setTimeout(() => {
        menuHide()
      }, 200)
    }
  }
  return (
    <>
      <IconButton onClick={(event) => onSortMenuClick(event.currentTarget)} title="Sort By">
        <SortIcon />
      </IconButton>

      <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
        {
          SORT_KEY_LIST.map((item, index) => {
            return (
              <MenuItem key={index} disabled={item === ""} onClick={() => handleClickSortItem(item)}>
                <div style={{ width: '100%' }}>
                  <Stack direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={2} sx={{ width: '100%' }}>
                    <span>{item}</span>
                    {
                      (sortItem === item) ? (
                        <span><CheckIcon />{(defaultSortOrder === 'asc') ? (<><ArrowDownwardIcon /></>) : (<><ArrowUpwardIcon /></>)}</span>
                      ) : (
                        <span style={{ opacity: 0 }}>{(item !== "") && <><CheckIcon /><ArrowDownwardIcon /></>}</span>
                      )
                    }
                  </Stack>
                </div>
              </MenuItem>
            )
          })
        }
      </Menu>
    </>
  );
};

export default MainSort;
