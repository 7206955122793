import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { api_call, console_log, copyObject, empty, get_data_value, is_null } from '../helpers/untils';
import {
  Button, TextField, Typography, Box, Checkbox, Snackbar, makeStyles,
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, IconButton
} from '@material-ui/core';
import { Stack } from '@mui/material';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import moment from 'moment';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

import ConfirmDialog from '../components/ConfirmDialog';
import { APP_NAME, MAINT_VIDEO_ID } from '../config/constant';
import { sleep } from '../helpers/misc';

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: '1px solid rgba(224, 224, 224, 1)',
      verticalAlign: 'middle',
      textAlign: 'center'
    }
  }
});

const MaintListTable = (props) => {
  const { open, setOpen, deviceId, deviceItem, onClickPL } = props
  const user = useSelector((state) => state.session.user);

  const TRACK_LENGTH = 3
  const classes = useStyles();
  const history = useHistory()
  const [pageLoading, setPageLoading] = useState(false)
  const [device, setDevice] = useState({})
  const [nextServiceLogList, setNextServiceLogList] = useState([]) // this is for edit text box

  const getNextLocalDate = () => {
    let value = moment().format("YYYY-MM-DD")
    const dateTime = `${value} 00:00:00`
    const utcDateTime = moment(dateTime).add(1, 'M').utc().format("YYYY-MM-DD HH:mm:ss")
    console.log("utcDateTime:::", utcDateTime)
    return utcDateTime
  }

  const defaultLogRow = {
    date: '',
    miles: '',
  }

  const initDeviceFieldData = (device) => {
    setDevice(device)
    setNotificationEmail(device.maint_notification_email)
    let deviceOdometer = device.odometer
    if (deviceOdometer) {
      deviceOdometer = parseInt(deviceOdometer)
    } else {
      deviceOdometer = 0
    }
    setMaintOdometer(deviceOdometer)
  }

  const initNextServiceLogList = (maintItemList) => {
    let next_service_log_row = []
    if (maintItemList) {
      for (let k in maintItemList) {
        let nextServiceLogRow = {
          ...defaultLogRow,
          date: getNextLocalDate()
        }
        const row = maintItemList[k]
        const log_list = row['log_list']
        if (log_list && log_list.length > 0) {
          nextServiceLogRow = log_list[log_list.length - 1]
        }
        next_service_log_row.push(nextServiceLogRow)
      }
    }
    setNextServiceLogList(next_service_log_row)
  }

  const initItemList = (maint_item_list) => {
    setItemList(copyObject(maint_item_list))
    initNextServiceLogList(copyObject(maint_item_list))
  }

  const loadPageData = async (deviceId) => {
    setPageLoading(true)
    const url = `/api/maint/${deviceId}`
    const response = await api_call(url);
    if (response.ok) {
      const responseObj = await response.json();
      initItemList(responseObj.maintItemList)
      initDeviceFieldData(responseObj.device)
    }
    setPageLoading(false)
  }

  useEffect(() => {
    loadPageData(deviceId)
  }, [deviceId]);

  const defaultItem = {
    name: "",
    note: "",
    log_list: [],
    repeat: false //true, false
  }
  const defaultItemList = []
  const [itemList, setItemList] = useState(defaultItemList)

  const showToast = (msg) => {
    setToastMessage(msg)
    setSnackbarOpen(true)
  }

  const validateCurrentItem = (currentItem) => {
    if (empty(currentItem['name'])) {
      setCheckError(true)
      showToast("Please enter the name")
      return false
    }
    setCheckError(false)
    return true
  }

  const onClickAddRow = () => {
    const item_list = [...itemList]
    const item = {
      ...defaultItem
    }
    const newLogRow = {
      ...defaultLogRow,
      date: getNextLocalDate()
    }
    const log_list = [newLogRow]
    item['log_list'] = log_list
    item_list.push(item)
    setItemList(item_list)

    const next_service_log_list = [...nextServiceLogList]
    next_service_log_list.push(copyObject(newLogRow))
    console.log("next_service_log_list::::", next_service_log_list)
    setNextServiceLogList(next_service_log_list)
  }

  const onClickDeleteItem = (index) => {
    const item_list = [...itemList]
    item_list.splice(index, 1);
    setItemList(item_list)

    const next_service_log_list = [...nextServiceLogList]
    next_service_log_list.splice(index, 1);
    console.log("next_service_log_list:::", next_service_log_list)
    setNextServiceLogList(next_service_log_list)
  }

  const adjustLogList = (log_list) => {
    let log_length = log_list.length
    if (log_length > TRACK_LENGTH) {
      let a_log_list = []
      for (let i = TRACK_LENGTH; i > 0; i--) {
        let a_index = log_length - i
        a_log_list.push(log_list[a_index])
      }
      log_list = a_log_list
    }
    return log_list
  }

  const shiftLogItemList = (log_list, field_name, current_service_log_item) => {
    if (empty(log_list)) {
      log_list = []
    }
    let log_length = log_list.length
    if (log_length === 0) {
      return false
    }
    let field_arr = []
    let other_arr = []
    for (let i = 0; i < log_length; i++) {
      let log_item = log_list[i]
      let field_item = {}
      field_item[field_name] = log_item[field_name]
      field_arr.push(field_item)

      let other_item = { ...log_item }
      delete other_item[field_name]
      other_arr.push(other_item)
    }

    let field_item = {}
    field_item[field_name] = current_service_log_item[field_name]
    field_arr = [...field_arr, { ...field_item }]
    other_arr = [{ ...defaultLogRow }, ...other_arr]

    let new_log_list = []
    for (let j = 0; j < field_arr.length; j++) {
      let new_log_item = { ...other_arr[j], ...field_arr[j] }
      new_log_list.push(new_log_item)
    }
    return new_log_list
  }

  const onClickSaveItem = (index) => {
    const item_list = [...itemList]
    const item = item_list[index]

    let log_list = item['log_list']
    if (empty(log_list)) {
      log_list = []
    }
    let log_length = log_list.length
    let last_log_item = null
    if (log_length - 1 >= 0) {
      last_log_item = log_list[log_length - 1]
    }

    const next_service_log_list = [...nextServiceLogList]
    let current_service_log_item = next_service_log_list[index]

    if (current_service_log_item['date'] || current_service_log_item['miles']) {
      if (last_log_item) {
        if (current_service_log_item['date'] !== last_log_item['date']) {
          log_list = shiftLogItemList(log_list, "date", current_service_log_item)
        }
        if (current_service_log_item['miles'] !== last_log_item['miles']) {
          log_list = shiftLogItemList(log_list, "miles", current_service_log_item)
        }
      } else {
        const new_log_row = {
          ...current_service_log_item
        }
        log_list.push(new_log_row)
      }
    } else {
      showToast("Please fill out date or miles")
    }

    console.log("log_list:::", log_list)
    const a_log_list = adjustLogList(log_list)
    console.log("a_log_list:::", a_log_list)
    item['log_list'] = a_log_list
    item_list[index] = item
    setItemList(item_list)
    return true
  }

  const getItemField = (field_name, index, default_value) => {
    let v = default_value
    let item = itemList[index]
    if (item) {
      v = get_data_value(item, field_name, default_value)
    }
    if (field_name === "repeat") {
      if (v) {
        v = true
      } else {
        v = false
      }
    }
    return v
  }

  const getItemLogIntervalField = (index, diff_log_index, field_name, default_value) => { //diff_log_index: -1, -2 ::: -1 => diff between 0 and -1, -2 => diff between -1 and -2
    let v = default_value
    let item = itemList[index]
    if (item) {
      let log_list = item['log_list']
      if (empty(log_list)) {
        log_list = []
      }
      let log_length = log_list.length
      if (log_length > 0) {
        let last_log_index = log_length - 1
        let log_index_0 = last_log_index + diff_log_index
        let log_index_1 = log_index_0 + 1
        if (log_index_0 >= 0 && log_index_1 >= 0) {
          const log_item_0 = log_list[log_index_0]
          const log_item_1 = log_list[log_index_1]
          if (log_item_0 && log_item_1) {
            if (field_name === 'days') {
              if (log_item_0['date'] && log_item_1['date']) {
                let item_0_date = moment(log_item_0['date'], "YYYY-MM-DD");
                let item_1_date = moment(log_item_1['date'], "YYYY-MM-DD");
                let interval_days = item_1_date.diff(item_0_date, 'days')
                if (!empty(interval_days)) {
                  v = interval_days
                }
              }
            }
            if (field_name === 'miles') {
              if (log_item_0['miles'] && log_item_1['miles']) {
                let interval_miles = log_item_1['miles'] - log_item_0['miles']
                if (!empty(interval_miles)) {
                  v = interval_miles
                }
              }
            }
          }
        }
      }
    }
    return v
  }

  const getItemLogField = (index, diff_log_index, field_name, default_value) => { //diff_log_index : 0, -1, -2
    let v = default_value
    let item = itemList[index]
    if (item) {
      let log_list = item['log_list']
      if (empty(log_list)) {
        log_list = []
      }
      let log_length = log_list.length
      if (log_length > 0) {
        let last_log_index = log_length - 1
        let log_index = last_log_index + diff_log_index
        if (log_index >= 0) {
          const log_item = log_list[log_index]
          if (log_item) {
            v = get_data_value(log_item, field_name, default_value)
          }
        }
      }
    }
    return v
  }

  const onChangeItemLogField = (value, index, diff_log_index, field_name) => {
    console.log("value::::", value)
    if (field_name === 'miles') {
      if (value) {
        value = Number(value)
      }
    } else if (field_name === 'date') {
      if (value) {
        const dateTime = `${value} 00:00:00`
        console.log("dateTime:::", dateTime)
        const utcDateTime = moment(dateTime).utc().format("YYYY-MM-DD HH:mm:ss")
        console.log("utcDateTime:::", utcDateTime)
        value = utcDateTime
      }
    }

    let item_list = [...itemList]
    let item = item_list[index]
    if (item) {
      let log_list = item['log_list']
      if (empty(log_list)) {
        log_list = []
      }
      let log_length = log_list.length
      if (log_length > 0) {
        let last_log_index = log_length - 1
        let log_index = last_log_index + diff_log_index
        if (log_index >= 0) {
          const log_item = log_list[log_index]
          if (log_item) {
            log_item[field_name] = value
            log_list[log_index] = log_item
          }
        }
      } else {
        const log_item = {}
        log_item[field_name] = value
        log_list.push(log_item)
      }
      item['log_list'] = log_list
      item_list[index] = item
      setItemList(item_list)
    }
  }

  const getItemNextServiceLogField = (index, field_name, default_value) => {
    let v = default_value
    let log_item = nextServiceLogList[index]
    if (log_item) {
      v = get_data_value(log_item, field_name, default_value)
    }
    return v
  }

  const onChangeItemNextServiceLogField = (value, index, field_name) => {
    if (field_name === 'miles') {
      if (value) {
        value = Number(value)
      }
    } else if (field_name === 'date') {
      if (value) {
        const dateTime = `${value} 00:00:00`
        console.log("dateTime:::", dateTime)
        const utcDateTime = moment(dateTime).utc().format("YYYY-MM-DD HH:mm:ss")
        console.log("utcDateTime:::", utcDateTime)
        value = utcDateTime
      }
    }

    const next_service_log_list = [...nextServiceLogList]
    let log_item = next_service_log_list[index]
    if (log_item) {
      log_item[field_name] = value
      next_service_log_list[index] = log_item
      console.log("next_service_log_list:::", next_service_log_list)
      console.log("itemList:::", itemList)
      setNextServiceLogList(next_service_log_list)
    }
  }

  const onChangeItemField = (v, field_name, index) => {
    setCheckError(false)
    const itme_list = [...itemList]
    const item = itme_list[index]
    if (item) {
      if (field_name === "repeat") {
        if (v) {
          v = true
        } else {
          v = false
        }
      }
      item[field_name] = v
      itme_list[index] = item
      setItemList(itme_list)
    }
  }

  const isLastIndex = (index) => {
    return itemList.length - 1 === index
  }

  const checkItemListValidation = (item_list) => {
    return true
  }

  const onClickSave = async () => {
    await sleep(100)
    const item_list = [...itemList]
    if (item_list.length > 0) {
      const isValid = checkItemListValidation(item_list)
      if (!isValid) {
        showToast("Please fill out all necessary fields")
        return false
      }
    }
    // const maint_charge_enabled = device.maint_charge_enabled
    // if (is_null(maint_charge_enabled)) {
    //   setShowConfirmModal(true)
    // } else {
    //   submitData(null)
    // }
    submitData(null)
  }

  const onClickCancelConfirm = () => {
    setShowConfirmModal(false)
    submitData(false)
  }

  const onClickAgreeConfirm = () => {
    setShowConfirmModal(false)
    submitData(true)
  }

  const submitData = async (agreed = null) => {
    let url = `/api/maint/${deviceId}`;
    const payload = {
      maint_odometer: maintOdometer,
      maint_notification_email: notificationEmail,
      // maint_charge_enabled: agreed,
      maint_item_list: itemList
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      setItemList(responseObj.maintItemList)
      initDeviceFieldData(responseObj.device)

      showToast("Saved successfully")
      setTimeout(() => {
        setOpen(false)
      }, 1500)
    } else {
      alert("Error encounted")
    }
  }

  const [maintOdometer, setMaintOdometer] = useState(0)
  const [notificationEmail, setNotificationEmail] = useState("")
  const [checkError, setCheckError] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const [videoOpen, setVideoOpen] = useState(false)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  return (
    <React.Fragment>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snackbarOpen}
          onClose={() => { setSnackbarOpen(false) }}
          autoHideDuration={1500}
          message={toastMessage}
        />

        <Box sx={{ mb: 1 }}>
          <Stack direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={1}>
            <Stack direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={2}>
              <Button type="button" color="primary" variant="contained" onClick={() => onClickAddRow()}>
                Add
              </Button>
              <Box>
                <Typography variant='p' onClick={() => setVideoOpen(true)} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1366ff' }}>How To Video</Typography>
              </Box>
            </Stack>
            <Stack direction={`row`} justifyContent={`flex-end`} alignItems={`flex-end`} spacing={1}>
              {
                (user.administrator) ? (
                  <Button type="button" color="primary" variant="contained" onClick={() => onClickPL()} style={{ marginBottom: '8px' }}>P&L</Button>
                ) : (
                  <></>
                )
              }

              <Box sx={{ width: '200px' }}>
                <TextField
                  className='odometer'
                  fullWidth
                  label={`Odometer`}
                  margin="normal"
                  value={maintOdometer}
                  onChange={(event) => setMaintOdometer(event.target.value)}
                  placeholder={``}
                  variant="filled"
                  inputProps={{
                    type: 'number'
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2}>Name</TableCell>
                  <TableCell colSpan={4}>Previous Services</TableCell>
                  <TableCell colSpan={2}>Interval</TableCell>
                  <TableCell colSpan={2}>Next Service</TableCell>
                  <TableCell colSpan={2}>Interval</TableCell>
                  <TableCell rowSpan={2}><span className=""><span className="vertical-text1">Repeat</span></span></TableCell>
                  <TableCell rowSpan={2}>Notes</TableCell>
                  <TableCell rowSpan={2}>Action</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Miles</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Miles</TableCell>
                  <TableCell>Days</TableCell>
                  <TableCell>Miles</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Miles</TableCell>
                  <TableCell>Days</TableCell>
                  <TableCell>Miles</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  (itemList.length > 0) ? (
                    <>
                      {
                        itemList.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <Box sx={{ width: '140px', display: 'inline-block' }}>
                                  <TextField
                                    error={checkError && getItemField("name", index) === ""}
                                    className='name'
                                    fullWidth
                                    margin="normal"
                                    value={getItemField("name", index)}
                                    onChange={(event) => onChangeItemField(event.target.value, "name", index)}
                                    placeholder={`Oil change`}
                                    variant="filled"
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                {getItemLogField(index, -2, "date") ? moment.utc(`${getItemLogField(index, -2, "date")}`).local().format("MM/DD/YYYY") : ""}
                              </TableCell>
                              <TableCell>
                                <Box className={`table-typograph`}>{getItemLogField(index, -2, "miles") ?? ""}</Box>
                              </TableCell>
                              <TableCell>
                                {getItemLogField(index, -1, "date") ? moment.utc(`${getItemLogField(index, -1, "date")}`).local().format("MM/DD/YYYY") : ""}
                              </TableCell>
                              <TableCell>
                                <Box className={`table-typograph`}>{getItemLogField(index, -1, "miles") ?? ""}</Box>
                              </TableCell>
                              <TableCell>
                                <>{getItemLogIntervalField(index, -2, "days") ?? "-"}</>
                              </TableCell>
                              <TableCell>
                                <>{getItemLogIntervalField(index, -2, "miles") ?? "-"}</>
                              </TableCell>
                              <TableCell>
                                <Box sx={{ width: '165px', display: 'inline-block' }}>
                                  <TextField
                                    className='next_service_date'
                                    fullWidth
                                    margin="normal"
                                    type="date"
                                    value={moment.utc(`${getItemNextServiceLogField(index, 'date')}`).local().format("YYYY-MM-DD")}
                                    onChange={(event) => onChangeItemNextServiceLogField(event.target.value, index, "date")}
                                    onBlur={(event) => onClickSaveItem(index)}
                                    placeholder={``}
                                    variant="filled"
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box sx={{ width: '100px', display: 'inline-block' }}>
                                  <TextField
                                    className='next_service_miles'
                                    fullWidth
                                    margin="normal"
                                    value={getItemNextServiceLogField(index, 'miles')}
                                    onChange={(event) => onChangeItemNextServiceLogField(event.target.value, index, "miles")}
                                    onBlur={(event) => onClickSaveItem(index)}
                                    placeholder={``}
                                    variant="filled"
                                    inputProps={{
                                      type: 'number'
                                    }}
                                  />
                                </Box>
                              </TableCell>

                              <TableCell>
                                <>{getItemLogIntervalField(index, -1, "days") ?? "-"}</>
                              </TableCell>
                              <TableCell>
                                <>{getItemLogIntervalField(index, -1, "miles") ?? "-"}</>
                              </TableCell>

                              <TableCell>
                                <Box sx={{ display: 'inline-block' }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={getItemField("repeat", index)}
                                        onChange={(event) => onChangeItemField(event.target.checked, "repeat", index)}
                                      />
                                    }
                                    label=""
                                    style={{ marginRight: 0, marginLeft: 0 }}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Box sx={{ width: '200px', display: 'inline-block' }}>
                                  <TextField
                                    className='note'
                                    fullWidth
                                    margin="normal"
                                    value={getItemField("note", index)}
                                    onChange={(event) => onChangeItemField(event.target.value, "note", index)}
                                    placeholder={``}
                                    variant="filled"
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Stack direction={`row`} spacing={1}>
                                  <IconButton title="Delete" color="primary" onClick={() => onClickDeleteItem(index)}>
                                    <CloseIcon />
                                  </IconButton>
                                </Stack>

                              </TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell colSpan={17} align={`center`}>
                          {(pageLoading) ? 'Loading...' : 'No record found'}
                        </TableCell>
                      </TableRow>
                    </>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ mb: 1 }}>
          <Stack direction={`column`} justifyContent={`flex-start`} alignItems={`flex-start`} spacing={1}>
            <Typography variant="body2">
              Notification Email
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              value={notificationEmail}
              onChange={(event) => setNotificationEmail(event.target.value)}
              placeholder={`Enter emails with comma separated`}
              variant="filled"
            />
          </Stack>
        </Box>
        <Box sx={{ mb: 1 }}>
          <Stack direction={`row`} justifyContent={`flex-end`} alignItems={`center`} spacing={1}>
            <Button type="button" color="primary" variant="contained" onClick={() => onClickSave()}>
              Save
            </Button>
            {/* <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
                          Close
                      </Button> */}
          </Stack>
        </Box>

        <>
          <ConfirmDialog
            open={showConfirmModal}
            setOpen={setShowConfirmModal}
            title={APP_NAME}
            content={`The cost for this service is $1 per month per car.`}
            textYes={`Agree`}
            textNo={`Cancel`}
            onClickYes={() => onClickAgreeConfirm()}
            onClickNo={() => onClickCancelConfirm()}
          />
        </>

        <React.Fragment>
          {
            (videoOpen && MAINT_VIDEO_ID !== "") ? (
              <>
                <ModalVideo
                  channel='youtube'
                  autoplay={1}
                  youtube={{
                    autoplay: 1,
                    mute: 1
                  }}
                  isOpen={videoOpen}
                  videoId={MAINT_VIDEO_ID}
                  onClose={() => setVideoOpen(false)}
                />
              </>
            ) : (<></>)
          }

        </React.Fragment>

      </div>
    </React.Fragment>
  )
}

export default MaintListTable;