import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles, Typography, FormControlLabel, Checkbox, Box, Link, Modal, TextField, Button, Snackbar, IconButton
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckIcon from '@material-ui/icons/Check';

import EditCollectionView from '../EditCollectionView';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';

import { api_call, console_log, empty, get_data_value } from '../helpers/untils';
import { useHistory } from 'react-router-dom';
import { sleep } from '../helpers/misc';

const useStyles = makeStyles((theme) => ({
  authBlockList: {
    padding: 16
  },
  authBlock: {
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 10,
    borderBottom: '1px solid #cccccc',
    '&:last-child': {
      // borderBottom: 'none',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formRowInline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '30px',
    paddingTop: '6px',
    paddingBottom: '6px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginRight: '0px',
      '& > *': {
        display: 'block',
      },
    },
  },
  formRowInlineLabel: {
    paddingRight: 20,
    minWidth: '60px',
    [theme.breakpoints.down('md')]: {
      minWidth: '0px',
    },
  },
  formRowInlineLabelAuto: {
    paddingRight: 20,
  },
  formRowInlineLabelAfter: {
    paddingLeft: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  formRowInlineController: {
    flex: 1
  },
  minutesInput: {
    width: '110px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  responsibleFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'block',
    },
  },
  checkResultWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '6px',
    paddingBottom: '6px',
    marginRight: '15px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },
  },
  checkResultBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '20px',
  },
  checkResultSuccessIcon: {
    color: "#4CAF50",
  },
  checkResultFailedIcon: {
    color: "#CC2222",
  },
  textList: {
    display: 'block',
    maxWidth: '300px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  mtHalfText: {
    marginTop: '8px',
  },
  messageWrapper: {
    display: 'block',
    maxWidth: '676px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  formRowInlineSMS: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '15px',
    paddingTop: '6px',
    paddingBottom: '6px',
    minWidth: '150px',
    maxWidth: '260px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginRight: '0px',
      '& > *': {
        display: 'block',
      },
    },
  },
  formRowInlineLabelSMS: {
    paddingRight: 20,
    minWidth: '60px',
    [theme.breakpoints.down('md')]: {
      minWidth: '0px',
    },
  },
  formRowInlineControllerSMS: {
    flex: 1
  },
  formRowInlineBtnSMS: {
    marginLeft: '15px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
      marginTop: '15px',
    },
  },
  tableActionBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    paddingBottom: '24px'
  },
  formActionBox: {
    paddingTop: '8px',
    paddingBottom: '6px',
    paddingLeft: '8px'
  }
}));

const TuroLoginListView = (props) => {
  const { updateTimestamp, onMenuClick } = props
  const classes = useStyles();
  const t = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory()
  const user = useSelector((state) => state.session.user);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [apiLoading, setApiLoading] = useState(false)
  const [apiSending, setApiSending] = useState(false)

  const [turoCallingList, setTuroCallingList] = useState({
    0: false
  })

  const [loginList, setLoginList] = useState([])
  const loginListRef = useRef([])

  const getApplyButtonText = (index) => {
    const login_list = [...loginList]
    const formData = login_list[index]
    let calUrl = formData['calUrl']
    const turoCalling = turoCallingList[index]

    if (turoCalling) {
      return "Connecting"
    } else {
      if (calUrl !== null) {
        if (calUrl) {
          return "Disconnect"
        }
      }
      return "Connect"
    }
  }


  useEffect(() => {
    reloadTuroLoginList()
    startCheckTuroTripQueue()
  }, []);

  const defaultLogin = {
    id: 0,
    userId: user.id,
    email: "",
    password: "",
    sms_code: "",
    calUrl: "",
    status: false,
  }

  const reloadTuroLoginList = async () => {
    if (user.id !== 0) {
      var url = `/api/turo-login/get-list`;
      const response = await api_call(url);
      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          setLoginTrimList(data)
        } else {
          AddItem()
        }
      }
    }
  }

  const setLoginTrimList = (itemList) => {
    if (user.administrator) {
      let newItemList = []
      for (let k in itemList) {
        const info = itemList[k]
        if (info && info['email'] && info['password']) {
          newItemList.push(info)
        }
      }
      setLoginList(newItemList)
      loginListRef.current = newItemList
    } else {
      setLoginList(itemList)
      loginListRef.current = itemList
    }
  }

  const onChangeTuroForm = (event, field_name, index) => {
    const login_list = [...loginListRef.current]
    const formData = login_list[index]
    let form_data = { ...formData }
    form_data[field_name] = event.target.value
    login_list[index] = form_data
    setLoginList(login_list)
    loginListRef.current = login_list
  }

  const setTuroCalling = (calling, index) => {
    const calling_list = { ...turoCallingList }
    calling_list[index] = calling
    setTuroCallingList(calling_list)
  }

  const setCalUrl = (url, index) => {
    const login_list = [...loginListRef.current]
    const formData = login_list[index]
    let form_data = { ...formData }
    form_data['calUrl'] = url
    login_list[index] = form_data
    setLoginList(login_list)
    loginListRef.current = login_list
  }

  const turoTripQueueList = useRef([]);

  const onClickTestTuro = async (index) => {
    const login_list = [...loginListRef.current]
    const formData = login_list[index]
    console_log("formData:::", formData)
    let calUrl = formData['calUrl']
    if (!empty(calUrl)) { //disconnect
      disconnectTuro(index)
      return false
    }
    const email = formData['email']
    const password = formData['password']
    if (empty(email)) {
      alert("Enter email address")
      return false
    }
    if (empty(password)) {
      alert("Enter password")
      return false
    }

    if (email && password) {
      let url = `/api/turo-login/auth`;
      const post_data = {
        ...formData,
        email: email,
        password: password,
        userId: user.id,
      }
      setApiLoading(true)

      setToastMessage("Please check your phone for a SMS");
      setSnackbarOpen(true);
      setTuroCalling(true, index)

      const response = await api_call(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(post_data),
      });

      let queue = null
      if (response.ok) {
        let responseObj = await response.json()
        console_log("responseObj::::", responseObj)
        if (responseObj['error']) {
          setToastMessage(responseObj['error']);
          setSnackbarOpen(true);
        } else {
          queue = get_data_value(responseObj, 'queue')
          if (queue) {
            addTuroTripQueue(queue.id)
          }
        }
      } else {

      }
      //setTuroCalling(false, index)
      //setCalUrl(cal_url, index)

      setApiLoading(false)
    }
  }

  const addTuroTripQueue = (queueId) => {
    if (empty(queueId)) {
      return false
    }
    let turo_trip_queue_list = turoTripQueueList.current
    if (turo_trip_queue_list && turo_trip_queue_list.length > 0) {
      if (turo_trip_queue_list.includes(queueId)) {
        return false
      }
    } else {
      turo_trip_queue_list = []
    }
    turo_trip_queue_list.push(queueId)
    turoTripQueueList.current = turo_trip_queue_list
    return turo_trip_queue_list
  }

  const removeTuroTripQueue = (queueId) => {
    if (empty(queueId)) {
      return false
    }
    let turo_trip_queue_list = turoTripQueueList.current
    if (turo_trip_queue_list && turo_trip_queue_list.length > 0) {
      if (turo_trip_queue_list.includes(queueId)) {
        turo_trip_queue_list = turo_trip_queue_list.filter((item) => item !== queueId)
      }
    } else {
      turo_trip_queue_list = []
    }
    turoTripQueueList.current = turo_trip_queue_list
    return turo_trip_queue_list
  }

  const startCheckTuroTripQueue = async () => {
    while (true) {
      const login_list = [...loginListRef.current]
      console_log("current login_list:::", login_list)

      let turo_trip_queue_list = turoTripQueueList.current
      console_log("startCheckTuroTripQueue:::", turo_trip_queue_list)

      if (turo_trip_queue_list && turo_trip_queue_list.length > 0) {
        await handleCheckTuroTripQueue(turo_trip_queue_list)
      }

      await sleep(3 * 1000)  //3 seconds
    }
  }

  const handleCheckTuroTripQueue = async (turo_trip_queue_list) => {
    const url = `/api/turo-login/check-auth-result`;
    const payload = {
      turo_trip_queue_ids: turo_trip_queue_list.join(',')
    }

    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      let success_turo_login_list = responseObj['success_turo_login_list']
      await updateTuroLoginStatus(success_turo_login_list, 'success')
      let failed_turo_login_list = responseObj['failed_turo_login_list']
      await updateTuroLoginStatus(failed_turo_login_list, 'failed')

      let remaining_ids = responseObj['remaining_ids']
      turoTripQueueList.current = remaining_ids
      return true
    } else {
      return false
    }
  }

  const getIndexFromTuroLoginId = (turoLoginId) => {
    const login_list = [...loginListRef.current]
    console_log("login_list:::", login_list)
    for (let k in login_list) {
      const row = login_list[k]
      if (row['id'] === turoLoginId) {
        return k
      }
    }
    return false
  }
  
  const updateTuroLoginStatus = async (turo_login_list, status = 'success') => {
    const login_list = [...loginListRef.current]
    console_log("login_list:::", login_list)

    for (let k in turo_login_list) {
      const row = turo_login_list[k]
      const turoLoginId = row['id']
      const index = getIndexFromTuroLoginId(turoLoginId)
      console_log("row, index::::", row, index)
      const cal_url = row['calUrl']
      if (status === 'success') {
        setCalUrl(cal_url, index)
      } else {
        setCalUrl("", index)
      }
      setTuroCalling(false, index)
    }
  }

  const onClickSaveSMS = (index) => {
    const login_list = [...loginListRef.current]
    const formData = login_list[index]
    if (empty(formData['email'])) {
      alert("Enter email address")
      return false
    }
    let sms_code = formData['sms_code']
    if (empty(sms_code)) {
      alert("Enter SMS code")
      return false
    }
    if (sms_code) {
      handleSaveSMS(sms_code, index)
    }
  }

  const handleSaveSMS = async (sms_code, index) => {
    const login_list = [...loginListRef.current]
    const formData = login_list[index]

    const url = `/api/turo-login/updateSMS`;
    const payload = {
      ...formData,
      sms_code: sms_code
    }

    setApiSending(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    delaySetApiSending(false, index)

    if (response.ok) {
      setToastMessage(`SMS code has been saved successfully.`);
      setSnackbarOpen(true);
      return true
    } else {
      setToastMessage(`Failed to save SMS`);
      setSnackbarOpen(true);
      return false
    }
  }

  const delaySetApiSending = (flag, index) => {
    setTimeout(function () {
      setApiSending(flag)
    }, 60 * 1000)
  }

  const disconnectTuro = async (index) => {
    const login_list = [...loginListRef.current]
    const formData = login_list[index]

    let url = `/api/turo-login/auth-disconnect`;
    const payload = {
      ...formData
    }
    setApiLoading(true)
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    setApiLoading(false)

    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      if (responseObj['error']) {
        alert(responseObj['error'])
      } else {
        let form_data = { ...responseObj['row'] }
        login_list[index] = form_data
        setLoginList(login_list)
        loginListRef.current = login_list
      }
    } else {

    }
  }

  const onClickAddNew = () => {
    AddItem()
  }

  const AddItem = async () => {
    const login_list = [...loginListRef.current]

    const formData = defaultLogin
    let url = `/api/turo-login`;
    const payload = {
      ...formData
    }
    setApiLoading(true)
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    setApiLoading(false)

    if (response.ok) {
      // continue 
      const row = await response.json()
      login_list.push(row)
      setLoginList(login_list)
      loginListRef.current = login_list
    }
  }

  const RemoveItem = async (index) => {
    const login_list = [...loginListRef.current]
    const formData = login_list[index]
    if (formData['id']) {
      let url = `/api/turo-login/${formData['id']}`;
      const payload = {
        ...formData
      }
      setApiLoading(true)
      const response = await api_call(url, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      setApiLoading(false)

      if (response.ok) {
        // continue 
      }
      login_list.splice(index, 1);
      setLoginList(login_list)
      loginListRef.current = login_list

    } else {
      login_list.splice(index, 1);
      setLoginList(login_list)
      loginListRef.current = login_list
    }
  }

  const onClickRemoveItem = (index) => {
    if (window.confirm("Are you sure you want to delete?")) {
      RemoveItem(index);
    }
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        autoHideDuration={1500}
        message={toastMessage}
      />
      <div>
        <div className='MuiPaper-root MuiAccordion-root Mui-expanded MuiAccordion-rounded MuiPaper-elevation1 MuiPaper-rounded'>
          <div className={classes.authBlockList}>
            {
              loginList.map((formData, index) => {
                return (
                  <div className={classes.authBlock} key={index}>
                    <div className={classes.responsibleFlex}>
                      <div className={classes.formRowInline}>
                        <label className={classes.formRowInlineLabel}>Turo Email:</label>
                        <TextField
                          fullWidth
                          className={classes.formRowInlineController}
                          value={formData['email']}
                          onChange={(event) => onChangeTuroForm(event, 'email', index)}
                          label=""
                          variant="filled"
                        />
                      </div>
                      <div className={classes.formRowInline}>
                        <label className={classes.formRowInlineLabel}>Turo Password:</label>
                        <TextField
                          fullWidth
                          className={classes.formRowInlineController}
                          value={formData['password']}
                          onChange={(event) => onChangeTuroForm(event, 'password', index)}
                          label=""
                          variant="filled"
                          type="password"
                        />
                      </div>
                      <div className={classes.checkResultWrapper}>
                        <Button size="small" type="button" color="primary" variant="contained" style={{ width: '110px' }} onClick={() => onClickTestTuro(index)} disabled={apiLoading}>
                          {
                            getApplyButtonText(index)
                          }
                        </Button>
                        <div className={classes.checkResultBox}>
                          {
                            (formData['calUrl'] !== null) ? (
                              <>
                                {
                                  (formData['calUrl']) ? (
                                    <>
                                      <CheckIcon fontSize="large" className={classes.checkResultSuccessIcon} />
                                    </>
                                  ) : (
                                    <>
                                      <NotInterestedIcon fontSize="large" className={classes.checkResultFailedIcon} />
                                    </>
                                  )
                                }
                              </>
                            ) : (
                              <>
                              </>
                            )
                          }
                        </div>
                      </div>
                      <div className={classes.formRowInlineSMS}>
                        <label className={classes.formRowInlineLabelSMS}>SMS Code:</label>
                        <TextField
                          fullWidth
                          className={classes.formRowInlineControllerSMS}
                          value={formData['sms_code']}
                          onChange={(event) => onChangeTuroForm(event, 'sms_code', index)}
                          label=""
                          variant="filled"
                          type="text"
                        />
                        <Button size="small" type="button" color="primary" variant="contained" className={classes.formRowInlineBtnSMS} onClick={() => onClickSaveSMS(index)} disabled={apiSending}>Save SMS</Button>
                      </div>
                      <div className={classes.formActionBox}>
                        <IconButton className={classes.removeButton} color="primary" onClick={() => onClickRemoveItem(index)}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>

          <div className={classes.tableActionBox}>
            <div className={classes.buttons}>
              <Button type="button" color="primary" variant="contained" startIcon={<AddIcon />} onClick={() => { onClickAddNew() }}>
                Add New Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

const TuroLoginsPage = () => {
  return (
    <>
      <OptionsLayout>
        <EditCollectionView content={TuroLoginListView} editPath="/turo-login" endpoint="turo-login" disableAdd={true} />
      </OptionsLayout>
    </>
  );
};

export default TuroLoginsPage;
