import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Typography, Box, Modal, makeStyles, Button, ButtonBase
} from '@material-ui/core';

import { Stack } from '@mui/material';
import { layoutActions } from '../store';
import { APP_NAME } from '../config/constant';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '96%',
  maxWidth: '450px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));

const TeslaQrCodeModal = (props) => {
  const { open, setOpen, onOk } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.session.user);
  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    if (open) {
      dispatch(layoutActions.closeDeviceListMenu());
    }
  }, [open]);

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  const handleOk = () => {
    onOk()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">{APP_NAME}</Typography>
          <Box sx={{ paddingTop: 16 }}>
            <>
              <div>
                <Stack direction={`column`} justifyContent={`center`} alignItems={`center`} spacing={1}>
                  <Typography align='center' display='block'>Allow locks by click or scanning the following QR code</Typography>
                  <ButtonBase href={`https://www.tesla.com/_ak/moovetrax.com`} target="_blank" disableRipple={true}>
                    <img src="/assets/img/tesla_qr_code.png" width="200" style={{ maxWidth: '100%', height: 'auto' }} alt="qr code" />
                  </ButtonBase>
                  <Stack direction={`column`} justifyContent={`center`} alignItems={`center`} spacing={1} style={{ marginTop: '16px' }}>
                    <Button type="button" color="primary" variant="contained" onClick={() => handleOk()} style={{ width: '180px' }}>
                      Done
                    </Button>
                  </Stack>
                </Stack>
              </div>
            </>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default TeslaQrCodeModal;
