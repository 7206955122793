import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';

import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import { Box, Link as Mlink, Stack } from '@mui/material';

import {
  Grid, useMediaQuery, makeStyles, InputLabel, Select, MenuItem, Button, TextField, Typography
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sessionActions } from '../../store';
import { useLocalization, useTranslation } from '../../LocalizationProvider';
import StartPage from '../../StartPage';
import usePersistedState from '../../common/usePersistedState';
import { api_call, console_log, getToken, get_utc_timestamp_ms, setToken, validateEmail } from '../../helpers/untils';
import { GPS_ID_PLACEHOLDER, ICCID_PLACEHOLDER, IMAGE_CACHE_VERSION, INSTALLATION_GUIDE_LINK, RECAPTCHA_ENABLED, RECAPTCHA_KEY } from '../../config/constant';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { getSettingPersist, setSettingPersist, sleep } from '../../helpers/misc';
import { Alert } from '@mui/material';
import CaptchaBox from '../CaptchaBox';

import { useMask } from '@react-input/mask';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  imgSmall: {
    width: '50px',
    height: '40px',
    marginLeft: '5px',
    marginTop: '5px',
    borderRadius: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  customSnakebar: {
    position: 'absolute',
    top: '140px'
  },
  mobileInstallerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  subRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  schematicsLink: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: '1rem',
    textDecoration: 'underline'
  }
}));

const InstallerSignupForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const t = useTranslation();

  const [failed, setFailed] = useState(false);

  const formDefaultData = {
    company_name: "",
    phone: "",
    email: "",
    country: "USA",
    zip_code: "",
    mobile_installer: "1",
    radius_serviced: 50,
    install_cost: "",
  }
  const [formData, setFormData] = useState(formDefaultData);
  const [isOpen, setIsOpen] = useState('');
  const [currentImage, setCurrentImage] = useState('');

  const [submitting, setSubmitting] = useState(false);

  ///////////// start recaptcha part //////////////////////////////////////////////////////////////////////////
  const captchaKey = 'captcha_installer_signup'
  const [recaptcha, setRecaptcha] = useState(RECAPTCHA_ENABLED === "false" ? "xxxxxxxxxxxxxxxxx" : "");
  const [captchaTimestamp, setCaptchaTimestamp] = useState(get_utc_timestamp_ms());
  const updateCaptchaSettingEnabled = (value) => {
    const oldValue = getSettingPersist(captchaKey)
    if (true || oldValue !== value) { // refresh every attempt
      setSettingPersist(captchaKey, value)
      setCaptchaTimestamp(get_utc_timestamp_ms())
    }
  }
  ///////////// end recaptcha part //////////////////////////////////////////////////////////////////////////

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!recaptcha) {
      setToast("Please conform if you are not a robot!")
      setSnackbarOpen(true)
      return false
    }

    if (!validateEmail(formData['email'])) {
      setToast("Invalid email address")
      setSnackbarOpen(true);
      return false
    }

    const rand = () => Math.floor((1 + Math.random()) * 0x100000000).toString(16).substring(1);
    const session = "installer-" + rand();
    const payload = { ...formData, session: session };
    if (payload['mobile_installer'] === '0') {
      payload['radius_serviced'] = ""
    }

    setSubmitting(true)
    const response = await api_call('/api/installer-signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      const sessionData = await response.json();
      console_log("sessionData:::::::", sessionData);
      setToast("Thank you!")
      setSnackbarOpen(true)

      setSubmitting(false)
      updateCaptchaSettingEnabled(true)

      setTimeout(() => {
        redirectToMainPage(sessionData)
      }, 2000)
    } else {
      const errorObj = response
      let msg = "Invalid request!"
      if (errorObj['error']) {
        msg = errorObj['error']
      }
      setToast(msg)
      setSnackbarOpen(true)

      setSubmitting(false)
      updateCaptchaSettingEnabled(true)
    }

    setTimeout(() => {
      setSnackbarOpen(false)
    }, 2000)
  }

  const redirectToMainPage = (sessionData) => {
    //setToken(sessionData['token'])
    history.push('/')
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toast, setToast] = useState("");

  useEffect(() => {
    updateCaptchaSettingEnabled(true)
  }, [])

  const phoneRef = useMask({ mask: '___-___-____', replacement: { _: /\d/ } });

  const onChangeCountry = (e) => {
    const value = e.target.value
    setFormData({ ...formData, country: value, zip_code: "" })
  }

  return (
    <>
      <StartPage formStyle={{ maxWidth: '570px' }}>
        <div>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toast}
          />
        </div>

        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={2}>
            {useMediaQuery(theme.breakpoints.down('md'))
              && (
                <Grid item className={classes.logoContainer}>
                  <Link to="/">
                    <img height="85" width="240" src="/logo.png" alt="Mobile Logo Login" />
                  </Link>
                </Grid>
              )}

            <Grid item>
              <div className={classes.subRowContainer} style={{ marginBottom: '8px' }}>
                <Typography variant="h6">
                  Installer Signup
                </Typography>
                <Mlink className={classes.schematicsLink} href={INSTALLATION_GUIDE_LINK} target="_blank">
                  Schematics
                </Mlink>
              </div>
            </Grid>

            <Grid item>
              <TextField
                required
                fullWidth
                error={failed}
                label={`Company Name`}
                name="company_name"
                value={formData['company_name']}
                type="text"
                onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
                variant="filled"
                disabled={submitting}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                error={failed}
                label={`Phone`}
                name="phone"
                value={formData['phone']}
                type="text"
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                variant="filled"
                disabled={submitting}
                inputRef={phoneRef}
                inputProps={{
                  placeholder: 'xxx-xxx-xxxx',
                  //pattern: "[0-9]*", //"\\d*",
                  inputMode: "numeric"
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                error={failed}
                label={`Email`}
                name="email"
                value={formData['email']}
                type="text"
                onChange={(e) => setFormData({ ...formData, email: e.target.value.trim() })}
                variant="filled"
                disabled={submitting}
                inputProps={{
                  type: 'text'
                }}
              />
            </Grid>

            <Grid item>
              <FormControl style={{ width: '100%' }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group-1"
                  value={formData.country || ''}
                  onChange={(e) => onChangeCountry(e)}
                  style={{ justifyContent: "space-around" }}
                  row
                >
                  <FormControlLabel value="USA" control={<Radio />} label="USA" />
                  <FormControlLabel value="Other" control={<Radio />} label="Other" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {
              (formData.country === 'USA') ? (
                <Grid item>
                  <TextField
                    required
                    fullWidth
                    error={failed}
                    label={`Zip code`}
                    name="zip_code"
                    value={formData['zip_code']}
                    type="text"
                    onChange={(e) => setFormData({ ...formData, zip_code: e.target.value.substring(0, 5) })}
                    variant="filled"
                    disabled={submitting}
                    inputProps={{
                      type: 'number',
                      min: 0
                    }}
                  />
                </Grid>
              ) : (
                <Grid item>
                  <TextField
                    required
                    fullWidth
                    error={failed}
                    label={`Zip code`}
                    name="zip_code"
                    value={formData['zip_code']}
                    type="text"
                    onChange={(e) => setFormData({ ...formData, zip_code: e.target.value.substring(0, 9) })}
                    variant="filled"
                    disabled={submitting}
                    inputProps={{
                      type: 'text',
                    }}
                  />
                </Grid>
              )
            }

            <Grid item>
              <div className={classes.mobileInstallerContainer}>
                <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  Mobile
                </Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={formData.mobile_installer || ''}
                    onChange={(e) => setFormData({ ...formData, mobile_installer: e.target.value })}
                    style={{ justifyContent: "space-around" }}
                    row
                  >
                    <FormControlLabel value="1" control={<Radio />} label="Yes" />
                    <FormControlLabel value="0" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>

            {
              (formData['mobile_installer'] === '1') ? (
                <Grid item>
                  <div className={classes.subRowContainer}>
                    <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                      Radius Serviced
                    </Typography>
                    <Box>
                      <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1}>
                        <TextField
                          required
                          error={failed}
                          name="radius_serviced"
                          value={formData['radius_serviced']}
                          type="text"
                          onChange={(e) => setFormData({ ...formData, radius_serviced: e.target.value })}
                          variant="filled"
                          disabled={submitting}
                          inputProps={{
                            type: 'number',
                            min: 0,
                            max: 100
                          }}
                          style={{ maxWidth: '90px' }}
                        />
                        <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                          Miles
                        </Typography>
                      </Stack>
                    </Box>
                  </div>
                </Grid>
              ) : (
                <></>
              )
            }

            <Grid item>
              <div className={classes.subRowContainer}>
                <Box sx={{ width: '100%' }}>
                  <Stack direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={1}>
                    <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', lineHeight: 1.35 }}>
                      Cost to Install Moovetrax in a 2020 Camry Turn key?
                    </Typography>
                    <TextField
                      required
                      error={failed}
                      name="install_cost"
                      value={formData['install_cost']}
                      type="text"
                      onChange={(e) => setFormData({ ...formData, install_cost: e.target.value })}
                      variant="filled"
                      disabled={submitting}
                      inputProps={{
                        type: 'number',
                        min: 0,
                        placeholder: '150'
                      }}
                      style={{ maxWidth: '70px' }}
                    />
                  </Stack>
                </Box>
              </div>
            </Grid>

            {
              (RECAPTCHA_ENABLED === "true") && (
                <Grid item>
                  <div className={`recaptcha-wrpper`}>
                    <CaptchaBox
                      recaptcha={recaptcha}
                      setRecaptcha={setRecaptcha}
                      captchaKey={captchaKey}
                      captchaTimestamp={captchaTimestamp}
                    />
                  </div>
                </Grid>
              )
            }

            <Grid item>
              <Button
                type={`submit`}
                variant="contained"
                color="secondary"
                disabled={!recaptcha || submitting}
                fullWidth
              >
                {`${(submitting) ? 'Processing' : 'Publish'}`}
              </Button>
            </Grid>
          </Grid>
        </form>

        {isOpen && (
          <Lightbox
            mainSrc={currentImage}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}

      </StartPage>
    </>

  );
};

export default InstallerSignupForm;
