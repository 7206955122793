import { createSlice } from '@reduxjs/toolkit';
import { is_null } from '../helpers/untils';
import { DEFAULT_GEO_LOCATION } from '../config/constant';

const { reducer, actions } = createSlice({
  name: 'positions',
  initialState: {
    items: {},
  },
  reducers: {
    update(state, action) {
      action.payload.forEach((item) => {
        // if(!(item.longitude === null || item.latitude === null)) {
        //   state.items[item.deviceId] = item
        // }
        if (is_null(item.latitude) || is_null(item.longitude)) {
          state.items[item.deviceId] = {
            ...item,
            latitude: DEFAULT_GEO_LOCATION[0],
            longitude: DEFAULT_GEO_LOCATION[1]
          }
        } else {
          state.items[item.deviceId] = item
        }
      })
    },
  },
});

export { actions as positionsActions };
export { reducer as positionsReducer };
