import React, { useState } from 'react';
import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton, TextField,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import { formatBoolean } from '../common/formatter';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';
import moment from 'moment';
import { api_call, console_log, empty } from '../helpers/untils';
import EscrowLogsModal from '../settings/EscrowLogsModal';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
}));

const UsersView = ({ updateTimestamp, onMenuClick }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [items, setItems] = useState([]);

  useEffectAsync(async () => {
    const response = await api_call('/api/users');
    if (response.ok) {
      const itemList = await response.json()
      setItems(itemList);
      setFilteredItems(itemList);
    }
  }, [updateTimestamp]);

  const [filteredItems, setFilteredItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const onChangeSearchText = (e) => {
    const filter = e.target.value
    setSearchText(filter)
    const filtered_items = items.filter(
      (item) => {
        let t_separator = "      "
        let subjectText = item.name + t_separator + item.email + t_separator + item.phone
        let userDevices = item.devices;
        if (!empty(userDevices)) {
          for (let i in userDevices) {
            subjectText += t_separator + userDevices[i]['name'] + t_separator + userDevices[i]['uniqueId'] + t_separator + userDevices[i]['vin']
          }
        }
        return subjectText.toLowerCase().includes(filter.toLowerCase())
      }
    );

    setFilteredItems(filtered_items);
    //console_log("search, filtered_items:::", value, filtered_items)
  }

  return (
    <TableContainer>
      <div className='pull-right mb-3' style={{ float: 'right' }}>
        <TextField
          margin="normal"
          value={searchText}
          onChange={(e) => onChangeSearchText(e)}
          label={`Search...`}
          inputProps={{ type: 'text' }}
        />
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.columnAction} />
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{t('userEmail')}</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell><div style={{ width: '115px' }}>Escrow Balance</div></TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Last Login</TableCell>
            <TableCell>{t('userAdmin')}</TableCell>
            {/* <TableCell>{t('sharedDisabled')}</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredItems.map((item) => (
            <TableRow key={item.id}>
              <TableCell className={classes.columnAction} padding="none">
                <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id, true, item)}>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.phone}</TableCell>
              <TableCell>{item.escrow_balance}</TableCell>
              <TableCell>{moment(item.createdAt).format('LLL')}</TableCell>
              <TableCell>{moment(item.updatedAt).format('LLL')}</TableCell>
              <TableCell>{formatBoolean(item.administrator, t)}</TableCell>
              {/* <TableCell>{formatBoolean(item.disabled, t)}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const UsersPage = () => {
  const [escrowLogsModalOpen, setEscrowLogsModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const getUserInfo = async (userId) => {
    let url = `/api/users/${userId}`
    let response = await api_call(url, {
      method: 'GET',
    });

    if (response.ok) {
      const selectedUser = await response.json()
      return selectedUser
    } else {
      return false
    }
  }

  const showEscrowLogsModal = async (itemId) => {
    const userInfo = await getUserInfo(itemId)
    setCurrentUser(userInfo)
    setEscrowLogsModalOpen(true)
  }

  return (
    <>
      <OptionsLayout>
        <EditCollectionView content={UsersView} editPath="/user" endpoint="users" showEscrowLogsModal={showEscrowLogsModal} />
      </OptionsLayout>

      <>
        <EscrowLogsModal
          open={escrowLogsModalOpen}
          setOpen={setEscrowLogsModalOpen}
          item={currentUser}
          setItem={setCurrentUser}
          modalType={'user'}
        />
      </>
    </>
  )
}

export default UsersPage;
