import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom"
import {
    makeStyles, Paper, Toolbar, TextField, IconButton, Button, Input,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import moment from 'moment';
import { api_call, console_log, empty, timeConverter } from './helpers/untils';

const useStyles = makeStyles((theme) => ({
    uploadBoxContainer: {
        maxWidth: '400px',
        margin: '15px auto',
        padding: '30px',
        backgroundColor: 'rgba(127,127,127,0)',
        border: '1px solid rgba(127,127,127,0.2)',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
    },
    uploadButtonList: {
        marginBottom: '10px',
        maxHeight: 'calc(100vh - 420px)',
        overflowY: 'auto'
    },
    uploadButton: {
        width: '100%',
        border: '2px solid rgba(76, 175, 80, 1)',
        backgroundColor: 'rgba(76, 175, 80, 0.1)',
        minHeight: '52px',
        height: 'auto',
        marginBottom: '10px',
        color: 'rgba(0, 0, 0, 0.7)',
        textTransform: 'none',
        "&$selected": {
            backgroundColor: "rgba(76, 175, 80, 0.2)",
        },
        "&$selected:hover": {
            backgroundColor: "rgba(76, 175, 80, 0.2)",
        },
        "&:hover": {
            backgroundColor: "rgba(76, 175, 80, 0.2)",
        }
    },
    uploadGrayButton: {
        width: '100%',
        border: '2px solid rgb(202 202 202)',
        backgroundColor: 'rgb(234 234 234)',
        minHeight: '52px',
        height: 'auto',
        marginBottom: '10px',
        color: 'rgba(0, 0, 0, 0.7)',
        textTransform: 'none',
    },
    uploadFileInput: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        cursor: 'pointer',
        opacity: 0
    },
}));

const indexes = [1, 2, 3, 4, 5]

export default function TuroTripTemporaryUploadPage(props) {
    const { link, setLink, tripInfo, device, current_timestamp, type = "avail", approve_status = "", loadLinkInfo } = props;
    console_log("tripInfo", tripInfo)
    const classes = useStyles()
    const history = useHistory()
    const [apiLoading, setApiLoading] = useState(false)

    const [files, setFiles] = useState({});
    const handleFileChange = (e, key) => {
        console_log("e, key::::", e, key)
        if (e.target.files) {
            const updatedFiles = {
                ...files
            }
            updatedFiles[key] = e.target.files[0]
            setFiles({ ...updatedFiles });
            console_log("updatedFiles", updatedFiles)
        }
    }

    const getUploadKeyList = (key = "avail") => {
        const turo_settings = device.turo_settings
        let key_list = []
        for (let i in indexes) {
            let k = key + i
            if (turo_settings[k]) {
                key_list.push(k)
            }
        }
        return key_list
    }
    const checkValidation = () => {
        const turo_settings = device.turo_settings
        let keyList = getUploadKeyList(type)
        //console_log("keyList, files", keyList, files)
        if (keyList && keyList.length > 0) {
            for (let i in keyList) {
                let k = keyList[i]
                if (files[k]) {
                    continue;
                } else {
                    alert("Please upload " + turo_settings[k]);
                    return false;
                }
            }
        }
        return true
    }
    const submitPhotos = async () => {
        const isValid = checkValidation()
        console_log("isValid", isValid)
        if (isValid) {
            const url = `/api/turo-trip-links/uploadImage`;
            var formData = new FormData();
            formData.append("type", type);
            formData.append("linkId", link.id);
            formData.append("tripId", tripInfo.id);
            formData.append("deviceId", device.id);
            const fileKeyArr = []
            const fileArr = []
            let i = 0;
            for (let k in files) {
                fileArr.push(files[k])
                fileKeyArr.push(k)
                formData.append("upload_file", files[k]);
                i++;
            }
            formData.append("upload_file_keys", JSON.stringify(fileKeyArr));

            setApiLoading(true)
            let response = await api_call(url, {
                method: 'POST',
                body: formData,
            });

            setApiLoading(false)
            if (response.ok) {
                const apiData = await response.json()
                const newLink = apiData.link
                loadLinkInfo()
            } else {
                const apiError = response
                console_log("apiError:::", apiError)
                if (apiError.error) {
                    alert(apiError.error);
                }
            }
        }
    }

    const uploadButtonItems = (turo_settings, key = "avail") => {
        return (
            <>
                {indexes.map((i) => {
                    let k = key + i
                    if (turo_settings[k]) {
                        return (
                            <React.Fragment key={k}>
                                <div className={classes.uploadButtonItem} key={k}>
                                    <Button
                                        size="large"
                                        variant="outlined"
                                        endIcon={<CameraAltIcon />}
                                        className={ files[k] ? classes.uploadGrayButton : classes.uploadButton}
                                    >
                                        <input accept="image/*" className={classes.uploadFileInput} type="file" onChange={(e) => handleFileChange(e, k)} />
                                        {turo_settings[k]}
                                    </Button>
                                </div>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment key={k}></React.Fragment>
                        )
                    }
                })}
            </>
        )
    }

    return (
        <React.Fragment>
            <main id="content" role="main">
                <div className="d-md-flex">
                    <div className="container d-flex align-items-center" style={{ minHeight: '100vh' }}>
                        <div className="row justify-content-between align-items-center flex-grow-1">
                            <div className="col-md-12">
                                <div className={classes.uploadBoxContainer}>
                                    {
                                        (approve_status === 'pending') ? (
                                            <>
                                                <h5 className={classes.uploadBoxTitle}>Please wait for approval</h5>
                                            </>
                                        ) : (
                                            <>
                                                <h5 className={classes.uploadBoxTitle}>Please Upload {tripInfo.primary_driver} </h5>
                                                {
                                                    (device && device.turo_settings) ? (
                                                        <>
                                                            <div className={classes.uploadButtonList}>
                                                                {
                                                                    uploadButtonItems(device.turo_settings, type)
                                                                }
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <Button
                                                    fullWidth
                                                    type="button"
                                                    color="primary"
                                                    variant="contained"
                                                    size="large"
                                                    onClick={() => submitPhotos()}
                                                >
                                                    Submit
                                                </Button>
                                            </>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}
