import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Grid, Typography, Divider, Drawer, makeStyles, IconButton, Hidden,
} from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/Timeline';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import SpeedIcon from '@material-ui/icons/Speed';

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import SettingsInputComponentSharpIcon from '@material-ui/icons/SettingsInputComponentSharp';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import SideNav from '../components/SideNav';
import NavBar from '../components/NavBar';
import { useTranslation } from '../LocalizationProvider';
import { api_call, console_log } from '../helpers/untils';
import { getIsAdmin } from '../common/selectors';
import { useSelector } from 'react-redux';
import { getSettingPersist, setSettingPersist } from '../helpers/misc';
import FooterBox from '../settings/OptionsLayout/FooterBox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawerContainer: {
    width: theme.dimensions.drawerWidthDesktop,
  },
  drawer: {
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('md')]: {
      width: theme.dimensions.drawerWidthTablet,
    },
  },
  content: {
    flex: 1,
    padding: theme.spacing(5, 3, 3, 3),
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  backArrowIconContainer: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  toolbar: {
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.toolbar,
    },
  },
}));

const ReportLayout = ({ children, filter }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const t = useTranslation();
  const isAdmin = useSelector(getIsAdmin);
  const defaultServerInfo = {
    serverPrivateIp: "",
    serverPublicIp: ""
  }

  const getServerInfoFromLocalStorage = () => {
    const server_info = getSettingPersist('serverInfo', defaultServerInfo)
    return server_info
  }

  const [serverInfo, setServerInfo] = useState(getServerInfoFromLocalStorage())

  const getServerInfo = async () => {
    let url = `/api/get-env`
    let response = await api_call(url, {
      method: 'GET',
    });

    if (response.ok) {
      const server_info = await response.json()
      setServerInfo(server_info)
      setSettingPersist('serverInfo', server_info)
    } else {
      return false
    }
  }

  useEffect(() => {
    if (isAdmin) {
      getServerInfo()
    }
  }, []);


  const [openDrawer, setOpenDrawer] = useState(false);
  const [reportTitle, setReportTitle] = useState();

  const routes = useMemo(() => [
    { name: "Commands", href: '/reports/commands', icon: <SettingsInputComponentSharpIcon /> },
    // { name: t('reportRoute'), href: '/reports/route', icon: <TimelineIcon /> },
    { name: t('reportEvents'), href: '/reports/event', icon: <NotificationsActiveIcon /> },
    { name: 'Daily Mileage', href: '/reports/daily-mileage', icon: <SpeedIcon /> },
    // { name: t('reportTrips'), href: '/reports/trip', icon: <PlayCircleFilledIcon /> },
    // { name: t('reportStops'), href: '/reports/stop', icon: <PauseCircleFilledIcon /> },
    // { name: t('reportSummary'), href: '/reports/summary', icon: <FormatListBulletedIcon /> },
    // { name: t('reportChart'), href: '/reports/chart', icon: <TrendingUpIcon /> },
  ], [t]);

  //console_log("location:::", location)

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.indexOf(route.href) > -1) {
        setReportTitle(route.name);
      }
    });
  }, [location]);

  return (
    <div className={classes.root}>
      <Hidden only={['lg', 'xl']}>
        <NavBar setOpenDrawer={setOpenDrawer} title={`${t('reportTitle')} / ${reportTitle}`} />
        <Drawer
          variant="temporary"
          open={openDrawer}
          onClose={() => setOpenDrawer(!openDrawer)}
          classes={{ paper: classes.drawer }}
        >
          <SideNav routes={routes} />

          {
            (isAdmin) ? (
              <FooterBox serverInfo={serverInfo} isAdmin={isAdmin} />
            ) : (
              <FooterBox serverInfo={serverInfo} isAdmin={isAdmin} />
            )
          }

        </Drawer>
      </Hidden>
      <Hidden only={['xs', 'sm', 'md']}>
        <Drawer
          variant="permanent"
          classes={{ root: classes.drawerContainer, paper: classes.drawer }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => history.push('/main')}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              {t('reportTitle')}
            </Typography>
          </div>
          <Divider />
          <SideNav routes={routes} />

          {
            (isAdmin) ? (
              <FooterBox serverInfo={serverInfo} isAdmin={isAdmin} />
            ) : (
              <FooterBox serverInfo={serverInfo} isAdmin={isAdmin} />
            )
          }

        </Drawer>
      </Hidden>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <Grid container direction="column" spacing={2}>
          <Grid item>{filter}</Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ReportLayout;
