import React from 'react';
import { GoogleMap, LoadScript, Marker, OverlayView, InfoWindow } from '@react-google-maps/api';
import Rating from '@mui/material/Rating';
import { getDistanceFromMiles, trimPhone } from '../helpers/untils';

const InstallerInfoWindow = (props) => {
  const {
    position,
    marker,
    installerData,
    open,
    setOpen,
  } = props

  const handleInfoWindowClose = () => {
    setOpen(false)
  }

  return (
    <>
      <InfoWindow
        position={position ?? marker?.position}
        onCloseClick={handleInfoWindowClose}
      >
        <div>
          <h5 style={{ marginBottom: '1.25rem' }}>Installer Information</h5>
          <p>Email: <span style={{ fontWeight: 'bold' }}><a href={`mailto:${installerData?.email}`}>{installerData?.email}</a></span></p>
          <p>Phone: <span style={{ fontWeight: 'bold' }}><a href={`tel:${trimPhone(installerData?.phone)}`}>{trimPhone(installerData?.phone)}</a></span></p>
          <p>Reviews: <span style={{ fontWeight: 'bold' }}>{installerData?.review_count}</span></p>
          {
            (installerData?.average_review) ? (
              <div style={{ marginBottom: '1rem' }}>
                <p style={{ marginBottom: '0.25rem' }}>Average of reviews:</p>
                <div>
                  <Rating
                    size={`medium`}
                    name="simple-controlled"
                    sx={{ color: '#4CAF50' }}
                    value={installerData?.average_review}
                    readOnly={true}
                  />
                </div>
              </div>
            ) : (<></>)
          }

          <p>Average price: <span style={{ fontWeight: 'bold' }}>${installerData.average_price.toFixed(2)}</span></p>

          {
            (installerData.radius_serviced) ? (
              <>
                <p>Radius: <span style={{ fontWeight: 'bold' }}>{installerData.radius_serviced} miles</span></p>
                {/* <p>Radius: <span style={{ fontWeight: 'bold' }}>{getDistanceFromMiles(installerData.radius_serviced, 'm', false, true)} m</span></p> */}
              </>
            ) : (<></>)
          }
        </div>
      </InfoWindow>
    </>
  );
}

export default InstallerInfoWindow;
