import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Checkbox, Box, Link, Modal, Grid, Toolbar,
} from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from './EditItemView';
import EditAttributesView from './attributes/EditAttributesView';
import SelectField from './form/SelectField';
import deviceCategories from './common/deviceCategories';
import LinkField from './form/LinkField';
import { prefixString } from './common/stringUtils';
import { useTranslation } from './LocalizationProvider';
import useDeviceAttributes from './attributes/useDeviceAttributes';
import { Button } from '@material-ui/core';
import CreditLogsModal from './settings/CreditLogsModal';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import CarTypeTab from './components/CarTypeTab';
import SmartcarDeviceEditPage from './SmartcarDeviceEditPage';

import moment from 'moment';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  checkboxColumn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  imgSmall: {
    width: '50px',
    height: '42px',
    marginLeft: '5px',
    marginTop: '16px',
    marginBottom: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'center'
  },
}));

const LinkPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { id } = useParams();

  const history = useHistory()

  const user = useSelector((state) => state.session.user);
  const [item, setItem] = useState();

  const [from, setFrom] = useState(moment());
  const [to, setTo] = useState(moment().add(1, 'day'));

  const current = moment();

  useEffect(() => {
    if (item?.id) {
      if(moment(item.from) < current && current < moment(item.to)){ // if item is active
        setFrom(moment(item.from))
        setTo(moment(item.to))
      }else{
        history.push("/settings/links")
      }
    }
  }, [item?.id])

  const onChangeFormData = (fieldName, fieldValue) => {
    if (fieldName === 'to') {
      setTo(fieldValue)
      fieldValue = fieldValue.toISOString()
    }
    else if (fieldName === 'from') {
      setFrom(fieldValue)
      fieldValue = fieldValue.toISOString()
    }

    const itemData = { ...item }
    itemData[fieldName] = fieldValue
    setItem({ ...itemData })
  }
  return (
    <>
      <EditItemView endpoint="links" item={item} setItem={setItem}>
        {item
          && (
            <>
              <Accordion defaultExpanded disabled={true}>
                <>
                  <AccordionSummary>
                    <Typography variant="subtitle1">
                      Edit Link
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={2} justifyContent="flex-start">
                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="filled"
                          label="From"
                          type="datetime-local"
                          value={from.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                          onChange={(e) => onChangeFormData("from", moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))}
                          fullWidth
                         // disabled={true}
                          inputProps={{readOnly:true}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="filled"
                          label="To"
                          type="datetime-local"
                          value={to.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                          onChange={(e) => onChangeFormData("to", moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </>
              </Accordion>
            </>
          )}
      </EditItemView>
    </>
  );
};

export default LinkPage;
