import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom"

import { debounce } from "lodash"
import {
  Box,
  CircularProgress,
  IconButton,
  InputLabel,
  TextField,
  makeStyles,
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';


import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import EditCollectionView from '../EditCollectionView';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';

import moment from 'moment';
import { api_call, console_log, empty, getDistanceFromMiles } from '../helpers/untils';
import { Link, useHistory } from 'react-router-dom';
import { layoutActions } from '../store';
import { sleep } from '../helpers/misc';
import { formatBoolean } from '../common/formatter';
import UserAutoCompleteDropdown from '../components/UserAutoCompleteDropdown';
import CreditLogsModal from '../settings/CreditLogsModal';
import { Stack } from '@mui/material';
import EscrowLogsModal from '../settings/EscrowLogsModal';

const useStyles = makeStyles((theme) => ({
  tableToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  tableToolBarLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    maxWidth: '340px',
    paddingTop: '1rem',
    [theme.breakpoints.only('xs')]: {

    },
  },
  tableToolBarRight: {
    maxWidth: '300px',
    width: '100%',
    paddingTop: '1rem',
    paddingLeft: '4px',
    textAlign: 'right',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'left',
      paddingTop: '0.5rem',
    },
  },
  tableSearchText: {
    '& input.MuiInput-input': {
      paddingRight: '46px'
    }
  },
  tableSearchBtn: {
    borderRadius: 0,
    marginLeft: '-46px'
  }
}));

const UsersView = (props) => {
  const { updateTimestamp, onMenuClick } = props
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory()
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const searchKey = params.get("search")
  //console.log(`searchKey:::::`, searchKey)

  const user = useSelector((state) => state.session.user);

  const dataRef = useRef([])

  const columnDefs = [
    {
      name: 'id',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className="">
              <IconButton onClick={(event) => onMenuClick(event.currentTarget, value)}>
                <MoreVertIcon />
              </IconButton>
            </div>
          );
        },
        //display: false
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const row = dataRef.current[dataIndex];
          const value = row?.name;
          //console.log('dataIndex, row, value::::', dataIndex, dataRef.current, row, value)
          return (
            <div className='td-div'><span className="normal-primary-color">{value}</span></div>
          )
        }
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{value}</div>
          );
        }
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{value}</div>
          );
        }
      },
    },
    {
      name: 'escrow_balance',
      label: 'Escrow Balance',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{value}</div>
          );
        }
      },
    },
    {
      name: 'createdAt',
      label: 'Created',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{moment(value).format('LLL')}</div>
          );
        }
      },
    },
    {
      name: 'updatedAt',
      label: 'Last Login',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{moment(value).format('LLL')}</div>
          );
        }
      },
    },
    {
      name: 'administrator',
      label: 'Admin',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{formatBoolean(value, t)}</div>
          );
        }
      },
    },

  ]

  const [page, setPage] = useState(0)
  const [count, setCount] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [sortOrder, setSortOrder] = useState({ name: "id", direction: "asc" })
  const [data, setData] = useState([])
  const [columns, setColumns] = useState(columnDefs)
  const [isLoading, setIsLoading] = useState(false)

  const [searchText, setSearchText] = useState("");
  const doChangeSearchText = (filter) => {
    setSearchText(filter)
    if (filter === "") {
      onSubmitChangeSearchText(filter)
    }
  }
  const onChangeSearchText = (e) => {
    const filter = e.target.value
    doChangeSearchText(filter)
  }

  // mock async function
  const xhrRequest = async (page, rowsPerPage, sortOrder = {}, filter_options = {}) => {
    if (empty(user)) {
      return false
    }

    setIsLoading(true);
    let api_url = "/api/users/get-data-table"
    let post_data = {}
    post_data['page'] = page
    post_data['rowsPerPage'] = rowsPerPage
    post_data['sortOrder'] = sortOrder

    const filterOptions = {
      ...filter_options
    }
    // if (user.administrator) {
    //   filterOptions['userId'] = selectedUserId
    // } else {
    //   filterOptions['userId'] = user.id
    // }

    const response = await api_call(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...post_data, ...filterOptions }),
    });
    if (response.ok) {
      const res = await response.json();
      setData(res.data)
      dataRef.current = res.data
      setPage(res.page)
      setSortOrder(sortOrder)
      setIsLoading(false)
      setCount(res.total)
      return res
    } else {
      return false
    }
  }

  const sort = async (page, sortOrder) => {
    const filterOptions = {
      search: searchText
    }
    await xhrRequest(page, rowsPerPage, sortOrder, filterOptions);
  }

  const changePage = async (page, sortOrder) => {
    const filterOptions = {
      search: searchText
    }
    await xhrRequest(page, rowsPerPage, sortOrder, filterOptions)
  }

  const onChangeRowsPerPage = (r) => {
    setRowsPerPage(r)
    setPage(0)
    const filterOptions = {
      search: searchText
    }
    xhrRequest(0, r, sortOrder, filterOptions)
  }

  const onSubmitChangeSearchText = async (text) => {
    setPage(0)
    const filterOptions = {
      search: text
    }
    await xhrRequest(0, rowsPerPage, sortOrder, filterOptions)
  }

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log("handleSearchSubmit:::")
    onSubmitChangeSearchText(searchText)
  }

  useEffect(() => {
    if (searchKey) {
      doChangeSearchText(searchKey)
    }
  }, [searchKey])

  useEffect(() => {
    const filterOptions = {
      search: empty(searchText) ? searchKey : searchText
    }
    xhrRequest(page, rowsPerPage, sortOrder, filterOptions)
  }, [updateTimestamp, searchKey]);

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive: 'standard',
    serverSide: true,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    onChangeRowsPerPage: onChangeRowsPerPage,
    sortOrder: sortOrder,
    download: false,
    viewColumns: false,
    print: false,
    search: false,
    selectableRowsHideCheckboxes: true,

    onTableChange: (action, tableState) => {
      // console.log("action, tableState:::", action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want
      switch (action) {
        case 'changePage':
          changePage(tableState.page, tableState.sortOrder);
          break;
        case 'sort':
          sort(tableState.page, tableState.sortOrder);
          break;
        default:
        //console.log('action not handled.');
      }
    },
  }

  return (
    <div>
      <div className="mt-3 custom-data-table-container">
        <MUIDataTable
          title={
            <Box className={classes.tableToolBar}>
              <div className={classes.tableToolBarLeft}>
                {<CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 5, visibility: isLoading ? 'visible' : 'hidden' }} />}
              </div>

              <div className={classes.tableToolBarRight}>
                <form onSubmit={handleSearchSubmit}>
                  <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={0}>
                    <TextField
                      className={classes.tableSearchText}
                      fullWidth
                      value={searchText}
                      onChange={(e) => onChangeSearchText(e)}
                      label={`Search...`}
                      inputProps={{ type: 'text' }}
                    />
                    <IconButton size={`medium`} className={classes.tableSearchBtn} type="submit">
                      <SearchIcon />
                    </IconButton>
                  </Stack>
                </form>
              </div>
            </Box>
          }
          data={data}
          columns={columns}
          options={options}
          isLoading={isLoading}
        />
      </div>
    </div>
  )

}

const UsersDataTablePage = () => {
  const [escrowLogsModalOpen, setEscrowLogsModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const getUserInfo = async (userId) => {
    let url = `/api/users/${userId}`
    let response = await api_call(url, {
      method: 'GET',
    });

    if (response.ok) {
      const selectedUser = await response.json()
      return selectedUser
    } else {
      return false
    }
  }

  const showEscrowLogsModal = async (userId) => {
    const userInfo = await getUserInfo(userId)
    setCurrentUser(userInfo)
    setEscrowLogsModalOpen(true)
  }

  return (
    <>
      <OptionsLayout>
        <EditCollectionView content={UsersView} editPath="/user" endpoint="users" showEscrowLogsModal={showEscrowLogsModal} />
      </OptionsLayout>

      <>
        <EscrowLogsModal
          open={escrowLogsModalOpen}
          setOpen={setEscrowLogsModalOpen}
          item={currentUser}
          setItem={setCurrentUser}
          modalType={'user'}
        />
      </>

    </>
  )
}

export default UsersDataTablePage;
