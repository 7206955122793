import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography, Box, Modal,
} from '@material-ui/core';

import moment from 'moment';
import PayPalBtn from '../components/PayPalBtn';
import { api_call, console_log, get_utc_timestamp } from '../helpers/untils';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '640px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

const PaypalPaymentModal = (props) => {
  const { open, setOpen, item, setItem, updateTimestamp, setUpdateTimestamp, amount = 10, itemType = "device_credit", callback, user_type, user_id } = props;

  //  const user = useSelector((state) => state.session.user);

  const handleModalClose = () => {
    setOpen(false)
  }

  const createOrder = (data, actions) => {
    console.log("data, actions:::", data, actions)

    return actions.order.create({
      purchase_units: [{
        amount: {
          currency_code: "USD",
          value: amount
        },
        custom_id: `${user_type}_${user_id}_${item['id']}_${itemType}`,
      }],
      // application_context: {
      //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
      // }
    });
  }

  const paypalOnError = (err) => {
    console.log("Error")
  }

  const paypalOnApprove = (details, data) => {
    console_log("paypalOnApprove:::details", details)
    console_log("paypalOnApprove::: data", data)

    // Capture the funds from the transaction
    submitPaypalPayment(details, data)
  }

  const submitPaypalPayment = async (details, data) => {
    let url = "/api/payment/paypal/payment-complete"
    if(user_type === 'installer') {
      url = "/api/payment/paypal/installer-payment-complete"
    }
    let postData = {
      itemType: itemType,
      orderID: details.orderID,
      itemId: item['id'],
      userType: user_type,
      user_id: user_id
    }

    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    });
    if (response.ok) {
      let apiRes = await response.json()
      callback('success', apiRes)

      setUpdateTimestamp(get_utc_timestamp())
      setOpen(false)
      return apiRes;
    } else {
      callback('failed', null)
      setOpen(false)
    }
  }

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>

          {/* <Typography id="paypalPaymentFormTitle" className='mt-3'>
            Pay with Paypal
          </Typography> */}

          <div className='text-center' style={{ marginTop: '1rem' }}>
            <PayPalBtn
              type="pay_now"
              amount={amount}
              currency="USD"
              createOrder={createOrder}
              onApprove={paypalOnApprove}
              catchError={paypalOnError}
              onError={paypalOnError}
              onCancel={paypalOnError}
            />

          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PaypalPaymentModal;