import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import NotificationsIcon from '@material-ui/icons/Notifications';
import FolderIcon from '@material-ui/icons/Folder';
import PersonIcon from '@material-ui/icons/Person';
import StorageIcon from '@material-ui/icons/Storage';
import LinkIcon from '@material-ui/icons/Link';
import TimeToLeave from '@material-ui/icons/TimeToLeave';
import BuildIcon from '@material-ui/icons/Build';
import PeopleIcon from '@material-ui/icons/People';
import DeviceIcon from '@material-ui/icons/Devices';
import CachedIcon from '@material-ui/icons/Cached';
import TodayIcon from '@material-ui/icons/Today';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CropDinIcon from '@material-ui/icons/CropDin';
import MapIcon from '@material-ui/icons/Map';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import TuneIcon from '@material-ui/icons/Tune';
import InventoryIcon from '@mui/icons-material/Inventory';

import { getIsAdmin, getUserId } from '../../common/selectors';
import { useTranslation } from '../../LocalizationProvider';
import SettingPage from '../../admin/SettingPage';

const useAdminRoutes = (t) => useMemo(() => [
  { subheader: t('userAdmin') },
  // {
  //   name: t('settingsServer'),
  //   href: '/admin/server',
  //   icon: <StorageIcon />,
  // },
  {
    name: t('settingsUsers'),
    href: '/admin/users',
    icon: <PeopleIcon />,
  },
  {
    name: "Devices",
    href: '/admin/devices',
    icon: <DeviceIcon />,
  },
  {
    match: 'turo-logins',
    name: 'Turo Logins',
    href: '/settings/turo-logins',
    icon: <VpnKeyIcon />,
  },
  {
    name: "Unmatched Devices",
    href: '/admin/unmatched-devices',
    icon: <TuneIcon />,
  },
  {
    name: "Missed Invoices",
    href: '/admin/missed-invoices',
    icon: <CropDinIcon />,
  },
  {
    name: "Add",
    href: '/admin/add-users-devices',
    icon: <PersonAddIcon />,
  },
  // {
  //   name: t('statisticsTitle'),
  //   href: '/admin/statistics',
  //   icon: <BarChartIcon />,
  // },
  
  {
    name: "Cache",
    href: '/admin/cache',
    icon: <CachedIcon />,
  },
  {
    name: "Reseller Inventory",
    href: '/admin/reseller-inventory',
    icon: <InventoryIcon />,
  },
  {
    name: "Setting",
    href: '/admin/setting',
    icon: <BuildIcon />,
  },

], [t]);

const useMainRoutes = (t, userId) => useMemo(() => [
  {
    name: t('settingsUser'),
    href: `/profile`,
    icon: <PersonIcon />,
  },
  {
    match: 'geofence',
    name: t('sharedGeofences'),
    href: '/geofences',
    icon: <MapIcon />,
  },
  {
    match: 'link',
    name: 'Shared Links',
    href: '/settings/links',
    icon: <LinkIcon />,
  },
  {
    match: 'turo-trips',
    name: 'Turo Trips',
    href: '/settings/turo-trips',
    icon: <TimeToLeave />,
  },
  // {
  //   match: 'notification',
  //   name: t('sharedNotifications'),
  //   href: '/settings/notifications',
  //   icon: <NotificationsIcon />,
  // },
  // {
  //   match: 'group',
  //   name: t('settingsGroups'),
  //   href: '/settings/groups',
  //   icon: <FolderIcon />,
  // },
  // {
  //   match: 'driver',
  //   name: t('sharedDrivers'),
  //   href: '/settings/drivers',
  //   icon: <PersonIcon />,
  // },
  // {
  //   match: 'calendar',
  //   name: t('sharedCalendars'),
  //   href: '/settings/calendars',
  //   icon: <TodayIcon />,
  // },
  // {
  //   match: 'attribute',
  //   name: t('sharedComputedAttributes'),
  //   href: '/settings/attributes',
  //   icon: <StorageIcon />,
  // },
  // {
  //   match: 'maintenance',
  //   name: t('sharedMaintenance'),
  //   href: '/settings/maintenances',
  //   icon: <BuildIcon />,
  // },
], [t, userId]);

export default () => {
  const t = useTranslation();

  const isAdmin = useSelector(getIsAdmin); //quan
  const userId = useSelector(getUserId);

  const mainRoutes = useMainRoutes(t, userId);
  const adminRoutes = useAdminRoutes(t);

  return useMemo(() => [...mainRoutes, ...(isAdmin ? adminRoutes : [])], [
    mainRoutes, isAdmin, adminRoutes,
  ]);
};
