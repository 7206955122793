import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';

import RemoveDialog from './RemoveDialog';
import { useTranslation } from './LocalizationProvider';
import { console_log } from './helpers/untils';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const EditCollectionView = (props) => {
  const { content, editPath, endpoint, disableAdd, filter, showPaypalCreditLogsPage, showDeviceCreditLogsPage, updateDeviceCountData, timestamp, setTimestamp, showEscrowLogsModal } = props
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();

  const [selectedId, setSelectedId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
  const [removeDialogShown, setRemoveDialogShown] = useState(false);
  const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());
  const adminEnabled = useSelector((state) => state.session.user && state.session.user.administrator);

  const [showEditMenu, setShowEditMenu] = useState(true);

  const menuShow = (anchorId, itemId, showEdit = true, itemInfo = null) => {

    setSelectedAnchorEl(anchorId);
    setSelectedId(itemId);
    if (itemInfo) {
      setSelectedItem(itemInfo)
    }
    setShowEditMenu(showEdit)
  };

  const menuHide = () => {
    setSelectedAnchorEl(null);
  };

  const handleAdd = () => {
    history.push(editPath);
    menuHide();
  };

  const handleEdit = () => {
    history.push(`${editPath}/${selectedId}`);
    menuHide();
  };

  const handleRemove = () => {
    setRemoveDialogShown(true);
    menuHide();
  };

  const handleEscrowLogs = () => {
    showEscrowLogsModal(selectedId)
    menuHide();
  };

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
    setUpdateTimestamp(Date.now());
    if (timestamp) {
      setTimestamp(Date.now())
    }
  };

  const Content = content;

  return (
    <>
      <Content
        updateTimestamp={updateTimestamp}
        setUpdateTimestamp={setUpdateTimestamp}
        onMenuClick={menuShow}
        filter={filter}
        updateDeviceCountData={updateDeviceCountData}
        showPaypalCreditLogsPage={showPaypalCreditLogsPage}
        showDeviceCreditLogsPage={showDeviceCreditLogsPage}

      />
      {adminEnabled && !disableAdd
        && (
          <Fab size="medium" color="primary" className={classes.fab} onClick={handleAdd}>
            <AddIcon />
          </Fab>
        )}
      <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
        {/* {editPath !== "/share" && <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>} */}

        {showEditMenu && <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>}
        {endpoint === "users" && <MenuItem onClick={handleEscrowLogs}>{`Escrow Logs`}</MenuItem>}
        <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>
      </Menu>
      <RemoveDialog open={removeDialogShown} endpoint={endpoint} itemId={selectedId} onResult={handleRemoveResult} />
    </>
  );
};

export default EditCollectionView;