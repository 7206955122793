import React from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import { PAYPAL_CLIENT_ID } from "../config/constant";

export function PayPalBtn(props) {
  const { type = "subscription", amount, currency, createSubscription, createOrder, onApprove, catchError, onError, onCancel, onSuccess } = props;
  const paypalKey = PAYPAL_CLIENT_ID // "<PayPal Client ID>"

  return (
    <>
      {
        (type === "subscription") ? (
          <>
            <PayPalButton
              amount={amount}
              currency={currency}
              createSubscription={(data, details) => createSubscription(data, details)}
              onApprove={(data, details) => onApprove(data, details)}
              onError={(err) => onError(err)}
              catchError={(err) => catchError(err)}
              onCancel={(err) => onCancel(err)}
              options={{
                clientId: paypalKey,
                vault: true
              }}
              style={{
                // shape: 'rect',
                // color: 'blue',
                // layout: 'horizontal',
                // label: 'subscribe',

                shape: 'rect',
                color: 'white',
                layout: 'vertical',
                label: 'subscribe'
              }}
            />
          </>
        ) : (type === 'pay_now') ? (
          <>
            <PayPalButton
              createOrder={(data, actions) => createOrder(data, actions)}
              onApprove={(data, actions) => onApprove(data, actions)}
              options={{
                clientId: paypalKey,
              }}
              style={{
                // shape: 'rect',
                // color: 'blue',
                // layout: 'horizontal',
                // label: 'subscribe',

                shape: 'rect',
                color: 'white',
                layout: 'vertical',
                label: 'pay' //pay
              }}
            />
          </>
        ) : (
          <>
            <PayPalButton
              amount={amount}
              currency={currency}
              // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
              onSuccess={(details, data) => {
                onSuccess(details, data)
              }}
              options={{
                clientId: paypalKey,
              }}
              style={{
                // shape: 'rect',
                // color: 'blue',
                // layout: 'horizontal',
                // label: 'subscribe',

                shape: 'rect',
                color: 'white',
                layout: 'vertical',
                label: 'pay' //pay
              }}
            />
          </>
        )
      }
    </>

  )
}

export default PayPalBtn;