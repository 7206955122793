import React from 'react';
import {
  Grid, Button, Typography, Box,
} from '@material-ui/core';

const SmartcarPaymentRequireBlock = (props) => {
  const {onSubmit, submitting, setSubmitting} = props
  const onSubmitOk = async () => {
    onSubmit()
  }

  return (
    <Box style={{ width: '400px', maxWidth: '100%' }}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Typography variant='title' component="p" style={{ textAlign: 'center', marginBottom: '20px', fontSize: '22px', fontWeight: 600 }}>
            Payment Setup Required
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle' component="p">
            Please check your email or junk folder for a MooveTrax login, After login, click on the balance in order to setup payment and to get full activation
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle' component="p" color="error">
            Disruption of Payment requires re-activation
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            disabled={submitting}
            fullWidth
            onClick={() => onSubmitOk()}
          >
            Ok
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SmartcarPaymentRequireBlock;
