import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom"

import {
  makeStyles, Paper, Grid, Typography, Box, TextField, Button
} from '@material-ui/core';
import { Snackbar } from '@material-ui/core';

import Rating from '@mui/material/Rating';
import { Stack } from '@mui/material';
import { api_call, console_log } from './helpers/untils';
import { sleep } from './helpers/misc';

const useStyles = makeStyles((theme) => ({
  ...theme.palette.colors,
  root: {
    minHeight: '100vh',
  },
  installerRateViewWrapper: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#abd3df'
  },
  paper: {
    width: '640px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '480px',
    },
    position: 'relative'
  },
  installerRateViewContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '32px',
    paddingTop: '56px',
    paddingBottom: '48px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  flex1: {
    flex: 1,
    width: '100%'
  },
  paperCloseBtn: {
    cursor: 'pointer',
    position: 'absolute',
    background: '#ffffff',
    right: '-18px',
    top: '-18px',
    padding: '7px',
    borderRadius: '30px',
    border: '1px solid #cccccc',
    [theme.breakpoints.down('md')]: {
      right: '8px',
      top: '8px',
    },
  },
}));

const InstallerRatePage = () => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation()

  const params = new URLSearchParams(location.search)
  const id = params.get("k")
  console.log(`id:::::`, id)

  const [refreshTimestamp, setRefreshTimestamp] = useState(0)

  useEffect(() => {

  }, [refreshTimestamp]);

  const initialFormData = {
    rating: 0,
    charge: ""
  }
  const [formData, setFormData] = useState(initialFormData)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const showToast = (msg) => {
    setToastMsg(msg)
    setSnackbarOpen(true);
  }

  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();
    var data = { ...formData, id: id };
    setSubmitting(true)
    showToast("submitting...")
    const response = await api_call('/api/submit-installer-rate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const sessionData = await response.json();
      //console_log("sessionData:::::::", sessionData);
      if (sessionData['error']) {
        showToast(sessionData['error'])
        //setSubmitting(false)
      } else {
        showToast("Thank you!")
        setSubmitting(false)
      }
 
      await sleep(2000)
      redirectToMainPage()
    }
  }

  const redirectToMainPage = () => {
    history.push('/')
  }

  return (
    <div className={classes.root}>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarOpen}
          onClose={() => { setSnackbarOpen(false) }}
          autoHideDuration={3000}
          message={toastMsg}
        />
      </div>

      <div className={classes.installerRateViewWrapper}>
        <form onSubmit={handleSubmit}>
          <Paper className={classes.paper} elevation={0} square>
            {/* <div className={classes.paperCloseBtn}> <CloseIcon /></div> */}
            <Box className={classes.installerRateViewContainer}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{ width: '100%', paddingBottom: '24px' }}>
                    <Typography align="center" variant="h6" color="primary">Please rate your installer</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={`column`} justifyItems={`center`} alignItems={`center`} spacing={2}>
                    <Typography align="center" variant="subtitle1" color="primary">How would you rate your installer?</Typography>
                    <div>
                      <Rating
                        size={`large`}
                        name="simple-controlled"
                        sx={{ color: '#4CAF50' }}
                        value={formData['rating']}
                        onChange={(event, newValue) => {
                          setFormData({ ...formData, rating: newValue });
                        }}
                      />
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={`column`} justifyItems={`center`} alignItems={`center`} spacing={2}>
                    <Typography align="center" variant="subtitle1" color="primary">How much did your installer charge for labor?</Typography>
                    <div>
                      <TextField
                        margin="none"
                        value={formData['charge']}
                        onChange={(e) => setFormData({ ...formData, charge: e.target.value.substring(0, 8) })}
                        label=""
                        placeholder=''
                        variant="filled"
                        required={true}
                        style={{ maxWidth: '191px' }}
                        inputProps={{
                          type: 'number',
                          min: 0,
                          style: {
                            textAlign: 'center'
                          }
                        }}
                      />
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={`row`} justifyContent={`center`} alignItems={`center`}>
                    <Button
                      type={`submit`}
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      style={{ maxWidth: '191px' }}
                      disabled={!formData['rating'] || submitting}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </form>
      </div>
    </div>
  )
}

export default InstallerRatePage
