import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid, useMediaQuery, makeStyles, Snackbar, InputLabel, FormControlLabel, Checkbox, Select, MenuItem, FormControl, Button, TextField, Typography,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { useHistory } from 'react-router-dom';
import { empty, api_call, console_log, isEmptyObject } from './helpers/untils';
import { useLocation } from "react-router-dom"
import { sessionActions } from './store';
import { getSettingPersist } from './helpers/misc';
import SmartcarDeviceNoAuthPage from './SmartcarDeviceNoAuthPage';
import { MACHINE_ENVIRONMENT } from './config/constant';
import SmartcarPaymentRequireBlock from './SmartcarPaymentRequireBlock';
import TeslaDeviceNoAuthPage from './TeslaDeviceNoAuthPage';

const TeslaCallbackPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()

  const updateQueryParams = (newParams) => {
    const searchParams = new URLSearchParams(location.search);
    Object.keys(newParams).forEach(key => {
      searchParams.set(key, newParams[key]);
    });

    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const params = new URLSearchParams(location.search)
  //console_log("params::::", params.get("code"))
  const code = params.get("code")
  const access_token = params.get("access_token")
  const refresh_token = params.get("refresh_token")
  console_log(`code,access_token,refresh_token:::`, code, access_token, refresh_token)

  useEffect(() => {
    processCallbackCode();
  }, [code, access_token, refresh_token]);

  const processCallbackCode = async () => {
    if (window.document.getElementById("smartcar-auth-code")) {
      window.document.getElementById("smartcar-auth-code").value = code
    }

    const smartcar_auth_source = getSettingPersist('smartcar_auth_source')
    console_log(`smartcar_auth_source:::`, smartcar_auth_source)
    if (smartcar_auth_source === 'signup') {
      teslaAuthCallback(code, smartcar_auth_source)
    } else {
      teslaDeviceCallback(code, smartcar_auth_source)
    }
  }

  const teslaDeviceCallback = async (code, smartcar_auth_source) => {
    if (code) {
      const response = await api_call(`/api/tesla/login-callback?code=${code}&smartcar_auth_source=${smartcar_auth_source}`);
      if (response.ok) {
        const res = await response.json()
        console_log("tesla login callback:::", res)
        const tokens = res.tokens;
        if (tokens) {
          const access_token = tokens['access_token']
          const refresh_token = tokens['refresh_token']
          console_log("tesla access_token, refresh_token:::", tokens)
          history.push('/tesla-device?access_token=' + access_token + '&refresh_token=' + refresh_token)
          return true
        }
      }
    } else if (access_token && refresh_token) {
      history.push('/tesla-device?access_token=' + access_token + '&refresh_token=' + refresh_token)
      return true
    }

    showToast("Tesla authentication error")
    setTimeout(() => {
      history.push('/device')
    }, 2000)
  }

  const teslaAuthCallback = async (code, smartcar_auth_source) => {
    if (code) {
      const response = await api_call(`/api/tesla/login-callback?code=${code}&smartcar_auth_source=${smartcar_auth_source}`);
      if (response.ok) {
        const res = await response.json()
        console_log("tesla login callback:::", res)
        const tokens = res.tokens;
        if (tokens) {
          const access_token = tokens['access_token']
          const refresh_token = tokens['refresh_token']
          console_log("tesla access_token, refresh_token:::", tokens)

          if (access_token && refresh_token) {
            const form_data = {
              ...formData,
              accessToken: access_token,
              refreshToken: refresh_token
            }
            setFormData(form_data)

            updateQueryParams({ code: "", access_token: access_token, refresh_token: refresh_token })
            return true
          }
        }
      }
    } else if (access_token && refresh_token) {
      const form_data = {
        ...formData,
        accessToken: access_token,
        refreshToken: refresh_token
      }
      setFormData(form_data)
      return true
    }

    showToast("Tesla authentication error!")
    setTimeout(() => {
      history.push('/login')
    }, 2000)
  }

  const defaultFormData = {
    email: "",
    accessToken: "",
    refreshToken: ""
  }
  const [formData, setFormData] = useState(defaultFormData);
  const [item, setItem] = useState({});
  const [vehicleInfoList, setVehicleInfoList] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const showToast = (msg) => {
    setToastMessage(msg)
    setSnackbarOpen(true)
  }

  const [submitting, setSubmitting] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const onSubmit = async (event) => {
    event.preventDefault();
    setOpenConfirmModal(true)
  }

  const submitApi = async () => {
    const payload = {
      ...formData,
      deviceInfo: item
    }

    console.log(`payload:::`, payload)
    setSubmitting(true)
    showToast("submitting...")
    const response = await api_call('/api/signup-tesla-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      const apiRes = await response.json();
      showToast(apiRes.message)
      redirectToMainPage()
    } else {
      const errorRes = response
      let errorMessage = "Invalid request"
      if (errorRes['error']) {
        errorMessage = errorRes['error']
      }
      setSubmitting(false)
      showToast(errorMessage)
      setOpenConfirmModal(false)
      return false
    }
  }
  const redirectToMainPage = () => {
    setTimeout(() => {
      setSubmitting(false)
      history.push('/login')
    }, 2000)
  }

  return (
    <div style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '24px', paddingBottom: '48px', paddingLeft: '16px', paddingRight: '16px' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        autoHideDuration={3000}
        message={toastMessage}
      />

      {
        (!openConfirmModal) ? (
          <>
            <form method="post" onSubmit={onSubmit} style={{ width: '400px', maxWidth: '100%' }}>
              <input type="hidden" name="code" id="smartcar-auth-code" value="" />
              <>
                {
                  (formData['accessToken']) ? (
                    <Grid container direction="column" spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant='title' component="p" style={{ textAlign: 'center', marginBottom: '20px', fontSize: '24px', fontWeight: 600 }}>OEM Signup</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          label={`Enter your email`}
                          name="email"
                          type="email"
                          value={formData.email}
                          autoFocus={true}
                          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                          helperText=""
                          variant="filled"
                          disabled={submitting}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TeslaDeviceNoAuthPage
                          accessToken={formData['accessToken']}
                          refreshToken={formData['refreshToken']}
                          item={item}
                          setItem={setItem}
                          vehicleInfoList={vehicleInfoList}
                          setVehicleInfoList={setVehicleInfoList}
                          email={formData.email}
                          showToast={showToast}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          disabled={empty(formData.email) || vehicleInfoList === null || submitting}
                          fullWidth
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )
                }
              </>
            </form>
          </>
        ) : (
          <>
            <SmartcarPaymentRequireBlock
              onSubmit={() => submitApi()}
              submitting={submitting}
              setSubmitting={setSubmitting}
            />
          </>
        )
      }
    </div>
  )
}

export default TeslaCallbackPage;
