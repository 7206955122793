import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import {
  Grid, Button, TextField, Typography, Link, makeStyles, Snackbar,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StartPage from '../../StartPage';
import { useTranslation } from '../../LocalizationProvider';
import useQuery from '../../common/useQuery';
import { api_call, console_log, validateEmail } from '../../helpers/untils';
import { RECAPTCHA_ENABLED, RECAPTCHA_KEY } from '../../config/constant';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    textTransform: 'uppercase',
  },
  link: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginTop: theme.spacing(0.5),
    cursor: 'pointer',
  },
}));

const ResetPasswordForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();
  const query = useQuery();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [recaptcha, setRecaptcha] = useState(RECAPTCHA_ENABLED === "false" ? "xxxxxxxxxxxxxxxxx" : "");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    let response;

    response = await api_call('/api/password/reset', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email }),
    });
    if (response.ok) {
      setToastMessage(`A password change link has been sent to your email.`)
      setSnackbarOpen(true);
    } else {
      const apiError = response
      setToastMessage(apiError['error'])
      setSnackbarOpen(true);
    }
  }

  const onChange = (value) => {
    // event.preventDefault();
    console.log(value);
    setRecaptcha(value);
  }


  return (
    <StartPage>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => history.push('/login')}
        autoHideDuration={3000}
        message={toastMessage}
      />
      <Grid container direction="column" spacing={2}>
        <Grid container item>
          <Grid item>
            <Typography className={classes.link} color="primary">
              <Link onClick={() => history.push('/login')}>
                <ArrowBackIcon />
              </Link>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography className={classes.title} color="primary">
              {t('loginReset')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            required
            fullWidth
            type="email"
            label={t('userEmail')}
            name="email"
            value={email}
            autoComplete="email"
            onChange={(event) => setEmail(event.target.value)}
            variant="filled"
          />
        </Grid>
        {
          (RECAPTCHA_ENABLED === "true") && (
            <Grid item>
              <ReCAPTCHA
                // sitekey = "6LcLyiIfAAAAACo511xcuJZcxKwIg1kYKFhE3nue"
                sitekey={RECAPTCHA_KEY}
                // 6LeMLwcfAAAAAOPKgdVdoh8mcpM7wrUiAwDS5QA7
                onChange={onChange}
              />
            </Grid>
          )
        }

        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            disabled={!email || !validateEmail(email) || !recaptcha}
            fullWidth
          >
            {t('loginReset')}
          </Button>
        </Grid>
      </Grid>
    </StartPage>
  );
};

export default ResetPasswordForm;
