import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_GEO_LOCATION } from '../config/constant';
import { console_log } from '../helpers/untils';
import { devicesActions } from '../store';
import { map } from './Map';

var flag = false;
const SelectedDeviceMap = (props) => {
  const { temporaryPage } = props;

  const dispatch = useDispatch();

  const user = useSelector((state) => state.session.user);
  const mapCenter = useSelector((state) => {
    if (state.devices.selectedId) {
      const position = state.positions.items[state.devices.selectedId] || null;
      if (position && position.id) {
        return { deviceId: state.devices.selectedId, position: [position.longitude, position.latitude] };
      } else {
        if (temporaryPage) {
          return null
        } else {
          return { deviceId: state.devices.selectedId, position: [DEFAULT_GEO_LOCATION[1], DEFAULT_GEO_LOCATION[0]] };
        }
      }
    }
    return null;
  });

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);

  useEffect(() => {
    updateMapCenterPosition()
  }, [mapCenter, selectedDeviceId]);

  useEffect(() => {
    map.on('zoom', onZoomMap);
    return () => {
      map.off('zoom', onZoomMap);
    }
  }, []);

  const zoomMapWatcherStatus = useRef(false)
  const updateMapCenterPosition = (resetZoom) => {
    //console.log("mapCenter::::::", mapCenter)
    zoomMapWatcherStatus.current = false
    if (mapCenter) {
      var paddingBottom = 0
      if (selectedDeviceId) {
        paddingBottom = 560
      }

      const easeToOption = {
        center: mapCenter.position,
        padding: { bottom: paddingBottom }
      }

      var currentZoom = map.getZoom()
      //console_log("currentZoom:::", currentZoom)
      easeToOption['zoom'] = 16

      if (!user.id && !flag) map.easeTo({ ...easeToOption });
      else if (!(!user.id && flag)) map.easeTo({ ...easeToOption, });
      flag = true;
      // map.easeTo({ center: mapCenter.position , zoom: 18 });

      //dispatch(devicesActions.unselect());
      setTimeout(function () {
        zoomMapWatcherStatus.current = true
      }, 1000)
    }
  }



  let lastZoom = map.getZoom();
  const onZoomMap = () => {
    const currentZoom = map.getZoom();
    //console.log("currentZoom > lastZoom:::", currentZoom, lastZoom)
    let zoomModified = false
    if (currentZoom > lastZoom) {
      // zoom in
      //console.log("zoom in::::", zoomMapWatcherStatus.current)
      zoomModified = true
    }
    else if (currentZoom < lastZoom) {
      // zoom in
      //console.log("zoom out::::", zoomMapWatcherStatus.current)
      zoomModified = true
    } else {
      //keep zoom
      zoomModified = false
    }
    lastZoom = currentZoom;
    if (zoomModified) {
      if (zoomMapWatcherStatus.current) {
        dispatch(devicesActions.unselect());
      }
    }
  }



  return null;
};

export default SelectedDeviceMap;
