import React, { useState } from 'react';
import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton, TextField, Button,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import { formatBoolean } from '../common/formatter';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';
import { api_call, console_log, empty, validateEmail } from '../helpers/untils';
import { useSelector } from 'react-redux';
import { getIsAdmin } from '../common/selectors';
import { GPS_ID_PLACEHOLDER } from '../config/constant';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > *': {
      marginLeft: '16px',
      marginRight: '16px',
      marginBottom: '16px',
      minWidth: '120px',
    },
  },
}));

const UsersDevicesView = () => {
  let totalRowCnt = 10;

  const classes = useStyles();
  const t = useTranslation();

  const getDefaultItems = () => {
    let row_list = [];
    for (let i = 0; i < totalRowCnt; i++) {
      let row = {
        email: "",
        phone: "",
        name: "",
        uniqueId: GPS_ID_PLACEHOLDER,
      }
      row_list.push(row);
    }
    return row_list;
  }

  const [items, setItems] = useState(getDefaultItems());

  const setItem = (item, index) => {
    let currentItems = [...items];
    currentItems[index] = item;
    setItems(currentItems)
  }

  const onClickDuplicate = () => {
    let currentItems = [...items];
    const first_row = currentItems[0];
    for (let i = 0; i < currentItems.length; i++) {
      currentItems[i] = first_row;
    }
    setItems(currentItems)
  }

  const onClickSubmitUsersDevices = () => {
    let validItems = [];
    let currentItems = [...items];
    for (let i = 0; i < currentItems.length; i++) {
      let row = currentItems[i];
      let email = row['email'];
      let uniqueId = row['uniqueId'];
      if (email !== "" && uniqueId !== "") {
        if (!validateEmail(email)) {
          alert("Please enter valid email address");
          return false
        }
        validItems.push(row);
      }
    }
    console_log("validItems:::", validItems)
    if (validItems.length === 0) {
      alert("Email and device is mandatory");
      return false;
    } else {
      handleSave(validItems)
    }
  }

  const handleSave = async (validItems) => {
    let url = `/api/users/add-users-devices`;
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(validItems),
    });

    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      if (responseObj['error']) {
        alert(responseObj['error'])
      }
      let cnt = responseObj['cnt'];
      if (empty(cnt) || cnt == 0) {
        return false
      } else {
        setItems(getDefaultItems())
      }

    } else {
      alert("Error encounted")
    }
  };


  return (
    <div>
      <div className="table-action-btn-box"  >
        <div className={classes.buttons}>
          <Button type="button" color="primary" variant="contained" onClick={() => { onClickDuplicate() }}>
            Duplicate
          </Button>
          <Button type="button" color="primary" variant="contained" onClick={() => { onClickSubmitUsersDevices() }}>
            Submit
          </Button>
        </div>
      </div>
      <TableContainer style={{ width: '100%', minWidth: '600px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={`25%`}>Email</TableCell>
              <TableCell width={`25%`}>Device</TableCell>
              <TableCell width={`25%`}>Phone</TableCell>
              <TableCell width={`25%`}>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    margin="none"
                    value={item.email || ''}
                    onChange={(event) => setItem({ ...item, email: event.target.value }, index)}
                    label=""
                    variant="filled"
                    InputLabelProps={{
                      style: {
                        fontStyle: 'normal',
                      }
                    }
                    }
                    fullWidth={true}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    margin="none"
                    value={item.uniqueId || ''}
                    onChange={(event) => setItem({ ...item, uniqueId: event.target.value }, index)}
                    label=""
                    variant="filled"
                    InputLabelProps={{
                      style: {
                        fontStyle: 'normal',
                      }
                    }}
                    fullWidth={true}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    margin="none"
                    value={item.phone || ''}
                    onChange={(event) => setItem({ ...item, phone: event.target.value }, index)}
                    label=""
                    variant="filled"
                    InputLabelProps={{
                      style: {
                        fontStyle: 'normal',
                      }
                    }
                    }
                    fullWidth={true}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    margin="none"
                    value={item.name || ''}
                    onChange={(event) => setItem({ ...item, name: event.target.value }, index)}
                    label=""
                    variant="filled"
                    InputLabelProps={{
                      style: {
                        fontStyle: 'normal',
                      }
                    }
                    }
                    fullWidth={true}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const UsersDevicesPage = () => {
  const isAdmin = useSelector(getIsAdmin);
  return (
    <>
      {
        (isAdmin) ? (<>
          <OptionsLayout>
            <UsersDevicesView />
          </OptionsLayout>
        </>) :
          (<></>)
      }
    </>
  )
}

export default UsersDevicesPage;
