import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions, sessionActions } from '../store';

import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Grid, Typography, Box, Modal, Snackbar, makeStyles, Divider
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Stack } from '@mui/material';

import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';

import moment from 'moment';
import { addItemToArray, api_call, console_log, empty, getLocalTimezone, getUnixTimestampFromTimezone, get_utc_timestamp, get_utc_timestamp_ms, is_null, removeItemFromArray, trimPhone } from '../helpers/untils';
import PaypalPaymentModal from './PaypalPaymentModal';
import { APP_NAME, MAINT_VIDEO_ID, MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID, TOLL_VIDEO_ID } from '../config/constant';
import TollListModal from './TollListModal';
import TollSearchListTable from './TollSearchListTable';

import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import ConfirmDialog from '../components/ConfirmDialog';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '500px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));

const FuelCalibrateModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const { open, setOpen, deviceItem, history } = props;
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (deviceItem && deviceItem.id) {
      setItem(deviceItem)
    }
  }, [deviceItem])

  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  ///////////////////////////////////////////////////////////////////////////////////

  const classes = useStyles();

  const [apiCalling, setApiCalling] = useState([])

  const apiLoading = (api_type, status) => {
    let api_calling = [...apiCalling]
    if (status) {
      api_calling = addItemToArray(api_calling, api_type)
    } else {
      api_calling = removeItemFromArray(api_calling, api_type)
    }
    setApiCalling(api_calling)
  }

  const getOpenVoltageConfirmText = (msg, volt) => {
    return (
      <Stack direction={`column`} spacing={2}>
        <Typography variant="body1">{msg}</Typography>
        {
          !is_null(volt) ? (
            <Typography variant="h6">{volt} v</Typography>
          ) : (
            <></>
          )
        }
      </Stack>
    )
  }

  ///////////////////////////// confirmation ///////////////////////////////////
  const [confirmAction, setConfirmAction] = useState('')
  const [confirmText, setConfirmText] = useState("")
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const onClickAgreeConfirm = () => {
    if (confirmAction) {
      setCarVoltage(confirmAction)
      setShowConfirmModal(false)
    }
  }
  const onClickCancelConfirm = () => {
    setShowConfirmModal(false)
  }

  const getDeviceDetail = async (deviceId) => {
    let url = `/api/devices/${deviceId}`
    let response = await api_call(url, {
      method: 'GET',
    });

    if (response.ok) {
      const apiRes = await response.json()
      const deviceInfo = apiRes
      return deviceInfo
    } else {
      return false
    }
  }

  const onClickFuelMinVoltage = async () => {
    const deviceInfo = await getDeviceDetail(item.id)
    if (deviceInfo) {
      setConfirmAction('fuel_min_voltage')
      const msg = "Has the fuel been Quarter for more than 5 minutes?"
      const fuelVal = Number(deviceInfo?.fuel)/10
      setConfirmText(getOpenVoltageConfirmText(msg, Number(fuelVal.toFixed(1))))
      setShowConfirmModal(true)
    }
  }

  const onClickFuelMaxVoltage = async () => {
    const deviceInfo = await getDeviceDetail(item.id)
    if (deviceInfo) {
      setConfirmAction('fuel_max_voltage')
      const msg = "Has the fuel been Full for more than 5 minutes?"
      const fuelVal = Number(deviceInfo?.fuel)/10
      setConfirmText(getOpenVoltageConfirmText(msg, Number(fuelVal.toFixed(1))))
      setShowConfirmModal(true)
    }
  }

  const setCarVoltage = async (type) => {
    apiLoading(type, true)

    let url = `/api/device/set-device-voltage`;
    const payload = {
      type: type,
      device_id: item.id,
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    apiLoading(type, false)

    if (response.ok) {
      let responseObj = await response.json()
      alert(`${responseObj.message}`)
    } else {
      alert("Error encounted")
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (open) {
      dispatch(layoutActions.closeDeviceListMenu());
    }
  }, [open]);
  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />
          <div>
            <Stack direction={`row`} sx={{ justifyContent: 'space-between', alignItems: 'center' }} spacing={2}>
              <Typography variant="h6" component="h2">Calibrate Fuel</Typography>
            </Stack>
          </div>
          <Box sx={{ paddingTop: 16 }}>
            <div>
              <div style={{ maxHeight: 'calc(100vh - 255px)', overflowY: 'auto' }}>
                <Box sx={{ maxWidth: '100%', margin: 'auto' }}>
                  <div className={classes.formContainer}>
                    <Grid container justifyContent="flex-start">
                      <Grid item xs={12} sm={12}>
                        <Button type="button" color="primary" variant="contained" fullWidth onClick={() => onClickFuelMinVoltage()} disabled={apiCalling.includes("fuel_min_voltage")} style={{ marginBottom: '8px' }}>
                          Fuel is Quarter
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Button type="button" color="primary" variant="contained" fullWidth onClick={() => onClickFuelMaxVoltage()} disabled={apiCalling.includes("fuel_max_voltage")} style={{ marginBottom: '24px' }}>
                          Fuel is Full
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Button type="button" color="primary" variant="outlined" fullWidth onClick={() => setOpen(false)}>
                          Close
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </div>
            </div>
          </Box>
        </Box>
      </Modal>

      <>
        <ConfirmDialog
          open={showConfirmModal}
          setOpen={setShowConfirmModal}
          title={APP_NAME}
          content={confirmText}
          textYes={`Yes`}
          textNo={`Cancel`}
          onClickYes={() => onClickAgreeConfirm()}
          onClickNo={() => onClickCancelConfirm()}
        />
      </>

    </>
  );
};

export default FuelCalibrateModal;
