import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  makeStyles,
} from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import { DEFAULT_GEO_LOCATION, GOOGLE_MAP_KEY } from '../config/constant';

import { GoogleMap, useJsApiLoader, LoadScript, Marker } from '@react-google-maps/api';
import { useState } from 'react';

const defaultContainerStyle = {
  width: '100%',
  height: '100vh'
}

const defaultCurrentPosition = {
  lat: DEFAULT_GEO_LOCATION[0],
  lng: DEFAULT_GEO_LOCATION[1],
}

const Map = (props) => {
  const {
    map,
    setMap,
    zoom = 14,
    containerStyle = defaultContainerStyle,
    currentPosition = defaultCurrentPosition,
    children
  } = props;

  console.log(`currentPosition:::::`, currentPosition)

  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: GOOGLE_MAP_KEY
  // })

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds();
    // // Extend the bounds with the marker's position
    // bounds.extend(currentPosition);

    if (map) {
      // map.fitBounds(bounds);
      // map.setZoom(zoom);

      setMap(map)
    }
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => {

  }, []);

  return (
    <LoadScript
      googleMapsApiKey={GOOGLE_MAP_KEY}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={currentPosition}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */}

        <>
          {children}
        </>
        {/* <Marker position={currentPosition} /> */}

      </GoogleMap>
    </LoadScript>
  )
};

export default Map // React.memo(FindInstallerPage)