import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions, sessionActions } from '../store';

import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, Typography, FormControlLabel, Checkbox, Box, Link, Modal, TextField, Button, Snackbar,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';
import VisibilityOffIcon from '@material-ui/icons/Close';

import moment from 'moment';
import { api_call, console_log, empty, get_utc_timestamp, priceFormat } from '../helpers/untils';
import PaypalSubscriptionModal from './PaypalSubscriptionModal';
import { MAX_CREDIT_AMOUNT, MIN_CREDIT_AMOUNT, PAYPAL_ESCROW_PLAN_PRICE, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID } from '../config/constant';
import { ArrowRightOutlined } from '@material-ui/icons';

const itemType = "escrow"

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '1192px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  py: 2,
};

const modalInnerStyle = {
  position: 'relative',
  width: '100%',
  maxHeight: 'calc(100vh - 160px)',
  overflowY: 'auto'
};

const paypalBtnStyle = {
  cursor: 'pointer',
  background: 'rgb(253 195 58)',
  padding: '4px 35px',
  borderRadius: '15px',
  fontSize: '14px',
  color: '#0a519a',
  textAlign: 'center',
  marginBottom: '5px',
  lineHeight: 1.5,
  display: 'inline-block',
};

const CreditLogsTable = (props) => {
  const { selectedUser, setUser, updateTimestamp, setUpdateTimestamp, paypalSubscriptionDetail, setPaypalSubscriptionDetail, modalType } = props;

  const user = useSelector((state) => state.session.user);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [tableItems, setTableItems] = useState([]);
  const PageSize = 5;

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTableData(tableItems.slice(firstPageIndex, lastPageIndex))
  }, [currentPage]);

  useEffectAsync(async () => {
    console.log("selectedUser::", selectedUser)
    let url = `/api/escrowLogs/` + selectedUser['id'];
    if (modalType === "self") {
      url = `/api/escrowLogs`
    }

    const response = await api_call(url);
    let t_items = []
    if (response.ok) {
      const creditLogsApiRes = await response.json()
      console_log("creditLogsApiRes::::", creditLogsApiRes)
      t_items = creditLogsApiRes['log_list']
      setItems(t_items);

      const suscription_detail = creditLogsApiRes['suscription_detail']
      if (!empty(suscription_detail)) {
        setPaypalSubscriptionDetail(suscription_detail)
      } else {
        setPaypalSubscriptionDetail({})
      }
    }
    setTableItems(t_items)
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTableData(t_items.slice(firstPageIndex, lastPageIndex));
  }, [updateTimestamp]);

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Added by</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Balance</TableCell>
              <TableCell>When</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (currentTableData.length > 0) ? (
                currentTableData.map((item) =>
                  <TableRow key={item.id}>
                    <TableCell>{item.amount}</TableCell>
                    <TableCell>{item.from_name ?? 'Owner'}</TableCell>
                    <TableCell>{item.note}</TableCell>
                    <TableCell>{item.to_name}</TableCell>
                    <TableCell>{item.to_balance}</TableCell>
                    <TableCell>{moment(item.createdAt).format('LLL')}</TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow key={`no-data`}>
                  <TableCell colSpan={7} align="center">No log data</TableCell>
                </TableRow>
              )
            }

          </TableBody>
        </Table>
        <div style={{ marginTop: '5px' }}></div>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={PageSize}
          totalCount={tableItems.length}
          onPageChange={page => setCurrentPage(page)}
        />
      </TableContainer>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  paypalBtnBox: {
    display: 'inline-block',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  paypalHideBtn: {
    cursor: 'pointer',
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '4px',
    paddingBottom: '4px',
    marginLeft: '2px',
  },
  modalTitleBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
}));

const CreditLogForm = (props) => {
  const { open, setOpen, selectedUser, updateTimestamp, setUpdateTimestamp, setSelectedUser, apiLoading, setApiLoading } = props;
  const user = useSelector((state) => state.session.user);

  const classes = useStyles();
  const defaultFormData = {
    charge_type: 'credit',
    amount: '0.00',
    note: ''
  }
  const [item, setItem] = useState(defaultFormData)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleAddCredit = async () => {
    const url = `/api/escrowLogs`;
    const payload = {
      user_id: user.id,
      user_name: (user.name ?? user.email),
      selectedUserId: selectedUser['id'],
      ...item,
    }
    if (Number(payload['amount']) <= 0) {
      setToastMessage(`Invalid amount`);
      setSnackbarOpen(true);
      return false
    }
    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    setApiLoading(false)
    if (response.ok) {
      setToastMessage(`Credit has been added successfully`);
      setSnackbarOpen(true);
      setUpdateTimestamp(get_utc_timestamp())
      setItem(defaultFormData)
      const userInfo = await response.json();
      setSelectedUser(userInfo)
    } else {
      setToastMessage(`Unknown error`);
      setSnackbarOpen(true);
    }
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        autoHideDuration={1500}
        message={toastMessage}
      />

      <div style={{ display: 'inline-block', background: '#ffffff', paddingRight: '20px' }}>
        <SelectField
          margin="normal"
          value={item.charge_type || 'credit'}
          emptyValue={null}
          onChange={(event) => setItem({ ...item, charge_type: event.target.value })}
          data={[{ id: 'credit', name: 'Add Credit' }, { id: 'invoice', name: 'Add Invoice' }]}
          label=''
        />
      </div>
      <div>
        <TextField
          margin="normal"
          value={item.amount || ''}
          onChange={(event) => setItem({ ...item, amount: event.target.value })}
          label={`Amount`}
          inputProps={{ type: 'number', step: 0.01 }}
        />
      </div>
      <div>
        <TextField
          margin="normal"
          label="Note"
          multiline={true}
          rows={3}
          value={item.note || ''}
          onChange={(event) => setItem({ ...item, note: event.target.value })}
          style={{ width: '100%' }}
        />
      </div>
      <div className={classes.buttons} style={{ paddingTop: '15px', maxWidth: '400px', margin: 'auto' }}>
        <Button type="button" color="primary" variant="contained" onClick={() => handleAddCredit()} disabled={apiLoading}>
          Add
        </Button>
        <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
          Close
        </Button>
      </div>
    </>
  )
}

const EscrowLogsModal = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.session.user);
  const classes = useStyles();

  const { open, setOpen, item, setItem, modalType = "user" } = props;
  const [updateTimestamp, setUpdateTimestamp] = useState(0)

  useEffect(() => {
    setPaypalSubscriptionDetail(null)
  }, [open]);

  const handleCreditModalClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    getUserEscrowCarBalance()
  }, [open]);

  const [apiLoading, setApiLoading] = useState(false)
  const getUserEscrowCarBalance = async () => {
    if (user.administrator) {
      console.log(`getUserEscrowCarBalance is not available for admin::::`)
      return false
    }
    console.log("selectedUser::", item)
    let url = `/api/getUserEscrowCarBalance/` + item['id'];
    if (modalType === "self") {
      url = `/api/getUserEscrowCarBalance`
    }
    const response = await api_call(url);
    if (response.ok) {
      const apiRes = await response.json()
      const userEscrowCarBalance = apiRes['userEscrowCarBalance']
      if (userEscrowCarBalance) {
        setItem({ ...item, monthly_cost: userEscrowCarBalance })
        if (userEscrowCarBalance > 0) {
          onSubmitEscrowPlanPrice(userEscrowCarBalance)
        }
      }
    }
  }

  ////////////////////////////////////paypal subscription modal///////////////////////////////////////////
  const [planId, setPlanId] = useState("");
  const [paypalAccessToken, setPaypalAccessToken] = useState("");
  const [paypalSubscriptionDetail, setPaypalSubscriptionDetail] = useState(null);
  const [oldMonthlyCost, setOldMonthlyCost] = useState(Number(item?.monthly_cost));

  const showPaypalPaymentModal = async () => {
    if (apiLoading) {
      return false
    }
    let planInfo = await getMoovetraxMonthlyPlan()
    console_log("PlanInfo:::", planInfo)
    if (!empty(planInfo)) {
      setPlanId(planInfo['id'])
      setPaypalPaymentModalOpen(true)
    }
  }
  const getMoovetraxMonthlyPlan = async () => {
    let planInfo = await createMoovetraxMonthlyPlan();
    return planInfo;
  }
  const createMoovetraxMonthlyPlan = async () => {
    let url = "/api/payment/paypal/create-plan"
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ itemId: item.id, itemType: itemType }),
    });

    if (response.ok) {
      let payalResponse = await response.json()
      if (payalResponse['id']) {
        setItem({ ...item, plan_id: payalResponse['id'] })
      }
      return payalResponse;
    }
    return false;
  }

  var cancellingSubscription = false;
  const onClickCancelSubscription = async (subscription_id) => {
    if (cancellingSubscription) {
      return false;
    }
    if (window.confirm("Are you sure you want to cancel subscription?")) {
      var url = `/api/payment/paypal/cancel-subscription`
      const post_data = {
        subscription_id: subscription_id,
        itemType: itemType
      }
      cancellingSubscription = true;
      const response = await api_call(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(post_data),
      });

      cancellingSubscription = false;
      if (response.ok) {
        let responseObj = await response.json()
        console_log("Cancel subscription result:::", responseObj)
        setPaypalSubscriptionDetail({})
      } else {
        alert("Error encounted during canceling subscription")
      }
    }
  }

  const [paypalPaymentModalOpen, setPaypalPaymentModalOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const showToast = (msg) => {
    setToastMessage(msg)
    setSnackbarOpen(true)
  }

  const checkShowPaypalButton = () => {
    return true
  }

  const onSubmitEscrowPlanPrice = async (monthly_cost = null) => {
    //console.log('event:::', event.target.value)
    if (empty(monthly_cost)) {
      monthly_cost = Number(item?.monthly_cost)
    }
    console.log("monthly_cost:::", monthly_cost)
    if (monthly_cost <= 0) {
      window.alert(`Monthly cost must be greater than 0`)
      return false
    }
    console.log("oldMonthlyCost:::", oldMonthlyCost)
    if (oldMonthlyCost === monthly_cost) {
      return false
    }

    /////////////////////////////////////////////////////////////
    setApiLoading(true)
    let url = "/api/payment/paypal/update-plan-price"
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ itemId: item.id, itemType: itemType, monthly_cost: monthly_cost }),
    });

    setApiLoading(false)
    // if (response.ok) {
    // } else {
    //  showToast("Invalid request")
    // }
  }

  const getLastBilledDate = () => {
    const last_billed = item?.last_billed
    if (last_billed) {
      const lastBilledDate = moment(last_billed).format("D")
      return lastBilledDate
    }
    return ""
  }

  const onClickAllCarToEscrow = async () => {
    setApiLoading(true)
    let url = "/api/users/set-devices-billing-source"
    const payload = {
      user_id: item.id,
      billing_source: 'escrow'
    }
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    setApiLoading(false)
    if (response.ok) {
      const apiRes = await response.json()
      showToast("All Cars Moved to Escrow")
    } else {
      let errorMsg = "Invalid request"
      const errorRes = response
      if (errorRes['error']) {
        errorMsg = errorRes['error']
      }
      showToast(errorMsg)
    }
  }

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Modal
        open={open}
        onClose={handleCreditModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Box sx={modalInnerStyle}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={snackbarOpen}
              onClose={() => { setSnackbarOpen(false) }}
              autoHideDuration={1500}
              message={toastMessage}
            />

            <Box className='m-block' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Box className={classes.modalTitleBox}>
                <Box sx={{ mr: 8, mb: 2 }}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <span>Balance:&nbsp;{priceFormat(item?.escrow_balance ?? 0)}</span>
                  </Typography>
                </Box>
                <Box sx={{ mr: 2, mb: 2 }}>
                  <TextField
                    id="outlined-required"
                    label="Amount"
                    value={priceFormat(item?.monthly_cost ?? 0)}
                    onChange={(event) => setItem({ ...item, monthly_cost: event.target.value })}
                    type="number"
                    inputProps={{ step: 0.01 }}
                    style={{ width: "100px" }}
                    //variant="filled"
                    onBlur={(event) => onSubmitEscrowPlanPrice()}
                  />
                </Box>
                {
                  (paypalSubscriptionDetail !== null) ? (
                    <Box sx={{ mr: 2, mb: 2 }}>
                      {
                        ((paypalSubscriptionDetail['status'] === 'ACTIVE' || paypalSubscriptionDetail['status'] === 'SUSPENDED')) ? (
                          <div className={classes.paypalBtnBox}>
                            <span onClick={() => onClickCancelSubscription(paypalSubscriptionDetail['id'])} style={paypalBtnStyle}>Cancel Subscription</span>
                          </div>
                        ) : (
                          <>
                            {(checkShowPaypalButton()) && (
                              <div className={classes.paypalBtnBox}>
                                <span onClick={() => showPaypalPaymentModal()} style={{ ...paypalBtnStyle, opacity: apiLoading ? 0.5 : 1, cursor: apiLoading ? 'not-allowed' : 'pointer' }}>
                                  <img src="/images/paypal-logo.png" style={{ width: '60px', height: 'auto' }} />
                                </span>
                              </div>
                            )}
                          </>
                        )
                      }
                    </Box>
                  ) : (<></>)
                }

                {
                  (!user.administrator) ?
                    (
                      <>
                        <Box sx={{ mr: 2, mb: 2 }}>
                          <Button
                            type="button"
                            variant="outlined"
                            color="primary"
                            startIcon={<ArrowRightOutlined />}
                            style={{ borderRadius: '80px', height: '30px', textTransform: 'none', lineHeight: 1 }}
                            disabled={apiLoading}
                            onClick={() => onClickAllCarToEscrow()}
                          >
                            All Cars to Escrow
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <></>
                    )
                }
              </Box>

              <Box className='m-block' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography className='pe-5 py-1' id="modal-modal-title1" variant="h7" component="h6">
                  Start:&nbsp;<span>{moment(item?.createdAt).format('LL')}</span>
                </Typography>

                <Typography className='pe-3 py-1' id="modal-modal-title2" variant="h7" component="h6">
                  Billed on:&nbsp;<span>{getLastBilledDate()}</span>
                </Typography>
              </Box>
            </Box>

            <Typography id="creditLogTableTitle" className='mt-3'>
              Escrow Logs
            </Typography>
            <div className='mt-3'>
              <CreditLogsTable
                open={open}
                setOpen={setOpen}
                selectedUser={item}
                setUser={setItem}
                updateTimestamp={updateTimestamp}
                setUpdateTimestamp={setUpdateTimestamp}
                paypalSubscriptionDetail={paypalSubscriptionDetail}
                setPaypalSubscriptionDetail={setPaypalSubscriptionDetail}
                modalType={modalType}
              />

              <>
                {
                  (user.administrator && modalType !== 'self') ? (
                    <>
                      <hr style={{ marginTop: '2.5rem' }} />

                      <div className='' style={{ marginTop: '-60px' }}>
                        <CreditLogForm
                          open={open}
                          setOpen={setOpen}
                          selectedUser={item}
                          updateTimestamp={updateTimestamp}
                          setUpdateTimestamp={setUpdateTimestamp}
                          setSelectedUser={setItem}
                          modalType={modalType}
                          apiLoading={apiLoading}
                          setApiLoading={setApiLoading}
                        />
                      </div>
                    </>) : (<></>)
                }
              </>
            </div>

            <>
              {
                (!user.administrator) ? (
                  <>
                    <div className={classes.buttons} style={{ paddingTop: '15px', maxWidth: '400px', margin: 'auto' }}>
                      <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
                        Close
                      </Button>
                    </div>
                  </>) : (<></>)
              }
            </>

          </Box>
        </Box>
      </Modal>

      <>
        <PaypalSubscriptionModal
          open={paypalPaymentModalOpen}
          setOpen={setPaypalPaymentModalOpen}
          item={item}
          setItem={setItem}
          plan_id={planId}
          updateTimestamp={updateTimestamp}
          setUpdateTimestamp={setUpdateTimestamp}
          itemType={itemType}
        />
      </>
    </>
  )
}

export default EscrowLogsModal;
