import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Container from '@material-ui/core/Container';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Checkbox, Box, Link, Modal, Grid, Toolbar, IconButton, InputLabel, Select, MenuItem, ButtonBase,
} from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from './EditItemView';
import EditAttributesView from './attributes/EditAttributesView';
import SelectField from './form/SelectField';
import deviceCategories from './common/deviceCategories';
import LinkField from './form/LinkField';
import { prefixString } from './common/stringUtils';
import { useTranslation } from './LocalizationProvider';
import useDeviceAttributes from './attributes/useDeviceAttributes';
import { Button } from '@material-ui/core';
import CreditLogsModal from './settings/CreditLogsModal';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import CarTypeTab from './components/CarTypeTab';
import SmartcarDeviceEditPage from './SmartcarDeviceEditPage';
import LockUnlockSettingModal from './settings/LockUnlockSettingModal';
import { addItemToArray, api_call, console_log, empty, get_utc_timestamp_ms, isGpsMT2VProtocolDevice, is_null, removeItemFromArray } from './helpers/untils';
import { APP_NAME, DISTANCE_UNIT_LIST, GPS_ID_PLACEHOLDER, ICCID_PLACEHOLDER, IMAGE_CACHE_VERSION, TESLA_PERMISSION_URL } from './config/constant';
import ConfirmDialog from './components/ConfirmDialog';
import { Stack } from '@mui/material';
import { getIsAdmin } from './common/selectors';
import SearchIcon from '@material-ui/icons/Search';
import PaypalPaymentModal from './settings/PaypalPaymentModal';
import { layoutActions } from './store';
import CarTypeTabBar from './components/CarTypeTabBar';
import { setSettingPersist } from './helpers/misc';
import TeslaMoovetraxInfoModal from './settings/TeslaMoovetraxInfoModal';
import FrontSiteNavbar from './FrontSiteNavbar';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    paddingTop: '50px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '130px',
    },
  },
  details: {
    flexDirection: 'column',
  },
  checkboxColumn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  imgSmall: {
    width: '50px',
    height: '42px',
    marginLeft: '5px',
    marginTop: '16px',
    marginBottom: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  normalColumn: {
    padding: '8px 0px'
  },
  normalColumnMargin: {
    marginTop: '4px'
  }
}));

const OemDeviceNoAuthPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { id } = useParams();
  const history = useHistory()
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);

  const deviceAttributes = useDeviceAttributes(t);
  const user = useSelector((state) => state.session.user);
  const [item, setItem] = useState();

  const showToast = (msg) => {
    alert(`${msg}`)
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const [openTeslaMoovetraxInfoModal, setOpenTeslaMoovetraxInfoModal] = useState(false)

  const onClickTesla = async () => {
    setOpenTeslaMoovetraxInfoModal(true)
  }

  const goTeslaLogin = async () => {
    const response = await api_call(`/api/tesla/get-login-url`);
    if (response.ok) {
      const res = await response.json()
      console_log("Tesla res:::", res)
      setSettingPersist('smartcar_auth_source', 'signup')
      window.location.href = res.link
    } else {
      alert("Tesla authentication error")
    }
  }

  const onClickSmartCar = async () => {
    const response = await api_call(`/api/smartcar/get-login-url`);
    if (response.ok) {
      const res = await response.json()
      console_log("smartcar res:::", res)
      setSettingPersist('smartcar_auth_source', 'signup')
      window.location.href = res.link
      //history.push('/smartcar-device')
    } else {
      alert("Smartcar authentication error")
    }
  }
  const [shoppingCartLink, setShoppingCartLink] = useState("")
  return (
    <>
      <Container maxWidth="xs" className={classes.container}>
        <FrontSiteNavbar
          setShoppingCartLink={setShoppingCartLink}
        />
        <Stack direction={`row`} justifyContent={`center`} alignItems={`center`} sx={{ width: '100%', height: '100%' }}>
          <Accordion defaultExpanded style={{ width: '100%', flex: 1 }}>
            <>
              <AccordionDetails>
                <Box sx={{ width: '100%', py: 0 }}>
                  <Stack direction={`column`} justifyContent={`center`} alignItems={`center`} spacing={6}>
                    <Typography variant="h5" style={{fontWeight: '600', marginTop:20}}>For MooveTrax Device. </Typography>
                    <Typography variant="subtitle1" style={{marginTop:10}}>Login into MooveTrax and Edit</Typography>
                    <ButtonBase onClick={() => onClickTesla()} disableRipple={true}>
                      <img src="/assets/img/tesla_icon.jpg" width="180" style={{ maxWidth: '100%', height: 'auto' }} alt="Tesla Cars" />
                    </ButtonBase>

                    <Stack direction={`column`} justifyContent={`center`} alignItems={`center`} spacing={1}>
                      <Typography variant="subtitle1">Other Cars</Typography>
                      <ButtonBase onClick={() => onClickSmartCar()} disableRipple={true}>
                        <img src="/assets/img/oem_icon.jpg" width="180" style={{ maxWidth: '100%', height: 'auto' }} alt="Other OEM Cars" />
                      </ButtonBase>
                    </Stack>
                  </Stack>
                </Box>
              </AccordionDetails>
            </>
          </Accordion>
        </Stack>
      </Container>

      <>
        <TeslaMoovetraxInfoModal
          open={openTeslaMoovetraxInfoModal}
          setOpen={setOpenTeslaMoovetraxInfoModal}
          onOk={() => goTeslaLogin()}
        />
      </>
    </>
  );
};

export default OemDeviceNoAuthPage;
