import React, { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';

// third-party
import ReactApexChart from 'react-apexcharts';
import { Card, Grid, IconButton, InputLabel, TextField } from '@material-ui/core';
import { addItemToArray, api_call, console_log, empty } from '../../helpers/untils';
import DeviceAutoCompleteDropdown from '../../components/DeviceAutoCompleteDropdown';
import { useSelector } from 'react-redux';
import moment from 'moment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { API_BASE_URL } from '../../config/constant';
import { getBetweenMonthsForDateRange } from '../../helpers/misc';

const todayDate = moment().format('YYYY-MM-DD')
const lastMonthEndDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')

// chart options
const columnChartOptions = {
  chart: {
    type: 'bar',
    height: 430,
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '30%',
      borderRadius: 4,
      // colors: {
      //   ranges: [{
      //     from: -999999999,
      //     to: 0,  
      //     color: '#ff4560' // red color for negative values
      //   }, {
      //     from: 0,
      //     to: 999999999,
      //     color: '#00e396' // green color for positive values
      //   }]
      // },
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 8,
    colors: ['transparent']
  },
  xaxis: {
    categories: getBetweenMonthsForDateRange(lastMonthEndDate, todayDate) // ['Jan', 'Feb', 'Mar', 'Apr']
  },
  yaxis: {
    title: {
      text: '$'
    }
  },
  fill: {
    opacity: 1
  },
  tooltip: {
    y: {
      formatter(val, obj) {
        //console_log(`obj:::`, obj)
        return `$ ${val}`;
      }
    }
  },
  legend: {
    show: false
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        yaxis: {
          show: false
        }
      }
    }
  ]
};

// ==============================|| SALES COLUMN CHART ||============================== //

const IncomeExpenseChartBlock = (props) => {
  const { deviceId, deviceItem, showToast } = props;

  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const warning = theme.palette.warning.main;
  const primaryMain = theme.palette.primary.main;
  const successDark = theme.palette.success.dark;

  const initialSeries = [
    // {
    //   name: 've_plus',
    //   data: [180, 90, 135, 114, 120, 145]
    // },
    // {
    //   name: 've_minus',
    //   data: [120, 45, 78, 150, 168, 99]
    // }
  ];
  const [series, setSeries] = useState(initialSeries);

  const handleVeChange = (event) => {
    const form_data = { ...formData }
    const ve = form_data.ve
    let ve_arr = ve.split(',')
    const field_name = event.target.name
    const checked = event.target.checked
    if (checked) {
      ve_arr = addItemToArray(ve_arr, field_name)
    } else {
      ve_arr = ve_arr.filter((item) => item !== field_name)
    }
    form_data['ve'] = ve_arr.join(',')
    setFormData(form_data)
  };

  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [options, setOptions] = useState(columnChartOptions);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  const [apiLoading, setApiLoading] = useState(false)
  const [pageData, setPageData] = useState({})

  const devices = useSelector((state) => state.devices.items);
  //console_log(`devices:::::`, devices)

  const getDeviceList = () => {
    const arr = []
    for (let k in devices) {
      arr.push(devices[k])
    }
    return arr
  }
  const userDeviceList = getDeviceList()

  const [deviceList, setDeviceList] = useState([])
  useEffect(() => {
    setDeviceList(userDeviceList)
  }, [userDeviceList.length])

  const defaultSelectedDeviceIds = [deviceItem.id]
  const [selectedDeviceIds, setSelectedDeviceIds] = useState(defaultSelectedDeviceIds)

  const defaultFormData = {
    from: moment().subtract(1, 'month').endOf('month'),
    to: moment(),
    ve: 've_plus,ve_minus'
  }
  const [formData, setFormData] = useState(defaultFormData)

  const onChnageDate = (e) => {
    console_log(`onChnageDate e.target.value:::`, e.target.value)
    const file_name = e.target.name
    const _dob = moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL)
    console_log(`onChnageDob _dob:::`, _dob)
    const form_data = { ...formData }
    form_data[file_name] = _dob
    setFormData(form_data)
  }

  const resetChartXaxis = (setFromFront = false) => {
    const form_data = { ...formData }

    const chart_options = { ...options }
    if (setFromFront) {
      const xaxis_categories = getBetweenMonthsForDateRange(form_data.from.format("YYYY-MM-DD"), form_data.to.format("YYYY-MM-DD"))
      console_log(`xaxis_categories:::`, xaxis_categories)
      chart_options.xaxis.categories = xaxis_categories
    } else {
      chart_options.xaxis.categories = []
    }
    setOptions(chart_options)
  }

  const loadPageData = async () => {
    const form_data = { ...formData }

    setApiLoading(true)
    let url = `/api/device-pl/get-chart-data`;
    const payload = {
      selectedDeviceIds: selectedDeviceIds,
      from: form_data.from.format("YYYY-MM-DD"),
      to: form_data.to.format("YYYY-MM-DD"),
      ve: form_data.ve
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    setApiLoading(false)

    if (response.ok) {
      let apiRes = await response.json()
      setPageData(apiRes)

      const plChartData = apiRes.plChartData
      if (plChartData.length === 0) {
        resetChartXaxis(true)
      } else {
        resetChartXaxis(false)
      }

      setSeries(plChartData)
    } else {
      showToast("Error encounted")
    }
  }

  useEffect(() => {
    loadPageData()
  }, [selectedDeviceIds, formData])

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [warning, primaryMain],
      xaxis: {
        labels: {
          style: {
            colors: [secondary, secondary, secondary, secondary, secondary, secondary]
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      tooltip: {
        theme: 'light'
      },
      plotOptions: {
        bar: {
          columnWidth: xsDown ? '60%' : '30%'
        }
      }
    }));
  }, [primary, secondary, line, warning, primaryMain, successDark, xsDown, selectedDeviceIds]);

  const exportCsv = () => {
    handleExportCsv()
  }

  const handleExportCsv = () => {
    const form_data = { ...formData }
    const payload = {
      selectedDeviceIds: selectedDeviceIds.join(','),
      from: form_data.from.format("YYYY-MM-DD"),
      to: form_data.to.format("YYYY-MM-DD"),
      ve: form_data.ve
    }

    let query = new URLSearchParams(payload);

    const exportUrl = `${API_BASE_URL}/api/device-pl/export-csv?${query.toString()}`
    console_log(`exportUrl::::`, exportUrl)
    window.location.href = exportUrl
  }

  return (
    <>
      <Box sx={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto', overflowX: 'hidden' }}>
        <Stack direction={`${xsDown ? 'column' : 'row'}`} alignItems={`${xsDown ? 'flex-start' : 'center'}`} justifyContent={`${xsDown ? 'flex-start' : 'space-between'}`} spacing={2}>
          <Box sx={{ width: xsDown ? '100%' : '300px' }}>
            <DeviceAutoCompleteDropdown
              label={`Select Devices`}
              definitions={deviceList}
              value={selectedDeviceIds}
              setValue={setSelectedDeviceIds}
              multiple={true}
            />
          </Box>
          <Box sx={{ width: xsDown ? '100%' : '360px' }}>
            <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`flex-end`} spacing={1}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel shrink>From</InputLabel>
                    <TextField
                      variant="filled"
                      label=""
                      type="date"
                      name="from"
                      value={formData.from.format("YYYY-MM-DD")}
                      onChange={(e) => onChnageDate(e)}
                      fullWidth
                      style={{ width: '100%' }}
                      inputProps={{
                        max: todayDate
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel shrink>To</InputLabel>
                    <TextField
                      variant="filled"
                      label=""
                      type="date"
                      name="to"
                      value={formData.to.format("YYYY-MM-DD")}
                      onChange={(e) => onChnageDate(e)}
                      fullWidth
                      style={{ width: '100%' }}
                      inputProps={{
                        max: todayDate
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <IconButton onClick={() => exportCsv()} title="Export" disabled={selectedDeviceIds.length === 0}>
                <FileDownloadIcon />
              </IconButton>
            </Stack>
          </Box>
        </Stack>

        <div id="chart">
          <ReactApexChart options={options} series={series} type="bar" height={xsDown ? 200 : 360} />

          <Box sx={{ width: '100%' }}>
            <Stack direction={`row`} justifyContent={`center`} alignItems={`center`}>
              <FormControl component="fieldset">
                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox checked={formData.ve.includes('ve_plus')} onChange={handleVeChange} name="ve_plus" />}
                    label="ve+"
                  />
                  <FormControlLabel
                    control={<Checkbox color="warning" checked={formData.ve.includes('ve_minus')} onChange={handleVeChange} name="ve_minus" />}
                    label="ve-"
                  />
                </FormGroup>
              </FormControl>
            </Stack>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default IncomeExpenseChartBlock;
