import React from 'react';
import { useState } from 'react';
import CameraUploadModal from './components/CameraUploadModal/CameraUploadModal';

const TestSite2 = () => {
  const [open, setOpen] = useState(true)
  
  const onPickupPhoto = (imageDataUrl) => {
    console.log(`imageDataUrl::::`, imageDataUrl)
    uploadImage(imageDataUrl)
  }

  const uploadImage = async (imageDataURL) => {
    if (imageDataURL) {
      try {
        const response = await fetch('http://localhost:5000/upload', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ image: imageDataURL })
        });
        const result = await response.json();
        console.log('Image uploaded successfully:', result);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  return (
    <>
      <button type="button" onClick={() => setOpen(true)}>Test Camera</button>
      <CameraUploadModal
        open={open}
        setOpen={setOpen}
        onPickupPhoto={onPickupPhoto}
      />
    </>
  )
}

export default TestSite2;
