import React, { useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField,
} from '@material-ui/core';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useTranslation } from '../LocalizationProvider';

const useStyles = makeStyles(() => ({
  paper: { minWidth: "300px" },
}));

const RePriceDeviceDialog = (props) => {
  const { open, onResult } = props
  const t = useTranslation();
  const classes = useStyles()

  const defaultValue = 12.5
  const [item, setItem] = useState({ price: defaultValue });

  return (
    <Dialog open={open} maxWidth="xs" classes={{ paper: classes.paper}}>
      <DialogContent>
        <TextField
          margin="normal"
          type="number"
          value={item.price}
          onChange={(event) => setItem({ ...item, price: event.target.value })}
          label="New Price For ALL Devices"
          placeholder=' '
          variant="filled"
          fullWidth
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          disabled={item.price <= 0}
          onClick={() => onResult(item)}
        >
          Submit
        </Button>
        <Button
          autoFocus
          onClick={() => onResult(false)}
        >
          {t('sharedCancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RePriceDeviceDialog;
