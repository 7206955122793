import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  makeStyles, FormControlLabel, Checkbox, TextField, Button, Grid, IconButton, useTheme, useMediaQuery,
} from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SmsIcon from '@mui/icons-material/Sms';
import WifiCallingIcon from '@mui/icons-material/WifiCalling';
import EditIcon from '@mui/icons-material/Edit';
import CreateIcon from '@material-ui/icons/Create';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { addItemToArray, api_call, console_log, empty } from '../helpers/untils';
import Lightbox from 'react-image-lightbox';
import UnlimitedTextBoxList from './unlimitedTextBoxList';
import UnlimitedStaticTextBoxList from './unlimitedStaticTextBoxList';
import { Box, Stack, Typography } from '@mui/material';
import ModalVideo from 'react-modal-video';
import { APP_NAME, SHRED_UPLOAD_VIDEO_ID } from '../config/constant';
import ConfirmDialog from '../components/ConfirmDialog';
import ShareTextDialog from './ShareTextDialog';
import { getDefaultSharedTextForClipboard, trimSharedTextTemplate } from '../helpers/misc';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
    minWidth: '800px',
    [theme.breakpoints.down('md')]: {
      minWidth: '0px',
    },
  },
  shareSettingFormContainer: {
    display: 'block',
    width: '100%',

  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formRowInline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '30px',
    paddingTop: '6px',
    paddingBottom: '6px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginRight: '0px',
      '& > *': {
        display: 'block',
      },
    },
  },
  formRowInlineLabel: {
    paddingRight: 20,
    minWidth: '160px',
    [theme.breakpoints.down('md')]: {
      minWidth: '0px',
    },
  },
  formRowInlineLabelAuto: {
    paddingRight: 20,
  },
  formRowInlineLabelAfter: {
    paddingLeft: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  formRowInlineController: {
    flex: 1
  },
  minutesInput: {
    width: '110px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  responsibleFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'block',
    },
  },
  checkResultWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '6px',
    paddingBottom: '6px',
    marginRight: '15px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },
  },
  checkResultBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '20px',
  },
  checkResultSuccessIcon: {
    color: "#4CAF50",
  },
  checkResultFailedIcon: {
    color: "#CC2222",
  },
  textList: {
    display: 'block',
    maxWidth: '300px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  mtHalfText: {
    marginTop: '8px',
  },
  messageWrapper: {
    display: 'block',
    maxWidth: '676px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  formRowInlineSMS: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '15px',
    paddingTop: '6px',
    paddingBottom: '6px',
    minWidth: '150px',
    maxWidth: '250px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginRight: '0px',
      '& > *': {
        display: 'block',
      },
    },
  },
  formRowInlineLabelSMS: {
    paddingRight: 20,
    minWidth: '60px',
    [theme.breakpoints.down('md')]: {
      minWidth: '0px',
    },
  },
  formRowInlineControllerSMS: {
    flex: 1
  },
  formRowInlineBtnSMS: {
    marginLeft: '15px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
      marginTop: '15px',
    },
  },
  uploadFileObject: {
    opacity: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    cursor: 'pointer',
    zIndex: 1,
    width: '100%',
    height: '100%'
  },
  imagePreview: {
    width: '103px',
    height: 'auto',
    cursor: 'pointer',
    marginTop: '8px',
    marginBottom: '8px',
  },
  flex1: {
    flex: 1
  },
  shareSettingBlock: {
    border: '1px solid #d4d4d4',
    padding: '8px 16px',
    marginBottom: '16px',
    borderRadius: '4px'
  },
  shareSettingBlockNoBorder: {
    padding: '8px 16px',
  },
  mobileBlock: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  aiPencentInput: {
    width: '60px'
  }
}));

const TEMP_SUFFIX = ""
const LIMIT_INPUT_PROPS = {
  maxLength: 40,
  title: "Max length is 40 characters"
}

const AVAIL_UPLOAD_PRIMARY_VALUES = [
  "Picture Of License" + TEMP_SUFFIX,
  "Selfie With License" + TEMP_SUFFIX
]

const UNLOCK_UPLOAD_PRIMARY_VALUES = [
  "Selfie With Car License Plate" + TEMP_SUFFIX
]

const UNKILL_UPLOAD_PRIMARY_VALUES = [
  "Selfie Inside Car" + TEMP_SUFFIX
]

const ShareSettingForm = (props) => {
  const { snackbarOpen, setSnackbarOpen, toastMessage, setToastMessage, history, selectedDeviceId, selectedDevice, shareUrl, onClickSaveSetting, apiLoading, setApiLoading, generatedUrl, showToast, copyTextClipboard, onClickSMS, onClickVoice } = props;
  ///console.log("history1111::::", history)
  const m_history = useHistory()

  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const user = useSelector((state) => state.session.user);

  const classes = useStyles();
  useEffect(() => {
    initShareSettings()
  }, [selectedDevice])

  const defaultFormData = {
    share_setting_enabled: false,
    avail_approve_type: 'manual',
    unlock_approve_type: 'auto',
    unkill_approve_type: 'auto',
    avail_car: true,
    manual_approve_email: user.email,
    avail: [],
    unlock: [],
    avail_upload_minutes_before: 1440,
    alert_guest: true,
    alert_guest_minutes_before: 1440,
    alert_guest_auto_send_message: "",
    unkill_by: 'renter',
    avail_ai_percent: 80,
    unlock_ai_percent: 80,
    unkill_ai_percent: 80
  }
  const [formData, setFormData] = useState(defaultFormData)
  const [availPicList, setAvailPicList] = useState([])
  const [unlockPicList, setUnlockPicList] = useState([])
  const [unkillPicList, setUnkillPicList] = useState([])

  const initShareSettings = () => {
    console.log("selectedDevice:::::", selectedDevice)
    let shareSettings = selectedDevice?.share_settings
    if (!empty(shareSettings)) {
      shareSettings = JSON.parse(shareSettings)
    } else {
      shareSettings = {}
    }
    console.log("shareSettings:::::", shareSettings)
    const form_data = {
      ...defaultFormData,
      ...shareSettings,
      defaultPrimaryUploadApplied: shareSettings['defaultPrimaryUploadApplied'] ? true : false
    }
    setFormData({ ...form_data })

    if (form_data['avail']) {
      setAvailPicList(form_data['avail'])
    }
    if (form_data['unlock']) {
      setUnlockPicList(form_data['unlock'])
    }
    if (form_data['unkill']) {
      setUnkillPicList(form_data['unkill'])
    }
    if (form_data['sharedTextTemplate']) {
      setSharedTextTemplate(trimSharedTextTemplate(form_data['sharedTextTemplate']))
    }
  }

  const onChangeFormInput = (event, field_name) => {
    const value = event.target.value
    if (field_name === 'avail_approve_type' || field_name === 'unlock_approve_type' || field_name === 'unkill_approve_type') {
      if (value === 'ai') {
        setConfirmAction(`${field_name}:${value}`)
        setConfirmText(`AI matching is done by computer and can be very wrong, AI can not tell the difference between a person holding a picture and just a picture of a picture.
        Do you want the proceed?
        `)
        setShowConfirmModal(true);
        return false
      }
    }
    let form_data = { ...formData }
    form_data[field_name] = value
    setFormData(form_data)
  }
  const onChangeFormCheckBox = (event, field_name) => {
    let form_data = { ...formData }
    form_data[field_name] = event.target.checked
    setFormData(form_data)
  }

  const reArrangePicList = (pic_list = [], type = "") => {
    let new_pic_list = []
    let primary_pic_list = []
    if (type === "avail") {
      primary_pic_list = [...AVAIL_UPLOAD_PRIMARY_VALUES]
    }
    else if (type === "unlock") {
      primary_pic_list = [...UNLOCK_UPLOAD_PRIMARY_VALUES]
    }
    else if (type === "unkill") {
      primary_pic_list = [...UNKILL_UPLOAD_PRIMARY_VALUES]
    }

    for (let k in primary_pic_list) {
      const pic = primary_pic_list[k]
      if (pic_list.includes(pic)) {
        new_pic_list = addItemToArray(new_pic_list, pic)
      }
    }

    for (let j in pic_list) {
      const pic = pic_list[j]
      if (primary_pic_list.includes(pic)) {
        //continue
      } else {
        new_pic_list = addItemToArray(new_pic_list, pic)
      }
    }
    return new_pic_list
  }

  const getSettingTextData = () => {
    const form_data = { ...formData }
    const file_field_name_list = [
      "avail_auto_send_picture",
      "unlock_auto_send_picture",
      "unkill_auto_send_picture"
    ]
    const text_field_data = {}
    for (let k in form_data) {
      if (!file_field_name_list.includes(k)) {
        text_field_data[k] = form_data[k]
      }
    }
    text_field_data['avail'] = reArrangePicList(availPicList, 'avail')
    text_field_data['unlock'] = reArrangePicList(unlockPicList, 'unlock')
    text_field_data['unkill'] = reArrangePicList(unkillPicList, 'unkill')

    text_field_data['sharedTextTemplate'] = sharedTextTemplate
    return text_field_data
  }

  const onCloseShareSettingForm = (delay = true) => {
    //return false // tempoaray
    const url = `/settings/links`
    if (delay) {
      setTimeout(() => {
        if (history) {
          history.push(url);
        } else {
          m_history.push(url);
        }
      }, 2000)
    } else {
      if (history) {
        history.push(url);
      } else {
        m_history.push(url);
      }
    }
  }

  const handleSaveSetting = async (useSecondDomain = true) => {
    if (formData['share_setting_enabled']) {
      if (empty(formData['manual_approve_email'])) {
        showToast(`Check errors in Red`);
        return false
      }
    }

    //const url = `/api/links/updateSetting`;
    console.log("setting data:::", formData)
    const textFormData = getSettingTextData(formData)
    const shareSetting = {
      ...textFormData,
      useSecondDomain: useSecondDomain,
      deviceId: selectedDevice['id'],
    }
    // const payload = {
    //   device_id: selectedDevice['id'],
    //   shareSetting: JSON.stringify(shareSetting),
    // }

    var form_data = new FormData();
    //form_data.append("shareUrl", shareUrl);
    form_data.append("shareSetting", JSON.stringify(shareSetting));
    form_data.append("user_id", user.id);
    form_data.append("device_id", selectedDevice['id']);

    form_data.append("avail_auto_send_picture", formData['avail_auto_send_picture']);
    form_data.append("unlock_auto_send_picture", formData['unlock_auto_send_picture']);
    form_data.append("unkill_auto_send_picture", formData['unkill_auto_send_picture']);

    onClickSaveSetting(form_data)

    // setApiLoading(true)
    // let response = await api_call(url, {
    //   method: 'POST',
    //   body: form_data,
    // });

    // setApiLoading(false)
    // if (response.ok) {
    //   setToastMessage(`Setting has been saved successfully`)
    //   setSnackbarOpen(true)
    //   console_log("history::::", history)

    //   onCloseShareSettingForm()
    // } else {
    //   setToastMessage(`Unknown error`);
    //   setSnackbarOpen(true);
    // }
  }

  /////////////////////// file uploading /////////////////////
  const fileRef = {
    avail_auto_send_picture: useRef(),
    unlock_auto_send_picture: useRef(),
    unkill_auto_send_picture: useRef()
  };
  const onClickFileBtn = (field_name) => {
    fileRef[field_name].current.click();
  }
  const onFileInputChanged = (e, field_name) => {
    const form_data = { ...formData }
    form_data[field_name] = e.target.files[0]
    let files = e.target.files;

    const preview_image_field = field_name + "_preview"
    console_log(files);
    if (!empty(files)) {
      form_data[preview_image_field] = URL.createObjectURL(files[0])
    } else {
      //form_data[preview_image_field] = ""
    }
    console_log("form_data:::", form_data);
    setFormData(form_data)
  }

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const onClickShowPreviewImage = (field_name) => {
    setCurrentImage(formData[field_name])
    setImageModalOpen(true)
  }

  const ImageBox = (field_name) => {
    const preview_field_name = field_name + "_preview"
    return (
      <div className={classes.messageWrapper}>
        <div className={classes.formRowInline}>
          <label className={classes.formRowInlineLabel}>Auto Send Picture </label>
          <div style={{ flex: 1 }}>
            {
              (formData[preview_field_name]) && (
                <img className={classes.imagePreview} onClick={() => onClickShowPreviewImage(preview_field_name)} src={formData[preview_field_name]} alt="upload image" />
              )
            }

            <input
              hidden
              ref={fileRef[field_name]}
              type="file"
              name={field_name}
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => onFileInputChanged(e, field_name)}
            />

            <Button size="small" type="button" color="primary" variant="contained" className={classes.formRowInline} onClick={() => onClickFileBtn(field_name)}>
              Choose file
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const shareSettingEnabled = useRef(false)
  const onChangeEnableShareSetting = (event, field_name) => {
    if (field_name === 'share_setting_enabled') {
      console.log("Alert, share_setting_enabled:::")
      const checked = event.target.checked
      if (checked) {
        shareSettingEnabled.current = checked
        setConfirmAction("agree_picture_upload")
        setConfirmText(`${APP_NAME} will use AI to extract data from pictures.`)
        setShowConfirmModal(true)
        return true
      }
    }

    let form_data = { ...formData }
    form_data[field_name] = event.target.checked
    setFormData(form_data)
  }

  const onChangeFormData = (values, field_name) => {
    console.log("onChangeFormData values, field_name::: ", values, field_name)
    let form_data = { ...formData }
    form_data[field_name] = values
    console.log("form_data::::", form_data)
    setFormData(form_data)
  }

  const [videoOpen, setVideoOpen] = useState(false);
  const onClickShowVideo = () => {
    setVideoOpen(true)
  }

  const onChangeCheckBox = (event, field_name) => {
    let form_data = { ...formData }
    form_data[field_name] = event.target.checked
    setFormData(form_data)
  }

  const onClickCopyUrl = () => {
    const shared_url = generatedUrl
    if (shared_url) {
      copyTextClipboard(shared_url, sharedTextTemplate)
      showToast("Link has been copied…")
    }
  }

  ///////////////////////////// confirmation ///////////////////////////////////
  const [confirmAction, setConfirmAction] = useState('')
  const [confirmText, setConfirmText] = useState("")
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const onClickAgreeConfirm = () => {
    if (confirmAction === "agree_picture_upload") {
      let form_data = { ...formData }
      form_data['share_setting_enabled'] = shareSettingEnabled.current
      setFormData(form_data)
    }
    else if (confirmAction.includes("approve_type:ai")) {
      const fieldObj = confirmAction.split(':')
      const field_name = fieldObj[0]
      const field_value = fieldObj[1]
      let form_data = { ...formData }
      form_data[field_name] = field_value
      setFormData(form_data)
    }
    setShowConfirmModal(false)
  }
  const onClickCancelConfirm = () => {
    setShowConfirmModal(false)
  }

  /////////////////////////////// shareTextDialog ///////////////////////////////////////////
  const [sharedTextTemplate, setSharedTextTemplate] = useState(getDefaultSharedTextForClipboard())
  const [showShareTextModal, setShowShareTextModal] = useState(false)
  const onClickEditClipboardText = () => {
    setShowShareTextModal(true)
  }
  const onClickSaveSharedTextTemplate = async (v) => {
    setSharedTextTemplate(v)
    await updateSharedTextTemplate(v)
    setShowShareTextModal(false)
  }
  const onSaveSharedTextTemplate = async (v) => {
    setSharedTextTemplate(v)
    await updateSharedTextTemplate(v)
  }

  const updateSharedTextTemplate = async (v) => {
    let post_data = {
      device_id: selectedDevice['id'],
      sharedTextTemplate: v
    }
    setApiLoading(true)
    const url = `/api/devices/updateSharedTextTemplate`;
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(post_data),
    });

    setApiLoading(false)
    if (response.ok) {
      const res = await response.json()
      console.log(`res::::`, res)
    }
  }

  return (
    <>
      <div className="enabled-block">
        <div className={classes.formRowInline} style={{ marginRight: 0 }}>
          <Stack direction={`row`} sx={{ justifyContent: 'flex-start', alignItems: 'center', flex: 1 }} spacing={2}>
            <Stack direction={`row`} sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
              <FormControlLabel
                control={<Checkbox checked={formData['share_setting_enabled'] ? true : false} onChange={(event) => onChangeEnableShareSetting(event, 'share_setting_enabled')} />}
                label="With Upload Requirements"
              />
              <Tooltip
                arrow
                title="Renter will be forced to upload some pictures in order to access the car"
              >
                <InfoIcon color="action" />
              </Tooltip>
            </Stack>

            <Box>
              <Typography variant='p' onClick={() => onClickShowVideo()} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1366ff' }}>Video</Typography>
            </Box>
          </Stack>
        </div>
      </div>

      <div className={classes.shareSettingFormContainer}>
        {
          (formData['share_setting_enabled']) ? (
            <>
              <div className={classes.formContainer}>
                <div className={classes.shareSettingBlock} id="alert-guest-block">
                  <div className='block'>
                    <div className='block' style={{ marginTop: '8px' }}>
                      <Stack sx={matchesXs ? { justifyContent: 'center', alignItems: 'flex-start' } : { justifyContent: 'flex-start', alignItems: 'center' }} direction={`${matchesXs ? 'column' : 'row'}`}>
                        <div className='block'>
                          <FormControlLabel
                            control={<Checkbox checked={formData['alert_guest'] ? true : false}
                              onChange={(event) => onChangeFormCheckBox(event, 'alert_guest')} />}
                            label="Alert guest"
                          />
                        </div>
                        <Stack sx={{ justifyContent: 'flex-start', alignItems: 'center' }} direction={`row`} spacing={1}>
                          <Box sx={{ width: '80px' }}>
                            <TextField
                              fullWidth
                              value={formData['alert_guest_minutes_before']}
                              onChange={(event) => onChangeFormInput(event, 'alert_guest_minutes_before')}
                              label=""
                              variant="filled"
                              placeholder=''
                            />
                          </Box>
                          <Typography>Minutes Before start of Trip</Typography>
                        </Stack>
                      </Stack>
                    </div>
                    <div className={classes.messageWrapper}>
                      <div className={classes.formRowInline}>
                        <label className={classes.formRowInlineLabel}>Auto Send Message </label>
                        <TextField
                          fullWidth
                          className={classes.formRowInlineController}
                          value={formData['alert_guest_auto_send_message']}
                          onChange={(event) => onChangeFormInput(event, 'alert_guest_auto_send_message')}
                          label=""
                          variant="filled"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.shareSettingBlock} id="avail-block">
                  <div className={classes.responsibleFlex}>
                    <div className={classes.formRowInline}>
                      <div className={classes.mtHalfText}>
                        <div className='block'>
                          <FormControlLabel
                            control={<Checkbox checked={formData['avail_car'] ? true : false}
                              onChange={(event) => onChangeFormCheckBox(event, 'avail_car')} />}
                            label="Car Location becomes available after"
                          />
                        </div>
                        <div className='block'>
                          <Stack sx={{ justifyContent: 'flex-start', alignItems: 'center' }} direction={`row`} spacing={1}>
                            <Typography>Can Upload</Typography>
                            <Box sx={{ width: '80px' }}>
                              <TextField
                                fullWidth
                                value={formData['avail_upload_minutes_before']}
                                onChange={(event) => onChangeFormInput(event, 'avail_upload_minutes_before')}
                                label=""
                                variant="filled"
                                placeholder=''
                              />
                            </Box>
                            <Typography>Minutes Before Trip</Typography>
                          </Stack>
                        </div>
                      </div>
                    </div>
                    <div className={[classes.flex1]}>
                      <UnlimitedStaticTextBoxList
                        defaultName={`avail`}
                        defaultCount={3}
                        defaultValues={availPicList}
                        setValues={(values) => setAvailPicList(values)}
                        inputProps={LIMIT_INPUT_PROPS}
                        primaryValues={AVAIL_UPLOAD_PRIMARY_VALUES}
                        defaultPrimaryUploadApplied={formData['defaultPrimaryUploadApplied']}
                        enabled={formData['avail_car'] ? true : false}
                      />
                    </div>
                  </div>
                  <div className={classes.block}>
                    <div className={classes.formRowInline}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={formData.avail_approve_type || ''}
                          onChange={(event) => onChangeFormInput(event, 'avail_approve_type')}
                          style={{ justifyContent: "flex-start" }}
                          row
                        >
                          <FormControlLabel value="auto" control={<Radio />} label="Always Approve" />
                          <FormControlLabel value="manual" control={<Radio />} label="Manual Approve" />
                          <Stack direction={`row`} alignItems={`center`} spacing={0}>
                            <FormControlLabel value="ai" control={<Radio />} label="AI Approve" />
                            <Stack direction={`row`} alignItems={`center`} spacing={1}>
                              <TextField
                                value={formData['avail_ai_percent']}
                                onChange={(event) => onChangeFormInput(event, 'avail_ai_percent')}
                                label=""
                                variant="filled"
                                placeholder=''
                                error={empty(formData['avail_ai_percent'])}
                                type="number"
                                inputProps={{
                                  type: 'number',
                                  min: 0,
                                  max: 100
                                }}
                                className={classes.aiPencentInput}
                              />
                              <label className={classes.formRowInlineLabelAuto}>%</label>
                            </Stack>
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className={classes.formRowInline} style={{ flex: 1, justifyContent: 'flex-start' }}>
                      <label className={classes.formRowInlineLabelAuto}>Approve Email </label>
                      <TextField
                        fullWidth
                        className={classes.formRowInlineController}
                        value={formData['manual_approve_email']}
                        onChange={(event) => onChangeFormInput(event, 'manual_approve_email')}
                        label=""
                        variant="filled"
                        placeholder='Please enter emails with comma separated'
                        error={empty(formData['manual_approve_email'])}
                        style={{ maxWidth: '536px' }}
                      />
                    </div>
                  </div>
                  <div className={classes.messageWrapper}>
                    <div className={classes.formRowInline}>
                      <label className={classes.formRowInlineLabel}>Auto Send Message </label>
                      <TextField
                        fullWidth
                        className={classes.formRowInlineController}
                        value={formData['avail_auto_send_message']}
                        onChange={(event) => onChangeFormInput(event, 'avail_auto_send_message')}
                        label=""
                        variant="filled"
                      />
                    </div>
                  </div>
                  {
                    ImageBox('avail_auto_send_picture')
                  }
                </div>
                <div className={classes.shareSettingBlock} id="unlock-block">
                  <div className={classes.responsibleFlex}>
                    <div className={classes.formRowInline}>
                      <div className="block">
                        <div className={classes.mtHalfText}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formData['unlock_car'] ? true : false}
                                onChange={(event) => onChangeFormCheckBox(event, 'unlock_car')}
                              />
                            }
                            label="Unlock will work after "
                          />
                        </div>
                      </div>
                    </div>
                    <div className={[classes.flex1]}>
                      <UnlimitedStaticTextBoxList
                        defaultName={`unlock`}
                        defaultCount={3}
                        defaultValues={unlockPicList}
                        setValues={(values) => setUnlockPicList(values)}
                        inputProps={LIMIT_INPUT_PROPS}
                        primaryValues={UNLOCK_UPLOAD_PRIMARY_VALUES}
                        defaultPrimaryUploadApplied={formData['defaultPrimaryUploadApplied']}
                        enabled={formData['unlock_car'] ? true : false}
                      />
                    </div>
                  </div>
                  <div className={classes.responsibleFlex}>
                    <div className={classes.formRowInline}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={formData.unlock_approve_type || ''}
                          onChange={(event) => onChangeFormInput(event, 'unlock_approve_type')}
                          style={{ justifyContent: "flex-start" }}
                          row
                        >
                          <FormControlLabel value="auto" control={<Radio />} label="Always Approve" />
                          <FormControlLabel value="manual" control={<Radio />} label="Manual Approve" />
                          <Stack direction={`row`} alignItems={`center`} spacing={0}>
                            <FormControlLabel value="ai" control={<Radio />} label="AI Approve" />
                            <Stack direction={`row`} alignItems={`center`} spacing={1}>
                              <TextField
                                value={formData['unlock_ai_percent']}
                                onChange={(event) => onChangeFormInput(event, 'unlock_ai_percent')}
                                label=""
                                variant="filled"
                                placeholder=''
                                error={empty(formData['unlock_ai_percent'])}
                                type="number"
                                inputProps={{
                                  type: 'number',
                                  min: 0,
                                  max: 100
                                }}
                                className={classes.aiPencentInput}
                              />
                              <label className={classes.formRowInlineLabelAuto}>%</label>
                            </Stack>
                          </Stack>

                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className={classes.messageWrapper}>
                    <div className={classes.formRowInline}>
                      <label className={classes.formRowInlineLabel}>Auto Send Message </label>
                      <TextField
                        fullWidth
                        className={classes.formRowInlineController}
                        value={formData['unlock_auto_send_message']}
                        onChange={(event) => onChangeFormInput(event, 'unlock_auto_send_message')}
                        label=""
                        variant="filled"
                      />
                    </div>
                  </div>
                  {
                    ImageBox('unlock_auto_send_picture')
                  }
                </div>

                <div className={classes.responsibleFlex}>
                  <div className={classes.formRowInline}>
                    <div className={classes.shareSettingBlockNoBorder}>
                      <div className={classes.mtHalfTextAAA}>
                        <FormControlLabel
                          control={<Checkbox checked={formData['auto_kill_switch'] ? true : false} onChange={(event) => onChangeFormCheckBox(event, 'auto_kill_switch')} />}
                          label="Auto killswitch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  {
                    (formData['auto_kill_switch']) ? (
                      <>
                        <div className={classes.shareSettingBlock} id="unkill-block">
                          <div className={classes.responsibleFlex}>
                            <div className={classes.formRowInline}>
                              <div className={classes.mtHalfText}>
                                <FormControlLabel
                                  control={<Checkbox checked={formData['unkill_car'] ? true : false} onChange={(event) => onChangeFormCheckBox(event, 'unkill_car')} />}
                                  label="Car will unkill after"
                                />
                              </div>
                            </div>
                            <div className={[classes.flex1]}>

                              <FormControl style={{ width: '100%', paddingTop: '8px' }}>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group_unkill_by"
                                  value={formData.unkill_by || ''}
                                  onChange={(event) => onChangeFormInput(event, 'unkill_by')}
                                  style={{ justifyContent: "flex-start" }}
                                  column
                                >
                                  <FormControlLabel value="renter" control={<Radio disabled={formData['unkill_car'] ? false : true} />} label="First Unlock By Renter" />
                                  <FormControlLabel value="picture" control={<Radio disabled={formData['unkill_car'] ? false : true} />}
                                    label={
                                      <>
                                        <TextField
                                          fullWidth
                                          className={classes.formRowInlineController}
                                          value={UNKILL_UPLOAD_PRIMARY_VALUES[0]}
                                          label=""
                                          variant="filled"
                                          disabled={true}
                                        />
                                      </>
                                    }
                                    className="full-width-form-control-label"
                                  />
                                </RadioGroup>
                              </FormControl>

                              <UnlimitedStaticTextBoxList
                                defaultName={`unkill`}
                                defaultCount={1}
                                defaultValues={unkillPicList}
                                setValues={(values) => setUnkillPicList(values)}
                                inputProps={LIMIT_INPUT_PROPS}
                                //primaryValues={UNKILL_UPLOAD_PRIMARY_VALUES}
                                defaultPrimaryUploadApplied={formData['defaultPrimaryUploadApplied']}
                                enabled={formData['unkill_car'] ? true : false}
                              />
                            </div>
                          </div>
                          <div className={classes.responsibleFlex}>
                            <div className={classes.formRowInline}>
                              <FormControl>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  value={formData.unkill_approve_type || ''}
                                  onChange={(event) => onChangeFormInput(event, 'unkill_approve_type')}
                                  style={{ justifyContent: "flex-start" }}
                                  row
                                >
                                  <FormControlLabel value="auto" control={<Radio />} label="Always Approve" />
                                  <FormControlLabel value="manual" control={<Radio />} label="Manual Approve" />
                                  <Stack direction={`row`} alignItems={`center`} spacing={0}>
                                    <FormControlLabel value="ai" control={<Radio />} label="AI Approve" />
                                    <Stack direction={`row`} alignItems={`center`} spacing={1}>
                                      <TextField
                                        value={formData['unkill_ai_percent']}
                                        onChange={(event) => onChangeFormInput(event, 'unkill_ai_percent')}
                                        label=""
                                        variant="filled"
                                        placeholder=''
                                        error={empty(formData['unkill_ai_percent'])}
                                        type="number"
                                        inputProps={{
                                          type: 'number',
                                          min: 0,
                                          max: 100
                                        }}
                                        className={classes.aiPencentInput}
                                      />
                                      <label className={classes.formRowInlineLabelAuto}>%</label>
                                    </Stack>
                                  </Stack>
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                          <div className={classes.messageWrapper}>
                            <div className={classes.formRowInline}>
                              <label className={classes.formRowInlineLabel}>Auto Send Message </label>
                              <TextField
                                fullWidth
                                className={classes.formRowInlineController}
                                value={formData['unkill_auto_send_message']}
                                onChange={(event) => onChangeFormInput(event, 'unkill_auto_send_message')}
                                label=""
                                variant="filled"
                              />
                            </div>
                          </div>
                          {
                            ImageBox('unkill_auto_send_picture')
                          }
                        </div>
                      </>
                    ) : (
                      <></>
                    )
                  }
                </>
              </div>
            </>
          ) :
            (
              <></>
            )
        }

        {/* <div className={classes.buttons} style={{ paddingTop: '15px', maxWidth: '400px', margin: 'auto', display: 'none' }}>
          <Button type="button" color="primary" variant="contained" onClick={() => handleSaveSetting()} disabled={apiLoading}>
            Save
          </Button>
          <Button type="button" color="primary" variant="outlined" onClick={() => onCloseShareSettingForm(false)}>
            Close
          </Button>
        </div> */}

        <Grid container direction="row" spacing={1}>
          {props.children}
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} sm={5}>
            <Stack direction={`row`} spacing={1}>
              <Button
                onClick={() => handleSaveSetting(false)}
                variant="outlined"
                color="secondary"
                fullWidth
                disabled={apiLoading}
                size={`small`}
              >
                Generate
              </Button>
              {
                (user?.administrator) ? (
                  <IconButton
                    disabled={apiLoading}
                    onClick={() => handleSaveSetting(true)}
                  >
                    <VisibilityOffIcon />
                  </IconButton>
                ) : (
                  <></>
                )
              }
            </Stack>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Stack direction={`column`} spacing={1}>
              <Stack direction={`row`} spacing={1}>
                <TextField
                  fullWidth
                  label="Generated URL"
                  value={generatedUrl}
                  readOnly={true}
                  variant="filled"
                />
                <IconButton onClick={() => onClickEditClipboardText()} title={`Edit`}>
                  <CreateIcon />
                </IconButton>
                {
                  (generatedUrl) && (
                    <IconButton onClick={() => onClickCopyUrl()} title={`Copy`}>
                      <ContentCopyIcon />
                    </IconButton>
                  )
                }
              </Stack>
              {
                (generatedUrl) && (
                  <Box className={classes.mobileBlock}>
                    <Stack direction={`row`} spacing={1} justifyContent={`flex-end`}>
                      <IconButton onClick={() => onClickSMS(sharedTextTemplate)} title={`SMS`}>
                        <SmsIcon />
                      </IconButton>
                      <IconButton onClick={() => onClickVoice(sharedTextTemplate)} title={`Voice`}>
                        <WifiCallingIcon />
                      </IconButton>
                    </Stack>
                  </Box>
                )
              }
            </Stack>
          </Grid>

        </Grid>
      </div>

      {imageModalOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setImageModalOpen(false)}
        />
      )}

      <React.Fragment>
        {
          (videoOpen) ? (
            <>
              <ModalVideo
                channel='youtube'
                autoplay={1}
                youtube={{
                  autoplay: 1,
                  mute: 1
                }}
                isOpen={videoOpen}
                videoId={SHRED_UPLOAD_VIDEO_ID}
                onClose={() => setVideoOpen(false)}
              />
            </>
          ) : (<></>)
        }

      </React.Fragment>

      <>
        <ShareTextDialog
          open={showShareTextModal}
          setOpen={setShowShareTextModal}
          title={`Edit shared text`}
          content={``}
          textYes={`Save`}
          textNo={`Cancel`}
          onClickYes={(v) => onClickSaveSharedTextTemplate(v)}
          onClickNo={() => setShowShareTextModal(false)}
          onSaveSharedTextTemplate={(v) => onSaveSharedTextTemplate(v)}
          sharedText={sharedTextTemplate}
        />
      </>

      <>
        <ConfirmDialog
          open={showConfirmModal}
          setOpen={setShowConfirmModal}
          title={`${APP_NAME}`}
          content={confirmText}
          textYes={`Agree`}
          textNo={`Decline`}
          onClickYes={() => onClickAgreeConfirm()}
          onClickNo={() => onClickCancelConfirm()}
        />
      </>

    </>
  )
}

export default ShareSettingForm;
