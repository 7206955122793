import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { layoutActions } from './store';


const WebPageFooter = () => {
  const navigate = useHistory()
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("WebPageFooter location::::", location);
    closeAllModal()

  }, [location, navigate]);

  const closeAllModal = () => {
    if (location) {
      const pathname = location.pathname
      // if (pathname === "/") {
      //   pageTitle = "Home"
      // }

      dispatch(layoutActions.closeAllAppModal());
    }
  }

  return (
    <>
    </>
  )
}

export default WebPageFooter;
