import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'geofences',
  initialState: {
    items: {},
    selectedGeofence: null,
    selectedDeviceId: null
  },
  reducers: {
    refresh(state, action) {
      state.items = {};
      action.payload.forEach((item) => state.items[item.id] = item);
    },
    update(state, action) {
      action.payload.forEach((item) => state.items[item.id] = item);
    },
    setSelectedGeofence(state, action) {
      state.selectedGeofence = action.payload;
    },
    setSelectedDeviceId(state, action) {
      state.selectedDeviceId = action.payload;
    },
  },
});

export { actions as geofencesActions };
export { reducer as geofencesReducer };
