import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'session',
  initialState: {
    server: null,
    user: null,
    device: null,
    paypalAccessToken: null,
    smartcarTokens: null,
  },
  reducers: {
    updateServer(state, action) {
      state.server = action.payload;
    },
    updateUser(state, action) {
      state.user = action.payload;
    },
    updateDevice(state, action) {
      state.device = action.payload;
    },
    setPaypalAccessToken(state, action) {
      state.paypalAccessToken = action.payload;
    },
    setSmartcarTokens(state, action) {
      state.smartcarTokens = action.payload;
    },
  },
});

export { actions as sessionActions };
export { reducer as sessionReducer };
