import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Modal, Snackbar, makeStyles, Button, IconButton, Grid, TextField } from '@material-ui/core';
import { Stack, useMediaQuery } from '@mui/material';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import CloseIcon from '@material-ui/icons/Close';
import { api_call } from '../helpers/untils';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import UnlimitedTuroCalTextBoxList from './unlimitedTuroCalTextBoxList';
import { APP_NAME, SHRED_TURO_VIDEO_ID } from '../config/constant';
import GeofenceAutoCompleteDropdown from '../components/GeofenceAutoCompleteDropdown';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '660px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  maxHeight: '100vh',
  overflowY: 'auto'
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));

const LinkGeofenceModal = (props) => {
  const { open, setOpen, selectedDeviceId, selectedDevice, selectedGeofenceIds = [], setSelectedGeofenceIds, showToast, pageData = {}, setPageData } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [apiLoading, setApiLoading] = useState(false)
  const [tmpSelectedGeofenceIds, setTmpSelectedGeofenceIds] = useState([])


  const handleModalClose = () => {
    setOpen("")
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (open) {
      loadGeofences()
    }
  }, [open])

  useEffect(() => {
    if (open) {
      setTmpSelectedGeofenceIds(selectedGeofenceIds)
    }
  }, [open, selectedGeofenceIds])

  const [geofenceList, setGeofenceList] = useState([])
  const loadGeofences = async () => {
    let url = '/api/geofences/get-list'
    const response = await api_call(url);
    if (response.ok) {
      const geofence_list = await response.json()
      setGeofenceList(geofence_list)
    }
  }

  const onClickSaveSetting = async () => {
    // setApiLoading(true)
    // const url = `/api/links/saveSelectedGeofenceIds`;
    // const payload = {
    //   deviceId: selectedDeviceId,
    //   selectedGeofenceIds: selectedGeofenceIds
    // }
    // let response = await api_call(url, {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify(payload),
    // });
    // setApiLoading(false)

    // if (response.ok) {
    //   //      
    // }

    setSelectedGeofenceIds(tmpSelectedGeofenceIds)

    handleModalClose()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />
          <Stack direction={`row`} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">{`Geofences`}</Typography>
            {/* <IconButton size="small" onClick={() => handleModalClose()}><CloseIcon /></IconButton> */}
          </Stack>
          <Box sx={{ paddingTop: 10 }}>
            <Grid container spacing={1} justifyContent="flex-start">
              <Grid item xs={12} sm={12}>
                <Box sx={{ width: xsDown ? '100%' : '100%' }}>
                  <GeofenceAutoCompleteDropdown
                    label={`Select Geofences`}
                    definitions={geofenceList}
                    value={tmpSelectedGeofenceIds}
                    setValue={setTmpSelectedGeofenceIds}
                    multiple={true}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Stack direction={`row`} spacing={1} sx={{ justifyContent: 'center', alignItems: 'center', marginTop: '24px' }}>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={apiLoading}
                    onClick={() => onClickSaveSetting()}
                  >
                    Ok
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={() => handleModalClose()}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LinkGeofenceModal;
