import React, { } from 'react';
import { useSelector } from 'react-redux';
import { console_log, empty } from '../helpers/untils';

import PositionsMap from './PositionsMap';

const CurrentPositionsMap = (props) => {
  const { temporaryPage, currentLink, loadLinkInfo, showPaypalCreditLogsPage, commands, enablePopup = true, isTest = false } = props;
  const positions = useSelector((state) => Object.values(state.positions.items));
  const devices = useSelector((state) => state.devices.items);

  return (
    <>
      {
        (!empty(devices)) ? (
          <>
            <PositionsMap
              positions={positions}
              temporaryPage={temporaryPage}
              currentLink={currentLink}
              loadLinkInfo={loadLinkInfo}
              showPaypalCreditLogsPage={showPaypalCreditLogsPage}
              commands={commands}
              enablePopup={enablePopup}
              isTest={isTest}
            />
          </>
        ) : (
          <></>
        )
      }
    </>
  );
};

export default CurrentPositionsMap;
