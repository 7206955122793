import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  makeStyles, Paper, BottomNavigation, BottomNavigationAction,
} from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import MapIcon from '@material-ui/icons/Map';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ReplayIcon from '@material-ui/icons/Replay';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import { sessionActions } from '../store';
import { useTranslation } from '../LocalizationProvider';
import { console_log } from '../helpers/untils';

const useStyles = makeStyles((theme) => ({
  container: {   
    bottom: theme.spacing(0),
    left: '0px',
    width: '100%',
    position: 'fixed',
    zIndex: 1301,
    [theme.breakpoints.up('lg')]: {
      left: theme.spacing(1.5),
      bottom: theme.spacing(1.5),
      width: theme.dimensions.drawerWidthDesktop,
    },
  },
  bottomItem: {
    minWidth: '50px'
  }
}));

const TemporaryBottomMenu = (props) => {
  const {deviceId} = props
  const classes = useStyles();
  const history = useHistory();
  const t = useTranslation();

  const dispatch = useDispatch();

  const handleSelection = (_, value) => {
    console_log("value:::", value)
    switch (value) {
      case 0:
        window.location.reload(true)
        break;
      case 1:
        history.push('/temporary-replay/' + deviceId);
        break;
      
      default:
        break;
    }
  };

  return (
    <Paper square elevation={4} className={classes.container}>
      <BottomNavigation
        value={0}
        onChange={handleSelection}
        showLabels
      >
        <BottomNavigationAction label={'Reload'} icon={<ReplayIcon />} className={classes.bottomItem} />
        <BottomNavigationAction label={'Playback'} icon={<PlayCircleOutlineIcon />} className={classes.bottomItem} />
      </BottomNavigation>
    </Paper>
  );
};

export default TemporaryBottomMenu;
