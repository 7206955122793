import React, { useEffect, useState } from 'react';
import {
  makeStyles, IconButton,
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import DeleteIcon from '@material-ui/icons/DeleteForever';

import moment from 'moment';
import { api_call, console_log, get_utc_timestamp_ms, validateEmail } from '../helpers/untils';
import AddCoHostDeviceDialog from '../devices/AddCoHostDeviceDialog';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 15
  },
}));

const CoHostDevicesPage = (props) => {
  const { user, devices, addDialogShown, setAddDialogShown, data, tableTimestamp, setTableTimestamp } = props;
  //console.log("data::::::::::::::", data)
  //console.log("user, devices", user, devices)
  const classes = useStyles();
  useEffect(() => {
    loadUserDevices();
  }, [tableTimestamp, data, devices]);

  const [userDevices, setUserDevices] = useState([])
  const getCoHostedDeviceIds = () => {
    const deviceIds = []
    if (data) {
      for (let k in data) {
        deviceIds.push(data[k]['device_id'])
      }
    }
    return deviceIds
  }
  const loadUserDevices = () => {
    const deviceIds = getCoHostedDeviceIds()
    let user_devices = [];
    for (let k in devices) {
      const deviceInfo = devices[k]
      if (user.administrator) {
        const users = deviceInfo['users']
        if(users) {
          const checkUser = users.filter((el) => el.id === user.id);
          if (checkUser.length > 0) {
            user_devices.push(deviceInfo)
            // if(!deviceIds.includes(deviceInfo.id)) {
            // }
          }
        }
      }else{
        user_devices.push(deviceInfo)
      }            
    }
    //console.log("user_devices", user_devices)
    setUserDevices(user_devices) 
  }
  ////////////////////////////////////////////////////////////////////////
  const OnClickDeleteRow = (id) => {
    console.log("id:::", id)
    if (window.confirm("Are you sure you want to delete?")) {
      DeleteRow(id)
    }
  }

  const DeleteRow = async (id) => {
    const url = `/api/devices/delete-co-host`
    const params = { id: id }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    });

    if (response.ok) {
      let responseObj = await response.json()
      setTableTimestamp(get_utc_timestamp_ms())
    } else {
      let responseObj = response
      console_log("error response:::", responseObj)
    }
  }

  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [sortOrder, setSortOrder] = useState({ name: "createdAt", direction: "desc" })

  const columns = [
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{value}</div>
          );
        },
      }
    },
    {
      name: "device_name",
      label: "Device Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{value}</div>
          );
        },
      }
    },
    {
      name: "uniqueId",
      label: "Device ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{value}</div>
          );
        },
      }
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{moment(value).format('LLL')}</div>
          );
        },
      }
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>
              <IconButton color="primary" size="small" onClick={() => OnClickDeleteRow(value)}>
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      }
    },
  ];

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive: 'standard',
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [],
    sortOrder: sortOrder,
    download: false,
    viewColumns: false,
    print: false,
    search: false,
    selectableRowsHideCheckboxes: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'sort':
          setSortOrder(tableState.sortOrder);
          break;
        default:
        //console.log('action not handled.');
      }
    },
  };

  ////////////////////////////////////////////////////////////////////////
  const handleAddResult = async (newItem) => {
    console.log("newItem", newItem)
    if (!newItem) {
      setAddDialogShown(false);
      return false
    }

    const email = newItem['email']
    if (!validateEmail(email)) {
      alert("Please enter a valid email address")
      return false
    }

    newItem['owner_user_id'] = user.id
    const url = `/api/devices/add-co-host`
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });

    if (response.ok) {
      let responseObj = await response.json()
      setAddDialogShown(false);
      setTableTimestamp(get_utc_timestamp_ms())
    } else {
      let responseObj = response
      console_log("error response:::", responseObj)
      //alert("Duplicated GPS ID")
      setAddDialogShown(false);
    }
  }

  return (
    <>
      <div className={classes.tableContainer}>
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
        />
      </div>

      <AddCoHostDeviceDialog
        open={addDialogShown}
        onResult={handleAddResult}
        definitions={userDevices}
        definitionLength={userDevices?.length}
      />
    </>
  );
};


export default CoHostDevicesPage;
