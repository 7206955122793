import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton, InputLabel, Select, CircularProgress, Typography
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import { useHistory, useParams } from 'react-router-dom';
import Pagination from '../components/pagination'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import { formatBoolean } from '../common/formatter';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';

import ReportFilter from './ReportFilter';
import ReportLayout from './ReportLayout';
import SelectField from '../form/SelectField';

import moment from 'moment';
import { api_call, console_log, empty } from '../helpers/untils';
import { devicesActions } from '../store';

const CommandDataTable = (props) => {
  const { id, devices, users } = props
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);


  const getDeviceName = (deviceId) => {
    for (const key in devices) {
      if (devices.hasOwnProperty(key) && devices[key].id === deviceId) {
        return devices[key].name;
      }
    }
    return "Unknown or Deleted device"
  }

  const getUserName = (item) => {
    const rowData = item['rowData']
    //console.log('rowData', rowData)
    const userId = rowData[0]
    if (userId === 0) {
      const command_data = rowData[7]
      if (command_data) {
        const command_data_obj = JSON.parse(command_data)
        if (command_data_obj['name']) {
          return command_data_obj['name']
        }
      }
      return "System"
    }
    else {
      for (const key in users) {
        if (users.hasOwnProperty(key) && users[key].id === userId) {
          let user_name = users[key].name
          if (empty(user_name)) {
            user_name = users[key].email
          }
          // if(item.shareUrl !== "") {
          //   user_name = user_name + " - " + item.shareUrl 
          // }
          if (rowData[6] && rowData[6] !== "") {
            user_name = user_name + " - " + rowData[6]
          }
          const command_data = rowData[7]
          if (command_data) {
            const command_data_obj = JSON.parse(command_data)
            if (command_data_obj['send_type'] && !empty(command_data_obj['send_type'])) {
              user_name = user_name + " - " + command_data_obj['send_type']
            }
          }
          return user_name
        } else if (userId == -1) {
          return "Installer";
        }
      }
      return "User deleted";
    }
  }

  const getCommandType = (value) => {
    if (value === "LightHorn") {
      value = "Horn + Light"
    }
    else if (value === "HonkHorn") {
      value = "Honk Horn"
    }
    else if (value === "TrueIgnitionOn") {
      value = "True Ignition On"
    }
    else if (value === "TrueIgnitionOff") {
      value = "True Ignition Off"
    }
    return value
  }

  const columnDefs = [
    {
      name: 'userId',
      label: 'User',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender::::', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{`${getUserName(tableMeta)}`}</div>
          );
        },
      },
    },
    {
      name: 'deviceId',
      label: 'Device',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{getDeviceName(value)}</div>
          );
        },
      },
    },
    {
      name: 'commandType',
      label: 'Command',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{getCommandType(value)}</div>
          );
        },
      },
    },
    {
      name: 'count',
      label: 'Value',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{tableMeta.count && (tableMeta.commandType === "Overspeed" ? tableMeta.count + "mph" : tableMeta.count + "mile")}</div>
          );
        },
      },
    },
    {
      name: 'createdAt',
      label: 'When',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{`${moment(value).format('LL')} ${moment(value).format('LTS')}`}</div>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{value}</div>
          );
        },
      },
    },
    {
      name: "shareUrl",
      options: {
        display: false,
      }
    },
    {
      name: "command_data",
      options: {
        display: false,
      }
    },
  ]

  const [page, setPage] = useState(0)
  const [count, setCount] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [sortOrder, setSortOrder] = useState({ name: "createdAt", direction: "desc" })
  const [data, setData] = useState([['Loading Data...']])
  const [columns, setColumns] = useState(columnDefs)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // getData(0);
  }, [])

  const sort = async (page, sortOrder) => {
    const res = await xhrRequest(page, sortOrder);
  };

  // mock async function
  const xhrRequest = async (page, sortOrder = {}, filter_options = {}) => {
    if (empty(user)) {
      return false
    }

    setIsLoading(true);
    let api_url = "/api/command/get-data-table"
    let post_data = {}
    post_data['page'] = page
    post_data['rowsPerPage'] = rowsPerPage
    post_data['sortOrder'] = sortOrder

    const filterOptions = {
      deviceId: selectedDeviceId,
      ...filter_options
    }
    if (user.administrator) {
      //
    } else {
      filterOptions['userId'] = user.id
    }

    const response = await api_call(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...post_data, ...filterOptions }),
    });
    if (response.ok) {
      const res = await response.json();
      setData(res.data)
      setPage(res.page)
      setSortOrder(sortOrder)
      setIsLoading(false)
      setCount(res.total)
      return res
    } else {
      return false
    }
  };

  const changePage = async (page, sortOrder) => {
    const res = await xhrRequest(page, sortOrder)
  };

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive: 'standard',
    serverSide: true,
    count: count,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [],
    sortOrder: sortOrder,
    download: false,
    viewColumns: false,
    print: false,
    search: false,
    selectableRowsHideCheckboxes: true,
    onTableChange: (action, tableState) => {
      // console.log("action, tableState:::", action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want
      switch (action) {
        case 'changePage':
          changePage(tableState.page, tableState.sortOrder);
          break;
        case 'sort':
          sort(tableState.page, tableState.sortOrder);
          break;
        default:
        //console.log('action not handled.');
      }
    },
  };

  const reduxSelectedDeviceId = useSelector((state) => state.devices.selectedDeviceId);
  const [selectDevices, setSelectDevices] = useState([]); // device name for dropdown
  const [selectedDeviceId, setSelectedDeviceId] = useState(null)

  const getDefaultSelect = () => {
    var defaultSelect = "All";
    if (!empty(reduxSelectedDeviceId)) {
      defaultSelect = reduxSelectedDeviceId
    } else {
      if (id) {
        defaultSelect = null;
        for (const key in devices) {
          if (devices[key].id === Number(id)) {
            defaultSelect = devices[key].id;
            dispatch(devicesActions.setSelectedDeviceId(defaultSelect));
            setSelectedDeviceId(Number(id))
            return defaultSelect
          }
        }
      }
    }
    return defaultSelect
  }

  const getSelectDevices = () => {
    var items = [];
    items.push({ id: "All", name: "All" });
    for (const key in devices) items.push({ id: devices[key].id, name: devices[key].name });
    return items;
  }

  const onChangeSelectedDevice = async (deviceId) => {
    //console.log("deviceId::::", deviceId)
    dispatch(devicesActions.setSelectedDeviceId(deviceId));
    setSelectedDeviceId(deviceId)
    const filter_options = { deviceId: deviceId }
    const res = await xhrRequest(page, sortOrder, filter_options);
  }

  useEffect(() => {
    let defaultSelectedDeviceId = ""
    setSelectDevices(getSelectDevices())
    if (selectedDeviceId === null) {
      defaultSelectedDeviceId = getDefaultSelect()
    }
    setSelectedDeviceId(defaultSelectedDeviceId)
    xhrRequest(0, sortOrder, { deviceId: defaultSelectedDeviceId })
  }, [id, user]);

  return (
    <>
      {
        (!empty(devices) && !empty(selectedDeviceId)) ? (
          <>
            <div>
              <MUIDataTable
                title={
                  <div className='pt-3'>
                    <FormControl margin="normal" variant="filled">
                      <InputLabel>Device</InputLabel>
                      <Select
                        multiple={false}
                        value={selectedDeviceId}
                        onChange={(event) => onChangeSelectedDevice(event.target.value)}
                        disabled={selectDevices.length === 0}
                      >
                        {selectDevices.map((item) => (
                          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        ))}
                      </Select>

                    </FormControl>
                    {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 24 }} />}
                  </div>
                }
                data={data}
                columns={columns}
                options={options}
              />
            </div>
          </>
        ) : (
          <></>
        )
      }
    </>
  )
}

const CommandsPage = () => {
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.devices.items);
  const { id } = useParams();

  const [users, setUsers] = useState(null)
  const getUsers = async () => {
    const response = await api_call('/api/user-name-list');
    if (response.ok) {
      const user_list = await response.json();
      //console.log('user_list::::', user_list)
      setUsers(user_list)
    }
  }

  useEffect(() => {
    getUsers();
  }, [])

  return (
    <>
      <ReportLayout>
        {
          (!empty(devices) && !empty(users)) ? (
            <CommandDataTable
              id={id}
              devices={devices}
              users={users}
            />
          ) : (
            <></>
          )
        }
      </ReportLayout>
    </>
  );
}
export default CommandsPage
