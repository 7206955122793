import React, { useEffect } from 'react';
import { Link, useHistory } from "react-router-dom"
import CmsPageLayout from './layout/CmsPageLayout';

export default function DisclaimerSite() {

    return (
        <CmsPageLayout title="MooveTrax Disclaimer Statement" lastUpdated={true}>
            <React.Fragment>
                <p className="page-subtitle no-margin"><b>Article 1: General Disclaimer</b></p>
                <p>
                    This disclaimer governs the use of the services provided by MooveTrax.com. By accessing and utilizing our services, you acknowledge and accept the terms outlined in this statement. If you disagree with any part of this disclaimer, you are advised not to use our services.
                </p>

                <p className="page-subtitle"><b>Article 2: No Warranty</b></p>
                <p>
                    While MooveTrax.com endeavors to ensure the accuracy, reliability, and integrity of its GPS tracking and car automation services, we do not guarantee, represent, or warrant that the services will be uninterrupted, error-free, or entirely accurate. All services are provided "as is" without any warranty of any kind, either expressed or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
                </p>

                <p className="page-subtitle"><b>Article 3:  Limitation of Liability</b></p>
                <p>
                    To the fullest extent permitted by applicable law, MooveTrax.com, its affiliates, officers, directors, employees, agents, suppliers, or licensors shall not be liable for any indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, data, or other intangible losses, resulting from (i) your access to or use of, or inability to access or use, the services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any errors or omissions in content; or (iv) any loss or damage of any kind incurred as a result of the use of any content made available via the services.
                </p>

                <p className="page-subtitle"><b>Article 4:  Third-Party Services</b></p>
                <p>
                    MooveTrax.com may employ third-party entities to facilitate our services or to provide services on our behalf, including but not limited to AI-driven photo verification. We are not responsible for the actions, content, accuracy, or reliability of these third-party services. Usage of these third-party services will be subject to their respective terms of service and policies, and it is the user's responsibility to review and accept those terms.
                </p>

                <p className="page-subtitle"><b>Article 5: User's Responsibility</b></p>
                <p>
                    All features, including but not limited to GPS tracking, car automation tools, and GSM signal strength tracking, are tools meant to aid the user. It remains the responsibility of the user to exercise caution, discretion, and judgment when using our services. MooveTrax.com is not responsible for any decisions or actions taken by users based on the information provided by our services.
                </p>

                <p className="page-subtitle"><b>Article 6: Third-Party AI for Photo Verification</b></p>
                <p>
                    6.1 Engagement with Third-Party Vendors: MooveTrax.com employs the services of third-party entities which utilize artificial intelligence ("AI") algorithms and systems ("Third-Party AI Vendors") to facilitate the verification of photographic content provided or uploaded by the users of our services.
                </p>
                <p>
                    6.2 Purpose of Engagement: The principal objective of integrating AI-driven photo verification is to augment the precision and operational efficacy of our content verification procedures. Nonetheless, MooveTrax.com neither endorses nor guarantees the absolute accuracy, reliability, or infallibility of the outputs generated by such third-party AI systems.
                </p>


                <p className="page-subtitle"><b>Article 7: Limitation on Photo Verification</b></p>
                <p>
                    7.1 Auxiliary Nature of AI Verification: The AI-based verification methodologies adopted and implemented by MooveTrax.com serve merely as supplementary tools designed to support, and not to supplant or replace, manual or human-based verification processes.
                </p>
                <p>
                    7.2 User's Due Diligence: All users, subscribers, or beneficiaries of MooveTrax.com's services are hereby advised and obligated to exercise their due diligence and discretion when relying upon or utilizing AI-validated photographic content. It remains the unambiguous responsibility of each user to ascertain and ensure that such content aligns with and meets their individual or corporate standards, stipulations, or prerequisites.
                </p>
                <p>
                    7.3 Exclusion of Liability: MooveTrax.com, its affiliates, associates, representatives, or any related entities, explicitly disclaim and absolve themselves from any and all liabilities, claims, damages, losses, or repercussions, whether direct or indirect, consequential or incidental, that may arise from or be related to any decisions, actions, or omissions made in reliance upon or in consequence of photos verified through AI systems provided by MooveTrax.com or its Third-Party AI Vendors.
                </p>

                <p className='mt-5'><b>Modifications</b></p>
                <p>
                    MooveTrax.com reserves the right to modify or replace this disclaimer at any time. It is the user's responsibility to review this disclaimer periodically. Continued use of our services following the posting of any changes to this disclaimer constitutes acceptance of those changes.
                </p>

                <p className='mt-5'><b>Contact Us</b></p>
                <p>
                    If you have any further questions about this policy, please don't hesitate to contact us.
                </p>
            </React.Fragment>
        </CmsPageLayout>
    )
}
