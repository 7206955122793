import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Typography, Box, Modal, makeStyles, Button, ButtonBase, TextField
} from '@material-ui/core';

import { Snackbar } from '@material-ui/core';

import { Stack } from '@mui/material';
import { layoutActions } from '../store';
import { APP_NAME } from '../config/constant';
import { api_call, console_log, empty, validateEmail } from '../helpers/untils';
import ConfirmDialog from '../components/ConfirmDialog';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '96%',
  maxWidth: '450px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));

const TransferServiceModal = (props) => {
  const { open, setOpen, onOk, uniqueId, name } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.session.user);
  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [transferEmail, setTransferEmail] = useState("");

  ///////////////////////////// confirmation ///////////////////////////////////
  const [confirmTransferText, setConfirmTransferText] = useState("")
  const [showConfirmTransferModal, setShowConfirmTransferModal] = useState(false)
  const onClickAgreeTransferConfirm = () => {
    handleSave(transferEmail)
  }
  const onClickCancelTransferConfirm = () => {
    setShowConfirmTransferModal(false)
  }


  useEffect(() => {
    if (open) {
      dispatch(layoutActions.closeDeviceListMenu());
    }
  }, [open]);

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  const handleOk = () => {
    if (transferEmail == "") {
      if (!validateEmail(transferEmail)) {
        setToastMessage("Please enter valid email address")
        setSnackbarOpen(true)
        return false
      }
    } else {
      setShowConfirmTransferModal(true)
    }
  }


  const handleSave = async (email) => {
    let url = `/api/users/add-user-device`;

    let postData = {
      email: email,
      uniqueId: uniqueId,
      name: name,
    }

    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    });

    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      if (responseObj['error']) {
        alert(responseObj['error'])
      }
      let cnt = responseObj['cnt'];
      if (empty(cnt) || cnt == 0) {
        return false
      }
      history.push("/main")
      return false
    } else {
      alert("Error encounted")
      setShowConfirmTransferModal(false)
      return false
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >

        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">Transfer Service</Typography>
          <Box sx={{ paddingTop: 16 }}>
            <>
              <div>
                <Stack direction={`column`} justifyContent={`start`} alignItems={`start`} spacing={1}>
                  <Typography align='left' display='block'>UniqueId: {uniqueId}</Typography>
                  <Typography align='left' display='block'>Name: {name}</Typography>
                  <TextField
                    margin="normal"
                    style={{ marginTop: 25, width: '100%' }}
                    value={transferEmail}
                    onChange={(event) => setTransferEmail(event.target.value)}
                    label={'Email Address'}
                    variant="filled"
                  />
                </Stack>
                <Stack direction={`column`} justifyContent={`center`} alignItems={`center`} spacing={1} style={{ marginTop: '16px' }}>
                  <Button type="button" color="primary" variant="contained" onClick={() => handleOk()} style={{ width: '180px' }}>
                    Submit
                  </Button>
                </Stack>
              </div>
            </>
          </Box>
        </Box>
      </Modal>
      <>
        <ConfirmDialog
          open={showConfirmTransferModal}
          setOpen={setShowConfirmTransferModal}
          title={APP_NAME}
          content={"This will remove this device from your account and add to " + transferEmail }
          textYes={`Yes`}
          textNo={`Cancel`}
          onClickYes={() => onClickAgreeTransferConfirm()}
          onClickNo={() => onClickCancelTransferConfirm()}
        />
      </>
    </>
  );
};

export default TransferServiceModal;
