import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions, sessionActions } from '../store';

import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Grid, Typography, Box, Modal, Snackbar, makeStyles, Divider
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Stack } from '@mui/material';

import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';

import moment from 'moment';
import { api_call, console_log, empty, getLocalTimezone, getUnixTimestampFromTimezone, get_utc_timestamp, get_utc_timestamp_ms, trimPhone } from '../helpers/untils';
import PaypalPaymentModal from './PaypalPaymentModal';
import { MAINT_VIDEO_ID, MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID, TOLL_VIDEO_ID } from '../config/constant';
import TollListModal from './TollListModal';
import TollSearchListTable from './TollSearchListTable';

import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '1024px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));

const PAYMENT_METHOD_LIST = [
  'Transponder', //tagCost
  'Cash', //cashCost
  'License', //licensePlateCost
  'Most Expensive',
  'Least Expensive'
]

const TollSettingModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const { open, setOpen, deviceItem, history } = props;
  const [item, setItem] = useState(null);
  const [tripList, setTripList] = useState([]);
  const [tollSearchList, setTollSearchList] = useState([]);

  useEffect(() => {
    if (deviceItem && deviceItem.id && open) {
      loadTollPageInfo(deviceItem.id);
    }
  }, [deviceItem, open])

  const loadTollPageInfo = async (deviceId) => {
    const url = `/api/device-tollguru/get-tollguru-page-data?user_id=${user.id}&device_id=${deviceId}`
    const response = await api_call(url);
    if (response.ok) {
      const pageData = await response.json()
      const turoTripList = pageData.turoTripList
      setTripList(turoTripList)
      const device = pageData.device
      setItem(device);
      setTollSearchList(pageData.tollSearchList)
    }
  }
  const [updateTimestamp, setUpdateTimestamp] = useState(0)

  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  ///////////////////////////////////////////////////////////////////////////////////

  const firstSetting = useRef(false)
  const classes = useStyles();
  useEffect(() => {
    initOptions()
  }, [item, updateTimestamp])

  const [apiLoading, setApiLoading] = useState(false)
  const [from, setFrom] = useState(moment().subtract(7, 'd'));
  const [to, setTo] = useState(moment());
  const [trip, setTrip] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD_LIST[0]);

  useEffect(() => {
    if (trip) {
      let startTimestamp = getUnixTimestampFromTimezone(trip.start, trip.tzid)
      let endTimestamp = getUnixTimestampFromTimezone(trip.end, trip.tzid)
      setFrom(moment.unix(startTimestamp))
      setTo(moment.unix(endTimestamp))
    }
  }, [trip])

  const filter = createFilterOptions({
    stringify: (option) => option.uid + "   " + option.reservation_id + "   " + option.summary + "   " + option.primary_driver + "   " + option.phone + "   " + option.vin,
  });

  const [options, setOptions] = useState([]);
  const initOptions = () => {
    let v_options = []
    tripList.map((definition) => {
      v_options.push({
        key: definition.id,
        // uid: definition.uid,
        // reservation_id: definition.reservation_id,
        // summary: definition.summary,
        // primary_driver: definition.primary_driver,
        // phone: definition.phone,
        // vin: definition.vin,
        // start: definition.start,
        // end: definition.end,
        ...definition
      })
    });
    //console.log("tripList:::", tripList)
    //console.log("v_options:::", v_options)
    setOptions(v_options)
  }

  const onChangeTrip = (option) => {
    setTrip(option);
  }

  const getTollList = (tollApiResult) => {
    try {
      const tolls = tollApiResult['route']['tolls']
      if (tolls && tolls.length > 0) {
        return tolls
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }

  const handleSubmitSetting = async () => {
    // setTollListModalOpen(true)
    // return false

    const url = `/api/device-tollguru/submit-tollguru`;
    const payload = {
      user_id: user.id,
      device_id: item['id'],
      trip: JSON.stringify(trip),
      turo_trip_id: trip?.id,
      from: from.unix(),
      to: to.unix(),
      paymentMethod: paymentMethod
    }
    const timezone = getLocalTimezone()
    payload['timezone'] = timezone

    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    setApiLoading(false)
    if (response.ok) {
      let responseObj = await response.json()
      console.log("responseObj::::", responseObj)
      if (responseObj['error']) {
        setToastMessage(responseObj['error'])
        setSnackbarOpen(true);
      } else {
        const tolls = getTollList(responseObj['tollApiResult'])
        console.log("tolls:::", tolls)
        if (tolls) {
          setTollList(tolls)
          setTollSearchList(responseObj['tollSearchList'])
        } else {
          setToastMessage(`No toll found`)
          setSnackbarOpen(true);
        }
      }
    } else {
      setToastMessage(`Unknown error`);
    }
  }

  const getPaymentList = () => {
    const sortList = [...PAYMENT_METHOD_LIST]
    let listData = [];
    for (let i in sortList) {
      listData.push({ id: sortList[i], name: sortList[i] })
    }
    return listData;
  }

  const [tollListModalOpen, setTollListModalOpen] = useState(false)
  const [tollList, setTollList] = useState([])

  const getTuroSummaryLabel = (tripInfo, returnType = "text") => {
    const summaryArr = tripInfo.summary.split('-')
    const summaryStr = summaryArr[0].trim()
    const summaryWordArr = summaryStr.split(' ')
    const summaryFirstWord = summaryWordArr[0]
    const summaryLastWord = summaryWordArr[summaryWordArr.length - 1]
    const start_timestamp = tripInfo.start_timestamp
    const start_time = start_timestamp ? moment.unix(start_timestamp).format("YYYY-MM-DD HH:mm") : ""
    const end_timestamp = tripInfo.end_timestamp
    const end_time = end_timestamp ? moment.unix(end_timestamp).format("YYYY-MM-DD HH:mm") : ""
    const label = `${summaryFirstWord} - ${summaryLastWord} \n${start_time} - ${end_time}`
    if (returnType === "object") {
      return (
        <div>
          <div>{`${summaryFirstWord} - ${summaryLastWord}`}</div>
          <div>{`${start_time} - ${end_time}`}</div>
        </div>
      )
    } else {
      return label
    }
  }

  const [videoOpen, setVideoOpen] = useState(false)

  useEffect(() => {
    if (open) {
      dispatch(layoutActions.closeDeviceListMenu());
    }
  }, [open]);
  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />
          <div>
            <Stack direction={`row`} sx={{ justifyContent: 'flex-start', alignItems: 'center' }} spacing={2}>
              <Typography id="modal-modal-title" variant="h6" component="h2">Toll Search</Typography>
              <Typography variant='p' onClick={() => setVideoOpen(true)} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1366ff' }}>How To Video</Typography>
            </Stack>
          </div>
          <Box sx={{ paddingTop: 16 }}>
            <div>
              {
                (!empty(item)) ? (
                  <div style={{ maxHeight: 'calc(100vh - 255px)', overflowY: 'auto' }}>
                    <Box sx={{ maxWidth: '400px', margin: 'auto' }}>
                      <div className={classes.formContainer}>
                        <Grid container spacing={1} justifyContent="flex-start">
                          <Grid item xs={12} sm={12}>
                            <Autocomplete
                              onChange={(_, option) => {
                                console.log("option::::", option)
                                // setKey(option && typeof option === 'object' ? option.key : option);
                                onChangeTrip(option)
                                // if (option && option.type) {
                                //   setType(option.type);
                                // }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                // if (params.inputValue) {
                                //   filtered.push({
                                //     // key: params.inputValue,
                                //     name: params.inputValue,
                                //   });
                                // }
                                return filtered;
                              }}
                              options={options}
                              getOptionLabel={(option) => getTuroSummaryLabel(option, "text")}
                              renderOption={(option) => getTuroSummaryLabel(option, "object")}
                              freeSolo
                              renderInput={(params) => (
                                <TextField {...params} label={`Turo trip`} variant="filled" margin="normal" />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <TextField
                              variant="filled"
                              label="From"
                              type="datetime-local"
                              value={from.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                              onChange={(e) => setFrom(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              variant="filled"
                              label="To"
                              type="datetime-local"
                              value={to.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                              onChange={(e) => setTo(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <SelectField
                              margin="normal"
                              value={paymentMethod}
                              emptyValue={null}
                              onChange={(event) => setPaymentMethod(event.target.value)}
                              data={getPaymentList()}
                              label='Payment Method'
                              variant="filled"
                              fullWidth
                              style={{ width: '100%' }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <div className={classes.buttons} style={{ paddingTop: '15px', maxWidth: '400px', margin: 'auto' }}>
                        <Button type="button" color="primary" variant="contained" onClick={() => handleSubmitSetting()} disabled={apiLoading}>
                          {apiLoading ? `Loading....` : `Search`}
                        </Button>
                        <Button type="button" color="primary" variant="outlined" onClick={() => setOpen(false)}>
                          Close
                        </Button>
                      </div>
                    </Box>
                    {
                      (tollSearchList.length > 0) && (
                        <Box sx={{ marginTop: '16px' }}>
                          <Divider />
                          <Box sx={{ marginTop: '16px' }}>
                            <TollSearchListTable
                              open={tollListModalOpen}
                              setOpen={setTollListModalOpen}
                              deviceItem={item}
                              setDeviceItem={setItem}
                              tollList={tollList}
                              setTollList={setTollList}
                              from={from}
                              to={to}
                              trip={trip}
                              paymentMethod={paymentMethod}
                              items={tollSearchList}
                            />
                          </Box>
                        </Box>
                      )
                    }
                  </div>
                ) : (
                  <div className='text-center'>
                    Loading....
                  </div>
                )
              }
            </div>
          </Box>

          <React.Fragment>
            {
              (videoOpen && TOLL_VIDEO_ID !== "") ? (
                <>
                  <ModalVideo
                    channel='youtube'
                    autoplay={1}
                    youtube={{
                      autoplay: 1,
                      mute: 1
                    }}
                    isOpen={videoOpen}
                    videoId={TOLL_VIDEO_ID}
                    onClose={() => setVideoOpen(false)}
                  />
                </>
              ) : (<></>)
            }
          </React.Fragment>

        </Box>
      </Modal>



    </>
  );
};

export default TollSettingModal;
