import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { layoutActions, sessionActions } from '../store';

import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, Typography, FormControlLabel, Checkbox, Box, Link, Modal, TextField, Button, Snackbar,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';

import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CheckIcon from '@material-ui/icons/Check';

import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import moment from 'moment';
import { api_call, console_log, empty, get_data_value, get_utc_timestamp, get_utc_timestamp_ms } from '../helpers/untils';
import PaypalPaymentModal from './PaypalPaymentModal';
import { MACHINE_ENVIRONMENT, MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID } from '../config/constant';
import Lightbox from 'react-image-lightbox';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '1200px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  },
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formRowInline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '30px',
    paddingTop: '6px',
    paddingBottom: '6px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginRight: '0px',
      '& > *': {
        display: 'block',
      },
    },
  },
  formRowInlineLabel: {
    paddingRight: 20,
    minWidth: '160px',
    [theme.breakpoints.down('md')]: {
      minWidth: '0px',
    },
  },
  formRowInlineLabelAuto: {
    paddingRight: 20,
  },
  formRowInlineLabelAfter: {
    paddingLeft: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  formRowInlineController: {
    flex: 1
  },
  minutesInput: {
    width: '110px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  responsibleFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'block',
    },
  },
  checkResultWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '6px',
    paddingBottom: '6px',
    marginRight: '15px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },
  },
  checkResultBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '20px',
  },
  checkResultSuccessIcon: {
    color: "#4CAF50",
  },
  checkResultFailedIcon: {
    color: "#CC2222",
  },
  textList: {
    display: 'block',
    maxWidth: '300px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  mtHalfText: {
    marginTop: '8px',
  },
  messageWrapper: {
    display: 'block',
    maxWidth: '676px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  formRowInlineSMS: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '15px',
    paddingTop: '6px',
    paddingBottom: '6px',
    minWidth: '150px',
    maxWidth: '250px',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginRight: '0px',
      '& > *': {
        display: 'block',
      },
    },
  },
  formRowInlineLabelSMS: {
    paddingRight: 20,
    minWidth: '60px',
    [theme.breakpoints.down('md')]: {
      minWidth: '0px',
    },
  },
  formRowInlineControllerSMS: {
    flex: 1
  },
  formRowInlineBtnSMS: {
    marginLeft: '15px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
      marginTop: '15px',
    },
  },
  uploadFileObject: {
    opacity: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    cursor: 'pointer',
    zIndex: 1,
    width: '100%',
    height: '100%'
  },
  imagePreview: {
    width: '103px',
    height: 'auto',
    cursor: 'pointer',
    marginTop: '8px',
    marginBottom: '8px',
  },

}));

const LIMIT_INPUT_PROPS = {
  maxLength: 40,
  title: "Max length is 40 characters"
}

const TuroSettingForm = (props) => {
  const { open, setOpen, selectedDevice, updateTimestamp, setUpdateTimestamp, setDeviceItem, containerRef, history } = props;
  ///console.log("history1111::::", history)
  const m_history = useHistory()

  const user = useSelector((state) => state.session.user);

  const classes = useStyles();
  useEffect(() => {
    initTuroSettings()
  }, [selectedDevice, updateTimestamp])

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [apiLoading, setApiLoading] = useState(false)
  const [apiSending, setApiSending] = useState(false)

  const defaultFormData = {
    avail_approve_type: 'manual',
    unlock_approve_type: 'auto',
    unkill_approve_type: 'auto',
    url_auto_send_minutes: 1440,
    url_resend_minutes: 60,
    url_start_minutes: 60,
    url_ends_minutes: 30,
    avail_car: true,
    avail1: "Picture of License",
    avail2: "Selfie with License Next To Face",
    manual_approve_email: user.email,
    turo_setting_disabled: false
  }
  const [formData, setFormData] = useState(defaultFormData)

  const initTuroSettings = () => {
    console.log("selectedDevice:::::", selectedDevice)
    let turoSettings = selectedDevice.turo_settings
    if (!empty(turoSettings)) {
      turoSettings = JSON.parse(turoSettings)
    } else {
      turoSettings = {}
    }
    console.log("turoSettings:::::", turoSettings)
    setFormData({ ...defaultFormData, ...turoSettings })
  }

  const onChangeTuroForm = (event, field_name) => {
    let form_data = { ...formData }
    form_data[field_name] = event.target.value
    setFormData(form_data)
  }
  const onChangeTuroFormCheckBox = (event, field_name) => {
    let form_data = { ...formData }
    form_data[field_name] = event.target.checked
    setFormData(form_data)
  }

  const getSettingTextData = () => {
    const form_data = { ...formData }
    const file_field_name_list = [
      "avail_auto_send_picture",
      "unlock_auto_send_picture",
      "unkill_auto_send_picture"
    ]
    const text_field_data = {}
    for (let k in form_data) {
      if (!file_field_name_list.includes(k)) {
        text_field_data[k] = form_data[k]
      }
    }
    return text_field_data
  }

  const onCloseTuroSettingForm = (delay = true) => {
    if (delay) {
      setTimeout(() => {
        if (history) {
          history.push(`/settings/turo-trips`);
        } else {
          m_history.push(`/settings/turo-trips`);
        }
        setOpen(false)
      }, 2000)
    } else {
      if (history) {
        history.push(`/settings/turo-trips`);
      } else {
        m_history.push(`/settings/turo-trips`);
      }
      setOpen(false)
    }
  }


  const handleSaveSetting = async () => {
    //console.log("history2222::::", history)
    if (empty(formData['manual_approve_email'])) {
      setToastMessage(`Check errors in Red`);
      setSnackbarOpen(true);
      return false
    }

    const url = `/api/turo/updateSetting`;
    console_log("setting data:::", formData)
    const textFormData = getSettingTextData(formData)
    const turoSetting = {
      ...textFormData,
      userId: user.id,
      deviceId: selectedDevice['id'],
    }
    const payload = {
      user_id: user.id,
      device_id: selectedDevice['id'],
      turoSetting: JSON.stringify(turoSetting),
    }

    var form_data = new FormData();
    form_data.append("turoSetting", JSON.stringify(turoSetting));
    form_data.append("user_id", user.id);
    form_data.append("device_id", selectedDevice['id']);

    form_data.append("avail_auto_send_picture", formData['avail_auto_send_picture']);
    form_data.append("unlock_auto_send_picture", formData['unlock_auto_send_picture']);
    form_data.append("unkill_auto_send_picture", formData['unkill_auto_send_picture']);

    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      body: form_data,
    });

    setApiLoading(false)
    if (response.ok) {
      setToastMessage(`Turo setting has been saved successfully`);
      setSnackbarOpen(true);
      console_log("history::::", history)

      onCloseTuroSettingForm();
    } else {
      setToastMessage(`Unknown error`);
      setSnackbarOpen(true);
    }
  }

  const [turoCalling, setTuroCalling] = useState(false)

  /////////////////////// file uploading /////////////////////
  const fileRef = {
    avail_auto_send_picture: useRef(),
    unlock_auto_send_picture: useRef(),
    unkill_auto_send_picture: useRef()
  };
  const onClickFileBtn = (field_name) => {
    fileRef[field_name].current.click();
  }
  const onFileInputChanged = (e, field_name) => {
    const form_data = { ...formData }
    form_data[field_name] = e.target.files[0]
    let files = e.target.files;

    const preview_image_field = field_name + "_preview"
    console_log(files);
    if (!empty(files)) {
      form_data[preview_image_field] = URL.createObjectURL(files[0])
    } else {
      //form_data[preview_image_field] = ""
    }
    console_log("form_data:::", form_data);
    setFormData(form_data)
  }

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const onClickShowPreviewImage = (field_name) => {
    setCurrentImage(formData[field_name])
    setImageModalOpen(true)
  }

  const ImageBox = (field_name) => {
    const preview_field_name = field_name + "_preview"
    return (
      <div className={classes.messageWrapper}>
        <div className={classes.formRowInline}>
          <label className={classes.formRowInlineLabel}>Auto Send Picture </label>
          <div style={{ flex: 1 }}>
            {
              (formData[preview_field_name]) && (
                <img className={classes.imagePreview} onClick={() => onClickShowPreviewImage(preview_field_name)} src={formData[preview_field_name]} alt="" />
              )
            }

            <input
              hidden
              ref={fileRef[field_name]}
              type="file"
              name={field_name}
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => onFileInputChanged(e, field_name)}
            />

            <Button size="small" type="button" color="primary" variant="contained" className={classes.formRowInline} onClick={() => onClickFileBtn(field_name)}>
              Choose file
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const onChangeDisableTuroSetting = (event, field_name) => {
    let form_data = { ...formData }
    form_data[field_name] = event.target.checked
    setFormData(form_data)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        autoHideDuration={1500}
        message={toastMessage}
      />

      <div className="disabled-block">
        <div className={classes.formRowInline}>
          <div>
            <FormControlLabel
              control={<Checkbox checked={formData['turo_setting_disabled'] ? true : false} onChange={(event) => onChangeDisableTuroSetting(event, 'turo_setting_disabled')} />}
              label="Disabled"
            />
          </div>
        </div>
      </div>

      <div className={classes.formContainer}>
        <div className="urls-block">
          <div className={classes.responsibleFlex}>
            <div className={classes.formRowInline}>
              <label className={classes.formRowInlineLabel}>Auto Send URL </label>
              <TextField
                fullWidth
                className={classes.minutesInput}
                value={formData['url_auto_send_minutes']}
                onChange={(event) => onChangeTuroForm(event, 'url_auto_send_minutes')}
                label=""
                variant="filled"
                type="number"
                inputProps={{ step: 1 }}
              />
              <label className={classes.formRowInlineLabelAfter}>minutes before the trip starts.</label>
            </div>
          </div>
          <div className={classes.responsibleFlex}>
            <div className={classes.formRowInline}>
              <label className={classes.formRowInlineLabel}>Resend URL </label>
              <TextField
                fullWidth
                className={classes.minutesInput}
                value={formData['url_resend_minutes']}
                onChange={(event) => onChangeTuroForm(event, 'url_resend_minutes')}
                label=""
                variant="filled"
                type="number"
                inputProps={{ step: 1 }}
              />
              <label className={classes.formRowInlineLabelAfter}>minutes before the trip ends.</label>
            </div>
          </div>
          <div className={classes.responsibleFlex}>
            <div className={classes.formRowInline}>
              <label className={classes.formRowInlineLabel}>URL will start working </label>
              <TextField
                fullWidth
                className={classes.minutesInput}
                value={formData['url_start_minutes']}
                onChange={(event) => onChangeTuroForm(event, 'url_start_minutes')}
                label=""
                variant="filled"
                type="number"
                inputProps={{ step: 1 }}
              />
              <label className={classes.formRowInlineLabelAfter}>minutes before the trip starts.</label>
            </div>
          </div>
          <div className={classes.responsibleFlex}>
            <div className={classes.formRowInline}>
              <label className={classes.formRowInlineLabel}>URL ends </label>
              <TextField
                fullWidth
                className={classes.minutesInput}
                value={formData['url_ends_minutes']}
                onChange={(event) => onChangeTuroForm(event, 'url_ends_minutes')}
                label=""
                variant="filled"
                type="number"
                inputProps={{ step: 1 }}
              />
              <label className={classes.formRowInlineLabelAfter}>minutes after the trip ends.</label>
            </div>
          </div>
        </div>
        <div className="avail-block">
          <div className={classes.responsibleFlex}>
            <div className={classes.formRowInline}>
              <div className={classes.mtHalfText}>
                <FormControlLabel
                  control={<Checkbox checked={formData['avail_car'] ? true : false} onChange={(event) => onChangeTuroFormCheckBox(event, 'avail_car')} />}
                  label="Car Location becomes available after"
                />
              </div>
            </div>
            <div className={classes.formRowInline}>
              <div className={classes.textList}>
                <TextField
                  fullWidth
                  className={classes.formRowInlineController}
                  value={formData['avail1']}
                  onChange={(event) => onChangeTuroForm(event, 'avail1')}
                  label=""
                  variant="filled"
                  inputProps={{
                    ...LIMIT_INPUT_PROPS
                  }}
                />
                <TextField
                  fullWidth
                  className={classes.formRowInlineController}
                  value={formData['avail2']}
                  onChange={(event) => onChangeTuroForm(event, 'avail2')}
                  label=""
                  variant="filled"
                  inputProps={{
                    ...LIMIT_INPUT_PROPS
                  }}
                />
                <TextField
                  fullWidth
                  className={classes.formRowInlineController}
                  value={formData['avail3']}
                  onChange={(event) => onChangeTuroForm(event, 'avail3')}
                  label=""
                  variant="filled"
                  inputProps={{
                    ...LIMIT_INPUT_PROPS
                  }}
                />
                <TextField
                  fullWidth
                  className={classes.formRowInlineController}
                  value={formData['avail4']}
                  onChange={(event) => onChangeTuroForm(event, 'avail4')}
                  label=""
                  variant="filled"
                  inputProps={{
                    ...LIMIT_INPUT_PROPS
                  }}
                />
                <TextField
                  fullWidth
                  className={classes.formRowInlineController}
                  value={formData['avail5']}
                  onChange={(event) => onChangeTuroForm(event, 'avail5')}
                  label=""
                  variant="filled"
                  inputProps={{
                    ...LIMIT_INPUT_PROPS
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.responsibleFlex}>
            <div className={classes.formRowInline}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={formData.avail_approve_type || ''}
                  onChange={(event) => onChangeTuroForm(event, 'avail_approve_type')}
                  style={{ justifyContent: "flex-start" }}
                  row
                >
                  <FormControlLabel value="auto" control={<Radio />} label="Always Approve" />
                  <FormControlLabel value="manual" control={<Radio />} label="Manual Approve" />
                </RadioGroup>
              </FormControl>
            </div>
            <div className={classes.formRowInline} style={{ flex: 1 }}>
              <label className={classes.formRowInlineLabelAuto}>Email </label>
              <TextField
                fullWidth
                className={classes.formRowInlineController}
                value={formData['manual_approve_email']}
                onChange={(event) => onChangeTuroForm(event, 'manual_approve_email')}
                label=""
                variant="filled"
                placeholder='Please enter emails with comma separated'
                error={empty(formData['manual_approve_email'])}
              />
            </div>
          </div>
          <div className={classes.messageWrapper}>
            <div className={classes.formRowInline}>
              <label className={classes.formRowInlineLabel}>Auto Send Message </label>
              <TextField
                fullWidth
                className={classes.formRowInlineController}
                value={formData['avail_auto_send_message']}
                onChange={(event) => onChangeTuroForm(event, 'avail_auto_send_message')}
                label=""
                variant="filled"
              />
            </div>
          </div>
          {
            ImageBox('avail_auto_send_picture')
          }
        </div>
        <div className="unlock-block">
          <div className={classes.responsibleFlex}>
            <div className={classes.formRowInline}>
              <div className="block">
                <div className={classes.mtHalfText}>
                  <FormControlLabel
                    control={<Checkbox checked={formData['unlock_car'] ? true : false} onChange={(event) => onChangeTuroFormCheckBox(event, 'unlock_car')} />}
                    label="Unlock will work after "
                  />
                </div>
              </div>
            </div>
            <div className={classes.formRowInline}>
              <div className={classes.textList}>
                <TextField
                  fullWidth
                  className={classes.formRowInlineController}
                  value={formData['unlock1']}
                  onChange={(event) => onChangeTuroForm(event, 'unlock1')}
                  label=""
                  variant="filled"
                  inputProps={{
                    ...LIMIT_INPUT_PROPS
                  }}
                />
                <TextField
                  fullWidth
                  className={classes.formRowInlineController}
                  value={formData['unlock2']}
                  onChange={(event) => onChangeTuroForm(event, 'unlock2')}
                  label=""
                  variant="filled"
                  inputProps={{
                    ...LIMIT_INPUT_PROPS
                  }}
                />
                <TextField
                  fullWidth
                  className={classes.formRowInlineController}
                  value={formData['unlock3']}
                  onChange={(event) => onChangeTuroForm(event, 'unlock3')}
                  label=""
                  variant="filled"
                  inputProps={{
                    ...LIMIT_INPUT_PROPS
                  }}
                />
                <TextField
                  fullWidth
                  className={classes.formRowInlineController}
                  value={formData['unlock4']}
                  onChange={(event) => onChangeTuroForm(event, 'unlock4')}
                  label=""
                  variant="filled"
                  inputProps={{
                    ...LIMIT_INPUT_PROPS
                  }}
                />
                <TextField
                  fullWidth
                  className={classes.formRowInlineController}
                  value={formData['unlock5']}
                  onChange={(event) => onChangeTuroForm(event, 'unlock5')}
                  label=""
                  variant="filled"
                  inputProps={{
                    ...LIMIT_INPUT_PROPS
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.responsibleFlex}>
            <div className={classes.formRowInline}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={formData.unlock_approve_type || ''}
                  onChange={(event) => onChangeTuroForm(event, 'unlock_approve_type')}
                  style={{ justifyContent: "flex-start" }}
                  row
                >
                  <FormControlLabel value="auto" control={<Radio />} label="Always Approve" />
                  <FormControlLabel value="manual" control={<Radio />} label="Manual Approve" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className={classes.messageWrapper}>
            <div className={classes.formRowInline}>
              <label className={classes.formRowInlineLabel}>Auto Send Message </label>
              <TextField
                fullWidth
                className={classes.formRowInlineController}
                value={formData['unlock_auto_send_message']}
                onChange={(event) => onChangeTuroForm(event, 'unlock_auto_send_message')}
                label=""
                variant="filled"
              />
            </div>
          </div>
          {
            ImageBox('unlock_auto_send_picture')
          }
        </div>

        <div className={classes.responsibleFlex}>
          <div className={classes.formRowInline}>
            <div className="block">
              <div className={classes.mtHalfText}>
                <FormControlLabel
                  control={<Checkbox checked={formData['auto_kill_switch'] ? true : false} onChange={(event) => onChangeTuroFormCheckBox(event, 'auto_kill_switch')} />}
                  label="Auto killswitch"
                />
              </div>
            </div>
          </div>
        </div>
        <>
          {
            (formData['auto_kill_switch']) ? (
              <>
                <div className="unkill-block">
                  <div className={classes.responsibleFlex}>
                    <div className={classes.formRowInline}>
                      <div className={classes.mtHalfText}>
                        <FormControlLabel
                          control={<Checkbox checked={formData['unkill_car'] ? true : false} onChange={(event) => onChangeTuroFormCheckBox(event, 'unkill_car')} />}
                          label="Car will unkill after"
                        />
                      </div>
                    </div>
                    <div className={classes.formRowInline}>
                      <div className={classes.textList}>
                        <TextField
                          fullWidth
                          className={classes.formRowInlineController}
                          value={formData['unkill1']}
                          onChange={(event) => onChangeTuroForm(event, 'unkill1')}
                          label=""
                          variant="filled"
                          inputProps={{
                            ...LIMIT_INPUT_PROPS
                          }}
                        />
                        <TextField
                          fullWidth
                          className={classes.formRowInlineController}
                          value={formData['unkill2']}
                          onChange={(event) => onChangeTuroForm(event, 'unkill2')}
                          label=""
                          variant="filled"
                          inputProps={{
                            ...LIMIT_INPUT_PROPS
                          }}
                        />
                        <TextField
                          fullWidth
                          className={classes.formRowInlineController}
                          value={formData['unkill3']}
                          onChange={(event) => onChangeTuroForm(event, 'unkill3')}
                          label=""
                          variant="filled"
                          inputProps={{
                            ...LIMIT_INPUT_PROPS
                          }}
                        />
                        <TextField
                          fullWidth
                          className={classes.formRowInlineController}
                          value={formData['unkill4']}
                          onChange={(event) => onChangeTuroForm(event, 'unkill4')}
                          label=""
                          variant="filled"
                          inputProps={{
                            ...LIMIT_INPUT_PROPS
                          }}
                        />
                        <TextField
                          fullWidth
                          className={classes.formRowInlineController}
                          value={formData['unkill5']}
                          onChange={(event) => onChangeTuroForm(event, 'unkill5')}
                          label=""
                          variant="filled"
                          inputProps={{
                            ...LIMIT_INPUT_PROPS
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.responsibleFlex}>
                    <div className={classes.formRowInline}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={formData.unkill_approve_type || ''}
                          onChange={(event) => onChangeTuroForm(event, 'unkill_approve_type')}
                          style={{ justifyContent: "flex-start" }}
                          row
                        >
                          <FormControlLabel value="auto" control={<Radio />} label="Always Approve" />
                          <FormControlLabel value="manual" control={<Radio />} label="Manual Approve" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className={classes.messageWrapper}>
                    <div className={classes.formRowInline}>
                      <label className={classes.formRowInlineLabel}>Auto Send Message </label>
                      <TextField
                        fullWidth
                        className={classes.formRowInlineController}
                        value={formData['unkill_auto_send_message']}
                        onChange={(event) => onChangeTuroForm(event, 'unkill_auto_send_message')}
                        label=""
                        variant="filled"
                      />
                    </div>
                  </div>
                  {
                    ImageBox('unkill_auto_send_picture')
                  }
                </div>
              </>
            ) : (
              <></>
            )
          }
        </>
      </div>

      <div className={classes.buttons} style={{ paddingTop: '15px', maxWidth: '400px', margin: 'auto' }}>
        <Button type="button" color="primary" variant="contained" onClick={() => handleSaveSetting()} disabled={apiLoading}>
          Save
        </Button>
        <Button type="button" color="primary" variant="outlined" onClick={() => onCloseTuroSettingForm(false)}>
          Close
        </Button>
      </div>

      {imageModalOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setImageModalOpen(false)}
        />
      )}

    </>
  )
}

const TuroSettingModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const { open, setOpen, deviceItem, history } = props;
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (deviceItem && deviceItem.id && open) {
      loadDeviceInfo(deviceItem.id);
    }
  }, [deviceItem, open])

  const loadDeviceInfo = async (deviceId) => {
    const url = `/api/devices/${deviceId}`
    const response = await api_call(url);
    if (response.ok) {
      const device = await response.json()
      setItem(device);
      setUpdateTimestamp(get_utc_timestamp_ms())
    }
  }
  const [updateTimestamp, setUpdateTimestamp] = useState(0)

  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const containerRef = useRef();

  useEffect(() => {
    if (open) {
      dispatch(layoutActions.closeDeviceListMenu());
    }
  }, [open]);
  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />

          <Typography id="modal-modal-title" variant="h6" component="h2">Turo Settings - <span style={{ fontSize: '0.8em' }}>Beta - Use at your own risk</span></Typography>

          <div className='mt-3' style={{ maxHeight: "calc(100vh - 280px)", overflow: "auto" }} ref={containerRef}>
            {
              (!empty(item)) ? (
                <div className=''>
                  <TuroSettingForm
                    open={open}
                    setOpen={setOpen}
                    selectedDevice={item}
                    updateTimestamp={updateTimestamp}
                    setUpdateTimestamp={setUpdateTimestamp}
                    setDeviceItem={setItem}
                    containerRef={containerRef}
                    history={history}
                  />
                </div>
              ) : (
                <div className='text-center'>
                  Loading....
                </div>
              )
            }
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default TuroSettingModal;
