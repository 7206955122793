import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from '../store';

import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Grid, Typography, Box, Modal, Snackbar, makeStyles,
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, IconButton,
} from '@material-ui/core';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';


import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';

import moment from 'moment';
import { api_call, console_log, empty, getUnixTimestampFromTimezone, get_utc_timestamp, get_utc_timestamp_ms, trimPhone } from '../helpers/untils';
import PaypalPaymentModal from './PaypalPaymentModal';
import { MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID } from '../config/constant';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '1024px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));


const TollListModal = (props) => {
  const { open, setOpen, deviceItem, tollList, setTollList, from, to, trip, paymentMethod, tollSearchList } = props;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const classes = useStyles();

  const [item, setItem] = useState(null);
  const [tripList, setTripList] = useState([]);

  useEffect(() => {
    if (deviceItem && deviceItem.id) {

    }
  }, [deviceItem])

  const [updateTimestamp, setUpdateTimestamp] = useState(0)

  const handleModalClose = () => {
    setOpen(false)
  }
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [searchText, setSearchText] = useState("");
  const onChangeSearchText = (e) => {
    const filter = e.target.value
    setSearchText(filter)

  }

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />

          <Typography id="modal-modal-title" variant="h6" component="h2">Toll Query List</Typography>
          <Box sx={{ paddingTop: 20 }}>
            <div style={{ maxHeight: 'calc(100vh - 255px)', overflowY: 'auto' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>JPG</TableCell>
                      <TableCell>PDF</TableCell>
                      <TableCell>Email to</TableCell>
                      <TableCell>Post on Turo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      tollSearchList.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{moment.unix(item.from).format("MM/DD/YYYY h:mm a")}</TableCell>
                            <TableCell>{moment.unix(item.to).format("MM/DD/YYYY h:mm a")}</TableCell>
                            <TableCell>{`jpg`}</TableCell>
                            <TableCell>{`pdf`}</TableCell>
                            <TableCell>
                              <TextField
                                placeholder="Enter emails with comma separated"
                              />
                            </TableCell>
                            <TableCell>
                              <Button>Send</Button>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default TollListModal;
