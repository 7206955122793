import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { layoutActions, sessionActions } from '../../store';

import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Grid, Typography, Box, Modal, Snackbar, makeStyles, Divider, ButtonBase
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { useParams } from 'react-router-dom';

import moment from 'moment';
import { api_call, console_log, empty, getLocalTimezone, getUnixTimestampFromTimezone, get_data_value, get_utc_timestamp, get_utc_timestamp_ms, trimPhone } from '../../helpers/untils';
import MaintListTable from '../MaintListTable';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CloseIcon from '@material-ui/icons/Close';
import Lightbox from 'react-image-lightbox';
import { getTuroSummaryFirstLastWord } from '../../helpers/misc';

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }
}));

const IncomeExpenseAddForm = (props) => {
  const dispatch = useDispatch();
  const { deviceId, deviceItem, showToast, type, pageLoading, setPageLoading, reloadTimestamp, setReloadTimestamp, tripList = [], usedTripIds = [] } = props;
  const user = useSelector((state) => state.session.user);
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const [apiLoading, setApiLoading] = useState(pageLoading)

  const defaultFormData = {}
  const [formData, setFormData] = useState(defaultFormData)
  const [dob, setDob] = useState(moment());
  const onChnageDob = (e) => {
    console_log(`onChnageDob e.target.value:::`, e.target.value)
    const _dob = moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL)
    console_log(`onChnageDob _dob:::`, _dob)
    setDob(_dob)
  }
  const onChangeText = (e) => {
    const val = e.target.value
    const field_name = e.target.name
    console_log(`field_name::::`, field_name)
    const form_data = { ...formData }
    form_data[field_name] = val
    setFormData({ ...form_data })
  }

  const fileRef = useRef();
  const onClickFileBtn = () => {
    fileRef.current.click();
  }
  const [uploadFile, setUploadFile] = useState(null);
  const [image, setImage] = useState("")
  const onFileInputChanged = (e) => {
    setUploadFile(e.target.files[0]);
    let files = e.target.files;
    console_log(files);
    if (!empty(files)) {
      setImage(URL.createObjectURL(files[0]))
    } else {
      setImage("")
    }
  }

  const resetForm = () => {
    setFormData(defaultFormData)
    setImage("")
    setUploadFile(null)
    fileRef.current.value = ""
  }

  const onClickAddPl = async () => {
    const url = `/api/device-pl/save-pl-data`;
    const form_data = new FormData();
    form_data.append('type', type)
    form_data.append("deviceId", deviceItem.id);
    form_data.append("plDate", dob.format("YYYY-MM-DD"));

    if (formData['tripId']) {
      form_data.append("tripId", formData['tripId']);
    }
    if (formData['amount']) {
      form_data.append("amount", formData['amount']);
    } else {
      showToast("Amount is empty")
      return false
    }
    if (formData['note']) {
      form_data.append("note", formData['note']);
    }

    if (uploadFile) {
      form_data.append("upload_file", uploadFile);
    }

    setApiLoading(true)
    let response = await api_call(url, {
      method: 'POST',
      body: form_data,
    });
    setApiLoading(false)

    if (response.ok) {
      const apiData = await response.json()
      resetForm()
      setReloadTimestamp(get_utc_timestamp_ms())
    } else {
      const apiError = response
    }
  }

  const [trip, setTrip] = useState()
  const filter = createFilterOptions({
    stringify: (option) => option.uid + "   " + option.reservation_id + "   " + option.summary + "   " + option.primary_driver + "   " + option.phone + "   " + option.vin,
  });

  const [options, setOptions] = useState([]);
  const initOptions = () => {
    let v_options = []
    tripList.map((definition) => {
      v_options.push({
        key: definition.id,
        // uid: definition.uid,
        // reservation_id: definition.reservation_id,
        // summary: definition.summary,
        // primary_driver: definition.primary_driver,
        // phone: definition.phone,
        // vin: definition.vin,
        // start: definition.start,
        // end: definition.end,
        ...definition
      })
    });
    //console.log("tripList:::", tripList)
    //console.log("v_options:::", v_options)
    setOptions(v_options)
  }

  useEffect(() => {
    initOptions()
  }, [tripList.length])

  const getTuroSummaryLabel = (tripInfo, returnType = "text") => {
    //console_log(`tripInfo::::`, tripInfo)
    const { summaryFirstWord, summaryLastWord } = getTuroSummaryFirstLastWord(tripInfo)
    const start_timestamp = tripInfo.start_timestamp
    const start_time = start_timestamp ? moment.unix(start_timestamp).format("YYYY-MM-DD HH:mm") : ""
    const end_timestamp = tripInfo.end_timestamp
    const end_time = end_timestamp ? moment.unix(end_timestamp).format("YYYY-MM-DD HH:mm") : ""
    const label = `${summaryFirstWord} - ${summaryLastWord} \n${start_time} - ${end_time}`
    if (returnType === "object") {
      return (
        <div className={usedTripIds.includes(tripInfo.id) ? 'text-opacity-54' : ''}>
          <div>{`${summaryFirstWord} - ${summaryLastWord}`}</div>
          <div>{`${start_time} - ${end_time}`}</div>
        </div>
      )
    } else {
      if (typeof tripInfo === 'object') {
        return label
      } else {
        return tripInfo
      }
    }
  }

  const onChangeTrip = (option) => {
    console_log(`onChangeTrip, option:::`, option)
    setTrip(option);

    const form_data = { ...formData }
    form_data['tripId'] = option?.id ?? ""
    setFormData({ ...form_data })
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={matchDownMD ? 1 : 1}>
          <Grid item xs={matchDownMD ? 12 : null}>
            <FormControl variant="filled" fullWidth>
              <InputLabel shrink>Date</InputLabel>
              <TextField
                variant="filled"
                label=""
                type="date"
                value={dob.format("YYYY-MM-DD")}
                onChange={(e) => onChnageDob(e)}
                fullWidth
                style={{ width: matchDownMD ? '100%' : '160px' }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={matchDownMD ? 12 : null}>
            {
              (tripList && tripList.length > 0) ? (
                <>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel shrink>Trip</InputLabel>
                    <Autocomplete
                      fullWidth={true}
                      onChange={(_, option) => {
                        console.log("option::::", option)
                        // setKey(option && typeof option === 'object' ? option.key : option);
                        onChangeTrip(option)
                        // if (option && option.type) {
                        //   setType(option.type);
                        // }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        // if (params.inputValue) {
                        //   filtered.push({
                        //     // key: params.inputValue,
                        //     name: params.inputValue,
                        //   });
                        // }
                        return filtered;
                      }}
                      options={options}
                      getOptionLabel={(option) => getTuroSummaryLabel(option, "text")}
                      renderOption={(option) => getTuroSummaryLabel(option, "object")}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          type="text"
                          name="tripId"
                          onChange={(e) => onChangeText(e)}
                          style={{ minWidth: '190px' }}
                        />
                      )}
                    />
                  </FormControl>
                </>
              ) : (
                <>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel shrink>Trip</InputLabel>
                    <TextField
                      variant="filled"
                      label=""
                      type="number"
                      name="tripId"
                      placeholder=""
                      value={get_data_value(formData, 'tripId')}
                      onChange={(e) => onChangeText(e)}
                      style={{ minWidth: '190px' }}
                      fullWidth
                    />
                  </FormControl>
                </>
              )
            }

          </Grid>
          <Grid item xs={matchDownMD ? 12 : null}>
            <FormControl variant="filled" fullWidth>
              <InputLabel shrink>Amount</InputLabel>
              <TextField
                variant="filled"
                label=""
                type="number"
                name="amount"
                placeholder=""
                value={get_data_value(formData, 'amount')}
                onChange={(e) => onChangeText(e)}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={matchDownMD ? 12 : null}>
            <Stack style={{ width: '100%' }} flexDirection={`row`} justifyContent={`flex-start`}>
              <Box sx={{ flex: 1 }}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel shrink>Notes</InputLabel>
                  <TextField
                    variant="filled"
                    label=""
                    type="text"
                    name="note"
                    placeholder=""
                    value={get_data_value(formData, 'note')}
                    onChange={(e) => onChangeText(e)}
                    fullWidth
                  />
                </FormControl>
              </Box>
              <Box sx={{ pl: 1 }}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel shrink><span style={{ visibility: 'hidden' }}>H</span></InputLabel>
                  <Box sx={{ pt: 1 }}>
                    <ButtonBase disableRipple={true} onClick={() => onClickFileBtn()}>
                      <PhotoCameraIcon fontSize="large" />
                    </ButtonBase>
                    <div className='hidden d-none'>
                      <input
                        ref={fileRef}
                        type="file"
                        name="upload_file"
                        className="file-upload-default upload_file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(e) => onFileInputChanged(e)}
                      />
                    </div>
                  </Box>
                </FormControl>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={matchDownMD ? 12 : null}>
            <FormControl variant="filled" fullWidth>
              <InputLabel shrink><span style={{ visibility: 'hidden' }}>H</span></InputLabel>
              <Box sx={{ pt: 0 }}>
                <Button type="button" color="primary" variant="contained" size="small" disabled={apiLoading} fullWidth onClick={() => onClickAddPl()}>Add</Button>
              </Box>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const IncomeExpenseTable = (props) => {
  const dispatch = useDispatch();
  const { deviceId, deviceItem, showToast, type, plList, pageLoading, setPageLoading, reloadTimestamp, setReloadTimestamp, imageModalOpen, setImageModalOpen, currentImage,
    setCurrentImage } = props;
  const user = useSelector((state) => state.session.user);
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const [apiLoading, setApiLoading] = useState(pageLoading)

  const onClickDeleteBtn = async (item) => {
    if (window.confirm("Are you sure you want to delete?")) {
      if (item) {
        const payload = {
          id: item.id
        }
        setApiLoading(true)
        const url = `/api/device-pl/delete-pl`;
        let response = await api_call(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
        setApiLoading(false)

        if (response.ok) {
          const res = await response.json()
          setReloadTimestamp(get_utc_timestamp_ms())
        }
      }
    }
  }

  const onClickNote = (item) => {
    console_log(`item:::`, item)
    if (item?.picture && item?.picture_preview) {
      setCurrentImage(item.picture_preview)
      setImageModalOpen(true)
    }
  }
  return (
    <Box sx={{ width: '100%' }}>
      {
        (plList) ? (
          <Grid container spacing={matchDownMD ? 3 : 0}>
            {
              plList.map((item, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Box sx={{ width: '100%' }}>
                      <Grid container spacing={matchDownMD ? 3 : 1}>
                        <Grid item xs={matchDownMD ? 12 : null}>
                          <FormControl variant="filled" fullWidth style={{ marginTop: 0, marginBottom: 0 }}>
                            <InputLabel shrink style={{ display: (matchDownMD || index === 0) ? 'block' : 'none' }}>Date</InputLabel>
                            <TextField
                              variant="filled"
                              label=""
                              type="date"
                              value={moment.utc(get_data_value(item, 'plDate')).format('YYYY-MM-DD')}
                              //onChange={(e) => onChnageDob(e)}
                              fullWidth
                              inputProps={{
                                readOnly: true
                              }}
                              style={{ width: matchDownMD ? '100%' : '160px' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={matchDownMD ? 12 : null}>
                          <FormControl variant="filled" fullWidth style={{ marginTop: 0, marginBottom: 0 }}>
                            <InputLabel shrink style={{ display: (matchDownMD || index === 0) ? 'block' : 'none' }}>Trip</InputLabel>
                            <TextField
                              variant="filled"
                              label=""
                              type="number"
                              name="tripId"
                              placeholder=""
                              value={get_data_value(item, 'tripId')}
                              //onChange={(e) => onChangeText(e)}
                              fullWidth
                              inputProps={{
                                readOnly: true
                              }}
                              style={{ minWidth: '190px' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={matchDownMD ? 12 : null}>
                          <FormControl variant="filled" fullWidth style={{ marginTop: 0, marginBottom: 0 }}>
                            <InputLabel shrink style={{ display: (matchDownMD || index === 0) ? 'block' : 'none' }}>Amount</InputLabel>
                            <TextField
                              variant="filled"
                              label=""
                              type="number"
                              name="amount"
                              placeholder=""
                              value={get_data_value(item, 'amount')}
                              //onChange={(e) => onChangeText(e)}
                              fullWidth
                              inputProps={{
                                readOnly: true
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={matchDownMD ? 12 : null}>
                          <Stack style={{ width: '100%' }} flexDirection={`row`} justifyContent={`flex-start`}>
                            <Box sx={{ flex: 1 }}>
                              <FormControl variant="filled" fullWidth style={{ marginTop: 0, marginBottom: 0 }}>
                                <InputLabel shrink style={{ display: (matchDownMD || index === 0) ? 'block' : 'none' }}>Notes</InputLabel>
                                <TextField
                                  variant="filled"
                                  label=""
                                  type="text"
                                  name="note"
                                  placeholder=""
                                  value={get_data_value(item, 'note')}
                                  //onChange={(e) => onChangeText(e)}
                                  fullWidth
                                  inputProps={{
                                    readOnly: true,
                                    style: get_data_value(item, 'picture') ? { cursor: 'pointer' } : {},
                                  }}
                                  onClick={() => {
                                    onClickNote(item)
                                  }}
                                />
                              </FormControl>
                            </Box>
                            <Box sx={{ pl: 1 }}>
                              <FormControl variant="filled" fullWidth>
                                <InputLabel shrink style={{ display: (matchDownMD || index === 0) ? 'block' : 'none' }}><span style={{ visibility: 'hidden' }}>H</span></InputLabel>
                                <Box sx={{ pt: 1 }}>
                                  <ButtonBase disableRipple={true} disabled={apiLoading} onClick={() => onClickDeleteBtn(item)}>
                                    <CloseIcon fontSize="large" color="error" />
                                  </ButtonBase>
                                </Box>
                              </FormControl>
                            </Box>

                          </Stack>
                        </Grid>
                      </Grid>

                      {
                        (matchDownMD || index === plList.length - 1) ? (
                          <>
                            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
                          </>
                        ) : (
                          <></>
                        )
                      }
                    </Box>
                  </Grid>
                )
              })
            }
          </Grid>
        ) : (
          <></>
        )
      }
    </Box>
  )
}

const IncomeExpenseBlock = (props) => {
  const dispatch = useDispatch();
  const { open, setOpen, deviceId, deviceItem, showToast, showPlChartModal, setShowPlChartModal } = props;
  const user = useSelector((state) => state.session.user);
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const [reloadTimestamp, setReloadTimestamp] = useState(0)
  const [apiLoading, setApiLoading] = useState(false)
  const [pageData, setPageData] = useState({})
  const [tripList, setTripList] = useState([])

  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState("")

  const loadPageData = async () => {
    setApiLoading(true)
    const url = `/api/device-pl/get-pl-data?deviceId=${deviceId}`
    const response = await api_call(url);
    setApiLoading(false)
    if (response.ok) {
      const res = await response.json();
      setPageData(res)
      setTripList(res.turoTripList)
    }
    else {
      //todo
    }
  }

  useEffect(() => {
    loadPageData()
  }, [reloadTimestamp])

  const showReportChart = () => {
    setShowPlChartModal(true)
    setOpen(false)
  }

  return (
    <>
      <Box sx={{ padding: '12px', maxHeight: 'calc(100vh - 210px)', overflowY: 'auto', overflowX: 'hidden' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction={`row`} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" component="h2">{deviceItem.name}</Typography>
              <ButtonBase disableRipple={true} onClick={() => showReportChart()}>
                <img src="/images/bar-chart.png" alt="icon" width="44" />
              </ButtonBase>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack direction={`row`} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6" component="h2">{`Income`}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <IncomeExpenseTable
                  {...props}
                  type="income"
                  plList={pageData?.plData?.incomeList}
                  pageLoading={apiLoading}
                  setPageLoading={setApiLoading}
                  reloadTimestamp={reloadTimestamp}
                  setReloadTimestamp={setReloadTimestamp}
                  imageModalOpen={imageModalOpen}
                  setImageModalOpen={setImageModalOpen}
                  currentImage={currentImage}
                  setCurrentImage={setCurrentImage}
                />
              </Grid>

              <Grid item xs={12}>
                <IncomeExpenseAddForm
                  {...props}
                  type="income"
                  pageLoading={apiLoading}
                  setPageLoading={setApiLoading}
                  reloadTimestamp={reloadTimestamp}
                  setReloadTimestamp={setReloadTimestamp}
                  tripList={tripList}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack direction={`row`} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="h6" component="h2">{`Expense`}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <IncomeExpenseTable
                  {...props}
                  type="expense"
                  plList={pageData?.plData?.expenseList}
                  pageLoading={apiLoading}
                  setPageLoading={setApiLoading}
                  reloadTimestamp={reloadTimestamp}
                  setReloadTimestamp={setReloadTimestamp}
                  imageModalOpen={imageModalOpen}
                  setImageModalOpen={setImageModalOpen}
                  currentImage={currentImage}
                  setCurrentImage={setCurrentImage}
                />
              </Grid>

              <Grid item xs={12}>
                <IncomeExpenseAddForm
                  {...props}
                  type="expense"
                  pageLoading={apiLoading}
                  setPageLoading={setApiLoading}
                  reloadTimestamp={reloadTimestamp}
                  setReloadTimestamp={setReloadTimestamp}
                  tripList={tripList}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {imageModalOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setImageModalOpen(false)}
        />
      )}

    </>
  )
}

export default IncomeExpenseBlock;
