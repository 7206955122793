import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  makeStyles, Button,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  formatDate
} from '../common/formatter';
import { api_call, isGpsMT3VProtocolDevice, is_null, removeItemFromArray } from '../helpers/untils';
import BtCommandSendDialog from './BtCommandSendDialog';
import ShockAlamDialog from './ShockAlamDialog';
import { useRef } from 'react';
import { sleep } from '../helpers/misc';

const CommandSendDialogWrapper = (props) => {
  const { onClose, selectedValue, open, commandsDetail, setCommandsDetail, history, coHostDeviceDetail, setCoHostDeviceDetail, device, userInfo, deviceId } = props;

  const timerStatusRef = useRef(false)

  useEffect(() => {
    startCommandDetailTimer()
    return () => stopCommandDetailTimer();
  }, [open]);

  useEffect(() => {
    runLoadCommandDetail()
  }, []);

  const startCommandDetailTimer = () => {
    if (open) {
      timerStatusRef.current = true
    } else {
      timerStatusRef.current = false
    }
  }

  const stopCommandDetailTimer = () => {
    timerStatusRef.current = false
  }

  const runLoadCommandDetail = async () => {
    while (true) {
      if (timerStatusRef.current) {
        await loadCommandDetail()
      }
      await sleep(1000)
    }
  }

  const loadCommandDetail = async () => {
    var url = `/api/userDeviceCommandsDetail/${deviceId}`;
    const response = await api_call(url);
    if (response.ok) {
      const apiRes = await response.json()
      setCommandsDetail(apiRes.commands);
      setCoHostDeviceDetail(apiRes.coHostDeviceRow)
    }
  }

  return (
    <>
      {props.children}
    </>
  );
}

export default CommandSendDialogWrapper;
