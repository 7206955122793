import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, FormControlLabel, Checkbox, Box, Link, Modal, Grid, Toolbar, Snackbar,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from './EditItemView';
import EditAttributesView from './attributes/EditAttributesView';
import SelectField from './form/SelectField';
import deviceCategories from './common/deviceCategories';
import LinkField from './form/LinkField';
import { prefixString } from './common/stringUtils';
import { useTranslation } from './LocalizationProvider';
import useDeviceAttributes from './attributes/useDeviceAttributes';
import { Button } from '@material-ui/core';
import CreditLogsModal from './settings/CreditLogsModal';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import CarTypeTab from './components/CarTypeTab';
import { api_call, console_log, empty } from './helpers/untils';
import { DEVICE_TYPE } from './config/constant';
import CarTypeTabBar from './components/CarTypeTabBar';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const SmartcarDevicePage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { id } = useParams();

  const history = useHistory()
  const location = useLocation()

  const user = useSelector((state) => state.session.user);
  const [item, setItem] = useState(
    {
      enableCycle: 0
    }
  );

  //////////////////////////////////////////////////////////////////////////////////////////////
  const [smartcarAccessToken, setSmartcarAccessToken] = useState("");
  const [smartcarRefreshToken, setSmartcarRefreshToken] = useState("");
  const [vehicleInfoList, setVehicleInfoList] = useState(null);

  useEffect(() => {
    getAccessToken();
  }, []);

  const getAccessToken = async () => {
    const params = new URLSearchParams(location.search)
    //console_log("params::::", params.get("code"))
    const accessToken = params.get("accessToken")
    setSmartcarAccessToken(accessToken)
    const refreshToken = params.get("refreshToken")
    setSmartcarRefreshToken(refreshToken)

    if (accessToken) {
      const response = await api_call(`/api/smartcar/get-all-vehicles?accessToken=${accessToken}`);
      if (response.ok) {
        const res = await response.json()
        console_log("smartcar vehicle list:::", res, item)

        const new_checked_idx = initCheckedVehicleIdxs(res['vehicle_info_list'])

        setItem({
          ...item,
          deviceType: DEVICE_TYPE.SMARTCAR,
          accessToken: accessToken,
          refreshToken: refreshToken,
          vehicle_info_list: res['vehicle_info_list'],
          vehicle_idxs: new_checked_idx,
          abi: false,
          tint_ai: false,
        })

        setVehicleInfoList(res['vehicle_info_list'])
        setCheckedVehicleIdxs(new_checked_idx);
      } else {
        const errorRes = response
        console_log("smartcar errorRes:::", errorRes)
        setToastMessage(errorRes.description ? errorRes.description : "Smartcar Api error")
        setSnackbarOpen(true);
        //history.push('/device')
      }
    } else {
      alert("Smartcar authentication error")
      setSnackbarOpen(true);
      history.push('/device')
    }
  }

  const [checkedVehicleIdxs, setCheckedVehicleIdxs] = useState([])
  const OnChangeVehicleCheckBox = (index, event) => {
    const checked = event.target.checked
    let newCheckedIdxs = [...checkedVehicleIdxs];
    if (checked) {
      if (!checkedVehicleIdxs.includes(index)) {
        newCheckedIdxs = ([...checkedVehicleIdxs, index])
      }
    } else {
      if (checkedVehicleIdxs.includes(index)) {
        const newArr = checkedVehicleIdxs.filter(val => val !== index);
        newCheckedIdxs = ([...newArr])
      }
    }
    setCheckedVehicleIdxs(newCheckedIdxs)
    setItem({
      ...item,
      vehicle_idxs: newCheckedIdxs
    })
    console_log("newCheckedIdxs:::", newCheckedIdxs)
  }
  const initCheckedVehicleIdxs = (vehicle_info_list) => {
    let new_checked_idx = []
    if (!empty(vehicle_info_list)) {
      for (let k in vehicle_info_list) {
        new_checked_idx.push(parseInt(k))
      }
    }
    console_log("new_checked_idx:::", new_checked_idx)
    return new_checked_idx
  }

  const onChangeAbiCheckbox = (event) => {
    const checked = event.target.checked
    // if(checked) {
    //   if(empty(item.vin)) {
    //     alert("please update your VIN.")
    //     return false
    //   }
    // }
    setItem({ ...item, abi: checked })
  }

  const onChangeTintAiCheckbox = (event) => {
    const checked = event.target.checked
    // if(checked) {
    //   if(empty(item.vin)) {
    //     alert("please update your VIN.")
    //     return false
    //   }
    // }
    setItem({ ...item, tint_ai: checked })
  }
  
  //////////////////////////////////////////////////////////////////////////////////////////
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        autoHideDuration={3000}
        message={toastMessage}
      />

      <EditItemView endpoint="devices" endpointParam1={DEVICE_TYPE.SMARTCAR} item={item} setItem={setItem}>
        {item
          && (
            <>
              <Accordion defaultExpanded>
                {
                  (item && item['id']) ? (<></>) : (
                    <CarTypeTabBar
                      currentPage="oem-device"
                    />
                  )
                }

                <AccordionDetails>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Typography variant="subtitle1">
                      Built In
                    </Typography>
                    {(item && item['id']) ? (
                      <Button variant="text" onClick={() => { }}>Balance:&nbsp;<span>{item.credit || '0.00'}</span></Button>
                    ) : (
                      <Button variant="text">Balance:&nbsp;<span>0.00</span></Button>
                    )}

                    <TextField
                      id="outlined-required"
                      label="Monthly ($)"
                      value={item.monthly_cost || '10.00'}
                      onChange={(event) => setItem({ ...item, monthly_cost: event.target.value })}
                      type="number"
                      inputProps={{ step: 0.01 }}
                      style={{ width: "100px" }}
                      disabled={!user.administrator && "true"}
                    />
                  </Box>
                </AccordionDetails>

                <AccordionDetails className={classes.details}>
                  <div className="vehicleListBox" style={{ marginBottom: '16px', paddingBottom: '16px', borderBottom: '1px solid rgba(0,0,0,0.12)' }}>
                    {
                      (vehicleInfoList === null) ? (
                        <div className='text-center'>Loading vehicles....</div>
                      ) : (
                        <>
                          <>
                            {(!empty(vehicleInfoList) && vehicleInfoList.length > 0) ? (
                              <>
                                <div style={{ paddingBottom: '16px' }}>
                                  <div>
                                    <FormControlLabel
                                      control={<Checkbox checked={item.abi} onChange={(event) => onChangeAbiCheckbox(event)} />}
                                      label="Share With ABI Insurance"
                                    />
                                    <Tooltip
                                      arrow
                                      title="By enabling this option you will be sharing your car position and other details with American Business Insurance."
                                    >
                                      <InfoIcon color="action" />
                                    </Tooltip>
                                  </div>
                                </div>
                                <div style={{ paddingBottom: '16px' }}>
                                  <div>
                                    <FormControlLabel
                                      control={<Checkbox checked={item.tint_ai} onChange={(event) => onChangeTintAiCheckbox(event)} />}
                                      label="Share With Tint.Ai"
                                    />
                                    <Tooltip
                                      arrow
                                      title="By enabling this, you will be sharing your car position and other information with Tint.Ai"
                                    >
                                      <InfoIcon color="action" />
                                    </Tooltip>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </>

                          <div>Vehicle list</div>
                          {(!empty(vehicleInfoList) && vehicleInfoList.length > 0) ? (
                            <>
                              <div>
                                {
                                  (vehicleInfoList.map((vehicleInfo, index) => {
                                    return (
                                      <div key={index}>
                                        <FormControlLabel
                                          control={<Checkbox checked={checkedVehicleIdxs.includes(index)} onChange={(event) => OnChangeVehicleCheckBox(index, event)} />}
                                          label={`${vehicleInfo['year']} ${vehicleInfo['make']} ${vehicleInfo['model']} ${vehicleInfo['vin']}`}
                                        />
                                      </div>
                                    )
                                  }))
                                }
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='text-center'>No vehicle found</div>
                            </>
                          )}
                        </>
                      )
                    }
                  </div>

                  {id && <FormControlLabel
                    control={<Checkbox checked={item.disabled} onChange={(event) => setItem({ ...item, disabled: event.target.checked })} />}
                    label={t('sharedDisabled')}
                    disabled={id && !user.administrator && "true"}
                  />}
                </AccordionDetails>
              </Accordion>
            </>
          )}
      </EditItemView>

    </>
  );
};

export default SmartcarDevicePage;
