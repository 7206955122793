import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Button, FormControl, Container, Checkbox, FormControlLabel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from '../store';
import EditAttributesView from '../attributes/EditAttributesView';
import useDeviceAttributes from '../attributes/useDeviceAttributes';
import useUserAttributes from '../attributes/useUserAttributes';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';
import { api_call, console_log } from '../helpers/untils';
import { layoutActions } from '../store';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '100%',
    },
  },
  details: {
    flexDirection: 'column',
  },
}));

const CachePage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTranslation();

  const user = useSelector((state) => state.session.user);


  const userAttributes = useUserAttributes(t);
  const deviceAttributes = useDeviceAttributes(t);

  const defaultItem = {
    forceLogin: false
  }
  const [item, setItem] = useState(defaultItem)

  const handleSave = async () => {
    if(user.administrator) {
      if(item['forceLogin']) {    
        dispatch(layoutActions.clearCacheWithLogin());
      }else{      
        dispatch(layoutActions.clearCache());
      }
    }    
  };

  return (
    <OptionsLayout>
      <Container className={classes.container} style={{maxWidth:'320px'}}>

        <FormControl fullWidth margin="normal">
          <div className={classes.buttons} >
            <FormControlLabel
              control={<Checkbox checked={item.forceLogin} onChange={(event) => setItem({ ...item, forceLogin: event.target.checked })} />}
              label={`Force redirect to login page`}
            />
          </div>

          <div className={classes.buttons} style={{ marginTop: '30px' }}>
            <Button type="button" color="primary" variant="contained" onClick={handleSave}>
              {`Clear cache`}
            </Button>
          </div>
        </FormControl>
      </Container>
    </OptionsLayout>
  );
};

export default CachePage;
