import React, { } from 'react';
import { useHistory } from "react-router-dom"
import {
    makeStyles,
} from '@material-ui/core';
import TemporaryUploadSection from './map/TemporaryUploadSection';
import TemporaryCameraUploadSection from './map/TemporaryCameraUploadSection';

const useStyles = makeStyles((theme) => ({
    uploadBoxContainer: {
        maxWidth: '400px',
        margin: '15px auto',
        padding: '30px',
        backgroundColor: 'rgba(127,127,127,0)',
        border: '1px solid rgba(127,127,127,0.2)',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
    },
    uploadButtonList: {
        marginBottom: '10px',
        maxHeight: 'calc(100vh - 420px)',
        overflowY: 'auto'
    },
    uploadButton: {
        width: '100%',
        border: '2px solid rgba(76, 175, 80, 1)',
        backgroundColor: 'rgba(76, 175, 80, 0.1)',
        minHeight: '52px',
        height: 'auto',
        marginBottom: '10px',
        color: 'rgba(0, 0, 0, 0.7)',
        textTransform: 'none',
        "&$selected": {
            backgroundColor: "rgba(76, 175, 80, 0.2)",
        },
        "&$selected:hover": {
            backgroundColor: "rgba(76, 175, 80, 0.2)",
        },
        "&:hover": {
            backgroundColor: "rgba(76, 175, 80, 0.2)",
        }
    },
    uploadGrayButton: {
        width: '100%',
        border: '2px solid rgb(202 202 202)',
        backgroundColor: 'rgb(234 234 234)',
        minHeight: '52px',
        height: 'auto',
        marginBottom: '10px',
        color: 'rgba(0, 0, 0, 0.7)',
        textTransform: 'none',
    },
    uploadFileInput: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        cursor: 'pointer',
        opacity: 0
    },
}));

export default function TemporaryUploadPage(props) {
    const { link, setLink, current_timestamp, type = "avail", approve_status = "", loadLinkInfo, isTest = false } = props;
    const classes = useStyles()
    const history = useHistory()

    return (
        <React.Fragment>
            <main id="content" role="main">
                <div className="d-md-flex">
                    <div className="container d-flex align-items-center" style={{ minHeight: '100vh' }}>
                        <TemporaryUploadSection
                            link={link}
                            type={type}
                            approve_status={approve_status}
                            loadLinkInfo={() => loadLinkInfo()}
                            isTest={isTest}
                        />
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}
