const year_labels = ['2024', '2023'];

export const years = year_labels.map((label, index) => ({
    label,
    id: index
}));


const month_labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const months = month_labels.map((label, index) => ({
    label,
    id: index
}));

// export default years;
  