import React, { useState } from 'react';

import {
  Button, Checkbox, FilledInput, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import AddDeviceDialog from './AddDeviceDialog';
import { useTranslation } from '../LocalizationProvider';
import { DevicesOther } from '@material-ui/icons';
import { addItemToArray, api_call, console_log, empty } from '../helpers/untils';
import { useDispatch } from 'react-redux';
import { layoutActions } from '../store';
import { useHistory } from 'react-router-dom';
import RePriceDeviceDialog from './RePriceDeviceDialog';
import { DEVICE_TYPE } from '../config/constant';

const useStyles = makeStyles((theme) => ({
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  removeButton: {
    //  marginRight: theme.spacing(1.5),
  },
  userDeviceList: {
    width: '100%',
    maxWidth: 'calc(100vw - 115px)'
  }
}));

const EditDevicesView = ({ devices, setDevices, definitions, userInfo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const t = useTranslation();
  //console_log("definitions::::::", devices, definitions);

  const [addDialogShown, setAddDialogShown] = useState(false);

  const updateDevice = (id) => {
    const updatedDevices = devices ? [...devices] : [];
    if (updatedDevices.find(item => item.id === id)) {
      return true
    }

    const key = getDeviceIndexById(id, definitions);
    const newDevice = definitions[key]
    updatedDevices.push(newDevice)
    setDevices(updatedDevices);
  }

  const deleteDevice = (id) => {
    const key = getDeviceIndexById(id, devices);
    //console.log(`id, key:::::`, id, key)
    const updatedDevices = [...devices];
    updatedDevices.splice(key, 1);  //delete updatedDevices[key];
    setDevices(updatedDevices);
  }

  const getDeviceIndexById = (id, devices) => {
    var res;
    for (let k in devices) {
      if (devices[k].id === id) {
        res = Number(k)
        return res
      }
    }
    return false
  }

  const convertToList = (devices) => {
    var deviceList = [];
    if (Array.isArray(devices)) return devices;

    Object.keys(devices || []).forEach((key) => {
      deviceList.push(devices[key]);
    });
    return deviceList;
  };

  const handleAddResult = (deviceId) => {
    setAddDialogShown(false);
    if (deviceId) updateDevice(deviceId);
  };

  const onClickDeviceItem = (deviceId) => {
    //console.log('deviceId:::',deviceId)
    dispatch(layoutActions.setCurrentDeviceId({ id: deviceId }));
    history.push('/main')
  }

  const [showRePriceModal, setShowRePriceModal] = useState(false);
  const handleSubmitPrePriceResult = (priceObj) => {
    console.log("priceObj:::", priceObj)
    if (priceObj) {
      const newPrice = priceObj['price']
      updateDevicesPrice(newPrice)
    }
    setShowRePriceModal(false)
  }
  const updateDevicesPrice = async (newPrice) => {
    const device_list = convertToList(devices)
    let devieIds = []
    for (let k in device_list) {
      devieIds.push(device_list[k]['id'])
    }
    if (devieIds.length === 0) {
      return false;
    }
    let url = `/api/devices/re-price`;
    const post_data = {
      devieIds: devieIds,
      price: newPrice
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(post_data),
    });

    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      if (responseObj['error']) {
        alert(responseObj['error'])
      }
    }
  }

  const [apiLoading, setApiLoading] = useState(false)

  const onClickAllCarToEscrow = async () => {
    if (window.confirm('Are you sure?')) {
      setApiLoading(true)
      let url = "/api/users/set-devices-billing-source"
      const payload = {
        user_id: userInfo.id,
        billing_source: 'escrow'
      }
      let response = await api_call(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      setApiLoading(false)
      if (response.ok) {
        const apiRes = await response.json()
        showToast("All Cars Moved to Escrow")
      } else {
        let errorMsg = "Invalid request"
        const errorRes = response
        if (errorRes['error']) {
          errorMsg = errorRes['error']
        }
        showToast(errorMsg)
      }
    }
  }

  const showToast = (msg) => {
    alert(msg)
  }

  return (
    <>
      <div className={classes.userDeviceList}>
        {convertToList(devices).map((device) => {
          return (
            <Grid container direction="row" justifyContent="space-between" alignItems="center" key={device.id}>
              <span
                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', width: 'calc(100% - 60px)' }}
                className={device.deviceType === DEVICE_TYPE.SMARTCAR ? "smartcar-primary-color" : device.deviceType === DEVICE_TYPE.TESLA ? "tesla-primary-color" : device.deviceType === DEVICE_TYPE.USB ? "usb-primary-color": device.deviceType === DEVICE_TYPE.OBD ? "obd-primary-color" : ""}
                onClick={() => onClickDeviceItem(device.id)}
              >
                <span
                  style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', width: 'calc(100% - 60px)', color: device.status === 'online' ? '#4CAF50' : '#CC2222' }}
                >
                  <span
                    className="text-emphasis"
                    style={{ width: '50%', paddingRight: '8px' }}
                  >
                    {device.name}
                  </span>
                  <span
                    className="text-emphasis"
                    style={{ width: '50%', paddingRight: '8px' }}
                  >
                    {!empty(device.uniqueId) ? device.uniqueId : device.vehicleId}
                  </span>
                </span>
                <span
                  className="text-emphasis"
                  style={{ width: '60px', paddingRight: '8px', color: Number(device.credit) > 0 ? '#CC2222' : '#4CAF50' }}
                >
                  {device.credit}
                </span>
              </span>
              <IconButton className={classes.removeButton} onClick={() => deleteDevice(device.id)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          )
        })}
      </div>

      <Button
        size="large"
        variant="outlined"
        color="primary"
        onClick={() => setAddDialogShown(true)}
        startIcon={<AddIcon />}
        className={classes.addButton}
      >
        {t('sharedAdd')}
      </Button>
      {
        (convertToList(devices).length > 0) ? (
          <>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={() => setShowRePriceModal(true)}
            >
              Re-Price
            </Button>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={() => onClickAllCarToEscrow()}
              disabled={apiLoading}
            >
              All Cars to Escrow
            </Button>
          </>
        ) : (
          <></>
        )
      }
      <AddDeviceDialog
        open={addDialogShown}
        onResult={handleAddResult}
        definitions={definitions}
        devices={devices}
      />

      <RePriceDeviceDialog
        open={showRePriceModal}
        onResult={handleSubmitPrePriceResult}
      />
    </>
  );
};

export default EditDevicesView;
