import React, { useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@material-ui/core';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { empty } from '../helpers/untils';

const UserAutoCompleteDropdown = (props) => {
  const { definitions = [], value, setValue, label = "Users" } = props

  const filter = createFilterOptions({
    stringify: (option) => option.email, //option.name + "   " + option.email,
  });

  // const options = Object.entries(definitions).map((definition) => ({
  //   key: definition.id,
  //   name: definition.name,
  // }));

  const options = [];
  definitions.map((definition) => {
    options.push({
      key: definition.id,
      name: definition.name,
      email: definition.email,
    })
  });

  const getDefaultValue = () => {
    return definitions.find((item) => item.id === value);
  }

  const getOptionLabel = (option) => {
    const label = option.email // !empty(option.name) ? option.name : option.email
    return label
  }

  return (
    <>
      <Autocomplete
        onChange={(_, option) => {
          console.log("_, option", _, option)
          if (option) {
            setValue(option.key);
          } else {
            setValue(null);
          }
        }}

        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return filtered;
        }}
        options={options}
        defaultValue={getDefaultValue()}
        getOptionLabel={(option) => getOptionLabel(option)}
        renderOption={(option) => getOptionLabel(option)}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={label} variant="filled" margin="normal" />
        )}
      />
    </>
  );
};

export default UserAutoCompleteDropdown;
