import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LinearProgress,
  makeStyles,
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { empty, api_call, console_log, isEmptyObject, getToken, setToken, isGpsMT2VProtocolDevice } from './helpers/untils';
import InstallerStatusView from './map/InstallerStatusView';
import { devicesActions, sessionActions } from './store';
import { trueAuthenticated } from './SocketController';
import MT2VInstallerStatusView from './map/MT2VInstallerStatusView';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  installerStatusViewWrapper: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#abd3df'
  },
}));

const InstallerMainPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const loginRoute = '/installer-login'

  const [refreshTimestamp, setRefreshTimestamp] = useState(0)

  const session = getToken();
  //console.log("session", session);
  if (!session) history.push(loginRoute);
  useEffect(() => {
    if (session) {
      getSessionDevice()
    }
  }, [refreshTimestamp]);

  const [installer, setInstaller] = useState(null)
  const [device, setDevice] = useState(null)
  const getSessionDevice = async () => {
    const response = await api_call('/api/installer-auth/' + session);
    if (response.ok) {
      const apiRes = await response.json();      
      console_log("apiRes:::::", apiRes)
      if (apiRes['error']) {
        setToken("")
        history.push(loginRoute);
      } else {
        const deviceInfo = apiRes['device']
        console_log("deviceInfo:::::", deviceInfo)
        setDevice(deviceInfo);

        const installerInfo = apiRes['installer']
        console_log("installerInfo:::::", installerInfo)
        setInstaller(installerInfo)

        refreshAll(deviceInfo.id);
      }
    } else {
      setToken("")
      history.push(loginRoute);
    }
  }

  const [authenticated, setAuthenticated] = useState(false);

  const refreshAll = async (deviceId) => {
    const user = {
      "id": 0,
      "attributes": {
      },
      "name": null,
      "login": null,
      "email": null,
      "phone": null,
      "readonly": false,
      "administrator": false,
      "map": null,
      "latitude": 0,
      "longitude": 0,
      "zoom": 10,
      "twelveHourFormat": false,
      "coordinateFormat": null,
      "disabled": false,
      "expirationTime": null,
      "deviceLimit": -1,
      "userLimit": 0,
      "deviceReadonly": false,
      "token": "",
      "limitCommands": false,
      "poiLayer": null,
      "password": null
    }
    dispatch(sessionActions.updateUser({ ...user }));

    const responseDevice = await api_call(`/api/installer-devices/${deviceId}`);
    if (responseDevice.ok) {
      const deviceInfo = await responseDevice.json();

      dispatch(devicesActions.refresh([deviceInfo]));
      dispatch(devicesActions.select({ id: deviceInfo.id }));
    }
    setAuthenticated(true);
    trueAuthenticated();
  }

  /////////////////////////////////////after login//////////////////////////////////////////////

  return (
    <div className={classes.root}>
      {
        (!empty(device) && authenticated) ? (
          <div className={classes.installerStatusViewWrapper}>
            {
              (isGpsMT2VProtocolDevice(device.uniqueId)) ? (
                <>
                  <MT2VInstallerStatusView
                    deviceInfo={device}
                    deviceId={device.id}
                    onShowDetails={(positionId) => history.push(`/position/${positionId}`)}
                    onShowHistory={(deviceId) => history.push(`/temporary-replay/${deviceId}`)}
                    onShareLink={(deviceId) => history.push(`/share/${deviceId}`)}
                    onEditClick={(deviceId) => history.push(`/device/${deviceId}`)}
                    onLogClick={(deviceId) => history.push(`/reports/event/${deviceId}`)}
                    onClickLogout={(deviceId) => history.push(`/installer-login`)}
                    refreshTimestamp={refreshTimestamp}
                    setRefreshTimestamp={setRefreshTimestamp}
                    installer={installer}
                  />
                </>
              ) : (
                <>
                  <InstallerStatusView
                    deviceInfo={device}
                    deviceId={device.id}
                    onShowDetails={(positionId) => history.push(`/position/${positionId}`)}
                    onShowHistory={(deviceId) => history.push(`/temporary-replay/${deviceId}`)}
                    onShareLink={(deviceId) => history.push(`/share/${deviceId}`)}
                    onEditClick={(deviceId) => history.push(`/device/${deviceId}`)}
                    onLogClick={(deviceId) => history.push(`/reports/event/${deviceId}`)}
                    onClickLogout={(deviceId) => history.push(`/installer-login`)}
                    refreshTimestamp={refreshTimestamp}
                    setRefreshTimestamp={setRefreshTimestamp}
                    installer={installer}
                  />
                </>
              )
            }
          </div>
        ) : (
          <>
            <LinearProgress />
          </>
        )
      }
    </div>
  )
}

export default InstallerMainPage;
