import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from "lodash"
import {
  Box,
  CircularProgress,
  IconButton,
  InputLabel,
  TextField,
  makeStyles,
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import CachedIcon from '@material-ui/icons/Cached';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';


import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import EditCollectionView from '../EditCollectionView';
import OptionsLayout from '../settings/OptionsLayout';
import { useTranslation } from '../LocalizationProvider';

import moment from 'moment';
import { api_call, console_log, empty, getDistanceFromMiles } from '../helpers/untils';
import { Link, useHistory } from 'react-router-dom';
import { layoutActions } from '../store';
import { sleep } from '../helpers/misc';
import { formatBoolean } from '../common/formatter';
import UserAutoCompleteDropdown from '../components/UserAutoCompleteDropdown';
import CreditLogsModal from '../settings/CreditLogsModal';
import { Stack } from '@mui/material';
import { DEVICE_TYPE } from '../config/constant';

const useStyles = makeStyles((theme) => ({
  tableToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  tableToolBarLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    maxWidth: '340px',
    paddingTop: '1rem',
    [theme.breakpoints.only('xs')]: {

    },
  },
  tableToolBarRight: {
    maxWidth: '300px',
    width: '100%',
    paddingTop: '1rem',
    paddingLeft: '4px',
    textAlign: 'right',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'left',
      paddingTop: '0.5rem',
    },
  },
  tableSearchText: {
    '& input.MuiInput-input': {
      paddingRight: '46px'
    }
  },
  tableSearchBtn: {
    borderRadius: 0,
    marginLeft: '-46px'
  }
}));

const DevicesView = (props) => {
  const { updateTimestamp, onMenuClick } = props
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory()
  const user = useSelector((state) => state.session.user);

  const dataRef = useRef([])

  const columnDefs = [
    {
      name: 'id',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className="">
              <IconButton onClick={(event) => onMenuClick(event.currentTarget, value)}>
                <MoreVertIcon />
              </IconButton>
            </div>
          );
        },
        //display: false
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const row = dataRef.current[dataIndex];
          const value = row?.name;
          //console.log('dataIndex, row, value::::', dataIndex, dataRef.current, row, value)
          return (
            <div className='td-div'><span className={row?.deviceType === DEVICE_TYPE.SMARTCAR ? "smartcar-primary-color" : row?.deviceType === DEVICE_TYPE.TESLA ? "tesla-primary-color" : row?.deviceType === DEVICE_TYPE.OBD ? "obd-primary-color" : row?.deviceType === DEVICE_TYPE.USB ? "usb-primary-color" : "normal-primary-color"}>{value}</span></div>
          )
        }
      },
    },
    {
      name: 'uniqueId',
      label: 'GPS ID',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{value}</div>
          );
        }
      },
    },
    {
      name: 'iccid',
      label: 'ICCID',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{value}</div>
          );
        }
      },
    },
    {
      name: 'vin',
      label: 'VIN',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div text-emphasis' style={{ maxWidth: '200px' }}>{value}</div>
          );
        }
      },
    },
    // {
    //   name: 'make',
    //   label: 'MAKE',
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       //console.log('customBodyRender', value, tableMeta, updateValue);
    //       return (
    //         <div className='td-div'>{value}</div>
    //       );
    //     }
    //   },
    // },
    // {
    //   name: 'model',
    //   label: 'MODEL',
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       //console.log('customBodyRender', value, tableMeta, updateValue);
    //       return (
    //         <div className='td-div'>{value}</div>
    //       );
    //     }
    //   },
    // },
    {
      name: 'odometer',
      label: 'Odometer',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const row = dataRef.current[dataIndex];
          const value = row?.odometer;
          //console.log('dataIndex, row, value::::', dataIndex, dataRef.current, row, value)
          return (
            <div className='td-div'>{getDistanceFromMiles(value, row?.distance_unit)}</div>
          )
        }
      },
    },
    {
      name: 'disabled',
      label: 'Disabled',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{formatBoolean(value, t)}</div>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{value === 'online' ? 'online' : 'offline'}</div>
          );
        },
      },
    },
    {
      name: 'credit',
      label: 'Balance',
      options: {
        sort: true,
        customBodyRenderLite: (dataIndex) => {
          const row = dataRef.current[dataIndex];
          const value = row?.credit;
          //console.log('dataIndex, row, value::::', dataIndex, dataRef.current, row, value)
          return (
            <div className='td-div cursor-pointer' onClick={() => showCreditLogsPage(row)}>{value}</div>
          )
        }
      },
    }
  ]

  const [page, setPage] = useState(0)
  const [count, setCount] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [sortOrder, setSortOrder] = useState({ name: "id", direction: "asc" })
  const [data, setData] = useState([])
  const [columns, setColumns] = useState(columnDefs)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState()

  const [searchText, setSearchText] = useState("");
  const onChangeSearchText = (e) => {
    const filter = e.target.value
    setSearchText(filter)
    if (filter === "") {
      onSubmitChangeSearchText(filter)
    }
  }

  // mock async function
  const xhrRequest = async (page, rowsPerPage, sortOrder = {}, filter_options = {}) => {
    if (empty(user)) {
      return false
    }

    setIsLoading(true);
    let api_url = "/api/devices/get-data-table"
    let post_data = {}
    post_data['page'] = page
    post_data['rowsPerPage'] = rowsPerPage
    post_data['sortOrder'] = sortOrder

    const filterOptions = {
      ...filter_options
    }
    if (user.administrator) {
      filterOptions['userId'] = selectedUserId
    } else {
      filterOptions['userId'] = user.id
    }

    const response = await api_call(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...post_data, ...filterOptions }),
    });
    if (response.ok) {
      const res = await response.json();
      setData(res.data)
      dataRef.current = res.data
      setPage(res.page)
      setSortOrder(sortOrder)
      setIsLoading(false)
      setCount(res.total)
      return res
    } else {
      return false
    }
  }

  const sort = async (page, sortOrder) => {
    const filterOptions = {
      search: searchText
    }
    await xhrRequest(page, rowsPerPage, sortOrder, filterOptions);
  }

  const changePage = async (page, sortOrder) => {
    const filterOptions = {
      search: searchText
    }
    await xhrRequest(page, rowsPerPage, sortOrder, filterOptions)
  }

  const onChangeRowsPerPage = (r) => {
    setRowsPerPage(r)
    setPage(0)
    const filterOptions = {
      search: searchText
    }
    xhrRequest(0, r, sortOrder, filterOptions)
  }

  const onSubmitChangeSearchText = async (text) => {
    setPage(0)
    const filterOptions = {
      search: text
    }
    await xhrRequest(0, rowsPerPage, sortOrder, filterOptions)
  }

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log("handleSearchSubmit:::")
    onSubmitChangeSearchText(searchText)
  }

  useEffect(() => {
    const filterOptions = {
      search: searchText
    }
    xhrRequest(0, rowsPerPage, sortOrder, filterOptions)
  }, [selectedUserId, updateTimestamp]);

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive: 'standard',
    serverSide: true,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    onChangeRowsPerPage: onChangeRowsPerPage,
    sortOrder: sortOrder,
    download: false,
    viewColumns: false,
    print: false,
    search: false,
    selectableRowsHideCheckboxes: true,

    onTableChange: (action, tableState) => {
      // console.log("action, tableState:::", action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want
      switch (action) {
        case 'changePage':
          changePage(tableState.page, tableState.sortOrder);
          break;
        case 'sort':
          sort(tableState.page, tableState.sortOrder);
          break;
        default:
        //console.log('action not handled.');
      }
    },
  }

  //////////////////////// load page data (user list) /////////////////////////////
  useEffect(() => {
    loadPageData()
  }, []);

  const [userList, setUserList] = useState([])
  const loadPageData = async () => {
    setIsLoading(true);
    let api_url = "/api/user-name-list"
    const response = await api_call(api_url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const res = await response.json();
      //console.log("res:::", res)
      setUserList(res)
      return res
    } else {
      return false
    }
  }
  ////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////credit log modal////////////////////////////////
  const [deviceItem, setDeviceItem] = useState({});
  const showCreditLogsPage = (item) => {
    console.log("item:::", item)
    setDeviceItem(item);
    setCreditModalOpen(true)
  }
  const [creditModalOpen, setCreditModalOpen] = useState(false);
  ////////////////////////////////////////////////////////////////////////////////

  return (
    <div>
      <div className="mt-3 custom-data-table-container">
        <MUIDataTable
          title={
            <Box className={classes.tableToolBar}>
              <div className={classes.tableToolBarLeft}>
                <div style={{ width: '100%' }}>
                  {
                    (userList.length > 0) ? (
                      <>
                        <UserAutoCompleteDropdown
                          label={`Search by Email`}
                          definitions={userList}
                          value={selectedUserId}
                          setValue={setSelectedUserId}
                        />
                      </>
                    ) : (
                      <></>
                    )
                  }
                </div>
                {<CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 5, visibility: isLoading ? 'visible' : 'hidden' }} />}
              </div>

              <div className={classes.tableToolBarRight}>
                <form onSubmit={handleSearchSubmit}>
                  <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={0}>
                    <TextField
                      className={classes.tableSearchText}
                      fullWidth
                      value={searchText}
                      onChange={(e) => onChangeSearchText(e)}
                      label={`Search...`}
                      inputProps={{ type: 'text' }}
                    />
                    <IconButton size={`medium`} className={classes.tableSearchBtn} type="submit">
                      <SearchIcon />
                    </IconButton>
                  </Stack>
                </form>
              </div>
            </Box>
          }
          data={data}
          columns={columns}
          options={options}
          isLoading={isLoading}
        />
      </div>

      <CreditLogsModal
        open={creditModalOpen}
        setOpen={setCreditModalOpen}
        item={deviceItem}
        setItem={setDeviceItem}
      />

    </div>
  )

}

const DevicesPage = () => {
  return (
    <>
      <OptionsLayout>
        <EditCollectionView content={DevicesView} editPath="/device" endpoint="devices" />
      </OptionsLayout>
    </>
  );
};

export default DevicesPage;
